/* eslint-disable no-unused-expressions */
import React, { useEffect, useState } from 'react';
import { Card } from 'react-bootstrap';
import Button from '@mui/material/Button';
import Badge from '@mui/material/Badge';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import AddIcon from '@mui/icons-material/Add';
import FilterIcon from '@mui/icons-material/FilterList';
import SortIcon from '@mui/icons-material/UnfoldMore';
import { useNavigate, Link, useLocation } from 'react-router-dom';
import Profile from '../../../components/profile/profile';
import Search from '../../../components/filter/Search';
import FilterModal from '../../../components/modal/FilterModal';
import Pencairan from './pencairan/index';
import Penyaluran from './penyaluran/index';
import excelIcon from '../../../assets/images/icons/excelIcon.svg';
import { toastSuccess } from '../../../components/toast';
import { exportPenggunaan } from '../../../services/infaq';

function index() {
  const styledButton = {
    textTransform: 'capitalize',
    color: '#4F5867',
    background: '#fff',
    border: '1px solid #e4e4e4',
    borderRadius: '8px',
    boxShadow: 'none',
  };
  const styleTextTabs = {
    textTransform: 'capitalize',
    fontFamily: 'BlueberrySans-Regular',
    fontSize: '14px',
  };
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const initialActiveTab = searchParams.get('activeTab') || 'pencairan';

  const [activeTab, setActiveTab] = useState(initialActiveTab);
  const [searchKeyword, setSearchKeyword] = useState('');
  const [showModalFilter, setShowModalFilter] = useState(false);
  const [sorting, setSorting] = useState('desc');
  const [filterPenyaluran, setFilterPenyaluran] = useState({ endDate: '', startDate: '' });
  const [filterPencairan, setFilterPencairan] = useState({ status: '', endDate: '', startDate: '' });
  const [filterParam, setFilterParam] = useState({});
  const [isOnFilter, setIsOnFilter] = useState(false);
  const [paramExport, setParamExport] = useState(null);

  // handle active tab
  useEffect(() => {
    const newActiveTab = searchParams.get('activeTab') || 'pencairan';
    setActiveTab(newActiveTab);
  }, [location.search]);

  const optionsFilter = [
    { value: '', label: 'Semua Status' },
    { value: 'ditinjau', label: 'Ditinjau' },
    { value: 'selesai', label: 'Selesai' },
  ];

  const handleFilter = (selectedOption, secondSelectedOption, startDate, endDate) => {
    if (activeTab === 'pencairan') {
      const filterData = {};
      if (selectedOption && selectedOption.value !== undefined) {
        filterData.status = selectedOption.value;
      } else {
        filterData.status = '';
      }
      const end = new Date(endDate);
      const start = new Date(startDate);

      if (startDate !== null && endDate !== null) {
        const rangeStart = start.toLocaleDateString().split('/');
        const rangeEnd = end.toLocaleDateString().split('/');
        filterData.startDate = (`${rangeStart[2]}/${rangeStart[0]}/${rangeStart[1]}`);
        filterData.endDate = (`${rangeEnd[2]}/${rangeEnd[0]}/${rangeEnd[1]}`);
      } else {
        filterData.startDate = '';
        filterData.endDate = '';
      }

      setFilterPencairan(filterData);
      setFilterParam({
        selectValue: filterData?.status,
        startDate: filterData?.startDate,
        endDate: filterData?.endDate,
      });
      setShowModalFilter(false);
      setIsOnFilter(true);
      if (!filterData.status && !filterData.startDate && !filterData.endDate) {
        setIsOnFilter(false);
      }
    } else {
      const filterData = {};
      const end = new Date(endDate);
      const start = new Date(startDate);

      if (startDate !== null && endDate !== null) {
        const rangeStart = start.toLocaleDateString().split('/');
        const rangeEnd = end.toLocaleDateString().split('/');
        filterData.startDate = (`${rangeStart[2]}/${rangeStart[0]}/${rangeStart[1]}`);
        filterData.endDate = (`${rangeEnd[2]}/${rangeEnd[0]}/${rangeEnd[1]}`);
      } else {
        filterData.startDate = '';
        filterData.endDate = '';
      }

      setFilterPenyaluran(filterData);
      setFilterParam({
        selectValue: null,
        startDate: filterData?.startDate,
        endDate: filterData?.endDate,
      });
      setShowModalFilter(false);
      setIsOnFilter(true);
      if (!filterData.status && !filterData.startDate && !filterData.endDate) {
        setIsOnFilter(false);
      }
    }
  };

  const handleReset = () => {
    if (activeTab === 'pencairan') {
      setFilterPencairan({ status: '', endDate: '', startDate: '' });
    } else {
      setFilterPenyaluran({ endDate: '', startDate: '' });
    }

    setFilterParam({
      selectValue: '',
      startDate: null,
      endDate: null,
    });
    setShowModalFilter(false);
    setIsOnFilter(false);
  };

  const handleSort = () => {
    if (sorting === 'desc') {
      setSorting('asc');
    } else {
      setSorting('desc');
    }
  };
  useEffect(() => {
    const isEditedPenyaluran = localStorage.getItem('isEditedPenyaluran');
    if (isEditedPenyaluran) {
      setActiveTab('penyaluran');
      toastSuccess('Data Berhasil Diedit');
      localStorage.removeItem('isEditedPenyaluran');
    }
    const isAddedPenyaluran = localStorage.getItem('isPenyaluranAdded');
    if (isAddedPenyaluran) {
      setActiveTab('penyaluran');
      toastSuccess('Data Berhasil Disimpan');
      localStorage.removeItem('isPenyaluranAdded');
    }

    const isAddedPencairan = localStorage.getItem('isPencairanAdded');
    const isEditedPencairan = localStorage.getItem('isEditedPencairan');
    if (isEditedPencairan) {
      toastSuccess('Data Berhasil Diedit');
      localStorage.removeItem('isEditedPencairan');
    }
    if (isAddedPencairan) {
      toastSuccess('Data Berhasil Disimpan');
      localStorage.removeItem('isPencairanAdded');
    }
  }, []);
  const handleExport = async () => {
    const dataPenggunaanInfaq = paramExport;
    const type = activeTab === 'pencairan' ? 'disbursement' : 'distribution';
    const formData = new FormData();
    formData.append('deleted', dataPenggunaanInfaq.deleted);
    formData.append('draw', dataPenggunaanInfaq.draw);
    formData.append('id', dataPenggunaanInfaq.id);
    formData.append('length', dataPenggunaanInfaq.length);
    formData.append('page', dataPenggunaanInfaq.page);
    formData.append('rangeEnd', dataPenggunaanInfaq.rangeEnd);
    formData.append('rangeStart', dataPenggunaanInfaq.rangeStart);
    formData.append('searchText', dataPenggunaanInfaq.searchText);
    formData.append('sort', dataPenggunaanInfaq.sort);
    formData.append('start', dataPenggunaanInfaq.start);
    formData.append('status', dataPenggunaanInfaq.status);
    const response = await exportPenggunaan(formData, type);
    return response;
  };
  return (
    <div className="content-container-side">
      <div className="d-flex flex-wrap">
        <p className="content-title mt-3">Infaq</p>
        <div className="ms-auto">
          <div className="d-flex flex-wrap justify-content-end align-items-center">
            <Profile label="Ridwan Fajar Widodo" />
          </div>
        </div>
      </div>
      <Card className="card-datatable">
        <div className="d-flex flex-wrap align-items-center">
          <p className="card-datatable-title">Database Penggunaan Dana</p>
          <div className="ms-auto">
            <div className="d-flex flex-wrap justify-content-end align-items-center">
              <Search
                name="search"
                type="text"
                placeholder="Masukkan kata kunci"
                style={{ marginRight: 10 }}
                mt="3 mt-sm-0"
                value={searchKeyword}
                onChange={(event) => setSearchKeyword(event.target.value)}
              />
              <div className="mt-3 mt-sm-0 me-2">
                {isOnFilter ? (
                  <Badge variant="dot" color="warning">
                    <Button
                      onClick={() => setShowModalFilter(true)}
                      variant="contained"
                      aria-label="filter"
                      aria-controls="long-menu"
                      aria-haspopup="true"
                      size="medium"
                      sx={{
                        border: '1px solid #2EC4B6 !important',
                      }}
                      style={styledButton}
                      endIcon={<FilterIcon />}
                    >
                      Filter
                    </Button>
                  </Badge>
                ) : (
                  <Button
                    onClick={() => setShowModalFilter(true)}
                    variant="contained"
                    aria-label="filter"
                    aria-controls="long-menu"
                    aria-haspopup="true"
                    size="medium"
                    style={styledButton}
                    endIcon={<FilterIcon />}
                  >
                    Filter
                  </Button>
                )}
              </div>
              <Button
                variant="contained"
                aria-label="filter"
                aria-controls="long-menu"
                aria-haspopup="true"
                style={styledButton}
                size="medium"
                onClick={handleSort}
                endIcon={<SortIcon />}
              >
                Sort
              </Button>
              <Button
                onClick={() => handleExport()}
                variant="outlined"
                aria-label="add"
                size="medium"
                style={{
                  textAlign: 'center',
                  padding: '0.5em 1em',
                  borderRadius: '8px',
                  display: 'flex',
                  alignItems: 'center',
                  marginLeft: '0.5rem',
                  color: '#2EC4B6',
                }}
                className="ms-2 mt-3 mt-sm-0 export-btn"
              >
                <i className="me-1">
                  <img src={excelIcon} alt="excel export" />
                </i>
                Export
              </Button>
              <Button
                onClick={() => {
                  if (activeTab === 'pencairan') {
                    navigate('/infaq/penggunaan/pencairan/add');
                  } else {
                    navigate('/infaq/penggunaan/penyaluran/add');
                  }
                }}
                variant="contained"
                aria-label="add"
                size="medium"
                style={{
                  textAlign: 'center',
                  padding: '0.5em 1em',
                  borderRadius: '8px',
                  display: 'flex',
                  alignItems: 'center',
                  marginLeft: '0.5rem',
                  background: '#2ec4b6',
                }}
                className="ms-2 mt-3 mt-sm-0"
              >
                <AddIcon
                  style={{
                    fontSize: 16,
                    marginRight: 5,
                  }}
                />
                Tambah
              </Button>
            </div>
          </div>
        </div>
        <Tabs
          value={activeTab}
          variant="scrollable"
          scrollButtons="auto"
          onChange={(event, newValue) => {
            setActiveTab(newValue);
            setIsOnFilter(false);
          }}
        >
          <Tab
            label="Pencairan Dana"
            value="pencairan"
            style={styleTextTabs}
            component={Link}
            to="/infaq/penggunaan?activeTab=pencairan"
          />
          <Tab
            label="Penyaluran Dana"
            value="penyaluran"
            style={styleTextTabs}
            component={Link}
            to="/infaq/penggunaan?activeTab=penyaluran"
          />
        </Tabs>
        {activeTab === 'pencairan' ? <Pencairan searchKeyword={searchKeyword} sort={sorting} filter={filterPencairan} paramExport={paramExport} setParamExport={setParamExport} /> : <Penyaluran searchKeyword={searchKeyword} sort={sorting} filter={filterPenyaluran} setParamExport={setParamExport} />}
      </Card>

      <FilterModal
        show={showModalFilter}
        onHide={() => setShowModalFilter(false)}
        titleSelect="Status"
        placeholderSelect="Pilih Status"
        confirmTitle="Terapkan"
        cancelTitle="Batal"
        withDateRange
        select={activeTab === 'pencairan'}
        paramFilter={filterParam}
        selectOptions={optionsFilter}
        handleFilter={handleFilter}
        handleReset={handleReset}
        toggle={() => setShowModalFilter(false)}
      />
    </div>
  );
}

export default index;
