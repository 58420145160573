export function actionPending(type) {
  return {
    type,
  };
}

export function actionSuccess(type, data) {
  return {
    type,
    data,
  };
}

export function actionError(type, error) {
  return {
    type,
    error,
  };
}
