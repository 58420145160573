/* eslint-disable consistent-return */
/* eslint-disable no-unused-vars */
/* eslint-disable no-use-before-define */
/* eslint-disable no-unused-expressions */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/prop-types */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable no-promise-executor-return */
/* eslint-disable jsx-a11y/label-has-associated-control */
import {
  React, useEffect, useRef, useState,
} from 'react';
import { useParams, useNavigate, Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Card } from 'react-bootstrap';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Typography from '@mui/material/Typography';

import Input from '../../components/forms/input';
import Select from '../../components/forms/Select';
import Textarea from '../../components/forms/Textarea';
import ButtonAction from '../../components/buttons/ButtonAction';
import ConfirmationModal from '../../components/modal/ConfirmModal';
import saveIcon from '../../assets/images/icons/SaveIcon.svg';

import {
  addValues,
  delValues,
  setEditedDoaValue,
  setAddedDoaValue,
  setAction,
} from '../../stores/actions/doa/formDoa';
import DoaService from '../../stores/actions/doa';

const styleActiveBreadcrumb = {
  fontWeight: 600,
  fontSize: '12px',
  lineHeight: '14px',
  color: 'rgba(79, 88, 103, 0.7)',
  textDecoration: 'none',
  fontFamily: 'BlueberrySans-Regular',
};

const styleInactiveBreadcrumb = {
  fontWeight: 500,
  fontSize: '12px',
  lineHeight: '14px',
  color: 'rgba(79, 88, 103, 0.7)',
  textDecoration: 'none',
  fontFamily: 'BlueberrySans-Regular',
};

const DoaForm = ({ onSubmit }) => {
  const dispatch = useDispatch();
  const formikRef = useRef();
  const navigate = useNavigate();
  const [showModalClose, setShowModalClose] = useState(false);
  const [audioName, setAudioName] = useState('Upload Audio File');
  const [audioFile, setAudioFile] = useState();
  const [inputAudioColor, setInputAudioColor] = useState('');
  const [isAdded, setIsAdded] = useState(false);
  const [dataDoaForm, setDataDoaForm] = useState({});
  const [doaCategories, setDoaCategories] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const { action, id } = useParams();
  const title = action === 'edit' ? 'Edit' : 'Tambah';
  const isArabic = (string) => /^[\p{Arabic}\s\p{N}]+$/gm.test(string);
  const sleep = (ms) => new Promise((r) => setTimeout(r, ms));

  useEffect(() => {
    document.documentElement.scrollTop = 0;
    dispatch(setAction(action));
    new Promise((resolve) => {
      const param = {
        id,
      };
      if (param.id !== undefined) {
        dispatch(DoaService.datatable(param, resolve));
      }
    }).then((res) => {
      if (res) {
        setDataDoaForm(res.data[0]);
        if (res?.data[0]?.audioName !== null) {
          const audioUrl = `${process.env.REACT_APP_API_URL}api/file/doa_audio/${res?.data[0]?.audioName}`;
          const audioNameFile = res?.data[0]?.audioName;
          setAudioName(audioNameFile);
          setInputAudioColor('color-input-primary');
          setAudioFile(
            new File([audioUrl], audioNameFile, { type: 'audio/mp3' }),
          );
        }
      }
    });
  }, []);

  useEffect(() => {
    new Promise((resolve) => {
      const param = {
        page: 0,
        length: 999,
        categoryType: 'Doa',
      };
      dispatch(DoaService.dataCategories(param, resolve));
    }).then((res) => {
      if (res) {
        const categories = [];
        res?.data?.forEach((item) => {
          categories?.push({ value: item?.id, label: `${item?.categoryType} ${item?.categoryName}` });
        });
        setDoaCategories(categories);
      }
    });
  }, []);

  const initialValues = {
    doaName: dataDoaForm?.doaName ?? '',
    categoryId: dataDoaForm?.categoryId ?? '',
    arabicText: dataDoaForm?.arabicText ?? '',
    latinText: dataDoaForm?.latinText ?? '',
    translate: dataDoaForm?.translate ?? '',
    referensi: dataDoaForm?.referensi ?? '',
    audio: audioFile ?? '',
  };

  const toggleCloseModal = () => {
    setShowModalClose(false);
  };

  const validation = Yup.object().shape({
    doaName: Yup.string().required('Nama doa wajib diisi').nullable(),
    categoryId: Yup.string().required('Kategori doa wajib diisi').nullable(),
    arabicText: Yup.string()
      .test(
        'Input tidak boleh selain arab',
        'Input tidak boleh selain arab',
        (value) => !isArabic(value),
      )
      .required('Doa Arab wajib diisi')
      .nullable(),
    latinText: Yup.string().required('Transliterasi doa wajib diisi').nullable(),
    translate: Yup.string().required('Translasi doa wajib diisi').nullable(),
  });

  const isInProgress = (values) => {
    if (action === 'add') {
      if (
        values.doaName === ''
        && values.categoryId === ''
        && values.arabicText === ''
        && values.latinText === ''
        && values.translate === ''
        && values.referensi === ''
        && audioName === 'Upload Audio File'
      ) {
        setIsAdded(false);
        dispatch(setAddedDoaValue(false));
      } else {
        setIsAdded(true);
        dispatch(setAddedDoaValue(true));
      }
    } else if (action === 'edit') {
      if (
        initialValues.doaName !== values.doaName
        || initialValues.categoryId !== values.categoryId
        || initialValues.arabicText !== values.arabicText
        || initialValues.latinText !== values.latinText
        || initialValues.translate !== values.translate
        || initialValues.referensi !== values.referensi
        || initialValues.audio !== values.audio
      ) {
        // setShowModalClose(true);
        setIsAdded(true);
        dispatch(setEditedDoaValue(true));
      } else {
        // navigate('/doa');
        setIsAdded(false);
        dispatch(setEditedDoaValue(false));
      }
    }
  };

  const handleSubmit = async (values) => {
    setIsLoading(true);

    const formData = new FormData();
    formData.append('doaName', values?.doaName);
    formData.append('categoryId', values?.categoryId);
    formData.append('arabicText', values?.arabicText);
    formData.append('latinText', values?.latinText);
    formData.append('translate', values?.translate);
    formData.append('referensi', values?.referensi);
    formData.append('audioFile', values?.audio);

    const callback = () => {
      navigate('/doa');
      dispatch(setAddedDoaValue(false));
      dispatch(setEditedDoaValue(false));
    };

    if (action === 'edit') {
      formData.append('id', id);
      dispatch(DoaService.put(formData, callback));
      onSubmit(values);
      await sleep(1000);
      setIsLoading(false);
    } else {
      dispatch(DoaService.post(formData, callback));
      onSubmit(values);
      await sleep(1000);
      setIsLoading(false);
    }
  };

  // useEffect(() => {
  //   if (isAdded && window.performance.getEntriesByType('navigation')[0].type === 'reload') {
  //     setShowModalClose(true);
  //   }
  // }, [window.performance.getEntriesByType('navigation')[0].type === 'reload']);

  return (
    <div className="content-container-side">
      <div className="d-flex">
        <p className="content-title">Doa</p>
      </div>
      <div className="mb-4">
        <Breadcrumbs aria-label="breadcrumb" separator="">
          <Link
            color="inherit"
            to="/doa"
            onClick={() => {
              if (isAdded) {
                setShowModalClose(true);
              } else {
                navigate('/doa');
              }
            }}
            style={styleActiveBreadcrumb}
          >
            Doa
          </Link>
          <Typography style={styleInactiveBreadcrumb}>
            {`${title} Doa`}
          </Typography>
        </Breadcrumbs>
      </div>
      <Card className="card-datatable">
        <div className="d-flex">
          <span
            className="material-icons clickable"
            onClick={() => {
              if (isAdded) {
                setShowModalClose(true);
              } else {
                navigate('/doa');
              }
            }}
            onKeyPress={() => {
              if (isAdded) {
                setShowModalClose(true);
              } else {
                navigate('/doa');
              }
            }}
            role="button"
            tabIndex={0}
          >
            arrow_back
          </span>
          <p className="card-datatable-title ms-3">
            {`${title} Doa`}
          </p>
        </div>
        <Formik
          innerRef={formikRef}
          initialValues={initialValues}
          enableReinitialize
          validationSchema={validation}
          onSubmit={handleSubmit}
        >
          {(props) => (
            <Form>
              {/** **************** */}
              {isInProgress(props?.values)}
              {/** **************** */}
              <Input
                title="Nama Doa"
                name="doaName"
                type="text"
                errors={props?.errors}
                touched={props?.touched}
                {...props?.getFieldProps('doaName')}
              />
              <Select
                name="categoryId"
                title="Kategori"
                placeholder="Pilih Kategori Doa"
                errors={props?.errors}
                touched={props?.touched}
                isSearchable
                options={doaCategories}
                value={props?.values?.categoryId}
                onChange={(name, value) => {
                  props.setFieldValue('categoryId', value);
                }}
                onBlur={props.setFieldTouched}
              />
              <Input
                title="Arab"
                name="arabicText"
                type="text"
                errors={props?.errors}
                touched={props?.touched}
                {...props?.getFieldProps('arabicText')}
              />
              <Input
                title="Transliterasi"
                name="latinText"
                type="text"
                errors={props?.errors}
                touched={props?.touched}
                {...props?.getFieldProps('latinText')}
              />
              <Textarea
                title="Translasi"
                name="translate"
                type="text"
                errors={props?.errors}
                touched={props?.touched}
                {...props?.getFieldProps('translate')}
                rows={3}
              />
              <Textarea
                title="Referensi"
                name="referensi"
                type="text"
                errors={props?.errors}
                touched={props?.touched}
                {...props?.getFieldProps('referensi')}
                rows={3}
              />
              <Input
                title="Audio"
                name="audio"
                filename={audioName}
                inputColor={inputAudioColor}
                type="file"
                accept="audio/mp3"
                errors={props?.errors}
                touched={props?.touched}
                onChange={(e) => {
                  const inputFile = new File([e.target.files[0].name], e.target.files[0].name, { type: 'audio/mp3' });
                  props?.setFieldValue('audio', inputFile);
                  setAudioName(e.target.files[0].name);
                  setInputAudioColor('color-input-primary');
                }}
              />
              <div className="my-5">
                <ButtonAction
                  toggle={() => {
                    if (isAdded) {
                      setShowModalClose(true);
                    } else {
                      navigate('/doa');
                    }
                  }}
                  confirmTitle="Simpan Data"
                  cancelTitle="Batal"
                  withLoading={isLoading}
                />
              </div>
            </Form>
          )}
        </Formik>
      </Card>
      <ConfirmationModal
        show={showModalClose}
        toggle={toggleCloseModal}
        inlineIcon={saveIcon}
        message={`Apakah anda yakin ingin membatalkan proses ${
          action === 'add' ? 'tambah' : 'edit'
        } doa?`}
        confirmTitle="Yakin"
        cancelTitle="Batal"
        confirm={() => {
          dispatch(setEditedDoaValue(false));
          dispatch(setAddedDoaValue(false));
          navigate('/doa');
        }}
      />
    </div>
  );
};

export default DoaForm;
