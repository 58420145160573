/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable camelcase */
import axios from 'axios';
import { actionPending, actionSuccess, actionError } from '../actionTypes';
import { toastError, toastSuccess } from '../../../components/toast/index';

// import API from '../API';

// ACTION TYPES TO DISPATCH: METHOD_URL_TYPES
export const GET_DZIKIR_PENDING = 'GET_DZIKIR_PENDING';
export const GET_DZIKIR_SUCCESS = 'GET_DZIKIR_SUCCESS';
export const GET_DZIKIR_ERROR = 'GET_DZIKIR_ERROR';
export const GET_CATEGORIES_PENDING = 'GET_CATEGORIES_PENDING';
export const GET_CATEGORIES_SUCCESS = 'GET_CATEGORIES_SUCCESS';
export const GET_CATEGORIES_ERROR = 'GET_CATEGORIES_ERROR';
export const POST_DZIKIR_PENDING = 'POST_DZIKIR_PENDING';
export const POST_DZIKIR_SUCCESS = 'POST_DZIKIR_SUCCESS';
export const POST_DZIKIR_ERROR = 'POST_DZIKIR_ERROR';
export const PUT_DZIKIR_PENDING = 'PUT_DZIKIR_PENDING';
export const PUT_DZIKIR_SUCCESS = 'PUT_DZIKIR_SUCCESS';
export const PUT_DZIKIR_ERROR = 'PUT_DZIKIR_ERROR';
export const POST_DZIKIR_AUDIO_PENDING = 'POST_DZIKIR_AUDIO_PENDING';
export const POST_DZIKIR_AUDIO_SUCCESS = 'POST_DZIKIR_AUDIO_SUCCESS';
export const POST_DZIKIR_AUDIO_ERROR = 'POST_DZIKIR_AUDIO_ERROR';
export const DELETE_DZIKIR_PENDING = 'DELETE_DZIKIR_PENDING';
export const DELETE_DZIKIR_SUCCESS = 'DELETE_DZIKIR_SUCCESS';
export const DELETE_DZIKIR_ERROR = 'DELETE_DZIKIR_ERROR';

// URL: URL_{URL}
const DZIKIR_URL = 'api/dzikir';
const CATEGORY_URL = 'api/category';

const user = JSON.parse(localStorage.getItem('user_dzikra'));
const headers = { 'x-auth-userid': user !== null ? user.id : '' };

const datatable = (param, resolve, callback) => (dispatch) => {
  dispatch(actionPending(GET_DZIKIR_PENDING));
  axios.post(`${process.env.REACT_APP_API_URL}${DZIKIR_URL}/datatable`, param, { headers })
    .then((response) => {
      if (response.error) {
        throw response.error;
      }
      dispatch(actionSuccess(GET_DZIKIR_SUCCESS, response));
      if (resolve) {
        const records_total = response.data.recordTotal;
        const data = response.data.data.map((item, i) => ({
          ...item,
          no: i + 1 + (param?.page - 1 < 0 ? 0 : param?.page) * 10,
        }));
        resolve({
          data,
          page: param?.page - 1,
          totalCount: records_total,
        });
      }
      if (callback) {
        callback();
      }
    })
    .catch((error) => {
      dispatch(actionError(GET_DZIKIR_ERROR));
      const message = error?.response?.data?.message;
      toastError(message);
    });
};

const post = (param, resolve, callback) => (dispatch) => {
  dispatch(actionPending(POST_DZIKIR_PENDING));
  axios.post(`${process.env.REACT_APP_API_URL}${DZIKIR_URL}`, param, { headers })
    .then((response) => {
      if (response.error) {
        throw response.error;
      }
      dispatch(actionSuccess(POST_DZIKIR_SUCCESS, response));
      toastSuccess('Data Berhasil Ditambah');
      if (resolve) {
        resolve({
          data: response.data.data,
          message: response.data.message,
          status: response.data.status,
        });
      }
      if (callback) {
        callback();
      }
    })
    .catch((error) => {
      dispatch(actionError(POST_DZIKIR_ERROR));
      const message = error?.response?.data?.message;
      toastError(message);
    });
};

const put = (param, resolve, callback) => (dispatch) => {
  dispatch(actionPending(PUT_DZIKIR_PENDING));
  axios.put(`${process.env.REACT_APP_API_URL}${DZIKIR_URL}`, param, { headers })
    .then((response) => {
      if (response.error) {
        throw response.error;
      }
      dispatch(actionSuccess(PUT_DZIKIR_SUCCESS, response));
      toastSuccess('Data Berhasil Diedit');
      if (resolve) {
        resolve({
          data: response.data.data,
          message: response.data.message,
          status: response.data.status,
        });
      }
      if (callback) {
        callback();
      }
    })
    .catch((error) => {
      dispatch(actionError(PUT_DZIKIR_ERROR));
      const message = error?.response?.data?.message;
      toastError(message);
    });
};

const deleteDzikir = (param, callback) => (dispatch) => {
  dispatch(actionPending(DELETE_DZIKIR_PENDING));
  axios.delete(`${process.env.REACT_APP_API_URL}${DZIKIR_URL}/${param}`, { headers })
    .then((response) => {
      if (response.error) {
        throw response.error;
      }
      dispatch(actionSuccess(DELETE_DZIKIR_SUCCESS, response));
      toastSuccess('Data Dzikir Berhasil Dihapus');
      if (callback) {
        callback();
      }
    })
    .catch((error) => {
      dispatch(actionError(DELETE_DZIKIR_ERROR));
      const message = error?.response?.data?.message;
      toastError(message);
    });
};

const dataCategories = (param, resolve, callback) => (dispatch) => {
  dispatch(actionPending(GET_CATEGORIES_PENDING));
  axios.post(`${process.env.REACT_APP_API_URL}${CATEGORY_URL}/datatable`, param, { headers })
    .then((response) => {
      if (response.error) {
        throw response.error;
      }
      dispatch(actionSuccess(GET_CATEGORIES_SUCCESS, response));
      if (resolve) {
        resolve({
          data: response.data.data,
        });
      }
      if (callback) {
        callback();
      }
    })
    .catch((error) => {
      dispatch(actionError(GET_CATEGORIES_ERROR));
      const message = error?.response?.data?.message;
      toastError(message);
    });
};

const postAudio = (param, resolve) => (dispatch) => {
  dispatch(actionPending(POST_DZIKIR_AUDIO_PENDING));
  axios.post(`${process.env.REACT_APP_API_URL}/api/dzikir_detail/upload_audio`, param, { headers })
    .then((response) => {
      if (response.error) {
        throw response.error;
      }
      dispatch(actionSuccess(POST_DZIKIR_AUDIO_SUCCESS, response));
      if (resolve) {
        resolve({
          data: response.data,
          status: response.status,
        });
      }
    })
    .catch((error) => {
      dispatch(actionError(POST_DZIKIR_AUDIO_ERROR));
      const message = error?.response?.data?.message;
      toastError(message);
    });
};

const dzikir = {
  datatable,
  post,
  put,
  deleteDzikir,
  dataCategories,
  postAudio,
};

export default dzikir;
