/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable camelcase */
import { actionPending, actionSuccess, actionError } from '../actionTypes';
import { toastError } from '../../../components/toast/index';

import API from '../API';

// ACTION TYPES TO DISPATCH: METHOD_URL_TYPES
export const GET_USER_PENDING = 'GET_USER_PENDING';
export const GET_USER_SUCCESS = 'GET_USER_SUCCESS';
export const GET_USER_ERROR = 'GET_USER_ERROR';

// URL: URL_{URL}
const USER_URL = 'api/user';

const datatable = (param, resolve, callback) => (dispatch) => {
  dispatch(actionPending(GET_USER_PENDING));
  API.post(`${USER_URL}/datatable`, param)
    .then((response) => {
      if (response.error) {
        throw response.error;
      }
      dispatch(actionSuccess(GET_USER_SUCCESS, response));
      if (resolve) {
        const records_total = response.data.recordTotal;
        const data = response.data.data.map((item, i) => ({
          ...item,
          no: i + 1 + (param?.page - 1 < 0 ? 0 : param?.page) * 10,
        }));
        resolve({
          data,
          page: param?.page - 1,
          totalCount: records_total,
        });
      }
      if (callback) {
        callback();
      }
    })
    .catch((error) => {
      dispatch(actionError(GET_USER_ERROR));
      const message = error?.response?.data?.message;
      toastError(message);
    });
};

const user = { datatable };

export default user;
