/* eslint-disable no-restricted-globals */
/* eslint-disable radix */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable import/no-named-as-default-member */
/* eslint-disable import/no-named-as-default */
/* eslint-disable max-len */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable react/jsx-no-comment-textnodes */
/* eslint-disable react/jsx-no-undef */
/* eslint-disable no-undef */
/* eslint-disable no-unused-vars */
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Typography from '@mui/material/Typography';
import { Form, Formik } from 'formik';
import { useDispatch } from 'react-redux';
import * as Yup from 'yup';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Card } from 'react-bootstrap';
import React, {
  useState, useRef, useEffect,
} from 'react';
import { ToastContainer } from 'react-toastify';
import moment from 'moment/moment';
import Input from '../../../../components/forms/input';
import Textarea from '../../../../components/forms/Textarea';
import ButtonAction from '../../../../components/buttons/ButtonAction';
import InputPrepend2 from '../../../../components/forms/InputPrepend2';
import Select2 from '../../../../components/forms/Select2';
import 'react-toastify/dist/ReactToastify.css';
import 'react-quill/dist/quill.snow.css';
import ConfirmationModal from '../../../../components/modal/ConfirmModal';
import saveIcon from '../../../../assets/images/icons/SaveIcon.svg';
import { setEditedInfaq } from '../../../../stores/actions/infaq/formInfaq';
import { updatePencairanDanaById, getPencairanDanaById, getAllProgramInfaq } from '../../../../services/infaq/index';

const styleInactiveBreadcrumb = {
  fontWeight: 500,
  fontSize: '12px',
  lineHeight: '14px',
  color: 'rgba(79, 88, 103, 0.7)',
  textDecoration: 'none',
  fontFamily: 'BlueberrySans-Regular',
};
const styleActiveBreadcrumb = {
  fontWeight: 600,
  fontSize: '12px',
  lineHeight: '14px',
  color: 'rgba(79, 88, 103, 0.7)',
  textDecoration: 'none',
  fontFamily: 'BlueberrySans-Regular',
};

function editPencairan() {
  const navigate = useNavigate();
  const { id } = useParams();
  const formikRef = useRef();
  const dispatch = useDispatch();
  const [isEditedPencairan, setIsEditedPencairan] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [availableFunds, setAvailableFunds] = useState(0);
  const [modalCloseConfirmation, setShowModalCloseConfirmation] = useState(false);
  const optionsStatus = [
    { value: 'ditinjau', label: 'Ditinjau' },
    { value: 'pengajuan', label: 'Pengajuan' },
    { value: 'selesai', label: 'Selesai' },
  ];
  const [programName, setProgramName] = useState('');
  const [status, setStatus] = useState('');
  const [description, setDescription] = useState('');
  const [usageDate, setUsageDate] = useState('');
  const [amount, setAmount] = useState('');
  const [amountDisplay, setAmountDisplay] = useState('');
  const [donationNameList, setDonationNameList] = useState([]);
  const [programInfaqId, setProgramInfaqId] = useState('');
  const [totalIncomingDonation, setTotalIncomingDonation] = useState('');
  const [incomingDisplay, setIncomingDisplay] = useState('');
  const [remainingDonation, setRemainingDonation] = useState('');
  const [remainingDisplay, setRemainingDisplay] = useState('');

  const initialValues = {
    programName: programName || '',
    status: status || '',
    description: description || '',
    usageDate: usageDate || '',
    amount: amount || '',
  };

  const validation = Yup.object().shape({
    programName: Yup.string()
      .required('Nama Donasi Wajib Diisi').nullable(),
    status: Yup.string()
      .required('Status Wajib Diisi').nullable(),
    description: Yup.string()
      .required('Keterangan Wajib Diisi').nullable(),
    usageDate: Yup.string()
      .required('Tanggal Donasi Wajib Diisi').nullable(),
    amount: Yup.string()
      .required('Jumlah Donasi Wajib Diisi').nullable(),
  });

  const handleClose = () => setShowModal(false);
  const handleCloseModalConfirmation = () => setShowModalCloseConfirmation(false);
  const checkFormPencairan = (oldvalues, currentValues) => {
    if (id) {
      if (oldvalues.programName !== currentValues.programName
      || oldvalues.status !== currentValues.status
      || oldvalues.description !== currentValues.description
      || oldvalues.usageDate !== currentValues.usageDate
      || oldvalues.amount !== currentValues.amount) {
        setIsEditedPencairan(true);
        dispatch(setEditedInfaq(true));
      } else {
        setIsEditedPencairan(false);
        dispatch(setEditedInfaq(false));
      }
    }
  };
  const isFormEditedPencairanProgress = () => {
    if (id && isEditedPencairan) {
      setShowModalCloseConfirmation(true);
    } else {
      navigate('/infaq/penggunaan');
    }
  };
  useEffect(() => {
    const handleProgramName = async () => {
      const dummy = [];
      const programList = await getAllProgramInfaq();
      programList.forEach((value) => {
        const objOption = { value: value.id, label: value.programName };
        dummy.push(objOption);
      });
      setDonationNameList(dummy);
    };
    setTimeout(() => {
      handleProgramName();
    }, 100);
  }, []);

  useEffect(() => {
    const handlePencairanDana = async () => {
      const response = await getPencairanDanaById(id);
      setProgramName(response.infaqProgram.programName);
      setProgramInfaqId(response.infaqProgramId);
      setStatus(response.status);
      setDescription(response.description);
      const date = moment(response.usageDate).utc().format('YYYY-MM-DD');
      setUsageDate(date);
      const moneyFormatter = new Intl.NumberFormat();
      const donationFormat = moneyFormatter.format(response.amount);
      const availableFundsFormat = moneyFormatter.format(response.infaqProgram.infaqUsageDetail.availableFunds);
      setAmountDisplay(donationFormat.replaceAll(',', '.'));
      setAvailableFunds(availableFundsFormat.replaceAll(',', '.'));
      setAmount(donationFormat);
      const totalFormat = moneyFormatter.format(response.infaqProgram.totalIncomingDonation);
      setTotalIncomingDonation(totalFormat.replaceAll(',', '.'));
      setIncomingDisplay(totalFormat);
      const balance = ((response.infaqProgram.totalIncomingDonation) - (response.amount));
      const balanceFormat = moneyFormatter.format(balance);
      setRemainingDonation(balanceFormat.replaceAll(',', '.'));
    };
    handlePencairanDana();
  }, []);
  return (
    <div className="content-container-side">
      <div className="d-flex">
        <p className="content-title">
          Infaq
        </p>
      </div>
      <div className="mb-4">
        <Breadcrumbs aria-label="breadcrumb" separator="">
          <Link
            color="inherit"
            to="/infaq/penggunaan"
            onClick={() => {
            }}
            style={styleActiveBreadcrumb}
          >
            Pencairan Dana
          </Link>
          <Typography style={styleInactiveBreadcrumb}>
            Edit Pencairan Dana
          </Typography>
        </Breadcrumbs>
      </div>
      <Card className="card-datatable">
        <div className="d-flex flex-wrap align-items-center">
          <span
            className="material-icons clickable"
            style={{ marginTop: '-12px' }}
            onClick={() => {
              isFormEditedPencairanProgress();
            }}
            onKeyPress={() => navigate('/infaq/penggunaan')}
            role="button"
            tabIndex={0}
          >
            arrow_back
          </span>
          <p className="card-datatable-title ms-3">
            Edit Pencairan Dana
          </p>
        </div>
        <Formik
          innerRef={formikRef}
          initialValues={initialValues}
          validationSchema={validation}
          enableReinitialize
          onSubmit={(values, errors, touched) => {
            setShowModal(true);
          }}
        >
          {(props) => (
            <Form>
              {checkFormPencairan(initialValues, props.values)}
              <ConfirmationModal
                show={showModal}
                message="Apakah Anda yakin data yang dimasukkan sudah sesuai?"
                onHide={handleClose}
                confirmTitle="Ya Lanjutkan"
                cancelTitle="Periksa Kembali"
                toggle={handleClose}
                inlineIcon={saveIcon}
                confirm={() => {
                  setShowModal(false);
                  let statusValue = 0;
                  if (props.values.status === 'ditinjau') {
                    statusValue = 1;
                  } else if (props.values.status === 'pengajuan') {
                    statusValue = 2;
                  } else if (props.values.status === 'selesai') {
                    statusValue = 3;
                  }
                  let idInfaq = '';
                  if (programInfaqId !== props.values.programName) {
                    idInfaq = programInfaqId;
                  } else {
                    idInfaq = props.values.programName;
                  }
                  const data = {
                    amount: props.values.amount.replaceAll(',', ''),
                    description: props.values.description,
                    infaqProgramId: idInfaq,
                    status: statusValue,
                    usageDate: props.values.usageDate,
                  };
                  updatePencairanDanaById(id, data).then((response) => {
                    if (response.status === 200) {
                      localStorage.setItem('isEditedPencairan', true);
                      dispatch(setEditedInfaq(true));
                      setTimeout(() => {
                        navigate('/infaq/penggunaan');
                      }, 100);
                    }
                  });
                }}
              />
              <div className="row">
                <div className="col-lg-6 col-md-12 amount">
                  <Select2
                    name="programName"
                    title="Nama Donasi"
                    placeholder="Pilih Nama Donasi"
                    options={donationNameList}
                    key={donationNameList}
                    defaultValue={donationNameList.find(
                      ({ value }) => value === programInfaqId,
                    )}
                    errors={props?.errors}
                    touched={props?.touched}
                    onChangeEvent={(selectedOption) => {
                      props.setFieldValue('programName', selectedOption.value);
                    }}
                    {...props?.getFieldProps('programName')}
                  />
                </div>
                <div className="col-lg-6 col-md-12 amount">
                  <Input
                    title="Tanggal Pencairan Dana"
                    name="usageDate"
                    type="date"
                    errors={props?.errors}
                    touched={props?.touched}
                    {...props?.getFieldProps('usageDate')}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-lg-6">
                  <div className="row">
                    <div className="col-lg-12 col-md-12">
                      <Select2
                        name="status"
                        title="Status Pencairan Dana"
                        key={status}
                        placeholder="Pilih Status Pencairan Dana"
                        options={optionsStatus}
                        defaultValue={optionsStatus.find(
                          ({ value }) => value === status,
                        )}
                        errors={props?.errors}
                        touched={props?.touched}
                        onChangeEvent={(selectedOption) => {
                          props.setFieldValue('status', selectedOption.value);
                        }}
                        {...props?.getFieldProps('status')}
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-12 col-md-12 disabled">
                      <InputPrepend2
                        label="Jumlah Dana yang Tersedia"
                        title="Rp"
                        name="donationAmount"
                        type="text"
                        width="10%"
                        disabled
                        value={availableFunds}
                        mb="0"
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-12 col-md-12">
                      <InputPrepend2
                        label="Jumlah Dana Yang Dicairkan"
                        title="Rp"
                        name="amount"
                        type="number/text"
                        width="10%"
                        value={amountDisplay}
                        onChange={(e) => {
                          if (!isNaN(e.target.value.replaceAll('.', ''))) {
                            const moneyFormatter = new Intl.NumberFormat();
                            const donationFormat = moneyFormatter.format(e.target.value.replaceAll('.', ''));
                            setAmountDisplay(donationFormat.replaceAll(',', '.'));
                            props.setFieldValue(
                              'amount',
                              e.target.value.replaceAll('.', ''),
                              true,
                            );
                          }
                        }}
                        errors={props?.errors}
                        touched={props?.touched}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-lg-6">
                  <Textarea
                    title="Keterangan"
                    name="decription"
                    type="textarea"
                    tooltip="Berisi tentang keterangan dana yang digunakan"
                    rows={6}
                    errors={props?.errors}
                    touched={props?.touched}
                    {...props?.getFieldProps('description')}
                  />
                </div>
              </div>
              <div className="d-flex align-items-center mt-5">
                <div className="ms-auto">
                  <ButtonAction
                    cancelTitle="Batal"
                    onClickCancel={() => {
                      navigate('/infaq/penggunaan');
                    }}
                    confirmTitle="Simpan"
                    toggle={() => {
                      isFormEditedPencairanProgress();
                    }}
                  />
                </div>
              </div>
            </Form>
          )}
        </Formik>
        <ConfirmationModal
          show={modalCloseConfirmation}
          message="Apakah anda yakin ingin membatalkan proses edit pencairan dana infaq?"
          onHide={handleClose}
          confirmTitle="Yakin"
          cancelTitle="Batal"
          toggle={handleCloseModalConfirmation}
          inlineIcon={saveIcon}
          confirm={() => {
            dispatch(setEditedInfaq(false));
            navigate('/infaq/penggunaan');
          }}
        />
        <ToastContainer />
      </Card>
    </div>
  );
}

export default editPencairan;
