/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/prop-types */
import { React, useEffect } from 'react';
import { connect, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Row } from 'react-bootstrap';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';

// Components
import Input from '../../components/forms/input';
import Button from '../../components/buttons/Button';
import { getUser } from '../../utils/User';

// Images
import loginIllustration from '../../assets/images/illustrations/login_illustration.svg';

// Services
import AuthService from '../../stores/actions/auth/index';

const Login = ({ loggingIn }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    if (getUser()) {
      navigate('/dashboard');
    }
  }, []);

  const validation = Yup.object().shape({
    username: Yup.string()
      .required('Username wajib diisi')
      .nullable(),
    password: Yup.string()
      .required('Kata sandi wajib diisi')
      .nullable(),
  });

  return (
    <section>
      <div className="wrap d-md-flex">
        <div className="login-wrap">
          <h1>Login</h1>
          <p className="mt-3 mb-5">Selamat datang di Dzikra</p>
          <div className="mb-4">
            <Formik
              initialValues={{
                username: '',
                password: '',
                remember_me: false,
              }}
              validationSchema={validation}
              onSubmit={(values) => {
                dispatch(AuthService.auth_login(values));
              }}
            >
              {(props) => (
                <Form>
                  <Input
                    title="Username"
                    name="username"
                    type="text"
                    errors={props?.errors}
                    touched={props?.touched}
                    {...props?.getFieldProps('username')}
                  />
                  <Input
                    title="Kata Sandi"
                    name="password"
                    type="password"
                    errors={props?.errors}
                    touched={props?.touched}
                    {...props?.getFieldProps('password')}
                  />
                  <div style={{ marginTop: '4em' }}>
                    <Button
                      type="submit"
                      title="Login"
                      style={{
                        width: '100%',
                        textAlign: 'center',
                        padding: '0.7em',
                        borderRadius: '10px',
                      }}
                      disabled={loggingIn}
                    />
                  </div>
                </Form>
              )}
            </Formik>
          </div>
          <div className="d-flex justify-content-center">
            <small-text>Copyright © 2022</small-text>
          </div>
        </div>
        <img className="login-illustration" src={loginIllustration} alt="login illustration" />
      </div>
    </section>
  );
};

const mapStateToProps = ({ auth: { loggingIn } }) => ({ loggingIn });

export default connect(mapStateToProps)(Login);
