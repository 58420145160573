/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/prop-types */
/* eslint-disable no-nested-ternary */
import React from 'react';
import moment from 'moment';

function Input(props) {
  const {
    min,
    max,
    step,
    name,
    type,
    accept,
    style,
    title,
    value,
    errors,
    touched,
    variant,
    onKeyUp,
    onChange,
    onKeyDown,
    disabled,
    maxLength,
    leftIcon,
    rightIcon,
    toggleIcon,
    placeholder,
    filename,
    inputColor,
    onShowMedia,
    isShowMedia,
  } = props;
  return (
    <div className="form-group">
      {title && <label className="form-label">{title}</label>}
      <div className="input-wrapper" style={{ width: '100%' }}>
        {leftIcon && (
          <span className="material-icons-round left-icon-input">
            {leftIcon}
          </span>
        )}
        {type === 'file' && (
          <label
            className={`custom-input-file ${inputColor} ${
              errors && touched
                ? errors[name] && touched[name]
                  ? ' is-invalid'
                  : ''
                : ''
            }`}
            disabled={disabled}
          >
            <input
              {...props}
              className={`form-control form-input ${
                disabled ? 'input-disabled ' : ''
              }${
                errors && touched
                  ? errors[name] && touched[name]
                    ? ' is-invalid'
                    : ''
                  : ''
              }`}
              disabled={disabled}
              type="file"
              accept={accept}
              value={value != null ? value : ''}
              style={{ display: 'none' }}
              onChange={onChange}
              onKeyUp={onKeyUp}
              onKeyDown={onKeyDown}
            />
            <span className="material-icons me-2" style={{ fontSize: '20px' }}>
              file_upload
            </span>
            <span
              style={{
                flex: '1', overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis', maxWidth: isShowMedia === true ? '32rem' : 'none',
              }}
            >
              {filename ?? `Upload ${title}`}
            </span>
            {isShowMedia === true && (
            <span
              className="ms-auto"
              onClick={(e) => { e.preventDefault(); onShowMedia(); }}
              style={{
                textAlign: 'center',
                padding: '0.5em 1em',
                borderRadius: '8px',
                display: 'flex',
                alignItems: 'center',
                marginLeft: '0.5rem',
                color: '#2EC4B6',
              }}
            >
              {`Lihat ${title}`}
            </span>
            ) }
          </label>
        )}
        {type !== 'file' && (
          <input
            {...props}
            className={`form-control form-input ${variant}${
              disabled ? 'input-disabled ' : ''
            }${
              errors && touched
                ? errors[name] && touched[name]
                  ? ' is-invalid'
                  : ''
                : ''
            }`}
            value={value != null ? value : ''}
            style={style}
            min={min}
            max={max}
            step={step}
            data-date={
              type === 'date' && value
                ? moment(value).format('DD/MM/YYYY')
                : 'dd/mm/yyyy'
            }
            onChange={onChange}
            onKeyUp={onKeyUp}
            onKeyDown={onKeyDown}
            disabled={disabled}
            maxLength={maxLength}
            placeholder={placeholder ?? `Masukkan ${title}`}
          />
        )}
        {rightIcon && (
          <span
            className="material-icons-round right-icon-input"
            style={{ cursor: toggleIcon ? 'pointer' : '' }}
            onClick={toggleIcon}
          >
            {rightIcon}
          </span>
        )}
      </div>
      <div
        className="invalid-feedback"
        style={{
          display:
            errors && touched
              ? errors[name] && touched[name]
                ? 'block'
                : 'none'
              : '',
        }}
      >
        {errors ? errors[name] : ''}
      </div>
    </div>
  );
}

export default Input;
