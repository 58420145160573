/* eslint-disable consistent-return */
/* eslint-disable no-unused-vars */
/* eslint-disable no-use-before-define */
/* eslint-disable no-unused-expressions */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/prop-types */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable no-promise-executor-return */
/* eslint-disable jsx-a11y/label-has-associated-control */
import {
  React, useEffect, useRef, useState,
} from 'react';
import { useParams, useNavigate, Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Card } from 'react-bootstrap';
import { Formik, Form } from 'formik';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Typography from '@mui/material/Typography';
import MenuItem from '@mui/material/MenuItem';

import Select from '../../components/forms/Select';
import Input from '../../components/forms/input';
import ButtonDetail from '../../components/table/button-detail';
import TableStatus from '../../components/table/status';
import Table from '../../components/table/table-dnd';
import DetailDzikirItem from './DetailDzikirItem';

import DzikirService from '../../stores/actions/dzikir';

const styleActiveBreadcrumb = {
  fontWeight: 600,
  fontSize: '12px',
  lineHeight: '14px',
  color: 'rgba(79, 88, 103, 0.7)',
  textDecoration: 'none',
  fontFamily: 'BlueberrySans-Regular',
};

const styleInactiveBreadcrumb = {
  fontWeight: 500,
  fontSize: '12px',
  lineHeight: '14px',
  color: 'rgba(79, 88, 103, 0.7)',
  textDecoration: 'none',
  fontFamily: 'BlueberrySans-Regular',
};

const DetailDzikir = () => {
  const dispatch = useDispatch();
  const formikRef = useRef();
  const navigate = useNavigate();
  const [page, setPage] = useState(0);
  const [maxPage, setMaxPage] = useState(1);
  const [totalData, setTotalData] = useState(1);
  const [dzikirCategories, setDzikirCategories] = useState([]);
  const [dataDzikirForm, setDataDzikirForm] = useState({});
  const [showListDzikirItem, setShowListDzikirItem] = useState(true);
  const [showDetailDzikirItem, setShowDetailDzikirItem] = useState(false);
  const [indexItem, setIndexItem] = useState();

  const { id } = useParams();

  useEffect(() => {
    new Promise((resolve) => {
      const param = {
        page: 0,
        length: 999,
        categoryType: 'Dzikir',
      };
      dispatch(DzikirService.dataCategories(param, resolve));
    }).then((res) => {
      const categories = [];
      res?.data?.forEach((item) => {
        categories?.push({ value: item?.id, label: `${item?.categoryType} ${item?.categoryName}` });
      });
      setDzikirCategories(categories);
    });
  }, []);

  useEffect(() => {
    document.documentElement.scrollTop = 0;
    new Promise((resolve) => {
      const param = {
        id,
      };
      if (param.id !== undefined) {
        dispatch(DzikirService.datatable(param, resolve));
      }
    }).then((res) => {
      if (res) {
        setDataDzikirForm(res.data[0]);
      }
    });
  }, []);

  // useEffect(() => {
  //   if (formikRef?.current?.values?.dzikir_items) {
  //     setMaxPage(Math.ceil(formikRef?.current?.values?.dzikir_items?.length / 10));
  //     setTotalData(formikRef?.current?.values?.dzikir_items?.length);
  //   }
  // }, [formikRef?.current?.values?.dzikir_items]);

  const initialValues = {
    dzikirName: dataDzikirForm?.dzikirName ?? '',
    dzikirCategory: dataDzikirForm?.dzikirCategory ?? '',
    dzikirDetails: dataDzikirForm?.dzikirDetails !== undefined ? dataDzikirForm?.dzikirDetails : [],
  };

  const detailItemDzikir = (formik, index) => {
    setIndexItem(index);
    setShowListDzikirItem(!showListDzikirItem);
    setShowDetailDzikirItem(!showDetailDzikirItem);
  };

  const columns = [
    {
      Header: 'No',
      Cell: ({ row }) => {
        const idDzikirItem = row.index + 1;
        return idDzikirItem;
      },
    },
    {
      Header: 'Nama Bacaan Dzikir',
      accessor: 'doaName',
    },
    {
      Header: 'Pengulangan',
      accessor: 'repeat',
      Cell: ({ row }) => {
        const { original } = row;
        return original?.repeat ? `${original?.repeat}x` : '-';
      },
    },
    {
      Header: 'Konten',
      accessor: 'referensi',
      Cell: ({ row }) => {
        const { original } = row;
        const contents = [
          {
            label: 'Referensi',
            value: original?.referensi,
          },
          {
            label: 'Audio',
            value: original?.audioName,
          },
        ];
        return (
          <div className="d-flex align-items-center">
            {contents?.map((item) => (
              <div className="mx-1">
                <TableStatus
                  status={item?.label}
                  color={item?.value !== null && item?.value !== undefined ? 'primary' : 'inactive'}
                />
              </div>
            ))}
          </div>
        );
      },
    },
    {
      Header: '',
      accessor: 'actions',
      Cell: ({ row }) => {
        const original = row ? row?.original : [];

        return (
          <ButtonDetail>
            <MenuItem
              onClick={() => detailItemDzikir(formikRef?.current, row.index)}
            >
              <span style={{ fontSize: 14 }}>Detail</span>
            </MenuItem>
          </ButtonDetail>
        );
      },
    },
  ];

  return (
    <Formik
      innerRef={formikRef}
      initialValues={initialValues}
      enableReinitialize
    >
      {(props) => (
        <Form>
          {showDetailDzikirItem && (
            <DetailDzikirItem
              formik={props}
              index={indexItem}
              toggle={detailItemDzikir}
            />
          )}
          {showListDzikirItem && (
            <div className="content-container-side">
              <div className="d-flex">
                <p className="content-title">Dzikir</p>
              </div>
              <div className="mb-4">
                <Breadcrumbs aria-label="breadcrumb" separator="">
                  <Link
                    color="inherit"
                    to="/dzikir"
                    onClick={() => navigate('/dzikir')}
                    style={styleActiveBreadcrumb}
                  >
                    Dzikir
                  </Link>
                  <Typography style={styleInactiveBreadcrumb}>
                    Detail Dzikir
                  </Typography>
                </Breadcrumbs>
              </div>
              <Card className="card-datatable">
                <div className="d-flex">
                  <span
                    className="material-icons clickable"
                    onClick={() => navigate('/dzikir')}
                    onKeyPress={() => navigate('/dzikir')}
                    role="button"
                    tabIndex={0}
                  >
                    arrow_back
                  </span>
                  <p className="card-datatable-title ms-3">
                    Detail Dzikir
                  </p>
                </div>
                <Input
                  disabled
                  title="Nama Dzikir"
                  name="dzikirName"
                  type="text"
                  errors={props?.errors}
                  touched={props?.touched}
                  {...props?.getFieldProps('dzikirName')}
                />
                <Select
                  disabled
                  name="dzikirCategory"
                  type="text"
                  title="Kategori"
                  placeholder="Pilih Kategori Dzikir"
                  errors={props?.errors}
                  touched={props?.touched}
                  isSearchable={false}
                  options={dzikirCategories}
                  value={props?.values?.dzikirCategory}
                  onChange={(name, value) => {
                    props.setFieldValue('kategori', value);
                  }}
                  onBlur={props.setFieldTouched}
                />
                <div className="d-flex align-items-center mt-5">
                  <p className="card-datatable-title">Bacaan Dzikir</p>
                </div>
                <Table
                  data={props?.values?.dzikirDetails}
                  columns={columns}
                  // pagination={{
                  //   page, totalData, maxPage, toggle: setPage,
                  // }}
                />
              </Card>
            </div>
          )}
        </Form>
      )}
    </Formik>
  );
};

export default DetailDzikir;
