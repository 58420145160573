/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-unused-vars */
/* eslint-disable no-use-before-define */
/* eslint-disable no-shadow */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable react/prop-types */
import React, { forwardRef, useRef, useEffect } from 'react';
import { useTable, useRowSelect } from 'react-table';
import Pagination from './pagination/index';
import Loader from '../loader/index';
import '../../assets/styles/table.scss';

function Table({
  id,
  columns,
  data,
  pagination,
  isLoading,
  selection,
  selectionAlign = 'left',
  customHeaderSelection,
  onSelection,
  selectionRow,
  length,
}) {
  const IndeterminateCheckbox = forwardRef(({ value, row }, ref) => {
    const defaultRef = useRef();

    useEffect(() => {
      defaultRef.current.checked = selection?.indexOf(value) > -1;
    }, [selection, row]);

    return (
      <label className="checkbox-container" style={{ display: 'initial' }}>
        <input
          type="checkbox"
          ref={defaultRef}
          value={value}
          onChange={(e) => handleSelect(e)}
        />
        <span className="checkmark" />
      </label>
    );
  });

  useEffect(() => {
    let selectedData = selection ?? [];
    const index = selectedData.indexOf('all');
    if (index > -1) {
      data.forEach((item) => {
        const index = selectedData.indexOf(item.id || item.no);
        if (index === -1) {
          selectedData = [...selectedData, item.id || item.no];
        }
      });
      onSelection(selectedData);
    }
  }, [data]);

  const handleSelect = (event) => {
    let selectedData = selection ?? [];
    if (event.target.value === 'all') {
      const index = selectedData.indexOf(event.target.value);
      if (index > -1) {
        selectedData = [];
      } else {
        selectedData = ['all', ...selectedData];
        data.forEach((item) => {
          selectedData = [...selectedData, item.id || item.no];
        });
      }
    } else if (event.target.value) {
      const indexAll = selectedData.indexOf('all');
      if (indexAll > -1) {
        selectedData.splice(indexAll, 1);
      }
      const index = selectedData.indexOf(event.target.value);
      if (index > -1) {
        selectedData.splice(index, 1);
      } else {
        selectedData = [...selectedData, event.target.value];
      }
    }
    const uniqArr = [...new Set(selectedData)];
    onSelection(uniqArr);
  };

  const {
    getTableProps,
    headerGroups,
    rows,
    prepareRow,
    selectedFlatRows,
    state: { selectedRowIds },
  } = useTable(
    {
      columns,
      data: data ?? [{}],
    },
    useRowSelect,
    (hooks) => {
      hooks.visibleColumns.push((columns) => {
        if (selectionRow) {
          const headSelection = {
            id: 'selection',
            Header: ({ getToggleAllRowsSelectedProps }) => (
              <div>
                {customHeaderSelection || (
                <IndeterminateCheckbox value="all" />
                )}
              </div>
            ),
            Cell: ({ row }) => {
              const id = row?.original?.id;
              return (
                <div>
                  <IndeterminateCheckbox value={id} row={rows} />
                </div>
              );
            },
          };
          if (selectionAlign === 'left') {
            return [headSelection, ...columns];
          } if (selectionAlign === 'right') {
            return [...columns, headSelection];
          }
          return [...columns];
        }
        return [...columns];
      });
    },
  );

  return (
    <div>
      <div
        className="table-responsive"
        style={{ position: 'relative', minHeight: '215px' }}
      >
        {isLoading && <Loader loading={isLoading} />}
        <table
          {...getTableProps()}
          className="table-customize"
        >
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <th
                    {...column.getHeaderProps()}
                    style={{ whiteSpace: 'nowrap' }}
                  >
                    {column.render('Header')}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody>
            {isLoading && (
            <tr>
              <td colSpan={columns.length}>
                <Loader loading />
              </td>
            </tr>
            )}
            {!isLoading && (
            <>
              {rows.map((row, i) => {
                prepareRow(row);
                return (
                  <tr {...row.getRowProps()}>
                    {row.cells.map((cell) => (
                      <td {...cell.getCellProps()} style={{ fontSize: 14 }}>
                        {cell.render('Cell')}
                      </td>
                    ))}
                  </tr>
                );
              })}
              {rows?.length < 1 && !isLoading && (
              <tr>
                <td
                  colSpan={
                    headerGroups ? headerGroups[0]?.headers?.length + 1 : 3
                  }
                  style={{ textAlign: 'center' }}
                >
                  Data tidak ditemukan
                </td>
              </tr>
              )}
            </>
            )}
          </tbody>
        </table>
      </div>
      <div style={{ width: '100%' }}>
        {pagination && !isLoading && (
        <Pagination
          page={pagination?.page !== 0 ? pagination.page : 0}
          length={length ?? data?.length}
          recordTotal={pagination?.totalData}
          maxPage={pagination?.maxPage}
          toggle={pagination?.toggle}
        />
        )}
      </div>
    </div>
  );
}

export default Table;
