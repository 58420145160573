/* eslint-disable consistent-return */
/* eslint-disable no-unused-vars */
/* eslint-disable no-use-before-define */
/* eslint-disable no-unused-expressions */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/prop-types */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable no-promise-executor-return */
/* eslint-disable jsx-a11y/label-has-associated-control */
import {
  React, useEffect, useRef, useState,
} from 'react';
import { useParams, useNavigate, Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Card } from 'react-bootstrap';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import moment from 'moment';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Typography from '@mui/material/Typography';
import ButtonStatus from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import saveIcon from '../../assets/images/icons/SaveIcon.svg';
import Table from '../../components/table/index';
import Input from '../../components/forms/input';
import InputPrepend from '../../components/forms/InputPrepend';
import ButtonDetail from '../../components/table/button-detail';
import ButtonAction from '../../components/buttons/ButtonAction';
import Button from '../../components/buttons/Button';
import ConfirmationModal from '../../components/modal/ConfirmModal';
import {
  addValues,
  delValues,
  setEditedValue,
  setAction,
  setType,
} from '../../stores/actions/mission/formMission';
import { toastError, toastSuccess } from '../../components/toast';
import ImageModal from '../../components/modal/ImageModal';

import MissionService from '../../stores/actions/mission';

const styleActiveBreadcrumb = {
  fontWeight: 600,
  fontSize: '12px',
  lineHeight: '14px',
  color: 'rgba(79, 88, 103, 0.7)',
  textDecoration: 'none',
  fontFamily: 'BlueberrySans-Regular',
};

const styleInactiveBreadcrumb = {
  fontWeight: 500,
  fontSize: '12px',
  lineHeight: '14px',
  color: 'rgba(79, 88, 103, 0.7)',
  textDecoration: 'none',
  fontFamily: 'BlueberrySans-Regular',
};

const MissionForm = () => {
  const dispatch = useDispatch();
  const formikRef = useRef();
  const navigate = useNavigate();
  const [detailMission, setDetailMission] = useState([]);
  const [isEdited, setIsEdited] = useState(false);
  const [missionData, setMissionData] = useState({});
  const [showModalClose, setShowModalClose] = useState(false);
  const [missionItemData, setMissionItemData] = useState([]);
  const [confirmModal, setConfirmModal] = useState(false);
  const [confirmStatusModal, setConfirmStatusModal] = useState(false);
  const [dataDeleted, setDataDeleted] = useState([]);
  const [status, setStatus] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [bannerName, setBannerName] = useState('Upload Media');
  const [currentBannerName, setCurrentBannerName] = useState();
  const [media, setMedia] = useState(null);
  const [badgeImgName, setBadgeImgName] = useState('Upload Gambar Badge');
  const [currentBadgeImgName, setCurrentBadgeImgName] = useState();
  const [badge, setBadge] = useState(null);
  const [bannerFile, setBannerFile] = useState();
  const [badgeImgFile, setBadgeImgFile] = useState();
  const [badgeName, setBadgeName] = useState('');
  const [badgeDescription, setBadgeDescription] = useState('');
  const [inputMediaColor, setInputMediaColor] = useState('');
  const [inputThumbnailColor, setInputThumnailColor] = useState('');
  const [showImageMedia, setShowImageMedia] = useState(false);
  const [showImageBadge, setShowImageBadge] = useState(false);

  const { type, action, id } = useParams();
  const title = action === 'edit' ? 'Edit' : 'Tambah';
  const getDate = moment().format('YYYY-MM-DD');
  const exceptThisSymbols = ['e', 'E', '+', '-', '.'];
  useEffect(() => {
    dispatch(setAction(action));
    dispatch(setType(type));
    const handleRefresh = () => {
      new Promise((resolve) => {
        const param = {
          id,
        };
        if (param.id !== undefined) {
          dispatch(MissionService.datatable(param, resolve));
        }
      }).then((res) => {
        // detail mission
        setDetailMission(res.data[0]);
        if (res) {
        // edit mission
          setStatus(res?.data[0].status);
          const missionStorage = JSON.parse(localStorage.getItem('mission'));
          if (!missionStorage) {
            setMissionData(res?.data[0]);
            if (res?.data[0]?.banner !== null) {
              const bannerUrl = `${process.env.REACT_APP_API_URL}api/file?imageUrl=${res?.data[0]?.banner}`;
              const bannerFilename = bannerUrl.split('/').pop();
              setMedia(bannerUrl);
              setBannerName(bannerFilename);
              setCurrentBannerName(bannerFilename);
              fetch(bannerUrl).then((response) => response.arrayBuffer()).then((buffer) => {
                const file = new File([buffer], bannerFilename, {
                  type: 'image/jpg',
                });
                setBannerFile(file);
              });
            }
            if (
              res?.data[0]?.badges.length
              && res?.data[0]?.badges !== undefined
            ) {
              const badgeUrl = `${process.env.REACT_APP_API_URL}api/file?imageUrl=${res?.data[0]?.badges[0]?.imageUrl}`;
              const badgeFilename = badgeUrl.split('/').pop();
              setBadge(badgeUrl);
              setBadgeImgName(badgeFilename);
              setCurrentBadgeImgName(badgeFilename);
              fetch(badgeUrl).then((response) => response.arrayBuffer()).then((buffer) => {
                const file = new File([buffer], badgeFilename, {
                  type: 'image/jpg',
                });
                setBadgeImgFile(file);
              });
              setBadgeName(res?.data[0].badges[0].name);
              setBadgeDescription(res?.data[0].badges[0].description);
            }
          }

          const missionDetailStorage = localStorage.getItem('missionDetails');
          if (!missionDetailStorage) {
            setMissionItemData(res?.data[0].missionDetail);
            localStorage.setItem(
              'missionDetails',
              JSON.stringify(res?.data[0].missionDetail),
            );
          }
        }
      });
    };
    if (action === 'edit') {
      setInputThumnailColor('color-input-black');
      setInputMediaColor('color-input-black');
    }
    handleRefresh();
    document.documentElement.scrollTop = 0;
  }, []);
  useEffect(() => {
    const missionDetails = localStorage.getItem('missionDetails');
    if (missionDetails) {
      setMissionItemData(JSON.parse(missionDetails));
    }

    const missions = JSON.parse(localStorage.getItem('mission'));
    if (missions) {
      setMissionData(missions[0]);
      setBadgeName(missions[0].badgeName);
      setBadgeDescription(missions[0].badgeDescription);
      setBannerName(missions[0].bannerName);
      setBadgeImgName(missions[0].badgeImageName);
      fetch(missions[0].banner).then((response) => response.arrayBuffer()).then((buffer) => {
        const file = new File([buffer], missions[0].bannerName, {
          type: 'image/jpg',
        });
        setBannerFile(file);
      });
      fetch(missions[0].badgeImage).then((response) => response.arrayBuffer()).then((buffer) => {
        const file = new File([buffer], missions[0].badgeImageName, {
          type: 'image/jpg',
        });
        setBadgeImgFile(file);
      });
    }
  }, []);
  const initialValues = {
    missionName: missionData?.missionName ?? '',
    description: missionData?.description ?? '',
    missionType: `${type.charAt(0).toUpperCase() + type.slice(1)} Mission`,
    activationDate: missionData?.activationDate
      ? moment(missionData?.activationDate).format('YYYY-MM-DD')
      : '',
    totalDay: missionData?.totalDay ?? '',
    banner: bannerFile ?? '',
    color: missionData?.color ?? '',
    badgeImage: badgeImgFile ?? '',
    badgeName: badgeName ?? '',
    badgeDescription: badgeDescription ?? '',
  };

  const showConfirmStatusModal = () => {
    setConfirmStatusModal(!confirmStatusModal);
  };

  const toogleCloseModal = () => {
    setShowModalClose(false);
  };

  const addNewFormValues = (data) => {
    dispatch(addValues(data));
  };

  const handleShowMediaInput = () => {
    setShowImageMedia(true);
  };

  const handleCloseMediaInput = () => {
    setShowImageMedia(false);
  };

  const handleShowBadgeInput = () => {
    setShowImageBadge(true);
  };

  const handleCloseBadgeInput = () => {
    setShowImageBadge(false);
  };

  const handleChangeStatus = () => {
    setConfirmStatusModal(!confirmStatusModal);
    const missionType = formikRef?.current?.values?.missionType === 'Daily Mission'
      ? 'Daily'
      : 'Special';
    const activationDate = moment(
      formikRef?.current?.values?.activationDate,
    ).format('YYYY-MM-DD 00:00:00');

    const formData = new FormData();
    formData.append('id', id);
    formData.append('status', -1);
    formData.append('missionType', missionType);
    formData.append('missionName', formikRef?.current?.values?.missionName);
    formData.append('description', formikRef?.current?.values?.description);
    formData.append('activationDate', activationDate);
    missionItemData.forEach((key, x) => {
      formData.append(`missionDetails[${[x]}].id`, key.id);
      formData.append(`missionDetails[${[x]}].missionId`, key.missionId);
      formData.append(`missionDetails[${[x]}].description`, key.description);
      formData.append(`missionDetails[${[x]}].name`, key.name);
      formData.append(`missionDetails[${[x]}].target`, key.target);
      formData.append(`missionDetails[${[x]}].unit`, key.unit);
      if (key.objectId && key.objectId !== null) {
        formData.append(`missionDetails[${[x]}].objectId`, key.objectId);
      }
      if (key.dzikirId && key.dzikirId !== null) {
        formData.append(`missionDetails[${[x]}].objectId`, key.dzikirId);
      }
      if (key.type && key.type !== null) {
        formData.append(`missionDetails[${[x]}].type`, key.type);
      }
      key.missionDetailReward.forEach((el, i) => {
        formData.append(`missionDetails[${[x]}].rewards[${[i]}].id`, el.id);
        formData.append(
          `missionDetails[${[x]}].rewards[${[i]}].missionDetailId`,
          el.missionDetailId,
        );
        formData.append(`missionDetails[${[x]}].rewards[${[i]}].type`, el.type);
        formData.append(
          `missionDetails[${[x]}].rewards[${[i]}].value`,
          el.value,
        );
      });
    });

    if (formikRef?.current?.values?.missionType === 'Special Mission') {
      formData.append('totalDay', formikRef?.current?.values?.totalDay);
      formData.append('banner', formikRef?.current?.values?.banner);
      formData.append('color', formikRef?.current?.values?.color);
      formData.append('badgeImage', formikRef?.current?.values?.badgeImage);
      formData.append('badgeName', formikRef?.current?.values?.badgeName);
      formData.append(
        'badgeDescription',
        formikRef?.current?.values?.badgeDescription,
      );
    }

    const callback = () => {
      localStorage.removeItem('mission');
      localStorage.removeItem('missionDetails');
      navigate('/mission');
    };
    dispatch(MissionService.put(formData, callback));
  };

  const handleStorageMissions = (props) => {
    const val = {
      description: props?.values?.description,
      missionName: props?.values?.missionName,
      missionType: props?.values.missionType,
      activationDate: props?.values.activationDate,
    };

    if (type === 'special') {
      if (props?.values?.banner) {
        val.banner = URL.createObjectURL(props?.values?.banner);
        val.bannerName = bannerName;
      }
      if (props?.values?.badgeImage) {
        val.badgeImage = URL.createObjectURL(props?.values?.badgeImage);
        val.badgeImageName = badgeImgName;
      }
      val.totalDay = props?.values?.totalDay;
      val.color = props?.values?.color;
      val.badgeName = props?.values?.badgeName;
      val.badgeDescription = props?.values?.badgeDescription;
    }

    const mission = [];
    mission.push(val);
    localStorage.setItem('mission', JSON.stringify(mission));
  };

  const showConfirmModal = (data, index) => {
    setConfirmModal(!confirmModal);
    setDataDeleted(data ? { ...data, id: index } : null);
  };

  const handleDelete = () => {
    const i = dataDeleted?.id - 1;
    missionItemData?.splice(i, 1);
    localStorage.setItem('missionDetails', JSON.stringify(missionItemData));
    setConfirmModal(!confirmModal);
  };

  const validation = Yup.object().shape({
    missionName: Yup.string().required('Nama misi wajib diisi').nullable(),
    description: Yup.string().required('Deskripsi misi wajib diisi').nullable(),
    activationDate: Yup.string()
      .required('Tanggal aktivasi misi wajib diisi')
      .nullable(),
    totalDay: Yup.number().when('missionType', {
      is: 'Special Mission',
      then: (d) => d
        .required('Durasi misi wajib diisi')
        .moreThan(0, 'Durasi misi minimal 1 hari')
        .nullable(),
    }),
    banner: Yup.mixed().when('missionType', {
      is: 'Special Mission',
      then: (d) => d
        .required('Banner misi wajib diisi')
        .test(
          'fileSize',
          'Gambar Maksimal 1MB',
          (value) => !value || value.size <= 1000000,
        )
        .test(
          'fileFormat',
          'Unsupported file type',
          (value) => !value
            || (value
              && ['image/jpg', 'image/jpeg', 'image/gif', 'image/png'].includes(
                value.type,
              )),
        ),
    }),
    color: Yup.string().when('missionType', {
      is: 'Special Mission',
      then: (d) => d.required('Warna banner misi wajib diisi').nullable(),
    }),
    badgeImage: Yup.mixed().when('missionType', {
      is: 'Special Mission',
      then: (schema) => schema
        .required('Gambar badge misi wajib diisi')
        .test(
          'fileSize',
          'Gambar Maksimal 1MB',
          (value) => !value || value.size <= 1000000,
        )
        .test(
          'fileFormat',
          'Unsupported file type',
          (value) => !value
            || (value
              && ['image/jpg', 'image/jpeg', 'image/gif', 'image/png'].includes(
                value.type,
              )),
        ),
    }),
    badgeName: Yup.string().when('missionType', {
      is: 'Special Mission',
      then: (d) => d.required('Nama badge misi wajib diisi').nullable(),
    }),
    badgeDescription: Yup.string().when('missionType', {
      is: 'Special Mission',
      then: (d) => d.required('Deskripsi badge misi wajib diisi').nullable(),
    }),
  });

  const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

  const handleDraft = async (props) => {
    if (
      props?.values?.missionName === ''
      || props?.values?.description === ''
      || props?.values?.activationDate === ''
      || props?.values?.activationDate === ''
      || (props?.values?.missionType === 'Special Mission'
        && (props?.values?.totalDay === ''
          || props?.values?.banner === ''
          || props?.values?.color === ''
          || props?.values?.badgeImage === ''
          || props?.values?.badgeName === ''
          || props?.values?.badgeDescription === ''))
    ) {
      toastError('Seluruh data misi wajib diisi!');
      return;
    }

    if (
      props?.values?.missionType === 'Special Mission'
      && props?.values?.totalDay === 0
    ) {
      toastError('Durasi misi minimal 1 hari!');
      return;
    }

    setIsLoading(true);
    const missionType = props?.values?.missionType === 'Daily Mission' ? 'Daily' : 'Special';
    const activationDate = moment(props?.values?.activationDate).format(
      'YYYY-MM-DD HH:mm:ss',
    );

    const formData = new FormData();
    formData.append('status', 0);
    formData.append('missionType', missionType);
    formData.append('missionName', props?.values?.missionName);
    formData.append('description', props?.values?.description);
    formData.append('activationDate', activationDate);
    missionItemData.forEach((key, x) => {
      formData.append(`missionDetails[${[x]}].description`, key.description);
      formData.append(`missionDetails[${[x]}].name`, key.name);
      formData.append(`missionDetails[${[x]}].target`, key.target);
      formData.append(`missionDetails[${[x]}].unit`, key.unit);
      if (key.objectId && key.objectId !== null) {
        formData.append(`missionDetails[${[x]}].objectId`, key.objectId);
      }
      if (key.dzikirId && key.dzikirId !== null) {
        formData.append(`missionDetails[${[x]}].objectId`, key.dzikirId);
      }
      if (key.type && key.type !== null) {
        formData.append(`missionDetails[${[x]}].type`, key.type);
      }
      key.missionDetailReward.forEach((el, i) => {
        formData.append(`missionDetails[${[x]}].rewards[${[i]}].type`, el.type);
        formData.append(
          `missionDetails[${[x]}].rewards[${[i]}].value`,
          el.value,
        );
      });
    });

    if (props?.values?.missionType === 'Special Mission') {
      formData.append('totalDay', props?.values?.totalDay);
      formData.append('banner', props?.values?.banner);
      formData.append('color', props?.values?.color);
      formData.append('badgeImage', props?.values?.badgeImage);
      formData.append('badgeName', props?.values?.badgeName);
      formData.append('badgeDescription', props?.values?.badgeDescription);
    }

    const callback = () => {
      localStorage.removeItem('mission');
      localStorage.removeItem('missionDetails');
      toastSuccess('Data berhasil disimpan');
      dispatch(delValues());
      dispatch(setEditedValue(false));
      setTimeout(() => {
        navigate('/mission');
      }, 500);
    };

    if (action === 'add') {
      dispatch(MissionService.post(formData, callback));
      await delay(5000);
      setIsLoading(false);
    } else {
      formData.append('id', id);
      missionItemData.forEach((key, x) => {
        formData.append(`missionDetails[${[x]}].missionId`, id);
        if (key.id) {
          formData.append(`missionDetails[${[x]}].id`, key.id);
        }
        key.missionDetailReward.forEach((el, i) => {
          if (el.id) {
            formData.append(`missionDetails[${[x]}].rewards[${[i]}].id`, el.id);
          }
          if (key.id) {
            formData.append(
              `missionDetails[${[x]}].rewards[${[i]}].missionDetailId`,
              key.id,
            );
          }
        });
      });
      dispatch(MissionService.put(formData, callback));
      await delay(5000);
      setIsLoading(false);
    }
  };

  const isFormValueEdited = (oldValues, currentValues) => {
    if (currentValues.missionName !== '') {
      if (
        oldValues.missionName !== currentValues.missionName
        || oldValues.description !== currentValues.description
        || oldValues.activationDate.slice(0, 10) !== currentValues.activationDate
        || oldValues.totalDay !== currentValues.totalDay
        || oldValues.banner !== currentValues.banner
        || oldValues.color !== currentValues.color
        || oldValues.badgeImage !== currentValues.badgeImage
        || oldValues.badgeName !== currentValues.badgeName
        || oldValues.badgeDescription !== currentValues.badgeDescription
      ) {
        setIsEdited(true);
        dispatch(setEditedValue(true));
      } else {
        setIsEdited(false);
        dispatch(setEditedValue(false));
      }
    }
  };

  const isInProgress = () => {
    if (action === 'add') {
      if (
        formikRef.current.values.missionName === ''
        && formikRef.current.values.description === ''
        && formikRef.current.values.activationDate === ''
        && formikRef.current.values.totalDay === ''
        && formikRef.current.values.banner === ''
        && formikRef.current.values.color === ''
        && formikRef.current.values.badgeImage === ''
        && formikRef.current.values.badgeName === ''
        && formikRef.current.values.badgeDescription === ''
      ) {
        navigate('/mission');
      } else {
        setShowModalClose(true);
      }
    }
  };
  const columns = [
    {
      Header: 'No',
      Cell: ({ row }) => {
        const idDetailMission = row.index + 1;
        return idDetailMission;
      },
    },
    {
      Header: 'Aktivitas',
      accessor: 'name',
    },
    {
      Header: 'Target',
      Cell: ({ row }) => {
        const original = row ? row?.original : [];
        return `${original.target} ${original.unit}`;
      },
    },
    {
      Header: 'Reward',
      Cell: ({ row }) => {
        const original = row ? row?.original : [];
        const rewards = original.missionDetailReward?.map(
          (item) => `${item.value} ${item.type}`,
        );
        return rewards?.join(', ');
      },
    },
    {
      Header: '',
      accessor: 'actions',
      Cell: ({ row }) => {
        const original = row ? row?.original : [];
        const index = row.index + 1;

        return (
          <ButtonDetail>
            <MenuItem
              onClick={() => {
                handleStorageMissions(formikRef.current);
                navigate(
                  `/mission/${action}/${type}/mission-item/detail/${index}`,
                );
              }}
            >
              <span style={{ fontSize: 14 }}>Detail</span>
            </MenuItem>
            <MenuItem
              onClick={() => {
                handleStorageMissions(formikRef.current);
                navigate(
                  `/mission/${action}/${type}/mission-item/edit/${index}`,
                );
              }}
            >
              <span style={{ fontSize: 14 }}>Edit</span>
            </MenuItem>
            <MenuItem onClick={() => showConfirmModal(original, index)}>
              <span style={{ fontSize: 14 }}>Hapus</span>
            </MenuItem>
          </ButtonDetail>
        );
      },
    },
  ];

  return (
    <div className="content-container-side">
      <ImageModal show={showImageMedia} handleClose={handleCloseMediaInput} srcMedia={media} />
      <ImageModal show={showImageBadge} handleClose={handleCloseBadgeInput} srcMedia={badge} />
      <div className="d-flex">
        <p className="content-title">Misi</p>
      </div>
      <div className="mb-4">
        <Breadcrumbs aria-label="breadcrumb" separator="">
          <Link
            color="inherit"
            to="/mission"
            onClick={() => {
              localStorage.removeItem('mission');
              localStorage.removeItem('missionDetails');
              navigate('/mission');
            }}
            style={styleActiveBreadcrumb}
          >
            Misi
          </Link>
          <Typography style={styleInactiveBreadcrumb}>
            {`${title} ${type.charAt(0).toUpperCase() + type.slice(1)} Mission`}
          </Typography>
        </Breadcrumbs>
      </div>
      <Card className="card-datatable">
        <div className="d-flex">
          <span
            className="material-icons clickable"
            onClick={() => {
              localStorage.removeItem('mission');
              localStorage.removeItem('missionDetails');
              isInProgress();
              if (action === 'edit') {
                if (isEdited === true) {
                  setShowModalClose(true);
                } else {
                  navigate('/mission');
                }
              }
            }}
            onKeyPress={() => navigate('/mission')}
            role="button"
            tabIndex={0}
          >
            arrow_back
          </span>
          <p className="card-datatable-title ms-3">
            {`${title} ${type.charAt(0).toUpperCase() + type.slice(1)} Mission`}
          </p>
        </div>
        <Formik
          innerRef={formikRef}
          initialValues={initialValues}
          enableReinitialize
          validationSchema={validation}
          onSubmit={async (values) => {
            const missionType = values?.missionType === 'Daily Mission' ? 'Daily' : 'Special';
            const activationDate = moment(values?.activationDate).format(
              'YYYY-MM-DD 00:00:00',
            );

            const formData = new FormData();
            formData.append('status', 1);
            formData.append('missionType', missionType);
            formData.append('missionName', values?.missionName);
            formData.append('description', values?.description);
            formData.append('activationDate', activationDate);
            missionItemData.forEach((key, x) => {
              formData.append(
                `missionDetails[${[x]}].description`,
                key.description,
              );
              formData.append(`missionDetails[${[x]}].name`, key.name);
              formData.append(`missionDetails[${[x]}].target`, key.target);
              formData.append(`missionDetails[${[x]}].unit`, key.unit);
              if (key.objectId && key.objectId !== null) {
                formData.append(
                  `missionDetails[${[x]}].objectId`,
                  key.objectId,
                );
              }
              if (key.dzikirId && key.dzikirId !== null) {
                formData.append(
                  `missionDetails[${[x]}].objectId`,
                  key.dzikirId,
                );
              }
              if (key.type && key.type !== null) {
                formData.append(`missionDetails[${[x]}].type`, key.type);
              }
              key.missionDetailReward.forEach((el, i) => {
                formData.append(
                  `missionDetails[${[x]}].rewards[${[i]}].type`,
                  el.type,
                );
                formData.append(
                  `missionDetails[${[x]}].rewards[${[i]}].value`,
                  el.value,
                );
              });
            });

            if (values.missionType === 'Special Mission') {
              formData.append('totalDay', values?.totalDay);
              formData.append('banner', values?.banner);
              formData.append('color', values?.color);
              formData.append('badgeImage', values?.badgeImage);
              formData.append('badgeName', values?.badgeName);
              formData.append('badgeDescription', values?.badgeDescription);
            }

            const callback = () => {
              localStorage.removeItem('mission');
              localStorage.removeItem('missionDetails');
              toastSuccess('Data berhasil disimpan');
              dispatch(delValues());
              dispatch(setEditedValue(false));
              setTimeout(() => {
                navigate('/mission');
              }, 500);
            };

            if (action === 'add') {
              dispatch(MissionService.post(formData, callback));
              await delay(5000);
            } else {
              formData.append('id', id);
              missionItemData.forEach((key, x) => {
                formData.append(`missionDetails[${[x]}].missionId`, id);
                if (key.id) {
                  formData.append(`missionDetails[${[x]}].id`, key.id);
                }
                key.missionDetailReward.forEach((el, i) => {
                  if (el.id) {
                    formData.append(
                      `missionDetails[${[x]}].rewards[${[i]}].id`,
                      el.id,
                    );
                  }
                  if (key.id) {
                    formData.append(
                      `missionDetails[${[x]}].rewards[${[i]}].missionDetailId`,
                      key.id,
                    );
                  }
                });
              });
              dispatch(MissionService.put(formData, callback));
              await delay(5000);
            }
          }}
        >
          {(props) => (
            <Form>
              {/** **************** */}
              {addNewFormValues(props.values)}
              {isFormValueEdited(initialValues, props.values)}
              {/** **************** */}
              <div className="row">
                <div className="col-lg-6 col-md-12">
                  <Input
                    title="Nama Misi"
                    name="missionName"
                    type="text"
                    errors={props?.errors}
                    touched={props?.touched}
                    {...props?.getFieldProps('missionName')}
                  />
                </div>
                <div className="col-lg-6 col-md-12">
                  <Input
                    title="Deskripsi"
                    name="description"
                    type="text"
                    errors={props?.errors}
                    touched={props?.touched}
                    {...props?.getFieldProps('description')}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-lg-6 col-md-12">
                  <Input
                    title="Jenis Misi"
                    name="missionType"
                    type="text"
                    disabled
                    errors={props?.errors}
                    touched={props?.touched}
                    {...props?.getFieldProps('missionType')}
                  />
                </div>
                <div className="col-lg-6 col-md-12">
                  <Input
                    title="Tanggal Aktivasi"
                    name="activationDate"
                    type="date"
                    value={props.values.activationDate}
                    min={getDate}
                    onChange={(e) => {
                      props.setFieldValue('activationDate', e.target.value);
                    }}
                    errors={props?.errors}
                    touched={props?.touched}
                  />
                </div>
              </div>
              {type === 'special' && (
                <>
                  <div className="row">
                    <div className="col-lg-6 col-md-12">
                      <InputPrepend
                        name="totalDay"
                        label="Durasi Misi"
                        title="Hari"
                        type="number"
                        onKeyDown={(e) => {
                          exceptThisSymbols.includes(e.key)
                            && e.preventDefault();
                        }}
                        width="15%"
                        min="1"
                        errors={props?.errors}
                        touched={props?.touched}
                        {...props.getFieldProps('totalDay')}
                      />
                    </div>
                    <div className="col-lg-6 col-md-12">
                      <Input
                        title="Media"
                        name="banner"
                        filename={bannerName}
                        inputColor={inputMediaColor}
                        type="file"
                        accept="image/png, image/jpeg"
                        errors={props?.errors}
                        touched={props?.touched}
                        // {...props?.getFieldProps('banner')}
                        onChange={(e) => {
                          props?.setFieldValue('banner', e.target.files[0]);
                          setBannerName(e.target.files[0].name);
                          setInputMediaColor('color-input-black');
                        }}
                        isShowMedia={action === 'edit' && bannerName === currentBannerName}
                        onShowMedia={handleShowMediaInput}
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-6 col-md-12">
                      <Input
                        name="color"
                        title="Warna Banner Misi"
                        type="color"
                        errors={props?.errors}
                        touched={props?.touched}
                        {...props.getFieldProps('color')}
                      />
                    </div>
                    <div className="col-lg-6 col-md-12">
                      <Input
                        title="Gambar Badge"
                        name="badgeImage"
                        inputColor={inputThumbnailColor}
                        filename={badgeImgName}
                        type="file"
                        accept="image/png, image/jpeg"
                        errors={props?.errors}
                        touched={props?.touched}
                        // {...props?.getFieldProps('badgeImage')}
                        onChange={(e) => {
                          props?.setFieldValue('badgeImage', e.target.files[0]);
                          setBadgeImgName(e.target.files[0].name);
                          setInputThumnailColor('color-input-black');
                        }}
                        isShowMedia={action === 'edit' && badgeImgName === currentBadgeImgName}
                        onShowMedia={handleShowBadgeInput}
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-6 col-md-12">
                      <Input
                        name="badgeName"
                        title="Nama Badge"
                        type="text"
                        errors={props?.errors}
                        touched={props?.touched}
                        {...props.getFieldProps('badgeName')}
                      />
                    </div>
                    <div className="col-lg-6 col-md-12">
                      <Input
                        title="Deskripsi Badge"
                        name="badgeDescription"
                        type="text"
                        errors={props?.errors}
                        touched={props?.touched}
                        {...props?.getFieldProps('badgeDescription')}
                      />
                    </div>
                  </div>
                </>
              )}
              <div className="d-flex align-items-center mt-5">
                <p className="card-datatable-title">Butir Misi</p>
                <div className="ms-auto">
                  <Button
                    type="button"
                    title="Tambah"
                    leftIcon="add"
                    style={{
                      textAlign: 'center',
                      padding: '0.7em 1em',
                      borderRadius: '8px',
                      display: 'flex',
                      alignItems: 'center',
                    }}
                    onClick={() => {
                      handleStorageMissions(props);
                      navigate(`/mission/${action}/${type}/mission-item/add`);
                    }}
                  />
                </div>
              </div>
              <Table
                data={missionItemData}
                columns={columns.filter((item) => item.show !== false)}
              />
              <div className="my-5">
                <ButtonAction
                  toggle={() => {
                    localStorage.removeItem('mission');
                    localStorage.removeItem('missionDetails');
                    isInProgress();
                    if (action === 'edit') {
                      if (isEdited === true) {
                        setShowModalClose(true);
                      } else {
                        navigate('/mission');
                      }
                    }
                  }}
                  draft={() => handleDraft(props)}
                  confirmTitle="Jadwalkan"
                  draftTitle="Simpan Sebagai Draft"
                  cancelTitle="Batal"
                  withLoading={props.isSubmitting}
                  withLoadingDraft={isLoading}
                />
              </div>
            </Form>
          )}
        </Formik>
      </Card>
      <ConfirmationModal
        show={confirmModal}
        toggle={showConfirmModal}
        message="Apakah anda yakin ingin menghapus Butir Misi?"
        confirm={handleDelete}
        confirmTitle="Hapus"
        cancelTitle="Batal"
        icon="delete"
      />
      <ConfirmationModal
        show={confirmStatusModal}
        toggle={showConfirmStatusModal}
        confirm={handleChangeStatus}
        message="Apakah Anda yakin ingin menghentikan misi?"
        confirmTitle="Hentikan"
        cancelTitle="Batal"
        isWarning
      />

      <ConfirmationModal
        show={showModalClose}
        toggle={toogleCloseModal}
        inlineIcon={saveIcon}
        message={`Apakah anda yakin ingin membatalkan proses ${
          action === 'add' ? 'tambah' : 'edit'
        } ${type} mission?`}
        confirmTitle="Yakin"
        cancelTitle="Batal"
        confirm={() => {
          dispatch(delValues());
          navigate('/mission');
        }}
      />
    </div>
  );
};

export default MissionForm;
