/* eslint-disable prefer-template */
/* eslint-disable no-unused-expressions */
/* eslint-disable no-unused-vars */
export const getNotificationList = async (time, type = null) => {
  try {
    let url = `${process.env.REACT_APP_API_URL}api/notification/?time=${time}`;
    if (type !== null) {
      url += `&type=${type}`;
    }
    const response = await fetch(url);
    const result = await response.json();
    return result;
  } catch (error) {
    return error;
  }
};

export const DeleteData = async (id) => {
  try {
    const response = await fetch(`${process.env.REACT_APP_API_URL}api/notification/${id}`, {
      method: 'DELETE',
    });
    const result = await response.json();
    return result;
  } catch (error) {
    return error;
  }
};

export const getNotificationById = async (id) => {
  try {
    const response = await fetch(`${process.env.REACT_APP_API_URL}api/notification/${id}`);
    const result = await response.json();
    return result;
  } catch (error) {
    return error;
  }
};
export const editNotificationById = async (id, data) => {
  try {
    const response = await fetch(`${process.env.REACT_APP_API_URL}api/notification/${id}`, {
      method: 'PUT',
      body: data,
    });
    return response;
  } catch (error) {
    return error;
  }
};

export const AddData = async (data) => {
  try {
    const response = await fetch(`${process.env.REACT_APP_API_URL}api/notification/add`, {
      method: 'POST',
      body: data,
    });
    return response;
  } catch (error) {
    return error;
  }
};

export const searchNotification = async (keyword) => {
  try {
    const response = await fetch(`${process.env.REACT_APP_API_URL}api/notification/search?keyword=${keyword}`);
    const result = await response.json();
    return result;
  } catch (error) {
    return error;
  }
};
