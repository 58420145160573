/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable camelcase */
import { actionPending, actionSuccess, actionError } from '../actionTypes';
import { toastError } from '../../../components/toast/index';

import API from '../API';

// ACTION TYPES TO DISPATCH: METHOD_URL_TYPES
export const GET_MISSION_PENDING = 'GET_MISSION_PENDING';
export const GET_MISSION_SUCCESS = 'GET_MISSION_SUCCESS';
export const GET_MISSION_ERROR = 'GET_MISSION_ERROR';
export const POST_MISSION_PENDING = 'POST_MISSION_PENDING';
export const POST_MISSION_SUCCESS = 'POST_MISSION_SUCCESS';
export const POST_MISSION_ERROR = 'POST_MISSION_ERROR';
export const PUT_MISSION_PENDING = 'PUT_MISSION_PENDING';
export const PUT_MISSION_SUCCESS = 'PUT_MISSION_SUCCESS';
export const PUT_MISSION_ERROR = 'PUT_MISSION_ERROR';
export const GET_STATISTIC_SUMMARY_PENDING = 'GET_STATISTIC_SUMMARY_PENDING';
export const GET_STATISTIC_SUMMARY_SUCCESS = 'GET_STATISTIC_SUMMARY_SUCCESS';
export const GET_STATISTIC_SUMMARY_ERROR = 'GET_STATISTIC_SUMMARY_ERROR';
export const GET_STATISTIC_USERS_PENDING = 'GET_STATISTIC_USERS_PENDING';
export const GET_STATISTIC_USERS_SUCCESS = 'GET_STATISTIC_USERS_SUCCESS';
export const GET_STATISTIC_USERS_ERROR = 'GET_STATISTIC_USERS_ERROR';
export const GET_ACTIVITIES_PENDING = 'GET_ACTIVITIES_PENDING';
export const GET_ACTIVITIES_SUCCESS = 'GET_ACTIVITIES_SUCCESS';
export const GET_ACTIVITIES_ERROR = 'GET_ACTIVITIES_ERROR';

// URL: URL_{URL}
const MISSION_URL = 'api/mission';
const STATISTIC_URL = 'api/statistic';
const MISSION_DETAIL_URL = 'api/mission_detail';

const datatable = (param, resolve, callback) => (dispatch) => {
  dispatch(actionPending(GET_MISSION_PENDING));
  API.post(`${MISSION_URL}/datatable`, param)
    .then((response) => {
      if (response.error) {
        throw response.error;
      }
      dispatch(actionSuccess(GET_MISSION_SUCCESS, response));
      if (resolve) {
        const records_total = response.data.recordTotal;
        const data = response.data.data.map((item, i) => ({
          ...item,
          no: i + 1 + (param?.page - 1 < 0 ? 0 : param?.page) * 10,
        }));
        resolve({
          data,
          page: param?.page - 1,
          totalCount: records_total,
        });
      }
      if (callback) {
        callback();
      }
    })
    .catch((error) => {
      dispatch(actionError(GET_MISSION_ERROR));
      const message = error?.response?.data?.message;
      toastError(message);
    });
};

const post = (param, resolve, callback) => (dispatch) => {
  dispatch(actionPending(POST_MISSION_PENDING));
  API.post(`${MISSION_URL}/add`, param)
    .then((response) => {
      if (response.error) {
        throw response.error;
      }
      dispatch(actionSuccess(POST_MISSION_SUCCESS, response));
      if (resolve) {
        resolve({
          data: response.data.data,
          message: response.data.message,
          status: response.data.status,
        });
      }
      if (callback) {
        callback();
      }
    })
    .catch((error) => {
      dispatch(actionError(POST_MISSION_ERROR));
      const message = error?.response?.data?.message;
      toastError(message);
    });
};

const put = (param, resolve, callback) => (dispatch) => {
  dispatch(actionPending(PUT_MISSION_PENDING));
  API.put(`${MISSION_URL}/edit`, param)
    .then((response) => {
      if (response.error) {
        throw response.error;
      }
      dispatch(actionSuccess(PUT_MISSION_SUCCESS, response));
      if (resolve) {
        resolve({
          data: response.data.data,
          message: response.data.message,
          status: response.data.status,
        });
      }
      if (callback) {
        callback();
      }
    })
    .catch((error) => {
      dispatch(actionError(PUT_MISSION_ERROR));
      const message = error?.response?.data?.message;
      toastError(message);
    });
};
const deleteMission = (id, resolve, callback) => (dispatch) => {
  dispatch(actionPending(PUT_MISSION_PENDING));
  API.delete(`${MISSION_URL}/${id}`)
    .then((response) => {
      if (response.error) {
        throw response.error;
      }
      dispatch(actionSuccess(PUT_MISSION_SUCCESS, response));
      if (resolve) {
        resolve();
      }
      if (callback) {
        callback();
      }
    })
    .catch((error) => {
      dispatch(actionError(PUT_MISSION_ERROR));
      const message = error?.response?.data?.message;
      toastError(message);
    });
};

const stopMission = (id, resolve, callback) => (dispatch) => {
  dispatch(actionPending(PUT_MISSION_PENDING));
  API.get(`${MISSION_URL}/stop-mission/${id}`)
    .then((response) => {
      if (response.error) {
        throw response.error;
      }
      dispatch(actionSuccess(PUT_MISSION_SUCCESS, response));
      if (resolve) {
        resolve();
      }
      if (callback) {
        callback();
      }
    })
    .catch((error) => {
      dispatch(actionError(PUT_MISSION_ERROR));
      const message = error?.response?.data?.message;
      toastError(message);
    });
};

const statistic_summary = (param, resolve, callback) => (dispatch) => {
  dispatch(actionPending(GET_STATISTIC_SUMMARY_PENDING));
  API.get(`${STATISTIC_URL}/summary`, { params: param })
    .then((response) => {
      if (response.error) {
        throw response.error;
      }
      dispatch(actionSuccess(GET_STATISTIC_SUMMARY_SUCCESS, response));
      if (resolve) {
        const [data] = response.data.data;
        resolve({
          data,
        });
      }
      if (callback) {
        callback();
      }
    })
    .catch((error) => {
      dispatch(actionError(GET_STATISTIC_SUMMARY_ERROR));
      const message = error?.response?.data?.message;
      toastError(message);
    });
};

const statistic_user = (param, resolve, callback) => (dispatch) => {
  dispatch(actionPending(GET_STATISTIC_USERS_PENDING));
  API.get(`${STATISTIC_URL}/users`, { params: param })
    .then((response) => {
      if (response.error) {
        throw response.error;
      }
      dispatch(actionSuccess(GET_STATISTIC_USERS_SUCCESS, response));
      if (resolve) {
        const records_total = response.data.recordTotal;
        const data = response.data.data.map((item, i) => ({
          ...item,
          no: i + 1 + (param?.page - 1 < 0 ? 0 : param?.page) * 10,
        }));
        resolve({
          data,
          page: param?.page - 1,
          totalCount: records_total,
        });
      }
      if (callback) {
        callback();
      }
    })
    .catch((error) => {
      dispatch(actionError(GET_STATISTIC_USERS_ERROR));
      const message = error?.response?.data?.message;
      toastError(message);
    });
};

const activities = (resolve, callback) => (dispatch) => {
  dispatch(actionPending(GET_ACTIVITIES_PENDING));
  API.get(`${MISSION_DETAIL_URL}/activities`)
    .then((response) => {
      if (response.error) {
        throw response.error;
      }
      dispatch(actionSuccess(GET_ACTIVITIES_SUCCESS, response));
      if (resolve) {
        resolve({
          data: response.data,
        });
      }
      if (callback) {
        callback();
      }
    })
    .catch((error) => {
      dispatch(actionError(GET_ACTIVITIES_ERROR));
      const message = error?.response?.data?.message;
      toastError(message);
    });
};

const mission = {
  datatable,
  post,
  put,
  statistic_summary,
  statistic_user,
  activities,
  deleteMission,
  stopMission,
};

export default mission;
