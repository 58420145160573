/* eslint-disable consistent-return */
/* eslint-disable no-unused-vars */
/* eslint-disable no-use-before-define */
/* eslint-disable no-unused-expressions */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/prop-types */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable no-promise-executor-return */
/* eslint-disable jsx-a11y/label-has-associated-control */
import {
  React, useEffect, useRef, useState,
} from 'react';
import { useParams, useNavigate, Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Card } from 'react-bootstrap';
import { Formik, Form } from 'formik';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Typography from '@mui/material/Typography';

import Input from '../../components/forms/input';
import Textarea from '../../components/forms/Textarea';

import DoaService from '../../stores/actions/doa';

const styleActiveBreadcrumb = {
  fontWeight: 600,
  fontSize: '12px',
  lineHeight: '14px',
  color: 'rgba(79, 88, 103, 0.7)',
  textDecoration: 'none',
  fontFamily: 'BlueberrySans-Regular',
};

const styleInactiveBreadcrumb = {
  fontWeight: 500,
  fontSize: '12px',
  lineHeight: '14px',
  color: 'rgba(79, 88, 103, 0.7)',
  textDecoration: 'none',
  fontFamily: 'BlueberrySans-Regular',
};

const DetailDoa = () => {
  const dispatch = useDispatch();
  const formikRef = useRef();
  const navigate = useNavigate();
  const { id } = useParams();
  const [audioName, setAudioName] = useState('Upload Audio File');
  const [audioFile, setAudioFile] = useState();
  const [inputAudioColor, setInputAudioColor] = useState('');
  const [dataDoaForm, setDataDoaForm] = useState({});

  useEffect(() => {
    document.documentElement.scrollTop = 0;
    new Promise((resolve) => {
      const param = {
        id,
      };
      if (param.id !== undefined) {
        dispatch(DoaService.datatable(param, resolve));
      }
    }).then((res) => {
      if (res) {
        setDataDoaForm(res.data[0]);
        if (res?.data[0]?.audioName !== null) {
          const audioUrl = `${process.env.REACT_APP_API_URL}api/file/doa_audio/${res?.data[0]?.audioName}`;
          const audioNameFile = res?.data[0]?.audioName;
          setAudioName(audioNameFile);
          setInputAudioColor('color-input-primary');
          setAudioFile(
            new File([audioUrl], audioNameFile, { type: 'audio/mp3' }),
          );
        }
      }
    });
  }, []);

  const initialValues = {
    doaName: dataDoaForm?.doaName ?? '',
    kategori: dataDoaForm?.category ? `${dataDoaForm?.category?.categoryType} ${dataDoaForm?.category?.categoryName}` : '',
    arabicText: dataDoaForm?.arabicText ?? '',
    latinText: dataDoaForm?.latinText ?? '',
    translate: dataDoaForm?.translate ?? '',
    referensi: dataDoaForm?.referensi ?? '',
    audio: audioFile ?? '',
  };

  return (
    <div className="content-container-side">
      <div className="d-flex">
        <p className="content-title">Doa</p>
      </div>
      <div className="mb-4">
        <Breadcrumbs aria-label="breadcrumb" separator="">
          <Link
            color="inherit"
            to="/doa"
            onClick={() => navigate('/doa')}
            style={styleActiveBreadcrumb}
          >
            Doa
          </Link>
          <Typography style={styleInactiveBreadcrumb}>
            Detail Doa
          </Typography>
        </Breadcrumbs>
      </div>
      <Card className="card-datatable">
        <div className="d-flex">
          <span
            className="material-icons clickable"
            onClick={() => navigate('/doa')}
            onKeyPress={() => navigate('/doa')}
            role="button"
            tabIndex={0}
          >
            arrow_back
          </span>
          <p className="card-datatable-title ms-3">
            Detail Doa
          </p>
        </div>
        <Formik
          innerRef={formikRef}
          initialValues={initialValues}
          enableReinitialize
        >
          {(props) => (
            <Form>
              <Input
                disabled
                title="Nama Doa"
                name="doaName"
                type="text"
                errors={props?.errors}
                touched={props?.touched}
                {...props?.getFieldProps('doaName')}
              />
              <Input
                disabled
                title="Kategori"
                name="kategori"
                type="text"
                errors={props?.errors}
                touched={props?.touched}
                {...props?.getFieldProps('kategori')}
              />
              <Input
                disabled
                title="Arab"
                name="arabicText"
                type="text"
                errors={props?.errors}
                touched={props?.touched}
                {...props?.getFieldProps('arabicText')}
              />
              <Input
                disabled
                title="Transliterasi"
                name="latinText"
                type="text"
                errors={props?.errors}
                touched={props?.touched}
                {...props?.getFieldProps('latinText')}
              />
              <Textarea
                disabled
                title="Translasi"
                name="translate"
                type="text"
                errors={props?.errors}
                touched={props?.touched}
                {...props?.getFieldProps('translate')}
                rows={3}
              />
              <Textarea
                disabled
                title="Referensi"
                name="referensi"
                type="text"
                errors={props?.errors}
                touched={props?.touched}
                {...props?.getFieldProps('referensi')}
                rows={3}
              />
              <Input
                disabled
                title="Audio"
                name="audio"
                filename={audioName}
                inputColor={inputAudioColor}
                type="file"
                accept="audio/mp3"
                errors={props?.errors}
                touched={props?.touched}
              />
            </Form>
          )}
        </Formik>
      </Card>
    </div>
  );
};

export default DetailDoa;
