/* eslint-disable import/no-named-as-default-member */
/* eslint-disable import/no-named-as-default */
/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { Routes, Route } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';

import Login from './views/auth/Login';
import Dashboard from './views/dashboard/index';
import User from './views/user/index';
import DetailUser from './views/user/detail';
import Dzikir from './views/dzikir/index';
import DzikirForm from './views/dzikir/DzikirForm';
import DetailDzikir from './views/dzikir/DetailDzikir';
import Doa from './views/doa/index';
import DoaForm from './views/doa/DoaForm';
import DetailDoa from './views/doa/DetailDoa';
import Misi from './views/mission/index';
import DetailMisi from './views/mission/DetailMission';
import MissionForm from './views/mission/MissionForm';
import MissionItem from './views/mission/MissionItem';
import Notification from './views/notification/index';
import Whatsnew from './views/whatNews/index';
import DetailWhatNew from './views/whatNews/detail';
import EditFormWhatNew from './views/whatNews/EditFormWhatNews';

import PrivateRoute from './components/helpers/PrivateRoute/index';
import DailyNotificationForm from './views/notification/DailyNotificationForm';
import SpecialNotificationForm from './views/notification/SpecialNotificationForm';
import AddWhats from './views/whatNews/addWhats';

import DetailProgram from './views/infaq/program/DetailProgram';
import ProgramInfaq from './views/infaq/program/index';
import PenggunaanInfaq from './views/infaq/penggunaan/index';
import AddProgramInfaq from './views/infaq/program/AddProgramInfaq';
import DaftarPenerimaan from './views/infaq/penerimaan/index';
import AddPencairanInfaq from './views/infaq/penggunaan/pencairan/AddPencairan';
import AddPenyaluranInfaq from './views/infaq/penggunaan/penyaluran/AddPenyaluran';
import EditProgram from './views/infaq/program/EditProgram';
import PrintNote from './views/infaq/penerimaan/PrintNote';

import DetailPenerimaan from './views/infaq/penerimaan/DetailPenerimaan';

import DetailPencairan from './views/infaq/penggunaan/pencairan/DetailPencairan';
import EditPencairan from './views/infaq/penggunaan/pencairan/EditPencairan';
import DetailPenyaluran from './views/infaq/penggunaan/penyaluran/DetailPenyaluran';
import EditPenyaluran from './views/infaq/penggunaan/penyaluran/EditPenyaluran';

function App() {
  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={false}
        hideProgressBar={false}
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnVisibilityChange
        draggable
        pauseOnHover
      />
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="/login" element={<Login />} />
        <Route
          path="/dashboard"
          element={<PrivateRoute element={<Dashboard />} />}
        />
        <Route path="/user" element={<PrivateRoute element={<User />} />} />
        <Route
          path="/user/detail/:id"
          element={<PrivateRoute element={<DetailUser />} />}
        />
        <Route path="/dzikir" element={<PrivateRoute element={<Dzikir />} />} />
        <Route
          path="/dzikir/detail/:id"
          element={<PrivateRoute element={<DetailDzikir />} />}
        />
        <Route
          path="/dzikir/:action"
          element={<PrivateRoute element={<DzikirForm />} />}
        />
        <Route
          path="/dzikir/:action/:id"
          element={<PrivateRoute element={<DzikirForm />} />}
        />
        <Route path="/doa" element={<PrivateRoute element={<Doa />} />} />
        <Route
          path="/doa/:action"
          element={<PrivateRoute element={<DoaForm />} />}
        />
        <Route
          path="/doa/:action/:id"
          element={<PrivateRoute element={<DoaForm />} />}
        />
        <Route
          path="/doa/detail/:id"
          element={<PrivateRoute element={<DetailDoa />} />}
        />
        <Route path="/mission" element={<PrivateRoute element={<Misi />} />} />
        <Route
          path="/mission/detail/:type/:id"
          element={<PrivateRoute element={<DetailMisi />} />}
        />
        <Route
          path="/mission/:action/:type"
          element={<PrivateRoute element={<MissionForm />} />}
        />
        <Route
          path="/mission/:action/:type/:id"
          element={<PrivateRoute element={<MissionForm />} />}
        />
        <Route
          path="/mission/:action/:type/mission-item/:subaction"
          element={<PrivateRoute element={<MissionItem />} />}
        />
        <Route
          path="/mission/:action/:type/mission-item/:subaction/:id"
          element={<PrivateRoute element={<MissionItem />} />}
        />
        <Route
          path="/whatsnew"
          element={<PrivateRoute element={<Whatsnew />} />}
        />
        <Route
          path="/whatsnew/add"
          element={<PrivateRoute element={<AddWhats />} />}
        />
        <Route
          path="/notification"
          element={<PrivateRoute element={<Notification />} />}
        />
        <Route
          path="/notification/add"
          element={(
            <PrivateRoute
              element={<DailyNotificationForm formType="Tambah Notifikasi" />}
            />
          )}
        />
        <Route
          path="/notification/add-special"
          element={(
            <PrivateRoute
              element={<SpecialNotificationForm formType="Tambah Notifikasi" />}
            />
          )}
        />
        <Route
          path="/notification/edit-daily/:id"
          element={(
            <PrivateRoute
              element={
                <DailyNotificationForm formType="Edit Notifikasi Rutin" />
              }
            />
          )}
        />
        <Route
          path="/notification/edit-special/:id"
          element={(
            <PrivateRoute
              element={
                <SpecialNotificationForm formType="Edit Notifikasi Khusus" />
              }
            />
          )}
        />
        <Route
          path="/detail-what-news/:id"
          element={<PrivateRoute element={<DetailWhatNew />} />}
        />

        <Route
          path="/whatsnew/edit/:id"
          element={<PrivateRoute element={<EditFormWhatNew />} />}
        />

        <Route
          path="/infaq/program"
          element={<PrivateRoute element={<ProgramInfaq />} />}
        />

        <Route
          path="/infaq"
          element={<PrivateRoute element={<ProgramInfaq />} />}
        />

        <Route
          path="infaq/program/detail-program/:id"
          element={<PrivateRoute element={<DetailProgram />} />}
        />

        <Route
          path="/infaq/program/add"
          element={<PrivateRoute element={<AddProgramInfaq />} />}
        />

        <Route
          path="/infaq/program/edit-program/:id"
          element={<PrivateRoute element={<EditProgram />} />}
        />
        <Route
          path="/infaq/penerimaan"
          element={<PrivateRoute element={<DaftarPenerimaan />} />}
        />

        <Route
          path="/infaq/penerimaan/detail-penerimaan/:id"
          element={<PrivateRoute element={<DetailPenerimaan />} />}
        />

        <Route
          path="/infaq/penerimaan/cetak-nota/:id"
          element={<PrivateRoute element={<PrintNote />} />}
        />

        <Route
          path="/infaq/penggunaan"
          element={<PrivateRoute element={<PenggunaanInfaq />} />}
        />

        <Route
          path="/infaq/penggunaan/pencairan/add"
          element={<PrivateRoute element={<AddPencairanInfaq />} />}
        />

        <Route
          path="/infaq/penggunaan/detail-pencairan/:id"
          element={<PrivateRoute element={<DetailPencairan />} />}
        />

        <Route
          path="/infaq/penggunaan/edit-pencairan/:id"
          element={<PrivateRoute element={<EditPencairan />} />}
        />

        <Route
          path="/infaq/penggunaan/detail-penyaluran/:id"
          element={<PrivateRoute element={<DetailPenyaluran />} />}
        />

        <Route
          path="/infaq/penggunaan/penyaluran/add"
          element={<PrivateRoute element={<AddPenyaluranInfaq />} />}
        />

        <Route
          path="/infaq/penggunaan/edit-penyaluran/:id"
          element={<PrivateRoute element={<EditPenyaluran />} />}
        />
      </Routes>
    </>
  );
}

export default App;
