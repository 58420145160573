/* eslint-disable default-case-last */
/* eslint-disable no-param-reassign */
/* eslint-disable no-plusplus */
/* eslint-disable no-undef */
/* eslint-disable no-unused-vars */
/* eslint-disable default-param-last */
const initialState = {
  deleted: 0,
  edited: 0,
};
const notificationReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case 'SET_DELETED_STATE':
      return {
        ...state,
        deleted: state.deleted + 1,
      };
    default:
      return {
        ...state,
      };
  }
};

export default notificationReducer;
