/* eslint-disable arrow-body-style */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/prop-types */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-shadow */
import { React, useEffect, useState } from 'react';
import Select from 'react-select';

const Select2 = ({
  name,
  title,
  value,
  filter,
  onBlur,
  errors,
  touched,
  variant,
  options,
  rounded,
  disabled,
  readonly,
  onChangeEvent,
  placeholder,
  isSearchable,
  ...otherProps
}) => {
  const [isValid, setIsValid] = useState(
    errors && touched ? !(errors[name] && touched[name]) : true,
  );

  const customStyles = {
    control: (base, state) => ({
      ...base,
      borderRadius: rounded ? '30px' : '5px',
      borderColor: state.isFocused ? '#ddd' : !isValid ? 'red' : '#ddd',
      height: 45,
      minHeight: 45,
      '&:hover': {
        borderColor: state.isFocused ? '#ddd' : !isValid ? 'red' : '#ddd',
      },
      marginLeft: rounded ? '8px' : '0px',
    }),
    option: (options) => ({
      ...options,
      textAlign: 'left',
    }),
    placeholder: (defaultStyles) => {
      return {
        ...defaultStyles,
        color: 'rgba(79, 88, 103, 0.5)',
        fontSize: 14,
        fontFamily: 'BlueberrySans-Regular',
      };
    },
  };

  useEffect(() => {
    setIsValid(errors && touched ? !(errors[name] && touched[name]) : true);
  }, [errors, touched]);

  const customFilter = (option, searchText) => {
    if (option.label.toLowerCase().includes(searchText.toLowerCase())) {
      return true;
    }
    return false;
  };

  return (
    <div className="form-group" style={{ marginBottom: filter ? 0 : 10 }}>
      {title ? (
        <label className="form-label">{title}</label>
      ) : (
        <label className="form-label" style={{ marginBottom: '1.8em' }} />
      )}
      <Select
        {...otherProps}
        name={name}
        isClearable="true"
        options={options}
        isDisabled={disabled}
        isReadonly={readonly}
        isSearchable={isSearchable}
        styles={customStyles}
        classNamePrefix="select"
        className={`basic-single ${variant ?? ''} `}
        onChange={onChangeEvent}
        onBlur={onBlur}
        placeholder={placeholder}
        filterOption={customFilter}
        components={{
          IndicatorSeparator: () => null,
        }}
      />
      <div
        className="invalid-feedback"
        style={{ display: isValid ? 'none' : 'block' }}
      >
        {errors ? errors[name] : ''}
      </div>
    </div>
  );
};

export default Select2;
