/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable camelcase */
import axios from 'axios';
import Swal from 'sweetalert2';
import { actionPending, actionSuccess, actionError } from '../actionTypes';
import { toastError, toastSuccess } from '../../../components/toast/index';

// import API from '../API';

// ACTION TYPES TO DISPATCH: METHOD_URL_TYPES
export const GET_DOA_PENDING = 'GET_DOA_PENDING';
export const GET_DOA_SUCCESS = 'GET_DOA_SUCCESS';
export const GET_DOA_ERROR = 'GET_DOA_ERROR';
export const GET_CATEGORIES_PENDING = 'GET_CATEGORIES_PENDING';
export const GET_CATEGORIES_SUCCESS = 'GET_CATEGORIES_SUCCESS';
export const GET_CATEGORIES_ERROR = 'GET_CATEGORIES_ERROR';
export const POST_DOA_PENDING = 'POST_DOA_PENDING';
export const POST_DOA_SUCCESS = 'POST_DOA_SUCCESS';
export const POST_DOA_ERROR = 'POST_DOA_ERROR';
export const PUT_DOA_PENDING = 'PUT_DOA_PENDING';
export const PUT_DOA_SUCCESS = 'PUT_DOA_SUCCESS';
export const PUT_DOA_ERROR = 'PUT_DOA_ERROR';
export const DELETE_DOA_PENDING = 'DELETE_DOA_PENDING';
export const DELETE_DOA_SUCCESS = 'DELETE_DOA_SUCCESS';
export const DELETE_DOA_ERROR = 'DELETE_DOA_ERROR';

// URL: URL_{URL}
const DOA_URL = 'api/doa';
const CATEGORY_URL = 'api/category';

const user = JSON.parse(localStorage.getItem('user_dzikra'));
const headers = { 'x-auth-userid': user !== null ? user.id : '' };

const datatable = (param, resolve, callback) => (dispatch) => {
  dispatch(actionPending(GET_DOA_PENDING));
  axios.post(`${process.env.REACT_APP_API_URL}${DOA_URL}/datatable`, param, { headers })
    .then((response) => {
      if (response.error) {
        throw response.error;
      }
      dispatch(actionSuccess(GET_DOA_SUCCESS, response));
      if (resolve) {
        const records_total = response.data.recordTotal;
        const data = response.data.data.map((item, i) => ({
          ...item,
          no: i + 1 + (param?.page - 1 < 0 ? 0 : param?.page) * 10,
        }));
        resolve({
          data,
          page: param?.page - 1,
          totalCount: records_total,
        });
      }
      if (callback) {
        callback();
      }
    })
    .catch((error) => {
      dispatch(actionError(GET_DOA_ERROR));
      const message = error?.response?.data?.message;
      toastError(message);
    });
};

const post = (param, resolve, callback) => (dispatch) => {
  dispatch(actionPending(POST_DOA_PENDING));
  axios.post(`${process.env.REACT_APP_API_URL}${DOA_URL}`, param, { headers })
    .then((response) => {
      if (response.error) {
        throw response.error;
      }
      dispatch(actionSuccess(POST_DOA_SUCCESS, response));
      Swal.fire({
        title: 'Berhasil!',
        text: 'Data telah berhasil disimpan!',
        icon: 'success',
        confirmButtonColor: '#2EC4B6',
        confirmButtonText: 'Kembali',
      });
      if (resolve) {
        resolve({
          data: response.data.data,
          message: response.data.message,
          status: response.data.status,
        });
      }
      if (callback) {
        callback();
      }
    })
    .catch((error) => {
      dispatch(actionError(POST_DOA_ERROR));
      const message = error?.response?.data?.message;
      toastError(message);
    });
};

const put = (param, resolve, callback) => (dispatch) => {
  dispatch(actionPending(PUT_DOA_PENDING));
  axios.put(`${process.env.REACT_APP_API_URL}${DOA_URL}`, param, { headers })
    .then((response) => {
      if (response.error) {
        throw response.error;
      }
      dispatch(actionSuccess(PUT_DOA_SUCCESS, response));
      Swal.fire({
        title: 'Berhasil!',
        text: 'Data telah berhasil diperbarui!',
        icon: 'success',
        confirmButtonColor: '#2EC4B6',
        confirmButtonText: 'Kembali',
      });
      if (resolve) {
        resolve({
          data: response.data.data,
          message: response.data.message,
          status: response.data.status,
        });
      }
      if (callback) {
        callback();
      }
    })
    .catch((error) => {
      dispatch(actionError(PUT_DOA_ERROR));
      const message = error?.response?.data?.message;
      toastError(message);
    });
};

const deleteDoa = (param, callback) => (dispatch) => {
  dispatch(actionPending(DELETE_DOA_PENDING));
  axios.delete(`${process.env.REACT_APP_API_URL}${DOA_URL}/${param}`, { headers })
    .then((response) => {
      if (response.error) {
        throw response.error;
      }
      dispatch(actionSuccess(DELETE_DOA_SUCCESS, response));
      toastSuccess('Data Doa Berhasil Dihapus');
      if (callback) {
        callback();
      }
    })
    .catch((error) => {
      dispatch(actionError(DELETE_DOA_ERROR));
      const message = error?.response?.data?.message;
      toastError(message);
    });
};

const dataCategories = (param, resolve, callback) => (dispatch) => {
  dispatch(actionPending(GET_CATEGORIES_PENDING));
  axios.post(`${process.env.REACT_APP_API_URL}${CATEGORY_URL}/datatable`, param, { headers })
    .then((response) => {
      if (response.error) {
        throw response.error;
      }
      dispatch(actionSuccess(GET_CATEGORIES_SUCCESS, response));
      if (resolve) {
        resolve({
          data: response.data.data,
        });
      }
      if (callback) {
        callback();
      }
    })
    .catch((error) => {
      dispatch(actionError(GET_CATEGORIES_ERROR));
      const message = error?.response?.data?.message;
      toastError(message);
    });
};

const doa = {
  datatable,
  deleteDoa,
  dataCategories,
  post,
  put,
};

export default doa;
