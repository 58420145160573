export const addValues = (data) => ({
  type: 'ADD_MISSION',
  payload: data,
});
export const delValues = (data) => ({
  type: 'DEL_MISSION',
  payload: data,
});

export const setEditedValue = (data) => ({
  type: 'EDITED_MISSION',
  payload: data,
});

export const setAction = (data) => ({
  type: 'SET_ACTION_MISSION',
  payload: data,
});

export const setAddedMissionValue = (data) => ({
  type: 'ADDED_MISSION',
  payload: data,
});

export const setType = (data) => ({
  type: 'SET_TYPE_MISSION',
  payload: data,
});
