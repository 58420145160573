/* eslint-disable consistent-return */
/* eslint-disable no-unused-vars */
/* eslint-disable no-use-before-define */
/* eslint-disable no-unused-expressions */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/prop-types */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable no-promise-executor-return */
/* eslint-disable jsx-a11y/label-has-associated-control */
import { React, useState, useEffect } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { Card } from 'react-bootstrap';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Typography from '@mui/material/Typography';

import Textarea from '../../components/forms/Textarea';
import Input from '../../components/forms/input';
import InputPrepend from '../../components/forms/InputPrepend';

const styleActiveBreadcrumb = {
  fontWeight: 600,
  fontSize: '12px',
  lineHeight: '14px',
  color: 'rgba(79, 88, 103, 0.7)',
  textDecoration: 'none',
  fontFamily: 'BlueberrySans-Regular',
};

const styleInactiveBreadcrumb = {
  fontWeight: 500,
  fontSize: '12px',
  lineHeight: '14px',
  color: 'rgba(79, 88, 103, 0.7)',
  textDecoration: 'none',
  fontFamily: 'BlueberrySans-Regular',
};

const DetailDzikirItem = ({
  formik,
  toggle,
  index,
}) => {
  const navigate = useNavigate();
  const [audioName, setAudioName] = useState('Upload Audio File');
  const [inputAudioColor, setInputAudioColor] = useState('');

  useEffect(() => {
    if (formik?.values?.dzikirDetails[index]?.audioName !== null
        && formik?.values?.dzikirDetails[index]?.audioName !== undefined
    ) {
      setAudioName(formik?.values?.dzikirDetails[index]?.audioName);
      setInputAudioColor('color-input-primary');
    }
  }, []);

  return (
    <div className="content-container-side">
      <div className="d-flex">
        <p className="content-title">
          Dzikir
        </p>
      </div>
      <div className="mb-4">
        <Breadcrumbs aria-label="breadcrumb" separator="">
          <Link
            color="inherit"
            to="/dzikir"
            onClick={() => navigate('/dzikir')}
            style={styleActiveBreadcrumb}
          >
            Dzikir
          </Link>
          <Link
            color="inherit"
            to={() => toggle()}
            onClick={() => toggle()}
            style={styleActiveBreadcrumb}
          >
            Detail Dzikir
          </Link>
          <Typography style={styleInactiveBreadcrumb}>
            Detail Bacaan Dzikir
          </Typography>
        </Breadcrumbs>
      </div>
      <Card className="card-datatable">
        <div className="d-flex">
          <span
            className="material-icons clickable"
            onClick={() => toggle()}
            onKeyPress={() => toggle()}
            role="button"
            tabIndex={0}
          >
            arrow_back
          </span>
          <p className="card-datatable-title ms-3">
            Detail Bacaan Dzikir
          </p>
        </div>
        <Input
          disabled
          title="Nama Bacaan Dzikir"
          name={`dzikirDetails[${index}].doaName`}
          type="text"
          errors={formik?.errors}
          touched={formik?.touched}
          {...formik?.getFieldProps(`dzikirDetails[${index}].doaName`)}
        />
        <Input
          disabled
          title="Arab"
          name={`dzikirDetails[${index}].arabicText`}
          type="text"
          errors={formik?.errors}
          touched={formik?.touched}
          {...formik?.getFieldProps(`dzikirDetails[${index}].arabicText`)}
        />
        <Input
          disabled
          title="Transliterasi"
          name={`dzikirDetails[${index}].latinText`}
          type="text"
          errors={formik?.errors}
          touched={formik?.touched}
          {...formik?.getFieldProps(`dzikirDetails[${index}].latinText`)}
        />
        <Textarea
          disabled
          title="Translasi"
          name={`dzikirDetails[${index}].translate`}
          type="text"
          errors={formik?.errors}
          touched={formik?.touched}
          {...formik?.getFieldProps(`dzikirDetails[${index}].translate`)}
          rows={3}
        />
        <InputPrepend
          disabled
          name={`dzikirDetails[${index}].repeat`}
          label="Pengulangan"
          title="x"
          type="number"
          width="5%"
          errors={formik?.errors}
          touched={formik?.touched}
          {...formik.getFieldProps(`dzikirDetails[${index}].repeat`)}
        />
        <Textarea
          disabled
          title="Referensi"
          name={`dzikirDetails[${index}].referensi`}
          type="text"
          errors={formik?.errors}
          touched={formik?.touched}
          {...formik?.getFieldProps(`dzikirDetails[${index}].referensi`)}
          rows={3}
        />
        <Input
          disabled
          title="Audio"
          name={`dzikirDetails[${index}].audio`}
          filename={audioName}
          inputColor={inputAudioColor}
          type="file"
          accept="audio/mp3,audio/*"
          errors={formik?.errors}
          touched={formik?.touched}
        />
      </Card>
    </div>
  );
};

export default DetailDzikirItem;
