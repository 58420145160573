/* eslint-disable import/prefer-default-export */
export const getupdateMobile = async () => {
  try {
    const response = await fetch(`${process.env.REACT_APP_API_URL}/api/mobile-update`, {
      method: 'GET',
    });
    const result = await response.json();
    return result;
  } catch (error) {
    return error;
  }
};

export const getUpdateMobileById = async (id) => {
  try {
    const response = await fetch(`${process.env.REACT_APP_API_URL}/api/mobile-update/${id}`);
    const result = await response.json();
    return result;
  } catch (error) {
    return error;
  }
};

export const addVersion = async (data) => {
  try {
    const response = await fetch(`${process.env.REACT_APP_API_URL}/api/mobile-update/add`, {
      method: 'POST',
      body: JSON.stringify(data),
      headers: {
        'Content-Type': 'application/json',
      },
    });
    let message = '';
    if (response.status === 200) {
      message = 'success';
    } else {
      const result = await response.json();
      message = result.message;
    }
    return { status: response.status, message };
  } catch (error) {
    return error;
  }
};

export const updateWhatNewById = async (id, data) => {
  try {
    const response = await fetch(`${process.env.REACT_APP_API_URL}/api/mobile-update/${id}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    });
    return response;
  } catch (error) {
    return error;
  }
};

export const getSortVersion = async (sort) => {
  try {
    const response = await fetch(`${process.env.REACT_APP_API_URL}/api/mobile-update/?order=${sort}`);
    const result = await response.json();
    return result;
  } catch (error) {
    return error;
  }
};
