/* eslint-disable react/prop-types */
import { React, useState } from 'react';
import Menu from '@mui/material/Menu';
import Button from '@mui/material/Button';
import SortIcon from '@mui/icons-material/Sort';

function Sort({ children, customClass }) {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const styledButton = {
    textTransform: 'capitalize',
    color: '#4F5867',
    background: '#fff',
    border: '1px solid #e4e4e4',
    borderRadius: '8px',
    boxShadow: 'none',
  };

  return (
    <div className={customClass}>
      <Button
        variant="contained"
        aria-label="filter"
        aria-controls="long-menu"
        aria-haspopup="true"
        size="medium"
        style={styledButton}
        onClick={handleClick}
        endIcon={<SortIcon />}
      >
        Sort
      </Button>
      <Menu
        id="demo-customized-menu"
        MenuListProps={{
          'aria-labelledby': 'demo-customized-button',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        {children}
      </Menu>
    </div>
  );
}

export default Sort;
