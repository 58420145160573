/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/prop-types */
/* eslint-disable no-nested-ternary */
import { React, useEffect, useState } from 'react';

const InputPrepend2 = ({
  name,
  value,
  disabled,
  title,
  label,
  placeholder,
  errors,
  touched,
  type,
  min,
  width,
  mt,
  mb,
  onKeyUp,
  onChange,
  onKeyDown,
}) => {
  const [isValid, setIsValid] = useState(
    errors && touched ? !(errors[name] && touched[name]) : true,
  );

  useEffect(() => {
    setIsValid(errors && touched ? !(errors[name] && touched[name]) : true);
  }, [errors, touched]);
  // const error = errors
  //   ? (
  //     objname && objname?.parent
  //       ? (
  //         errors[objname?.parent]
  //           ? errors[objname?.parent][objname?.child]
  //           : errors[objname?.parent]
  //       )
  //       : errors[name]
  //   )
  //   : false;
  // const touch = touched
  //   ? (
  //     objname && objname?.parent
  //       ? (
  //         touched[objname?.parent]
  //           ? touched[objname?.parent][objname?.child]
  //           : touched[objname?.parent]
  //       )
  //       : touched[name]
  //   )
  //   : false;
  // const border = (error && touch
  //   ? 'solid 1px red'
  //   : '');

  return (
    <div className="form-group" style={{ marginBottom: '0', width: '100%' }}>
      {label && (
        <label className="form-label">
          {label}
        </label>
      )}
      <div className={`input-group mb-${mb ?? 3} mt-${mt ?? 0}`}>
        <div
          className="input-group-append"
          style={{
            width: width ?? '50%',
          }}
        >
          <span
            className={`input-group-text ${disabled ? 'input-group-text-disabled2' : 'input-group-text-disabled2'} ${isValid ? '' : 'br-red'}`}
            value={value != null ? value : ''}
            style={{
              width: '100%',
            // border: { border },
            }}
            id="basic-addon1"
          >
            {title}
          </span>
        </div>
        <input
          name={name}
          type={type ?? 'text'}
          min={min}
          className={`form-control form-input ${disabled ? 'input-disabled ' : ''} ${isValid ? '' : 'br-red'}`}
          value={value != null ? value : ''}
          onChange={onChange}
          onKeyUp={onKeyUp}
          onKeyDown={onKeyDown}
          disabled={disabled}
          placeholder={placeholder ?? `Masukkan ${label}`}
          aria-label={placeholder}
          aria-describedby="basic-addon1"
          style={{ borderRightStyle: 'none' }}
        />
      </div>
      <div
        className="invalid-feedback"
        style={{ display: isValid ? 'none' : 'block' }}
      >
        {errors ? errors[name] : ''}
      </div>
    </div>
  );
};
export default InputPrepend2;
