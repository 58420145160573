/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/img-redundant-alt */
/* eslint-disable import/no-named-as-default-member */
/* eslint-disable import/no-named-as-default */
/* eslint-disable max-len */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable react/jsx-no-comment-textnodes */
/* eslint-disable react/jsx-no-undef */
/* eslint-disable no-undef */
/* eslint-disable no-unused-vars */
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Typography from '@mui/material/Typography';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Card } from 'react-bootstrap';
import React, {
  useState, useRef, useMemo, useEffect,
} from 'react';
import ReactQuill from 'react-quill';
import moment from 'moment/moment';
import Input from '../../../components/forms/input';
import Textarea from '../../../components/forms/Textarea';
import InputPrepend from '../../../components/forms/InputPrepend';
import InputPrepend2 from '../../../components/forms/InputPrepend2';
import ImageModal from '../../../components/modal/ImageModal';
import 'react-toastify/dist/ReactToastify.css';
import 'react-quill/dist/quill.snow.css';
import { getProgramInfaqById } from '../../../services/infaq/index';

const styleInactiveBreadcrumb = {
  fontWeight: 500,
  fontSize: '12px',
  lineHeight: '14px',
  color: 'rgba(79, 88, 103, 0.7)',
  textDecoration: 'none',
  fontFamily: 'BlueberrySans-Regular',
};
const styleActiveBreadcrumb = {
  fontWeight: 600,
  fontSize: '12px',
  lineHeight: '14px',
  color: 'rgba(79, 88, 103, 0.7)',
  textDecoration: 'none',
  fontFamily: 'BlueberrySans-Regular',
};

function detailProgram() {
  const navigate = useNavigate();
  const { id } = useParams();
  const [programName, setProgramName] = useState('');
  const [media, setMedia] = useState(null);
  const [mediaName, setMediaName] = useState('');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [donationTarget, setDonationTarget] = useState('');
  const [duration, setDuration] = useState('0');
  const [status, setStatus] = useState('');
  const [fundraiser, setFundraiser] = useState('');
  const [storyThumbnail, setStoryThumbnail] = useState(null);
  const [storyThumbnailName, setStoryThumbnailName] = useState(null);
  const [startTime, setStartTime] = useState('');
  const [endTime, setEndTime] = useState('');
  const [endTimeDisplay, setEndTimeDisplay] = useState('');
  const [repeat, setRepeat] = useState('');
  const [storyDescription, setStoryDescription] = useState('');
  const [quillValue, setQuillValue] = useState('');
  const [showImageMedia, setShowImageMedia] = useState(false);
  const [showThumbnail, setShowThumbnail] = useState(false);
  const quillRef = useRef();

  useEffect(() => {
    const handleDetailProgram = async () => {
      const detailProgramInfaq = await getProgramInfaqById(id);
      setProgramName(detailProgramInfaq.programName);
      setMedia(detailProgramInfaq.media);
      if (detailProgramInfaq.media !== null) {
        const mediaFilename = detailProgramInfaq.media.split('/').pop();
        setMediaName(mediaFilename);
      }
      setStoryThumbnail(detailProgramInfaq.storyThumbnail);
      if (detailProgramInfaq.storyThumbnail !== null) {
        const storyThumbnailFilename = detailProgramInfaq.storyThumbnail.split('/').pop();
        setStoryThumbnailName(storyThumbnailFilename);
      }
      setStartDate(detailProgramInfaq.startDate);
      setEndDate(detailProgramInfaq.endDate);
      const moneyFormatter = new Intl.NumberFormat();
      const donationFormat = moneyFormatter.format(detailProgramInfaq.donationTarget);
      setDonationTarget(donationFormat.replaceAll(',', '.'));
      if (detailProgramInfaq.status) {
        setStatus('Aktif');
      } else {
        setStatus('Tidak Aktif');
      }
      setFundraiser(detailProgramInfaq.fundraiser);
      setStartTime(detailProgramInfaq.startTime);
      setEndTime(detailProgramInfaq.endTime);
      setRepeat(detailProgramInfaq.repeat);
      setStoryDescription(detailProgramInfaq.storyDescription);
      setQuillValue(detailProgramInfaq.description);

      const start = moment(detailProgramInfaq.startDate, 'YYYY-MM-DD');
      const end = moment(detailProgramInfaq.endDate, 'YYYY-MM-DD');
      const day = moment.duration(end.diff(start)).asDays();
      setDuration(day);
    };
    handleDetailProgram();
  }, []);

  const handleShowMediaInput = () => {
    setShowImageMedia(true);
  };

  const handleCloseMediaInput = () => {
    setShowImageMedia(false);
  };
  const handleShowThumbnailInput = () => {
    setShowThumbnail(true);
  };

  const handleCloseThumnailInput = () => {
    setShowThumbnail(false);
  };

  const modules = useMemo(() => ({
    toolbar: {
      container: [
        [{ font: [] }],
        [{ header: [1, 2, 3, 4, 5, 6, false] }],
        ['bold', 'italic', 'underline', 'strike'],
        [{ color: [] }, { background: [] }],
        [{ script: 'sub' }, { script: 'super' }],
        ['blockquote', 'code-block'],
        [{ list: 'ordered' }, { list: 'bullet' }],
        [{ indent: '-1' }, { indent: '+1' }, { align: [] }],
        ['link', 'image'],
        ['clean'],
      ],
    },
  }), []);
  return (
    <div className="content-container-side">
      <ImageModal show={showImageMedia} handleClose={handleCloseMediaInput} srcMedia={media} />
      <ImageModal show={showThumbnail} handleClose={handleCloseThumnailInput} srcMedia={storyThumbnail} />
      <div className="d-flex">
        <p className="content-title">
          Infaq
        </p>
      </div>
      <div className="mb-4">
        <Breadcrumbs aria-label="breadcrumb" separator="">
          <Link
            color="inherit"
            to="/infaq/program"
            onClick={() => {
            }}
            style={styleActiveBreadcrumb}
          >
            Daftar Program
          </Link>
          <Typography style={styleInactiveBreadcrumb}>
            Detail Program
          </Typography>
        </Breadcrumbs>
      </div>
      <Card className="card-datatable">
        <div className="d-flex flex-wrap align-items-center">
          <span
            className="material-icons clickable"
            style={{ marginTop: '-12px' }}
            onClick={() => {
              navigate('/infaq/program');
            }}
            onKeyPress={() => navigate('/whatnew')}
            role="button"
            tabIndex={0}
          >
            arrow_back
          </span>
          <p className="card-datatable-title ms-3">
            Detail Program
          </p>
        </div>
        <div className="row">
          <div className="col-lg-6 col-md-12">
            <Input
              title="Nama Donasi"
              name="programName"
              type="text"
              value={programName}
              disabled
            />
          </div>
          <div className="col-lg-6 col-md-12">
            <Input
              title="Poster"
              name="media"
              type="file"
              filename={mediaName}
              isShowMedia
              onShowMedia={handleShowMediaInput}
              disabled
              accept="image/png, image/jpeg"
            />
          </div>
        </div>
        <div className="row">
          <div className="col-lg-6 col-md-6">
            <Input
              title="Tanggal Program"
              name="startDate"
              type="text"
              value={`${startDate} - ${endDate}`}
              disabled
            />
          </div>
          <div className="col-lg-6 col-md-12">
            <InputPrepend2
              label="Target Donasi"
              title="Rp"
              name="donationTarget"
              type="number/text"
              value={donationTarget}
              disabled
              width="10%"
            />
          </div>
        </div>
        <div className="row">
          <div className="col-lg-6 col-md-12">
            <InputPrepend
              label="Durasi"
              name="duration"
              title="Hari"
              type="number"
              value={duration}
              disabled
              width="20%"
            />
          </div>
          <div className="col-lg-6 col-md-12">
            <Input
              title="Status"
              name="status"
              type="text"
              value={status}
              disabled
            />
          </div>
        </div>
        <div className="row">
          <div className="col-lg-6 col-md-12">
            <Input
              title="Penggalang Dana"
              name="fundraiser"
              type="text"
              value={fundraiser}
              disabled
            />
          </div>
        </div>
        <div className="row">
          <div className="col-lg-6 col-md-12 mt-3 card-datatable-title">
            Cerita Program
          </div>
        </div>
        <div className="row">
          <div className="col-lg-12">
            <Input
              title="Gambar Cerita Program"
              name="storyThumbnail"
              type="file"
              filename={storyThumbnailName}
              isShowMedia
              onShowMedia={handleShowThumbnailInput}
              disabled
              accept="image/png, image/jpeg"
            />
          </div>
          <div className="col-lg-12">
            <div className="col-lg-12 col-md-12">
              <div className="col-lg-6 col-md-12 description-label">
                Deskripsi
              </div>
              <ReactQuill
                title="Deskripsi"
                theme="snow"
                modules={modules}
                ref={quillRef}
                value={storyDescription}
                readOnly
              />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-6 col-md-12 mt-4 card-datatable-title">
            Periode Program
          </div>
        </div>
        <div className="row">
          <div className="col-lg-3 col-md-6">
            <Input
              title="Waktu Mulai"
              name="startTime"
              type="time"
              value={startTime}
              disabled
            />
          </div>
          <div className="col-lg-3 col-md-6">
            <Input
              title="Waktu Selesai"
              name="endTime"
              type="time"
              value={endTime}
              disabled
            />
          </div>
          <div className="col-lg-6 col-md-12">
            <Input
              title="Ulangi"
              name="repeat"
              type="text"
              value={repeat}
              disabled
            />
          </div>
        </div>
      </Card>
    </div>
  );
}

export default detailProgram;
