/* eslint-disable default-param-last */
import {
  GET_USER_PENDING,
  GET_USER_SUCCESS,
  GET_USER_ERROR,
} from '../../actions/user/index';

const initialState = {
  pending: false,
  error: null,
  data: null,
};

const user = (state = initialState, action) => {
  switch (action.type) {
    case GET_USER_PENDING:
      return {
        ...state,
        pending: true,
      };
    case GET_USER_SUCCESS:
      return {
        ...state,
        pending: false,
        data_list: action.data,
      };
    case GET_USER_ERROR:
      return {
        ...state,
        pending: false,
        error: action.error,
      };
    default:
      return state;
  }
};

export default user;
