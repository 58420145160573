import axios from 'axios';

const user = JSON.parse(localStorage.getItem('user_dzikra'));

const instance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  // headers: { 'x-auth-token': user !== null ? user.token : '' },
  headers: { 'x-auth-userid': user !== null ? user.id : '' },

});

export default instance;
