/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable no-restricted-globals */
/* eslint-disable max-len */
/* eslint-disable no-undef */
/* eslint-disable no-unused-vars */
/* eslint-disable radix */
/* eslint-disable import/no-named-as-default */

/* eslint-disable */
import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Card } from 'react-bootstrap';
import MenuItem from '@mui/material/MenuItem';
import Badge from '@mui/material/Badge';
import { useNavigate } from 'react-router-dom';
import Button from '@mui/material/Button';
import FilterIcon from '@mui/icons-material/FilterList';
import FilterModal from '../../components/modal/FilterModal';
import ButtonAdd from '../../components/buttons/ButtonAddMulti';
import Search from '../../components/filter/Search';
import Profile from '../../components/profile/profile';
import ListNotification from './ListNotification';
import { toastSuccess } from '../../components/toast';
import { getNotificationList, searchNotification } from '../../services/notification/notificationService';
import '../../assets/styles/notification.scss';

function Notification() {
  const styledButton = {
    textTransform: 'capitalize',
    color: '#4F5867',
    background: '#fff',
    borderRadius: '8px',
    boxShadow: 'none',
  };
  const navigate = useNavigate();
  const [notificationToday, setNotificationToday] = useState([]);
  const [notificationTomorrow, setNotificationTomorrow] = useState([]);
  const [today, setToday] = useState('');
  const [tomorrow, setTomorrow] = useState('');
  const deletedState = useSelector((state) => state.notificationReducer.deleted);
  const [search, setSearch] = useState('');
  const [dataResult, setDataResult] = useState(['']);
  const [isOnFilter, setIsOnFilter] = useState(false);
  const [showModalFilter, setShowModalFilter] = useState(false);
  const [paramFilter, setParamFilter] = useState({});

  const handleSearch = async () => {
    setSearch(event.target.value);
    try {
      const searchResult = await searchNotification(search);
      setDataResult(searchResult);
    } catch (e) {
      console.log(e);
    }
  };
  useEffect(() => {
    const isEdited = localStorage.getItem('isEdited');
    const isAdded = localStorage.getItem('isAdded');
    if (isEdited) {
      toastSuccess('Data Berhasil Diedit');
      localStorage.removeItem('isEdited');
    }
    if (isAdded) {
      toastSuccess('Data Berhasil Ditambah');
      localStorage.removeItem('isAdded');
    }
  }, []);

  useEffect(() => {
    const monthNames = ['Januari', 'Februari', 'Maret', 'April', 'Mei', 'Juni', 'Juli', 'Agustus', 'September', 'Oktober', 'November', 'Desember'];
    const timeToday = new Date();
    const timeTomorrow = new Date(timeToday);
    timeTomorrow.setDate(timeToday.getDate() + 1);

    //Today
    const dd = String(timeToday.getDate()).padStart(2, '0');
    const mm = String(timeToday.getMonth() + 1).padStart(2, '0');
    const yyyy = timeToday.getFullYear();

    // Tomorrow
    const nextDay = String(timeTomorrow.getDate()).padStart(2, '0');
    const nextMonth = String(timeTomorrow.getMonth() + 1).padStart(2, '0');
    const nextYear = timeTomorrow.getFullYear();

    setToday(`Hari ini, ${parseInt(dd)} ${monthNames[parseInt(mm) - 1]} ${yyyy}`);
    setTomorrow(`Besok, ${parseInt(nextDay)} ${monthNames[parseInt(nextMonth) - 1]} ${nextYear}`);

    const handleNotification = async () => {
      if (paramFilter.notificationType === null) {
        setNotificationToday(await getNotificationList('today'));
        setNotificationTomorrow(await getNotificationList('tomorrow'));
      } else {
        setNotificationToday(await getNotificationList('today', paramFilter?.notificationType));
        setNotificationTomorrow(await getNotificationList('tomorrow', paramFilter?.notificationType));
      }
    };
    handleNotification();
  }, [deletedState, paramFilter]);

  const optionsFilter = [
    { value: 'rutin', label: 'Rutin' },
    { value: 'khusus', label: 'Khusus' },
  ];

  const handleFilter = (selectedOption, startDate, endDate) => {
    setParamFilter({
      notificationType: selectedOption.value,
    });
    setShowModalFilter(false);
    setIsOnFilter(true);
  };

  const handleFilterReset = () => {
    setParamFilter({
      notificationType: null,
    });
    setShowModalFilter(false);
    setIsOnFilter(false);
  };
  return (
    <div className="content-container-side">
      <div className="d-flex flex-wrap">
        <p className="content-title mt-3">Notifikasi</p>
        <div className="ms-auto">
          <div className="d-flex flex-wrap justify-content-end align-items-center">
            <Profile label="Ridwan Fajar Widodo" />
          </div>
        </div>
      </div>
      <Card className="card-notification">
        <div className="d-flex flex-wrap align-items-center">
          <p className="card-notification-title">Notifikasi</p>
          <div className="ms-auto">
            <div className="d-flex flex-wrap justify-content-end align-items-center">
              <Search
                name="search"
                type="text"
                placeholder="Search"
                style={{ marginRight: 10 }}
                mt="3 mt-sm-0"
                value={search}
                onChange={(e) => handleSearch(e.target.value)}
              />
              {isOnFilter ? (
                <Badge variant="dot" color="warning">
                  <Button
                    onClick={() => setShowModalFilter(true)}
                    variant="contained"
                    aria-label="filter"
                    aria-controls="long-menu"
                    aria-haspopup="true"
                    size="medium"
                    sx={{
                      border: '1px solid #2EC4B6 !important',
                    }}
                    style={styledButton}
                    endIcon={<FilterIcon />}
                  >
                    Filter
                  </Button>
                </Badge>
              ) : (
                <Button
                  onClick={() => setShowModalFilter(true)}
                  variant="contained"
                  aria-label="filter"
                  aria-controls="long-menu"
                  aria-haspopup="true"
                  size="medium"
                  style={styledButton}
                  endIcon={<FilterIcon />}
                >
                  Filter
                </Button>
              )}
              <ButtonAdd customClass="mt-3 mt-sm-0">
                <MenuItem
                  onClick={() => {
                    navigate('/notification/add');
                  }}
                >
                  <span style={{ fontSize: 14 }}>Notifikasi Rutin</span>
                </MenuItem>
                <MenuItem onClick={() => {
                  navigate('/notification/add-special');
                }}
                >
                  <span style={{ fontSize: 14 }}>Notifikasi Khusus</span>
                </MenuItem>
              </ButtonAdd>
            </div>
          </div>
        </div>
        {search === '' ? (
          <>
            {notificationToday.length > 0 && <ListNotification day={today} notifications={notificationToday} /> }
            {notificationTomorrow.length > 0 && <ListNotification day={tomorrow} notifications={notificationTomorrow} /> }
          </>
        ) : (
          <>
            {dataResult.length > 0 && <ListNotification day={today} notifications={dataResult} /> }
          </>
        )}
      </Card>
      <FilterModal
        show={showModalFilter}
        onHide={() => setShowModalFilter(false)}
        titleSelect="Status"
        select
        selectOptions={optionsFilter}
        placeholderSelect="Pilih Status"
        confirmTitle="Terapkan"
        cancelTitle="Batal"
        paramFilter={paramFilter}
        handleFilter={handleFilter}
        handleReset={handleFilterReset}
        toggle={() => setShowModalFilter(false)}
      />
    </div>
  );
}

export default Notification;
