/* eslint-disable radix */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { MenuItem } from '@mui/material';
import moment from 'moment';
import Table from '../../../../components/table/index';
import TableStatus from '../../../../components/table/status';
import ButtonDetail from '../../../../components/table/button-detail';
import { toastSuccess } from '../../../../components/toast';
import TrashIcon from '../../../../assets/images/icons/TrashIcon.svg';
import ConfirmModal from '../../../../components/modal/ConfirmModal';
import { getPencairanDanaInfaq, DeletePencairanDanaInfaq } from '../../../../services/infaq';

function index({
  searchKeyword,
  sort,
  filter,
  setParamExport,
}) {
  const navigate = useNavigate();
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
  const [data, setData] = useState([]);
  const [page, setPage] = useState(0);
  const [maxPage, setMaxPage] = useState(0);
  const [totalData, setTotalData] = useState(0);
  const [idPencairanInfaq, setIdPencairanInfaq] = useState('');
  const [deletedState, setDeletedState] = useState(0);
  const columns = [
    {
      Header: 'No',
      accessor: 'no',
      Cell: ({ row }) => <div>{row.index + 1}</div>,
    },
    {
      Header: 'Nama Donasi',
      Cell: ({ row }) => {
        const { original } = row;
        const { infaqProgram } = original;
        return infaqProgram.programName;
      },
    },
    {
      Header: 'Tanggal Pencairan Dana',
      Cell: ({ row }) => {
        const monthNames = ['Januari', 'Februari', 'Maret', 'April', 'Mei', 'Juni', 'Juli', 'Agustus', 'September', 'Oktober', 'November', 'Desember'];
        const { original } = row;
        const splitedDate = moment(original.usageDate).utc().format('DD-MM-YYYY').split('-');
        return `${splitedDate[0]} ${monthNames[splitedDate[1] - 1]} ${splitedDate[2]}`;
      },
    },
    {
      Header: 'Jumlah Dana Yang Dicairkan',
      Cell: ({ row }) => {
        const { original } = row;
        const { amount } = original;
        const moneyFormatter = new Intl.NumberFormat();
        return `Rp ${moneyFormatter.format(amount).replaceAll(',', '.')}`;
      },
    },
    {
      Header: 'Status',
      Cell: ({ row }) => {
        const { original } = row;
        const { status } = original;
        const str2 = status.charAt(0).toUpperCase() + status.slice(1);
        return <TableStatus status={str2} color={status === 'ditinjau' ? 'warning' : status === 'selesai' ? 'success' : 'primary'} />;
      },
    },
    {
      Header: ' ',
      Cell: ({ row }) => {
        const { original } = row;
        const { id } = original;
        return (
          <ButtonDetail>
            <MenuItem onClick={() => {
              navigate(`/infaq/penggunaan/detail-pencairan/${id}`);
            }}
            >
              Detail

            </MenuItem>
            <MenuItem onClick={() => {
              navigate(`/infaq/penggunaan/edit-pencairan/${id}`);
            }}
            >
              Edit
            </MenuItem>
            <MenuItem onClick={async () => {
              setIdPencairanInfaq(id);
              setShowDeleteConfirm(true);
            }}
            >
              Hapus
            </MenuItem>
          </ButtonDetail>
        );
      },
    },
  ];

  useEffect(() => {
    const handleTable = async () => {
      const param = {
        deleted: false,
        draw: 0,
        id: '',
        length: 10,
        page,
        rangeEnd: filter.endDate,
        rangeStart: filter.startDate,
        searchText: searchKeyword,
        sort: [
          `${'usageDate'}.${sort}`,
        ],
        start: 1,
        status: filter.status,
      };
      const dataPencairanInfaq = await getPencairanDanaInfaq(param);
      setData(dataPencairanInfaq.data);
      setTotalData(dataPencairanInfaq.recordTotal);
      setParamExport(param);
      setMaxPage(Math.ceil(dataPencairanInfaq.recordTotal / 10));
    };
    handleTable();
  }, [filter, searchKeyword, sort, page, deletedState]);
  return (
    <>
      <ConfirmModal
        show={showDeleteConfirm}
        onHide={() => setShowDeleteConfirm(false)}
        confirmTitle="Hapus"
        message="Apakah anda yakin ingin menghapus data?"
        cancelTitle="Batal"
        toggle={() => setShowDeleteConfirm(false)}
        inlineIcon={TrashIcon}
        confirm={() => {
          DeletePencairanDanaInfaq(idPencairanInfaq).then((res) => {
            if (res.status === 200) {
              toastSuccess('Data Pencairan Berhasil Dihapus');
              setDeletedState(deletedState + 1);
            }
            setShowDeleteConfirm(false);
          });
        }}
      />
      <Table
        columns={columns}
        data={data}
        pagination={{
          page,
          totalData,
          maxPage,
          toggle: setPage,
        }}
      />
    </>
  );
}

export default index;
