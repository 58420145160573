/* eslint-disable react/button-has-type */
/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import React from 'react';

// styles
import '../../../assets/styles/button.scss';

function Button({
  type,
  title,
  style,
  variant,
  rounded,
  onClick,
  disabled,
  iconOnly,
  leftIcon,
  rightIcon,
  withLoading,
}) {
  return (
    <button
      style={style}
      type={type ?? 'button'}
      className={`btn ${variant ?? 'btn-primary'}`}
      onClick={onClick}
      disabled={disabled}
    >
      {leftIcon && (
        <span className="material-icons-round left-icon">{leftIcon}</span>
      )}

      {iconOnly && (
        <span className="material-icons-round icon-only">{iconOnly}</span>
      )}

      {withLoading && (
        <span className="spinner-border spinner-border-sm me-2" />
      )}

      {title ?? ''}

      {rightIcon && (
        <span className="material-icons-round right-icon">{rightIcon}</span>
      )}
    </button>
  );
}

export default Button;
