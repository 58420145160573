/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react';
import Modal from 'react-bootstrap/Modal';

const ImageModal = ({ handleClose, show, srcMedia }) => (
  <Modal show={show} onHide={handleClose} centered backdrop="static">
    <Modal.Header className="mt-2" closeButton>
      <Modal.Title>{srcMedia && 'Foto'}</Modal.Title>
    </Modal.Header>
    <Modal.Body>
      {srcMedia ? (
        <img src={srcMedia} alt="Media" style={{ width: '100%' }} />
      ) : (
        <div className="text-center mx-auto">
          <i
            className="material-icons-round"
            style={{ color: '#F57D41', fontSize: '64px', marginTop: '-24px' }}
          >
            warning
          </i>
          <p style={{
            fontFamily: 'BlueberrySans-Medium',
            fontSize: '18px',
            color: '#4F5867',
            marginTop: '24px',
          }}
          >
            Belum ada foto yang diunggah
          </p>
        </div>
      )}
    </Modal.Body>
  </Modal>
);

export default ImageModal;
