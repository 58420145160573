/* eslint-disable camelcase */
import axios from 'axios';
import { actionPending, actionSuccess, actionError } from '../actionTypes';
import { toastError } from '../../../components/toast/index';

// ACTION TYPES TO DISPATCH: METHOD_URL_TYPES
export const POST_AUTH_LOGIN_PENDING = 'POST_AUTH_LOGIN_PENDING';
export const POST_AUTH_LOGIN_SUCCESS = 'POST_AUTH_LOGIN_SUCCESS';
export const POST_AUTH_LOGIN_ERROR = 'POST_AUTH_LOGIN_ERROR';

// URL: URL_{URL}
const AUTH_LOGIN_URL = `${process.env.REACT_APP_API_URL}api/login`;

const auth_login = (param) => (dispatch) => {
  dispatch(actionPending(POST_AUTH_LOGIN_PENDING));
  axios
    .post(AUTH_LOGIN_URL, param)
    .then((res) => {
      if (res.error) {
        throw res.error;
      }
      dispatch(actionSuccess(POST_AUTH_LOGIN_SUCCESS, res));
      localStorage.setItem('user_dzikra', JSON.stringify(res.data));
      window.location.href = '/dashboard';
    })
    .catch((err) => {
      dispatch(actionError(POST_AUTH_LOGIN_ERROR));
      toastError(err?.response?.data?.message);
    });
};

const AuthService = {
  auth_login,
};
export default AuthService;
