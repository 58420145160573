/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { MenuItem } from '@mui/material';
import moment from 'moment/moment';
import Table from '../../../../components/table/index';
import TrashIcon from '../../../../assets/images/icons/TrashIcon.svg';
import ConfirmModal from '../../../../components/modal/ConfirmModal';
import { toastSuccess } from '../../../../components/toast';
import ButtonDetail from '../../../../components/table/button-detail';
import { getPenyaluranDanaInfaq, DeletePenyaluranDanaInfaq } from '../../../../services/infaq';

function index({
  searchKeyword,
  sort,
  filter,
  setParamExport,
}) {
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [page, setPage] = useState(0);
  const [maxPage, setMaxPage] = useState(0);
  const [totalData, setTotalData] = useState(0);
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
  const [idPenyaluranInfaq, setIdPenyaluranInfaq] = useState('');
  const [deletedState, setDeletedState] = useState(0);
  const columns = [
    {
      Header: 'No',
      accessor: 'no',
      Cell: ({ row }) => <div>{row.index + 1}</div>,
    },
    {
      Header: 'Nama Donasi',
      Cell: ({ row }) => {
        const { original } = row;
        const { infaqProgram } = original;
        return infaqProgram.programName;
      },
    },
    {
      Header: 'Tanggal Penggunaan Dana',
      Cell: ({ row }) => {
        const monthNames = ['Januari', 'Februari', 'Maret', 'April', 'Mei', 'Juni', 'Juli', 'Agustus', 'September', 'Oktober', 'November', 'Desember'];
        const { original } = row;
        const splitedDate = moment(original.usageDate).utc().format('DD-MM-YYYY').split('-');
        return `${splitedDate[0]} ${monthNames[splitedDate[1] - 1]} ${splitedDate[2]}`;
      },
    },
    {
      Header: 'Total Dana yang Digunakan',
      Cell: ({ row }) => {
        const { original } = row;
        const { amount } = original;
        const moneyFormatter = new Intl.NumberFormat();
        return `Rp ${moneyFormatter.format(amount).replaceAll(',', '.')}`;
      },
    },
    {
      Header: 'Sisa Pencairan Dana',
      Cell: ({ row }) => {
        const { original } = row;
        const { infaqProgram } = original;
        const moneyFormatter = new Intl.NumberFormat();
        return `Rp ${moneyFormatter.format(infaqProgram.infaqUsageDetail.availableFunds).replaceAll(',', '.')}`;
      },
    },
    {
      Header: ' ',
      Cell: ({ row }) => {
        const { original } = row;
        const { id } = original;
        return (
          <ButtonDetail>
            <MenuItem onClick={() => {
              navigate(`/infaq/penggunaan/detail-penyaluran/${id}`);
            }}
            >
              Detail

            </MenuItem>
            <MenuItem onClick={() => {
              navigate(`/infaq/penggunaan/edit-penyaluran/${id}`);
            }}
            >
              Edit

            </MenuItem>
            <MenuItem onClick={async () => {
              setIdPenyaluranInfaq(id);
              setShowDeleteConfirm(true);
            }}
            >
              Hapus
            </MenuItem>
          </ButtonDetail>
        );
      },
    },
  ];

  useEffect(() => {
    const isEditedInfaq = localStorage.getItem('isEditedPenyaluran');
    if (isEditedInfaq) {
      toastSuccess('Data Berhasil Diedit');
      localStorage.removeItem('isEditedPenyaluran');
    }
    const isAdded = localStorage.getItem('isPenyaluranAdded');
    if (isAdded) {
      toastSuccess('Data Berhasil Disimpan');
      localStorage.removeItem('isPenyaluranAdded');
    }
    const handleTable = async () => {
      const param = {
        deleted: false,
        draw: 0,
        id: '',
        length: 10,
        page,
        rangeEnd: filter.endDate,
        rangeStart: filter.startDate,
        searchText: searchKeyword,
        sort: [
          `${'usageDate'}.${sort}`,
        ],
        start: 1,
        status: '',
      };
      const dataPenyaluranInfaq = await getPenyaluranDanaInfaq(param);
      setData(dataPenyaluranInfaq.data);
      setTotalData(dataPenyaluranInfaq.recordTotal);
      setParamExport(param);
      setMaxPage(Math.ceil(dataPenyaluranInfaq.recordTotal / 10));
    };
    handleTable();
  }, [filter, searchKeyword, sort, page, deletedState]);
  return (
    <>
      <ConfirmModal
        show={showDeleteConfirm}
        onHide={() => setShowDeleteConfirm(false)}
        confirmTitle="Hapus"
        cancelTitle="Batal"
        message="Apakah anda yakin ingin menghapus data?"
        toggle={() => setShowDeleteConfirm(false)}
        inlineIcon={TrashIcon}
        confirm={() => {
          DeletePenyaluranDanaInfaq(idPenyaluranInfaq).then((res) => {
            if (res.status === 200) {
              toastSuccess('Data Penyaluran Berhasil Dihapus');
              setDeletedState(deletedState + 1);
            }
            setShowDeleteConfirm(false);
          });
        }}
      />
      <Table
        columns={columns}
        data={data}
        pagination={{
          page,
          totalData,
          maxPage,
          toggle: setPage,
        }}
      />
    </>
  );
}

export default index;
