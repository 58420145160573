/* eslint-disable react/button-has-type */
/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/prop-types */
/* eslint-disable no-nested-ternary */
/* eslint-disable arrow-body-style */
import React, { useState, useEffect } from 'react';
import Modal from 'react-bootstrap/Modal';
import DatePicker from 'react-datepicker';
import Chip, { chipClasses } from '@mui/material/Chip';
import { styled } from '@mui/material/styles';
import Done from '@mui/icons-material/Done';
import Button from '../buttons/Button';
import Select2 from '../forms/Select2';
import 'react-datepicker/dist/react-datepicker.css';

// const CustomChip = styled(Chip)({
//   [`&.${chipClasses.outlinedActive}`]: {
//     borderColor: '#2EC4B6',
//   },
// });
const optionsProp = {
  shouldForwardProp: (prop) => prop !== 'color',
};

const StyledChip = styled(
  Chip,
  optionsProp,
)(({ color }) => ({
  '& .MuiChip-deleteIcon': {
    color,
  },
  backgroundColor: 'white',
  color,
  borderColor: color,
}));

const btnStyle = {
  borderRadius: '8px',
  padding: '15px',
  background: '#FEEBEB !important',
};

const FilterModal = ({
  show,
  toggle,
  onHide,
  selectOptions,
  paramFilter,
  handleFilter,
  handleReset,
  withDateRange,
  select,
  titleSelect,
  placeholderSelect,
  inputChips,
  secondSelect,
  titleSecondSelect,
  placeholderSecondSelect,
  secondSelectOptions,
}) => {
  const styleTitle = {
    fontWeight: 500,
    fontSize: '24px',
    lineHeight: '120%',
    color: '#4F5867',
    fontFamily: 'BlueberrySans-Medium',
  };
  const styleCloseIcon = {
    paddingTop: '5px',
    fontWeight: 400,
    fontSize: '34px',
    color: '#4F5867',
    marginLeft: 'auto',
  };
  const [dateRange, setDateRange] = useState([null, null]);
  const [startDate, endDate] = dateRange;
  const [selectedOption, setSelectedOption] = useState({});
  const [secondSelectedOption, setSecondSelectedOption] = useState({});

  useEffect(() => {
    if (paramFilter?.startDate === null && paramFilter?.endDate === null) {
      setDateRange([null, null]);
    }
  }, [paramFilter]);

  return (
    <Modal
      show={show}
      backdrop="static"
      onHide={toggle}
      centered
      className="modal-filter"
    >
      <Modal.Header className="text-extra-bold">
        <div className="d-flex align-items-baseline w-100 mx-auto">
          <p style={styleTitle}>
            Filter
          </p>
          <span
            style={styleCloseIcon}
            className="close clickable"
            role="button"
            tabIndex={0}
            onKeyPress={toggle}
            onClick={toggle}
          >
            &times;
          </span>

        </div>
      </Modal.Header>
      <Modal.Body>
        <div className="mx-auto w-100 row">
          <div className="title-filter text-center col-lg-12">
            <p>Pilih data yang ingin difilter</p>
          </div>
          <div className="col-lg-12">
            { secondSelect ? (
              <Select2
                title={titleSecondSelect}
                name="menuSelect"
                isSearchable
                placeholder={placeholderSecondSelect}
                options={secondSelectOptions}
                defaultValue={secondSelectOptions.find(
                  ({ value }) => value === paramFilter?.selectValue,
                )}
                onChangeEvent={(selected) => {
                  if (!selected) {
                    setSecondSelectedOption('');
                  }
                  setSecondSelectedOption(selected);
                }}
              />
            ) : ''}
          </div>
          {withDateRange ? (
            <div className="col-lg-12">
              <div className="form-group">
                <label className="form-label">Tanggal</label>
                <DatePicker
                  selectsRange
                  className="form-control form-input"
                  placeholderText="Masukan Tanggal"
                  startDate={startDate}
                  endDate={endDate}
                  onChange={(update) => {
                    setDateRange(update);
                  }}
                />
              </div>
            </div>
          ) : ''}
          {select ? (
            <div className="col-lg-12">
              <Select2
                title={titleSelect}
                name="menuSelect"
                isSearchable
                placeholder={placeholderSelect}
                options={selectOptions}
                defaultValue={selectOptions.find(
                  ({ value }) => value === paramFilter?.selectValue,
                )}
                onChangeEvent={(selected) => {
                  if (!selected) {
                    setSelectedOption('');
                  }
                  setSelectedOption(selected);
                }}
              />
            </div>
          ) : ''}
          {inputChips && (
            <div className="row">
              <div className="form-group mb-1">
                <label className="form-label">Konten</label>
              </div>
              <div className="d-flex align-items-center">
                {inputChips?.map((item) => (
                  <div className="me-2">
                    {/* <ThemeProvider theme={theme}> */}
                    <StyledChip
                      onClick={item?.onChange}
                      onDelete={item?.onDelete}
                      color={item?.color}
                      variant={item?.variant}
                      label={item?.label}
                      deleteIcon={<Done />}
                    />
                    {/* </ThemeProvider> */}
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>
      </Modal.Body>
      <Modal.Footer>
        <div className="w-100 d-flex">
          <div className="button-reset">
            <Button
              variant="btn-warning"
              className="btn-reset"
              rounded
              title="Reset"
              onClick={handleReset}
              type="Button"
              style={btnStyle}
            />
          </div>
          <div className="ms-auto d-flex">
            <div className="cancel me-2">
              <Button
                variant="btn-cancel"
                rounded
                className="me-5"
                title="Batal"
                onClick={onHide}
                type="Button"
                style={btnStyle}
              />
            </div>
            <div className="filter">
              <Button
                variant="btn-primary"
                rounded
                title="Terapkan"
                type="Button"
                onClick={() => {
                  handleFilter(selectedOption, secondSelectedOption, startDate, endDate);
                }}
                style={btnStyle}
              />
            </div>
          </div>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default FilterModal;
