/* eslint-disable consistent-return */
/* eslint-disable no-unused-vars */
/* eslint-disable no-use-before-define */
/* eslint-disable no-unused-expressions */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/prop-types */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable no-promise-executor-return */
/* eslint-disable jsx-a11y/label-has-associated-control */
import { React, useEffect, useState } from 'react';
import { useParams, useNavigate, Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Card } from 'react-bootstrap';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Typography from '@mui/material/Typography';

import { toastSuccess, toastError } from '../../components/toast';
import Textarea from '../../components/forms/Textarea';
import Input from '../../components/forms/input';
import InputPrepend from '../../components/forms/InputPrepend';
import ButtonAction from '../../components/buttons/ButtonAction';
import ConfirmationModal from '../../components/modal/ConfirmModal';
import saveIcon from '../../assets/images/icons/SaveIcon.svg';

import DzikirService from '../../stores/actions/dzikir';
import {
  setEditedDzikirValue,
  setAddedDzikirValue,
} from '../../stores/actions/dzikir/formDzikir';

const styleActiveBreadcrumb = {
  fontWeight: 600,
  fontSize: '12px',
  lineHeight: '14px',
  color: 'rgba(79, 88, 103, 0.7)',
  textDecoration: 'none',
  fontFamily: 'BlueberrySans-Regular',
};

const styleInactiveBreadcrumb = {
  fontWeight: 500,
  fontSize: '12px',
  lineHeight: '14px',
  color: 'rgba(79, 88, 103, 0.7)',
  textDecoration: 'none',
  fontFamily: 'BlueberrySans-Regular',
};

const DzikirItemForm = ({
  formik,
  toggle,
  index,
  indexCancel,
  lastItemValue,
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [audioName, setAudioName] = useState('Upload Audio File');
  const [inputAudioColor, setInputAudioColor] = useState('');
  const [errorValidation, setErrorValidation] = useState({});
  const [isFormAdded, setIsFormAdded] = useState(false);
  const [showModalClose, setShowModalClose] = useState(false);
  const [oldFormikValues, setOldFormikValues] = useState({});

  const { action } = useParams();
  const titleParent = action === 'edit' ? 'Edit' : 'Tambah';
  const title = indexCancel !== null ? 'Edit' : 'Tambah';

  const exceptThisSymbols = ['e', 'E', '+', '-', '.'];
  const isArabic = /^[\p{Arabic}\s\p{N}]+$/gm;

  const handleCancelItem = () => {
    const i = formik?.values?.dzikirDetails?.length - 1;
    if (
      (indexCancel === null || indexCancel === undefined)
      && (formik?.values?.dzikirDetails[index]?.doaName
        || formik?.values?.dzikirDetails[index]?.arabicText
        || formik?.values?.dzikirDetails[index]?.latinText
        || formik?.values?.dzikirDetails[index]?.translate
        || formik?.values?.dzikirDetails[index]?.repeat
        || formik?.values?.dzikirDetails[index]?.referensi
        || formik?.values?.dzikirDetails[index]?.audioName)
    ) {
      const dzikirItems = formik?.values?.dzikirDetails;
      dzikirItems.splice(i, 1);
      formik?.setFieldValue('dzikirDetails', dzikirItems);
      toggle();
    } else if (
      indexCancel === null
      && formik?.values?.dzikirDetails[index]?.doaName === undefined
      && formik?.values?.dzikirDetails[index]?.arabicText === undefined
      && formik?.values?.dzikirDetails[index]?.latinText === undefined
      && formik?.values?.dzikirDetails[index]?.translate === undefined
      && formik?.values?.dzikirDetails[index]?.repeat === undefined
      && formik?.values?.dzikirDetails[index]?.referensi === undefined
      && formik?.values?.dzikirDetails[index]?.audioName === undefined
    ) {
      toggle();
    } else if (
      indexCancel === null
      && formik?.values?.dzikir_items[index]?.doa_name === undefined
      && formik?.values?.dzikir_items[index]?.arab === undefined
      && formik?.values?.dzikir_items[index]?.transliterasi === undefined
      && formik?.values?.dzikir_items[index]?.translasi === undefined
      && formik?.values?.dzikir_items[index]?.pengulangan === undefined
      && formik?.values?.dzikir_items[index]?.referensi === undefined
      && formik?.values?.dzikir_items[index]?.audio === undefined
    ) {
      toggle();
    } else {
      formik?.setFieldValue('dzikirDetails', lastItemValue);
      toggle();
    }
  };

  const handlePostAudio = (file) => {
    new Promise((resolve) => {
      const formData = new FormData();
      formData.append('file', file);

      dispatch(DzikirService.postAudio(formData, resolve));
    }).then((res) => {
      if (res) {
        formik?.setFieldValue(`dzikirDetails[${index}].audioName`, res?.data?.fileName);
        setAudioName(res?.data?.fileNameOrigin);
        setInputAudioColor('color-input-primary');
      }
    });
  };

  const handleAddItem = () => {
    let isNotValid = false;
    const objErrorValidation = {};
    if (!formik?.values?.dzikirDetails[index]?.doaName) {
      const doaNameKey = `dzikirDetails[${index}].doaName`;
      const errorDoaName = { [doaNameKey]: 'Nama bacaan dzikir wajib diisi!' };
      Object.assign(objErrorValidation, errorDoaName);
      isNotValid = true;
    }
    if (!formik?.values?.dzikirDetails[index]?.arabicText) {
      const arrabNameKey = `dzikirDetails[${index}].arabicText`;
      const errorArabName = { [arrabNameKey]: 'Bacaan arab wajib diisi' };
      Object.assign(objErrorValidation, errorArabName);
    }
    if (
      formik?.values?.dzikirDetails[index]?.arabicText
      && isArabic.test(formik?.values?.dzikirDetails[index]?.arabicText)
    ) {
      const arrabNameKey = `dzikirDetails[${index}].arabicText`;
      const errorArabName = { [arrabNameKey]: 'Input Arab tidak boleh selain bahasa arab!' };
      Object.assign(objErrorValidation, errorArabName);
      isNotValid = true;
    }
    if (!formik?.values?.dzikirDetails[index]?.latinText) {
      const transliterasiNameKey = `dzikirDetails[${index}].latinText`;
      const errorTransliterasiName = { [transliterasiNameKey]: 'Transliterasi bacaan wajib diisi!' };
      Object.assign(objErrorValidation, errorTransliterasiName);
      isNotValid = true;
    }
    if (!formik?.values?.dzikirDetails[index]?.translate) {
      const translasiNameKey = `dzikirDetails[${index}].translate`;
      const errorTranslasiName = { [translasiNameKey]: 'Translasi bacaan wajib diisi!' };
      Object.assign(objErrorValidation, errorTranslasiName);
      isNotValid = true;
    }
    if (!formik?.values?.dzikirDetails[index]?.repeat) {
      const repeatNameKey = `dzikirDetails[${index}].repeat`;
      const repeatName = { [repeatNameKey]: 'Pengulangan bacaan wajib diisi!' };
      Object.assign(objErrorValidation, repeatName);
      isNotValid = true;
    }

    setErrorValidation(objErrorValidation);

    if (!isNotValid) {
      formik?.setFieldValue('dzikirDetails', formik?.values?.dzikirDetails);
      formik?.setFieldValue(`dzikirDetails[${index}].orderDzikir`, index);
      dispatch(setAddedDzikirValue(false));
      toggle();
      if (title === 'Edit') {
        toastSuccess('Data bacaan dzikir berhasil diperbarui!');
      } else {
        toastSuccess('Data bacaan dzikir berhasil ditambahkan!');
      }
    }
  };

  const isInProgress = () => {
    const formikValues = formik?.values?.dzikirDetails[index];
    if (action === 'add') {
      if (formikValues?.doaName || formikValues?.arabicText
        || formikValues?.latinText || formikValues?.translate
         || formikValues?.translate) {
        setIsFormAdded(true);
        dispatch(setAddedDzikirValue(true));
      } else {
        setIsFormAdded(false);
        dispatch(setAddedDzikirValue(false));
      }
    } else if (action === 'edit') {
      if (oldFormikValues?.doaName !== formikValues?.doaName
        || oldFormikValues?.arabicText !== formikValues?.arabicText
        || oldFormikValues?.latinText !== formikValues?.latinText
        || oldFormikValues?.translate !== formikValues?.translate) {
        setIsFormAdded(true);
        dispatch(setEditedDzikirValue(true));
      } else {
        setIsFormAdded(false);
        dispatch(setEditedDzikirValue(false));
      }
    }
  };

  useEffect(() => {
    const formikValues = formik?.values?.dzikirDetails[index];
    const cloneFormikValues = { ...formikValues };
    setOldFormikValues(cloneFormikValues);
  }, []);

  useEffect(() => {
    isInProgress();
  }, [formik?.values?.dzikirDetails[index]]);

  return (
    <div className="content-container-side">
      <div className="d-flex">
        <p className="content-title">
          Dzikir
        </p>
      </div>
      <div className="mb-4">
        <Breadcrumbs aria-label="breadcrumb" separator="">
          <Link
            color="inherit"
            to="/dzikir"
            style={styleActiveBreadcrumb}
          >
            Dzikir
          </Link>
          <Link
            color="inherit"
            to={() => handleCancelItem()}
            onClick={() => handleCancelItem()}
            style={styleActiveBreadcrumb}
          >
            {`${titleParent} Dzikir`}
          </Link>
          <Typography style={styleInactiveBreadcrumb}>
            {`${title} Bacaan Dzikir`}
          </Typography>
        </Breadcrumbs>
      </div>
      <Card className="card-datatable">
        <div className="d-flex">
          <span
            className="material-icons clickable"
            onClick={() => {
              if (isFormAdded) {
                setShowModalClose(true);
              } else {
                handleCancelItem();
              }
            }}
            onKeyPress={() => handleCancelItem()}
            role="button"
            tabIndex={0}
          >
            arrow_back
          </span>
          <p className="card-datatable-title ms-3">
            {`${title} Bacaan Dzikir`}
          </p>
        </div>
        <Input
          title="Nama Bacaan Dzikir"
          name={`dzikirDetails[${index}].doaName`}
          type="text"
          errors={errorValidation}
          touched={errorValidation}
          {...formik?.getFieldProps(`dzikirDetails[${index}].doaName`)}
        />
        <Input
          title="Arab"
          name={`dzikirDetails[${index}].arabicText`}
          type="text"
          errors={errorValidation}
          touched={errorValidation}
          {...formik?.getFieldProps(`dzikirDetails[${index}].arabicText`)}
        />
        <Input
          title="Transliterasi"
          name={`dzikirDetails[${index}].latinText`}
          type="text"
          errors={errorValidation}
          touched={errorValidation}
          {...formik?.getFieldProps(`dzikirDetails[${index}].latinText`)}
        />
        <Textarea
          title="Translasi"
          name={`dzikirDetails[${index}].translate`}
          type="text"
          errors={errorValidation}
          touched={errorValidation}
          {...formik?.getFieldProps(`dzikirDetails[${index}].translate`)}
          rows={3}
        />
        <InputPrepend
          name={`dzikirDetails[${index}].repeat`}
          label="Pengulangan"
          title="x"
          type="number"
          onKeyDown={(e) => exceptThisSymbols.includes(e.key) && e.preventDefault()}
          width="5%"
          errors={errorValidation}
          touched={errorValidation}
          {...formik.getFieldProps(`dzikirDetails[${index}].repeat`)}
        />
        <Textarea
          title="Referensi"
          name={`dzikirDetails[${index}].referensi`}
          type="text"
          errors={formik?.errors}
          touched={formik?.touched}
          {...formik?.getFieldProps(`dzikirDetails[${index}].referensi`)}
          rows={3}
        />
        <Input
          title="Audio"
          name={`dzikirDetails[${index}].audioName`}
          filename={audioName}
          inputColor={inputAudioColor}
          type="file"
          accept="audio/mp3,audio/*"
          errors={formik?.errors}
          touched={formik?.touched}
          onChange={(e) => handlePostAudio(e.target.files[0])}
        />
        <div className="my-4">
          <ButtonAction
            confirmTitle="Simpan Data"
            cancelTitle="Batal"
            toggle={() => handleCancelItem()}
            confirm={() => handleAddItem()}
            typeConfirm="Button"
          />
        </div>
      </Card>
      <ConfirmationModal
        show={showModalClose}
        toggle={() => setShowModalClose(false)}
        message={`Apakah anda yakin ingin membatalkan proses ${
          action === 'add' ? 'tambah' : 'edit'
        } bacaan dzikir?`}
        confirmTitle="Yakin"
        inlineIcon={saveIcon}
        cancelTitle="Batal"
        confirm={() => {
          dispatch(setAddedDzikirValue(false));
          navigate('/dzikir');
        }}
      />
    </div>
  );
};

export default DzikirItemForm;
