export const addValues = (data) => ({
  type: 'ADD',
  payload: data,
});
export const delValues = (data) => ({
  type: 'DEL',
  payload: data,
});

export const setEditedNotificationValue = (data) => ({
  type: 'EDITED',
  payload: data,
});

export const setAddeddNotificationValue = (data) => ({
  type: 'ADDED',
  payload: data,
});

export const setAction = (data) => ({
  type: 'SET_ACTION',
  payload: data,
});

export const setType = (data) => ({
  type: 'SET_TYPE',
  payload: data,
});
