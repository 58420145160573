/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/prop-types */
/* eslint-disable no-nested-ternary */
import { React } from 'react';
import Tooltip from '@mui/material/Tooltip';
import tooltipInfo from '../../../assets/images/icons/tooltipInfo.svg';

const Textarea = (props) => {
  const {
    rows,
    name,
    type,
    style,
    title,
    value,
    errors,
    touched,
    variant,
    onKeyUp,
    onChange,
    onKeyDown,
    disabled,
    maxLength,
    placeholder,
    tooltip,
  } = props;

  return (
    <div className="form-group">
      {title && (
        <label className="form-label">
          {title}
          {' '}
          {tooltip ? (
            <Tooltip title={tooltip}>
              <img src={tooltipInfo} alt="tooltip" />
            </Tooltip>
          ) : ''}
        </label>
      )}
      <div className="input-wrapper" style={{ width: '100%' }}>
        <textarea
          {...props}
          className={`form-control ${variant ?? ''} ${disabled ? 'input-disabled ' : ''} ${errors && touched
            ? errors[name]
              && touched[name]
              ? ' is-invalid'
              : ''
            : ''}`}
          value={value != null ? value : ''}
          type={type ?? 'text'}
          rows={rows ?? 2}
          style={{
            ...style,
            width: '100%',
          }}
          onChange={onChange}
          onKeyUp={onKeyUp}
          onKeyDown={onKeyDown}
          disabled={disabled}
          maxLength={maxLength}
          placeholder={placeholder ?? `Masukkan ${title}`}
        />
      </div>
      <div
        className="invalid-feedback"
        style={{ display: errors && touched ? errors[name] && touched[name] ? 'block' : 'none' : '' }}
      >
        {errors ? errors[name] : ''}
      </div>
    </div>
  );
};

export default Textarea;
