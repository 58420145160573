import { combineReducers } from 'redux';
import auth from './auth';
import users from './users';
import missionReducer from './mission/formMission';
import notificationReducer from './notification';
import notificationFormReducer from './notification/formNotification';
import whatNewFormReducer from './whatnew/form';
import doaReducer from './doa/formDoa';
import dzikirReducer from './dzikir/formDzikir';

const rootReducer = combineReducers({
  auth,
  users,
  missionReducer,
  notificationReducer,
  notificationFormReducer,
  whatNewFormReducer,
  doaReducer,
  dzikirReducer,
});

export default rootReducer;
