export const addValues = (data) => ({
  type: 'ADD_DOA',
  payload: data,
});
export const delValues = (data) => ({
  type: 'DEL_DOA',
  payload: data,
});

export const setEditedDoaValue = (data) => ({
  type: 'EDITED_DOA',
  payload: data,
});

export const setAddedDoaValue = (data) => ({
  type: 'ADDED_DOA',
  payload: data,
});

export const setAction = (data) => ({
  type: 'SET_ACTION_DOA',
  payload: data,
});
