/* eslint-disable no-unused-vars */
/* eslint-disable max-len */
import React, { useEffect, useState } from 'react';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Typography from '@mui/material/Typography';
import { Link, useParams, useNavigate } from 'react-router-dom';
import { Card } from 'react-bootstrap';
import Input from '../../components/forms/input';
import { getUpdateMobileById } from '../../services/whatsnew/whatsnewService';

const styleInactiveBreadcrumb = {
  fontWeight: 500,
  fontSize: '12px',
  lineHeight: '14px',
  color: 'rgba(79, 88, 103, 0.7)',
  textDecoration: 'none',
  fontFamily: 'BlueberrySans-Regular',
};
const styleActiveBreadcrumb = {
  fontWeight: 600,
  fontSize: '12px',
  lineHeight: '14px',
  color: 'rgba(79, 88, 103, 0.7)',
  textDecoration: 'none',
  fontFamily: 'BlueberrySans-Regular',
};

function detail() {
  const { id } = useParams();
  const [versionList, setVersionList] = useState([]);
  const [version, setVersion] = useState('');
  const navigate = useNavigate();
  useEffect(() => {
    const handleWhatNew = async () => {
      const detailVersion = await getUpdateMobileById(id);
      setVersionList(detailVersion.mobileUpdateDetails);
      setVersion(detailVersion.version);
    };
    handleWhatNew();
  }, []);

  return (
    <div className="content-container-side">
      <div className="d-flex">
        <p className="content-title">
          Management What’s New Update
        </p>
      </div>
      <div className="mb-4">
        <Breadcrumbs aria-label="breadcrumb" separator="">
          <Link
            color="inherit"
            to="/mission"
            onClick={() => {
            }}
            style={styleActiveBreadcrumb}
          >
            Management What’s New Update
          </Link>
          <Typography style={styleInactiveBreadcrumb}>
            Detail What’s New Update
          </Typography>
        </Breadcrumbs>
      </div>
      <Card className="card-datatable">
        <div className="d-flex flex-wrap align-items-center">
          <span
            className="material-icons clickable"
            style={{ marginTop: '-12px' }}
            onClick={() => {
              navigate('/whatsnew');
            }}
            onKeyPress={() => navigate('/whatnew')}
            role="button"
            tabIndex={0}
          >
            arrow_back
          </span>
          <p className="card-datatable-title ms-3">
            Detail What’s New Update
          </p>
        </div>
        <div className="row">
          <div className="col-lg-12">
            <Input
              title="Versi"
              name="version"
              type="text"
              value={version}
              disabled
            />
          </div>
          {versionList.map((versionItem, index) => (
            <div className="col-lg-12" key={versionItem.id}>
              <Input
                title={index === 0 ? 'Deskripsi' : null}
                name="missionName"
                type="text"
                value={versionItem.description}
                disabled
              />
            </div>
          ))}
        </div>

      </Card>
    </div>
  );
}

export default detail;
