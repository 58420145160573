/* eslint-disable react/button-has-type */
/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/prop-types */
/* eslint-disable no-nested-ternary */
import React from 'react';
import Modal from 'react-bootstrap/Modal';
import ButtonAction from '../buttons/ButtonAction';

const ConfirmationModal = ({
  show,
  toggle,
  message,
  isDelete,
  isWarning,
  children,
  icon,
  confirmTitle,
  cancelTitle,
  variantIcon,
  confirm,
  messageData,
  param,
  noIcon,
  inlineIcon,
  isLoading,
}) => {
  const styleTitle = {
    fontWeight: 500,
    fontSize: '24px',
    lineHeight: '120%',
    color: '#4F5867',
    fontFamily: 'BlueberrySans-Medium',
  };
  const styleCloseIcon = {
    paddingTop: '5px',
    fontWeight: 400,
    fontSize: '34px',
    color: '#4F5867',
    marginLeft: 'auto',
  };
  const styleBgMessage = {
    background: '#FFF1EA',
    borderRadius: '8px',
    borderLeft: '5px solid #F57D41',
    padding: '15px',
  };

  return (
    <Modal
      show={show}
      backdrop="static"
      onHide={toggle}
      centered
    >
      <Modal.Header className="text-extra-bold py-4 px-5">
        <div className="d-flex align-items-baseline w-100">
          <p style={styleTitle}>
            Konfirmasi
          </p>
          <span
            style={styleCloseIcon}
            className="close clickable"
            role="button"
            tabIndex={0}
            onKeyPress={toggle}
            onClick={toggle}
          >
            &times;
          </span>

        </div>
      </Modal.Header>
      <Modal.Body className="px-5 mt-0 py-0">
        <div
          style={{
            display: 'flex',
            lineHeight: '2rem',
          }}
        >
          {icon && (
            <i
              className={`material-icons-round icon-modal ${variantIcon}`}
            >
              {icon}
            </i>
          )}
          <img src={inlineIcon} alt={inlineIcon} />
          <p
            style={{ fontSize: '16px', marginLeft: '15px', marginTop: '10px' }}
          >
            {message ?? 'Apakah Anda yakin akan menghapus data '}
            {messageData && (
              <span>
                {`${messageData} ?`}
              </span>
            )}
          </p>
          {children}
          {param && (
            <div>
              Apakah Anda yakin akan menghapus data
              <strong>
                {param}
              </strong>
              ?
            </div>
          )}
        </div>
        {isWarning && (
          <div
            className="d-flex"
            style={styleBgMessage}
          >
            <i
              className="material-icons-round me-2"
              style={{ color: '#F57D41', fontSize: '17px' }}
            >
              warning
            </i>
            <div
              className="d-flex flex-column"
              style={{
                fontFamily: 'BlueberrySans-Medium',
                fontSize: '14px',
                color: '#F05406',
              }}
            >
              <p style={{ marginBottom: 5 }}>Peringatan</p>
              <p
                style={{
                  fontFamily: 'BlueberrySans-Reguler',
                }}
              >
                misi yang berjalan akan dihentikan pada pukul 00.00.
                Anda tidak akan bisa mengembalikan misi.
              </p>
            </div>
          </div>
        )}
      </Modal.Body>
      <Modal.Footer className="px-5">
        <ButtonAction
          toggle={toggle}
          deleted={isDelete}
          confirm={confirm}
          confirmTitle={confirmTitle}
          cancelTitle={cancelTitle}
          noIcon={noIcon}
          hideConfirm={isWarning}
          warning={isWarning}
          isLoading={isLoading}
        />
      </Modal.Footer>
    </Modal>
  );
};

export default ConfirmationModal;
