/* eslint-disable no-restricted-globals */
/* eslint-disable radix */
/* eslint-disable max-len */
/* eslint-disable no-undef */
/* eslint-disable no-shadow */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/jsx-boolean-value */
/* eslint-disable no-unused-vars */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/prop-types */
/* eslint-disable no-console */
import { Breadcrumbs, Typography } from '@mui/material';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import React, { useState, useRef, useEffect } from 'react';
import { Card } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import ButtonAction from '../../../../components/buttons/ButtonAction';
import Input from '../../../../components/forms/input';
import Textarea from '../../../../components/forms/Textarea';
import Select from '../../../../components/forms/Select';
import ConfirmModal from '../../../../components/modal/ConfirmModal';
import InputPrepend2 from '../../../../components/forms/InputPrepend2';
import saveIcon from '../../../../assets/images/icons/SaveIcon.svg';
import { addPencairanDanaInfaq, getAllProgramInfaq, getProgramInfaqById } from '../../../../services/infaq';

const styleActiveBreadcrumb = {
  fontWeight: 600,
  fontSize: '12px',
  lineHeight: '14px',
  color: 'rgba(79, 88, 103, 0.7)',
  textDecoration: 'none',
  fontFamily: 'BlueberrySans-Regular',
};

const styleInactiveBreadcrumb = {
  fontWeight: 500,
  fontSize: '12px',
  lineHeight: '14px',
  color: 'rgba(79, 88, 103, 0.7)',
  textDecoration: 'none',
  fontFamily: 'BlueberrySans-Regular',
};

function index(props) {
  const formikRef = useRef();
  const navigate = useNavigate();
  const [donationName, setDonationName] = useState('');
  const [description, setDescription] = useState('');
  const [donationDate, setDonationDate] = useState('');
  const [donationAmountOriginal, setDonationAmountOriginal] = useState(null);
  const [donationAmount, setDonationAmount] = useState(null);
  const [donationAmountDisplay, setDonationAmountDisplay] = useState('');
  const [availableFunds, setAvailableFunds] = useState(0);
  const [availableFundsFormat, setAvailableFundsFormat] = useState(0);
  const [modalCloseConfirmation, setShowModalCloseConfirmation] = useState(false);
  const [modalAddConfirmation, setShowModalAddConfirmation] = useState(false);
  const [donationNameList, setDonationNameList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const initialValues = {
    donationName: donationName || '',
    description: description || '',
    donationDate: donationDate || '',
    donationAmount: donationAmount || null,
  };
  const validation = Yup.object().shape({
    donationName: Yup.string()
      .required('Nama Donasi Wajib Diisi')
      .nullable(),
    description: Yup.string()
      .required('Keterangan Wajib Diisi')
      .nullable(),
    donationDate: Yup.string()
      .required('Tanggal Pencairan Donasi Wajib Diisi')
      .nullable(),
    donationAmount: Yup.string()
      .required('Jumlah Dana yang Dicairkan Wajib Diisi')
      .nullable(),
  });

  const checkForm = (values) => {
    if (values.donationName !== '' || values.donationAmount !== null || values.description !== '' || values.donationDate !== '') {
      setShowModalCloseConfirmation(true);
    } else {
      navigate('/infaq/penggunaan');
    }
  };

  const handleSubmit = async (values) => {
    try {
      const formData = new FormData();
      formData.append('amount', parseInt(values.donationAmount.replaceAll('.', '')));
      formData.append('infaqProgramId', values.donationName);
      formData.append('description', values.description);
      formData.append('usageDate', values.donationDate);
      const response = await addPencairanDanaInfaq(formData);
      if (response.status === 200) {
        navigate('/infaq/penggunaan');
        localStorage.setItem('isPencairanAdded', true);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleAvailableFunds = async (id) => {
    try {
      const detailProgramInfaq = await getProgramInfaqById(id);
      setAvailableFunds(detailProgramInfaq.infaqUsageDetail.availableFunds);
      const moneyFormatter = new Intl.NumberFormat();
      const Format = moneyFormatter.format(detailProgramInfaq.infaqUsageDetail.availableFunds);
      setAvailableFundsFormat(Format.replaceAll(',', '.'));
    } catch (error) {
      setAvailableFunds(0);
      setAvailableFundsFormat(0);
    }
  };

  useEffect(() => {
    const handleProgramName = async () => {
      const programList = await getAllProgramInfaq();
      const dummyDonationNameList = [];
      programList.forEach((value) => {
        const objOption = { value: value.id, label: value.programName };
        dummyDonationNameList.push(objOption);
      });
      setDonationNameList(dummyDonationNameList);
    };

    handleProgramName();
  }, []);

  // handle pengurangan availableFund
  useEffect(() => {
    if (availableFunds !== 0) {
      if (!isNaN(donationAmountOriginal)) {
        const newAvailableFunds = availableFunds - donationAmountOriginal;
        const moneyFormatter = new Intl.NumberFormat();
        const availableFundsFormat = moneyFormatter.format(newAvailableFunds);
        setAvailableFundsFormat(availableFundsFormat.replaceAll(',', '.'));
      }
    }
  }, [donationAmountOriginal]);

  return (
    <div className="content-container-side">
      <div className="d-flex">
        <p className="content-title">Infaq</p>
      </div>
      <div className="mb-4">
        <Breadcrumbs aria-label="breadcrumb" separator="">
          <Link
            color="inherit"
            to="/infaq/penggunaan"
            style={styleActiveBreadcrumb}
          >
            Pencairan Dana
          </Link>
          <Typography style={styleInactiveBreadcrumb}>
            Tambah Pencairan Dana
          </Typography>
        </Breadcrumbs>
      </div>
      <Card className="card-datatable">
        <div className="d-flex">
          <span
            className="material-icons clickable"
            onClick={() => {
              checkForm(formikRef.current.values);
            }}
            onKeyPress={() => navigate('/notification')}
            role="button"
            tabIndex={0}
          >
            arrow_back
          </span>
          <p className="card-datatable-title ms-3">
            Tambah Pencairan Dana
          </p>
        </div>
        <Formik
          innerRef={formikRef}
          initialValues={initialValues}
          enableReinitialize
          validationSchema={validation}
          onSubmit={(values, errors, touched) => {
            // console.log(values);
            setShowModalAddConfirmation(true);
          }}
        >
          {(props) => (
            <Form>
              <div className="row">
                <div className="col-lg-6 col-md-12">
                  <Select
                    name="donationName"
                    title="Nama Donasi"
                    value={props?.values?.donationName}
                    errors={props?.errors}
                    touched={props?.touched}
                    isSearchable={true}
                    options={donationNameList}
                    onChange={async (name, value) => {
                      props.setFieldValue('donationName', value);
                      await handleAvailableFunds(value);
                    }}
                    onBlur={props.setFieldTouched}
                  />
                </div>
                <div className="col-lg-6 col-md-12">
                  <Input
                    title="Tanggal Pencairan Dana"
                    name="donationDate"
                    type="date"
                    errors={props?.errors}
                    touched={props?.touched}
                    {...props?.getFieldProps('donationDate')}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-lg-6 col-md-12">
                  <div className="row">
                    <div className="col-lg-12 col-md-12 disabled">
                      <InputPrepend2
                        label="Jumlah Dana yang Tersedia"
                        title="Rp"
                        name="availableFunds"
                        type="text"
                        width="10%"
                        disabled
                        value={availableFundsFormat}
                        mb="0"
                      />
                    </div>
                  </div>
                  <div className="row mt-4">
                    <div className="col-lg-12 col-md-12">
                      <InputPrepend2
                        label="Jumlah Dana yang Dicairkan"
                        title="Rp"
                        name="donationAmount"
                        type="text"
                        width="10%"
                        value={donationAmountDisplay}
                        mb="0"
                        onChange={(e) => {
                          const inputValue = e.target.value;
                          if (!isNaN(inputValue.replaceAll('.', ''))) {
                            const moneyFormatter = new Intl.NumberFormat();
                            const donationFormat = moneyFormatter.format(inputValue.replaceAll('.', ''));
                            setDonationAmountDisplay(donationFormat.replaceAll(',', '.'));
                            setDonationAmountOriginal(inputValue.replace(/\D/g, ''));
                            props.setFieldValue(
                              'donationAmount',
                              inputValue.replaceAll('.', ''),
                              true,
                            );
                          }
                        }}
                        errors={props?.errors}
                        touched={props?.touched}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 col-md-12">
                  <Textarea
                    title="Keterangan"
                    name="description"
                    type="textarea"
                    tooltip="Berisi keterangan dana yang digunakan"
                    rows={6}
                    errors={props?.errors}
                    touched={props?.touched}
                    {...props?.getFieldProps('description')}
                  />
                </div>
              </div>
              <div className="d-flex align-items-center mt-5">
                <div className="ms-auto">
                  <ButtonAction
                    cancelTitle="Batal"
                    confirmTitle="Simpan Data"
                    toggle={() => {
                      checkForm(formikRef.current.values);
                    }}
                  />
                </div>
              </div>
            </Form>
          )}
        </Formik>
        <ConfirmModal
          show={modalCloseConfirmation}
          message="Apakah Anda yakin ingin membatalkan proses Tambah Data Pencairan Dana?"
          onHide={() => setShowModalCloseConfirmation(false)}
          confirmTitle="Yakin"
          cancelTitle="Batal"
          toggle={() => setShowModalCloseConfirmation(false)}
          inlineIcon={saveIcon}
          confirm={() => {
            navigate('/infaq/penggunaan');
          }}
        />
        <ConfirmModal
          show={modalAddConfirmation}
          message="Apakah Anda yakin data yang dimasukkan sudah sesuai?"
          onHide={() => setShowModalAddConfirmation(false)}
          confirmTitle={
            isLoading ? (
              <>
                <span className="spinner-border spinner-border-sm me-2" />
                Loading...
              </>
            ) : (
              'Ya, lanjutkan'
            )
          }
          cancelTitle="Periksa Kembali"
          toggle={() => setShowModalAddConfirmation(false)}
          inlineIcon={saveIcon}
          confirm={() => {
            setIsLoading(true);
            handleSubmit(formikRef.current.values);
          }}
          isLoading={isLoading}
        />
      </Card>
    </div>
  );
}

export default index;
