/* eslint-disable indent */
/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable react/jsx-boolean-value */
/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable no-console */
/* eslint-disable import/no-duplicates */
/* eslint-disable no-unused-vars */

/* eslint-disable */
import React from 'react';
import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import MenuItem from '@mui/material/MenuItem';
import { useNavigate } from 'react-router-dom';
import ConfirmModal from '../../components/modal/ConfirmModal';
import ButtonDetail from '../../components/table/button-detail';
import LinkIcon from '../../assets/images/icons/LinkIcon.svg';
import TrashIcon from '../../assets/images/icons/TrashIcon.svg';
import { DeleteData } from '../../services/notification/notificationService';
import { toastSuccess } from '../../components/toast';
import { setDeletedState } from '../../stores/actions/notification/index';

function NotificationItem(props) {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const [notificationType, setNotificationType] = useState('');
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    if (props.type === 'KHUSUS') {
      setNotificationType('special');
    } else if (props.type === 'RUTIN') {
      setNotificationType('daily');
    }
  }, []);
  return (
    <>
      <ConfirmModal
        show={show}
        onHide={handleClose}
        confirmTitle="Hapus"
        cancelTitle="Batal"
        message="Apakah anda yakin ingin menghapus Notifikasi?"
        toggle={handleClose}
        inlineIcon={TrashIcon}
        confirm={() => {
          DeleteData(props.id).then(() => {
            toastSuccess('Data Notifikasi Berhasil Dihapus');
            dispatch(setDeletedState());
            setShow(false);
          });
        }}
      />
      <div className="item-notification-header">
        <div className="d-flex flex-wrap align-items-center">
          <p className={props.special ? 'special' : 'text-title'}>
            &#x2022;
            {props.time} - {props.title}
          </p>
          <div className="ms-auto me-4">
            <ButtonDetail>
              <MenuItem
                onClick={() => {
                  navigate(`/notification/edit-${notificationType}/${props.id}`);
                }}
              >
                <span style={{ fontSize: 14, padding: '0px 30px' }}>Edit</span>
              </MenuItem>
              <MenuItem
                onClick={() => {
                  setShow(true);
                }}
              >
                <span style={{ fontSize: 14, padding: '0px 30px' }}>Hapus</span>
              </MenuItem>
            </ButtonDetail>
          </div>
        </div>
      </div>
      <div className="item-notification-body">
        <div className="d-flex">
          {props.image !== `${process.env.REACT_APP_API_URL}api/file?imageUrl=${null}` && (
            <img src={props.image} alt="gambar" className="img-notification" />
          )}

          <div className="ms-2">
            <p className="mb-2">{props.caption}</p>
            <div className="linknotif">
              <img className="me-2" src={LinkIcon} alt="" />
              <span className="text-secondary">{props.link}</span>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default NotificationItem;
