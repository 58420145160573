/* eslint-disable no-restricted-globals */
/* eslint-disable import/no-named-as-default */
/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-no-undef */
/* eslint-disable react/jsx-no-comment-textnodes */
/* eslint-disable no-unused-vars */
/* eslint-disable no-undef */
import React, { useState, useEffect } from 'react';
import { Card } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { MenuItem } from '@mui/material';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import Profile from '../../components/profile/profile';
import Search from '../../components/filter/Search';
import Sort from '../../components/filter/Sort';
import ButtonAdd from '../../components/buttons/ButtonAddMulti';
import ButtonDetail from '../../components/table/button-detail';
import Table from '../../components/table';
import { getSortVersion, getupdateMobile } from '../../services/whatsnew/whatsnewService';
import { toastSuccess } from '../../components/toast';

function Whatsnew() {
  const [searchWhatsnew, setSearchWhatsnew] = useState('');
  const [daftarWhatsnew, setDaftarWhatsnew] = useState([]);
  const navigate = useNavigate();

  const searchUrl = 'https://dev.svc.dzikra.apps360.id/api/mobile-update';

  const handleSearch = async () => {
    setSearchWhatsnew(event.target.value);
    try {
      const response = await window.fetch(`${searchUrl}?search=${event.target.value}`);
      const data = await response.json();
      setDaftarWhatsnew(data);
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    const handleWhatsnew = async () => {
      const response = await getupdateMobile();
      setDaftarWhatsnew(response);
    };
    handleWhatsnew();
  }, []);

  useEffect(() => {
    const isEdited = localStorage.getItem('isEdited');
    const isAdded = localStorage.getItem('isAdded');
    if (isEdited) {
      toastSuccess('Data Berhasil Diedit');
      localStorage.removeItem('isEdited');
    }
    if (isAdded) {
      toastSuccess('Data Berhasil Ditambah');
      localStorage.removeItem('isAdded');
    }
  }, []);
  const columns = [
    {
      Header: 'No',
      accessor: 'no',
      Cell: ({ row }) => <div>{row.index + 1}</div>,
    },
    {
      Header: 'Versi',
      accessor: 'version',
      Cell: ({ row }) => {
        const { original } = row;
        const { version } = original;
        return version !== null
          ? version
          : 100;
      },
    },
    {
      Header: 'Deskripsi',
      accessor: 'description',
      Cell: ({ row }) => {
        const { original } = row;
        const { mobileUpdateDetails } = original;
        return (
          <ul>
            {mobileUpdateDetails.map((item) => (
              <li>{item.description}</li>
            ))}
          </ul>
        );
      },
    },
    {
      Header: '',
      accessor: 'whatsnewDetail',
      Cell: ({ row }) => {
        const { original } = row;
        const { id } = original;
        return (
          <ButtonDetail>
            <MenuItem onClick={() => {
              navigate(`/detail-what-news/${id}`);
            }}
            >
              Detail

            </MenuItem>
            <MenuItem onClick={() => {
              navigate(`/whatsnew/edit/${id}`);
            }}
            >
              Edit

            </MenuItem>
          </ButtonDetail>
        );
      },
    },
  ];

  return (
    <div className="content-container-side">
      <div className="d-flex flex-wrap">
        <p className="content-title mt-3">
          Management What’s New Update
        </p>
        <div className="ms-auto">
          <div className="d-flex flex-wrap justify-content-end align-items-center">
            <Profile label="Ridwan Fajar Widodo" />
          </div>
        </div>
      </div>
      <Card className="card-datatable">
        <div className="d-flex flex-wrap align-items-center">
          <p className="card-datatable-title">
            Database Management What’s New Update
          </p>
          <div className="ms-auto">
            <div className="d-flex flex-wrap justify-content-end align-items-center">
              <Search
                name="search"
                type="text"
                placeholder="Search"
                style={{ marginRight: 10 }}
                mt="3 mt-sm-0"
                value={searchWhatsnew}
                onChange={(e) => handleSearch(e.target.value)}
              />
              <Sort>
                <MenuItem onClick={async () => {
                  const response = await getSortVersion('desc');
                  const sorted = daftarWhatsnew.sort((a, b) => a.version - b.version);
                  setDaftarWhatsnew(response);
                }}
                >
                  <span style={{ fontSize: 14 }}>Terbesar</span>
                </MenuItem>
                <MenuItem onClick={async () => {
                  const response = await getSortVersion('asc');
                  const sorted = daftarWhatsnew.sort((a, b) => b.version - a.version);
                  setDaftarWhatsnew(response);
                }}
                >
                  <span style={{ fontSize: 14 }}>Terkecil</span>
                </MenuItem>
              </Sort>
              <Button
                variant="contained"
                aria-label="add"
                size="medium"
                onClick={() => navigate('/whatsnew/add')}
                style={{
                  textAlign: 'center',
                  padding: '0.5em 1em',
                  borderRadius: '8px',
                  display: 'flex',
                  alignItems: 'center',
                  marginLeft: '0.5rem',
                  background: '#2ec4b6',
                }}
                className="ms-2 mt-3 mt-sm-0"
              >
                <AddIcon
                  style={{
                    fontSize: 16,
                    marginRight: 5,
                  }}
                />
                Tambah
              </Button>
            </div>
          </div>
        </div>
        <Table
          data={daftarWhatsnew}
          columns={columns}
        />
      </Card>
    </div>
  );
}

export default Whatsnew;
