/* eslint-disable import/no-named-as-default-member */
/* eslint-disable import/no-named-as-default */
/* eslint-disable max-len */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable react/jsx-no-comment-textnodes */
/* eslint-disable react/jsx-no-undef */
/* eslint-disable no-undef */
/* eslint-disable no-unused-vars */
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Typography from '@mui/material/Typography';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Card } from 'react-bootstrap';
import React, {
  useState, useEffect,
} from 'react';
import moment from 'moment';
import Input from '../../../../components/forms/input';
import Textarea from '../../../../components/forms/Textarea';
import InputPrepend2 from '../../../../components/forms/InputPrepend2';
import Select2 from '../../../../components/forms/Select2';
import 'react-toastify/dist/ReactToastify.css';
import 'react-quill/dist/quill.snow.css';
import { getPencairanDanaById } from '../../../../services/infaq/index';

const styleInactiveBreadcrumb = {
  fontWeight: 500,
  fontSize: '12px',
  lineHeight: '14px',
  color: 'rgba(79, 88, 103, 0.7)',
  textDecoration: 'none',
  fontFamily: 'BlueberrySans-Regular',
};
const styleActiveBreadcrumb = {
  fontWeight: 600,
  fontSize: '12px',
  lineHeight: '14px',
  color: 'rgba(79, 88, 103, 0.7)',
  textDecoration: 'none',
  fontFamily: 'BlueberrySans-Regular',
};

function detailPencairan() {
  const navigate = useNavigate();
  const { id } = useParams();
  const optionsStatus = [
    { value: 'ditinjau', label: 'Ditinjau' },
    { value: 'pengajuan', label: 'Pengajuan' },
    { value: 'selesai', label: 'Selesai' },
  ];
  const [programName, setProgramName] = useState('');
  const [usageDate, setUsageDate] = useState('');
  const [description, setDescription] = useState('');
  const [amount, setAmount] = useState('');
  const [availableFunds, setAvailableFunds] = useState(0);
  const [optionStatus, setOptionStatus] = useState('');

  useEffect(() => {
    const handleDetailPencairan = async () => {
      const detailPencairanDana = await getPencairanDanaById(id);
      setOptionStatus(detailPencairanDana.status);
      setProgramName(detailPencairanDana.infaqProgram.programName);
      const date = moment(detailPencairanDana.usageDate).utc().format('DD-MM-YYYY');
      setUsageDate(date);
      setDescription(detailPencairanDana.description);
      const moneyFormatter = new Intl.NumberFormat();
      const donationFormat = moneyFormatter.format(detailPencairanDana.amount);
      const availableFundFormat = moneyFormatter.format(detailPencairanDana.infaqProgram.infaqUsageDetail.availableFunds);
      setAmount(donationFormat.replaceAll(',', '.'));
      setAvailableFunds(availableFundFormat.replaceAll(',', '.'));
    };
    handleDetailPencairan();
  }, []);
  return (
    <div className="content-container-side">
      <div className="d-flex">
        <p className="content-title">
          Infaq
        </p>
      </div>
      <div className="mb-4">
        <Breadcrumbs aria-label="breadcrumb" separator="">
          <Link
            color="inherit"
            to="/infaq/penggunaan"
            onClick={() => {
            }}
            style={styleActiveBreadcrumb}
          >
            Pencairan Dana
          </Link>
          <Typography style={styleInactiveBreadcrumb}>
            Detail Pencairan Dana
          </Typography>
        </Breadcrumbs>
      </div>
      <Card className="card-datatable">
        <div className="d-flex flex-wrap align-items-center">
          <span
            className="material-icons clickable"
            style={{ marginTop: '-12px' }}
            onClick={() => {
              navigate('/infaq/penggunaan');
            }}
            onKeyPress={() => navigate('/whatnew')}
            role="button"
            tabIndex={0}
          >
            arrow_back
          </span>
          <p className="card-datatable-title ms-3">
            Detail Pencairan Dana
          </p>
        </div>
        <div className="row">
          <div className="col-lg-6 col-md-12">
            <Input
              name="programName"
              title="Nama Donasi"
              type="text"
              value={programName}
              disabled
            />
          </div>
          <div className="col-lg-6 col-md-12">
            <Input
              title="Tanggal Pencairan Dana"
              name="usageDate"
              type="string"
              value={usageDate}
              disabled
            />
          </div>
        </div>
        <div className="row">
          <div className="col-lg-6 col-md-12">
            <div className="row">
              <div className="col-lg-12 col-md-12">
                <Select2
                  name="status"
                  title="Status Pencairan Dana"
                  placeholder="Pilih Status Pencairan Dana"
                  options={optionsStatus}
                  key={optionStatus}
                  disabled
                  defaultValue={optionsStatus.find(
                    ({ value }) => value === optionStatus,
                  )}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-lg-12 col-md-12 disabled">
                <InputPrepend2
                  label="Jumlah Dana yang Tersedia"
                  title="Rp"
                  name="totalIncomingDonation"
                  type="number/text"
                  value={availableFunds}
                  disabled
                  width="10%"
                />
              </div>
            </div>
            <div className="row">
              <div className="col-lg-12 col-md-12">
                <InputPrepend2
                  label="Jumlah Dana Yang Dicairkan"
                  title="Rp"
                  name="amount"
                  type="number/text"
                  value={amount}
                  disabled
                  width="10%"
                />
              </div>
            </div>
          </div>
          <div className="col-lg-6 col-md-12">
            <Textarea
              title="Keterangan"
              name="description"
              type="textarea"
              tooltip="Berisi tentang keterangan dana yang digunakan"
              value={description}
              rows={12}
              disabled
            />
          </div>
        </div>
      </Card>
    </div>
  );
}

export default detailPencairan;
