/* eslint-disable import/prefer-default-export */
export const getInfaqProgram = async (params) => {
  try {
    const response = await fetch(`${process.env.REACT_APP_API_URL}api/infaq_program/datatable`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(params),
    });
    const result = await response.json();
    return result;
  } catch (error) {
    return error;
  }
};

export const getAllProgramInfaq = async (param) => {
  try {
    const type = param ? `?distribution=${param}` : '';
    const response = await fetch(`${process.env.REACT_APP_API_URL}api/infaq_program${type}`);
    const result = await response.json();
    return result;
  } catch (error) {
    return error;
  }
};
export const getProgramInfaqById = async (id) => {
  try {
    const response = await fetch(`${process.env.REACT_APP_API_URL}api/infaq_program/${id}`);
    const result = await response.json();
    return result;
  } catch (error) {
    return error;
  }
};

export const AddInfaqData = async (data) => {
  try {
    const response = await fetch(`${process.env.REACT_APP_API_URL}api/infaq_program/add`, {
      method: 'POST',
      body: data,
    });
    return response;
  } catch (error) {
    return error;
  }
};

export const AddStoryImage = async (data) => {
  try {
    const response = await fetch(`${process.env.REACT_APP_API_URL}api/infaq_program/story_description_img`, {
      method: 'POST',
      body: data,
    });
    return response;
  } catch (error) {
    return error;
  }
};

export const getPenerimaanDanaInfaq = async (params) => {
  try {
    const response = await fetch(`${process.env.REACT_APP_API_URL}api/infaq_admission/datatable`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(params),
    });
    const result = await response.json();
    return result;
  } catch (error) {
    return error;
  }
};

export const getPencairanDanaInfaq = async (params) => {
  try {
    const response = await fetch(`${process.env.REACT_APP_API_URL}api/infaq_usage/disbursement/datatable`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(params),
    });
    const result = await response.json();
    return result;
  } catch (error) {
    return error;
  }
};

export const getPenyaluranDanaInfaq = async (params) => {
  try {
    const response = await fetch(`${process.env.REACT_APP_API_URL}api/infaq_usage/distribution/datatable`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(params),
    });
    const result = await response.json();
    return result;
  } catch (error) {
    return error;
  }
};
export const updateInfaqById = async (id, data) => {
  try {
    const response = await fetch(`${process.env.REACT_APP_API_URL}api/infaq_program/${id}`, {
      method: 'PUT',
      body: data,
    });
    return response;
  } catch (error) {
    return error;
  }
};
export const addPencairanDanaInfaq = async (data) => {
  try {
    const response = await fetch(`${process.env.REACT_APP_API_URL}api/infaq_usage/disbursement/add`, {
      method: 'POST',
      body: data,
    });
    return response;
  } catch (error) {
    return error;
  }
};
export const getPencairanDanaById = async (id) => {
  try {
    const response = await fetch(`${process.env.REACT_APP_API_URL}api/infaq_usage/disbursement/${id}`);
    const result = await response.json();
    return result;
  } catch (error) {
    return error;
  }
};

export const getPenerimaanInfaqById = async (id) => {
  try {
    const response = await fetch(`${process.env.REACT_APP_API_URL}api/infaq_admission/${id}`);
    const result = await response.json();
    return result;
  } catch (error) {
    return error;
  }
};

export const getPenyaluranDanaById = async (id) => {
  try {
    const response = await fetch(`${process.env.REACT_APP_API_URL}api/infaq_usage/distribution/${id}`);
    const result = await response.json();
    return result;
  } catch (error) {
    return error;
  }
};

export const addPenyaluranDanaInfaq = async (data) => {
  try {
    const response = await fetch(`${process.env.REACT_APP_API_URL}api/infaq_usage/distribution/add`, {
      method: 'POST',
      body: data,
    });
    return response;
  } catch (error) {
    return error;
  }
};
export const updatePencairanDanaById = async (id, data) => {
  try {
    const response = await fetch(`${process.env.REACT_APP_API_URL}api/infaq_usage/disbursement/${id}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    });
    return response;
  } catch (error) {
    return error;
  }
};

export const DeletePencairanDanaInfaq = async (id) => {
  try {
    const response = await fetch(`${process.env.REACT_APP_API_URL}api/infaq_usage/disbursement/${id}`, {
      method: 'DELETE',
    });
    return response;
  } catch (error) {
    return error;
  }
};

export const DeletePenyaluranDanaInfaq = async (id) => {
  try {
    const response = await fetch(`${process.env.REACT_APP_API_URL}api/infaq_usage/distribution/${id}`, {
      method: 'DELETE',
    });
    return response;
  } catch (error) {
    return error;
  }
};

export const updatePenyaluranDanaById = async (id, data) => {
  try {
    const response = await fetch(`${process.env.REACT_APP_API_URL}/api/infaq_usage/distribution/${id}`, {
      method: 'PUT',
      body: data,
    });
    return response;
  } catch (error) {
    return error;
  }
};

export const printNotaPenerimaanInfaqById = (id) => {
  try {
    return (`${process.env.REACT_APP_API_URL}api/infaq_admission/print_bills/${id}`);
  } catch (error) {
    return error;
  }
};

export const exportPenerimaan = async (param) => {
  try {
    const params = new URLSearchParams(param);
    const url = `${process.env.REACT_APP_API_URL}api/infaq_admission/export?${params.toString()}`;
    window.open(url, '_blank');
    return { success: true, message: 'File download started' };
  } catch (error) {
    return error;
  }
};

export const exportPenggunaan = async (param, type) => {
  try {
    const params = new URLSearchParams(param);
    const url = `${process.env.REACT_APP_API_URL}api/infaq_usage/${type}/export?${params.toString()}`;
    window.open(url, '_blank');
    return { success: true, message: 'File download started' };
  } catch (error) {
    return error;
  }
};
