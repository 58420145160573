/* eslint-disable no-restricted-globals */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-shadow */
/* eslint-disable import/no-named-as-default-member */
/* eslint-disable import/no-named-as-default */
/* eslint-disable max-len */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable react/jsx-no-comment-textnodes */
/* eslint-disable react/jsx-no-undef */
/* eslint-disable no-undef */
/* eslint-disable no-unused-vars */
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Typography from '@mui/material/Typography';
import { Form, Formik } from 'formik';
import { useDispatch } from 'react-redux';
import * as Yup from 'yup';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Card, ToastContainer } from 'react-bootstrap';
import React, {
  useState, useRef, useMemo, useEffect,
} from 'react';
import ReactQuill from 'react-quill';
import moment from 'moment/moment';
import Input from '../../../../components/forms/input';
import InputPrepend2 from '../../../../components/forms/InputPrepend2';
import Select2 from '../../../../components/forms/Select2';
import 'react-toastify/dist/ReactToastify.css';
import 'react-quill/dist/quill.snow.css';
import ButtonAction from '../../../../components/buttons/ButtonAction';
import ConfirmationModal from '../../../../components/modal/ConfirmModal';
import ImageModal from '../../../../components/modal/ImageModal';
import saveIcon from '../../../../assets/images/icons/SaveIcon.svg';
import { setEditedInfaq } from '../../../../stores/actions/infaq/formInfaq';
import {
  updatePenyaluranDanaById, getPenyaluranDanaById, AddStoryImage, getAllProgramInfaq, getProgramInfaqById,
} from '../../../../services/infaq/index';

const styleInactiveBreadcrumb = {
  fontWeight: 500,
  fontSize: '12px',
  lineHeight: '14px',
  color: 'rgba(79, 88, 103, 0.7)',
  textDecoration: 'none',
  fontFamily: 'BlueberrySans-Regular',
};
const styleActiveBreadcrumb = {
  fontWeight: 600,
  fontSize: '12px',
  lineHeight: '14px',
  color: 'rgba(79, 88, 103, 0.7)',
  textDecoration: 'none',
  fontFamily: 'BlueberrySans-Regular',
};

function editPenyaluran(props) {
  const { id } = useParams();
  const navigate = useNavigate();
  const formikRef = useRef();
  const dispatch = useDispatch();
  const quillRef = useRef();
  const [isEditedPenyaluran, setIsEditedPenyaluran] = useState(false);
  const [programName, setProgramName] = useState('');
  const [usageDate, setUsageDate] = useState('');
  const [storyThumbnail, setStoryThumbnail] = useState(null);
  const [amount, setAmount] = useState('');
  const [amountDisplay, setAmountDisplay] = useState('');
  const [storyTitle, setStoryTitle] = useState('');
  const [storyDescription, setStoryDescription] = useState('');
  const [nameThumbnail, setNameThumbnail] = useState('Upload File Thumbnail');
  const [quillValue, setQuillValue] = useState('');
  const [donationNameList, setDonationNameList] = useState([]);
  const [programInfaqId, setProgramInfaqId] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [availableFunds, setAvailableFunds] = useState(0);
  const [showThumbnail, setShowThumbnail] = useState(false);
  const [modalCloseConfirmation, setShowModalCloseConfirmation] = useState(false);
  const [editThumbnail, setEditThumbnail] = useState(false);

  const initialValues = {
    programName: programName || '',
    usageDate: usageDate || '',
    storyThumbnail: storyThumbnail || null,
    amount: amount || '',
    storyTitle: storyTitle || '',
    quillValue: quillValue || '',
  };

  const validation = Yup.object().shape({
    programName: Yup.string().required('Nama Donasi Wajib Diisi').nullable(),
    usageDate: Yup.string().required('Tanggal Penyaluran Wajib Diisi').nullable(),
    amount: Yup.string().required('Target Donasi Wajib Diisi').nullable(),
    storyTitle: Yup.string().required('Judul Wajib Diisi').nullable(),
    storyThumbnail: Yup.mixed().when('editThumbnail', {
      is: true,
      then: (schema) => schema
        .required('Thumbnail Wajib Diisi')
        .test(
          'fileSize',
          'Gambar Maksimal 1MB',
          (value) => !value || value.size <= 1000000,
        )
        .test(
          'fileFormat',
          'Tipe file tidak didukung',
          (value) => !value || ['image/jpg', 'image/jpeg', 'image/gif', 'image/png'].includes(value.type),
        ),
    }),
  });

  const imageHandler = () => {
    const editor = quillRef.current.getEditor();
    const input = document.createElement('input');
    input.setAttribute('type', 'file');
    input.setAttribute('accept', 'image/*');
    input.click();

    input.onchange = async () => {
      const file = input.files[0];
      try {
        const formData = new FormData();
        formData.append('storyDescriptionImg', file);
        const response = await AddStoryImage(formData);
        const result = await response.text();
        const link = result;
        editor.insertEmbed(editor.getSelection(), 'image', link);
      } catch (err) {
        console.log('upload err:', err);
      }
    };
  };

  const modules = useMemo(() => ({
    toolbar: {
      container: [
        [{ font: [] }],
        [{ header: [1, 2, 3, 4, 5, 6, false] }],
        ['bold', 'italic', 'underline', 'strike'],
        [{ color: [] }, { background: [] }],
        [{ script: 'sub' }, { script: 'super' }],
        ['blockquote', 'code-block'],
        [{ list: 'ordered' }, { list: 'bullet' }],
        [{ indent: '-1' }, { indent: '+1' }, { align: [] }],
        ['link', 'image'],
        ['clean'],
      ],
      handlers: {
        image: imageHandler,
      },
    },
  }), []);

  const handleClose = () => setShowModal(false);
  const handleCloseModalConfirmation = () => setShowModalCloseConfirmation(false);
  const checkFormPenyaluran = (oldvalues, currentValues) => {
    if (id) {
      if (oldvalues.programName !== currentValues.programName
      || oldvalues.usageDate !== currentValues.usageDate
      || oldvalues.storyThumbnail !== currentValues.storyThumbnail
      || oldvalues.amount !== currentValues.amount
      || oldvalues.storyTitle !== currentValues.storyTitle) {
        setIsEditedPenyaluran(true);
        dispatch(setEditedInfaq(true));
      } else {
        setIsEditedPenyaluran(false);
        dispatch(setEditedInfaq(false));
      }
    }
  };
  const handleAvailableFunds = async (id) => {
    try {
      const detailProgramInfaq = await getProgramInfaqById(id);
      setAvailableFunds(detailProgramInfaq.infaqUsageDetail.availableFunds);
    } catch (error) {
      setAvailableFunds(0);
    }
  };
  const isFormEditedPenyaluranProgress = () => {
    if (id && isEditedPenyaluran) {
      setShowModalCloseConfirmation(true);
    } else {
      navigate('/infaq/penggunaan?activeTab=penyaluran');
    }
  };
  const handleShowThumbnailInput = () => {
    setShowThumbnail(true);
  };

  const handleCloseThumnailInput = () => {
    setShowThumbnail(false);
  };
  useEffect(() => {
    const handleProgramName = async () => {
      const dummy = [];
      const programList = await getAllProgramInfaq(true);
      programList.forEach((value) => {
        const objOption = { value: value.id, label: value.programName };
        dummy.push(objOption);
      });
      setDonationNameList(dummy);
    };
    setTimeout(() => {
      handleProgramName();
    }, 100);
  }, []);
  useEffect(() => {
    const handlePenyaluranDana = async () => {
      const response = await getPenyaluranDanaById(id);
      await handleAvailableFunds(response.infaqProgramId);
      setProgramName(response.infaqProgram.programName);
      setProgramInfaqId(response.infaqProgramId);
      const date = moment(response.usageDate).utc().format('YYYY-MM-DD');
      setUsageDate(date);
      setStoryDescription(response.storyDescription);
      const editor = quillRef.current.getEditor();
      editor.clipboard.dangerouslyPasteHTML(response.storyDescription);
      const moneyFormatter = new Intl.NumberFormat();
      const donationFormat = moneyFormatter.format(response.amount);
      setAmountDisplay(donationFormat.replaceAll(',', '.'));
      setAmount(response.amount);
      setStoryTitle(response.storyTitle);
      setStoryThumbnail(response.storyThumbnail);
      if (response.storyThumbnail !== null) {
        const storyThumbnailFilename = response.storyThumbnail.split('/').pop();
        setNameThumbnail(storyThumbnailFilename);
      }
    };
    handlePenyaluranDana();
  }, []);
  return (
    <div className="content-container-side">
      <ImageModal show={showThumbnail} handleClose={handleCloseThumnailInput} srcMedia={storyThumbnail} />
      <div className="d-flex">
        <p className="content-title">
          Infaq
        </p>
      </div>
      <div className="mb-4">
        <Breadcrumbs aria-label="breadcrumb" separator="">
          <Link
            color="inherit"
            to="/infaq/penggunaan?activeTab=penyaluran"
            onClick={() => {
            }}
            style={styleActiveBreadcrumb}
          >
            Penyaluran Dana
          </Link>
          <Typography style={styleInactiveBreadcrumb}>
            Edit Penyaluran Dana
          </Typography>
        </Breadcrumbs>
      </div>
      <Card className="card-datatable">
        <div className="d-flex flex-wrap align-items-center">
          <span
            className="material-icons clickable"
            style={{ marginTop: '-12px' }}
            onClick={() => {
              isFormEditedPenyaluranProgress();
            }}
            onKeyPress={() => navigate('/infaq/penggunaan')}
            role="button"
            tabIndex={0}
          >
            arrow_back
          </span>
          <p className="card-datatable-title ms-3">
            Edit Penyaluran Dana
          </p>
        </div>
        <Formik
          innerRef={formikRef}
          initialValues={initialValues}
          validationSchema={validation}
          enableReinitialize
          onSubmit={(values, errors, touched) => {
            setShowModal(true);
          }}
        >
          {(props) => (
            <Form>
              {checkFormPenyaluran(initialValues, props.values)}
              <ConfirmationModal
                show={showModal}
                message="Apakah Anda yakin data yang dimasukkan sudah sesuai?"
                onHide={handleClose}
                confirmTitle="Ya, lanjutkan"
                cancelTitle="Periksa Kembali"
                toggle={handleClose}
                inlineIcon={saveIcon}
                confirm={() => {
                  setShowModal(false);
                  let idInfaq = '';
                  if (programInfaqId !== props.values.programName) {
                    idInfaq = programInfaqId;
                  } else {
                    idInfaq = props.values.programName;
                  }
                  const formData = new FormData();
                  formData.append('infaqProgramId', idInfaq);
                  formData.append('usageDate', props.values.usageDate.toString().replaceAll('-', '/'));
                  formData.append('thumbnail', props.values.storyThumbnail);
                  formData.append('amount', props.values.amount);
                  formData.append('storyTitle', props.values.storyTitle);
                  formData.append('storyDescription', quillValue);
                  updatePenyaluranDanaById(id, formData).then((response) => {
                    if (response.status === 200) {
                      localStorage.setItem('isEditedPenyaluran', true);
                      dispatch(setEditedInfaq(false));
                      setTimeout(() => {
                        navigate('/infaq/penggunaan?activeTab=penyaluran');
                      }, 100);
                    }
                  });
                }}
              />
              <div className="row">
                <div className="col-lg-6 col-md-12">
                  <Select2
                    name="programName"
                    title="Nama Donasi"
                    placeholder="Pilih Nama Donasi"
                    options={donationNameList}
                    key={donationNameList}
                    defaultValue={donationNameList.find(
                      ({ value }) => value === programInfaqId,
                    )}
                    errors={props?.errors}
                    touched={props?.touched}
                    onChangeEvent={async (selectedOption) => {
                      props.setFieldValue('programName', selectedOption.value);
                      await handleAvailableFunds(selectedOption.value);
                    }}
                    {...props?.getFieldProps('programName')}
                  />
                </div>
                <div className="col-lg-6 col-md-12">
                  <Input
                    title="Tanggal Penggunaan Dana"
                    name="usageDate"
                    type="date"
                    errors={props?.errors}
                    touched={props?.touched}
                    {...props?.getFieldProps('usageDate')}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-lg-6 col-md-12 donation-goals">
                  <InputPrepend2
                    label="Total Dana Yang Digunakan"
                    title="Rp"
                    name="amount"
                    type="number/text"
                    value={amountDisplay}
                    onChange={(e) => {
                      if (!isNaN(e.target.value.replaceAll('.', ''))) {
                        const moneyFormatter = new Intl.NumberFormat();
                        const donationFormat = moneyFormatter.format(e.target.value.replaceAll('.', ''));
                        setAmountDisplay(donationFormat.replaceAll(',', '.'));
                        props.setFieldValue(
                          'amount',
                          e.target.value.replaceAll('.', ''),
                          true,
                        );
                      }
                    }}
                    errors={props?.errors}
                    touched={props?.touched}
                    width="10%"
                  />
                </div>
                <div className="col-lg-6 donation-goals disabled">
                  <InputPrepend2
                    label="Sisa Pencairan Dana"
                    title="Rp"
                    name="donationAmount"
                    type="text"
                    width="10%"
                    disabled
                    mb="0"
                    value={availableFunds}
                    errors={props?.errors}
                    touched={props?.touched}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-lg-6 col-md-12 card-datatable-title">
                  Cerita Penyaluran
                </div>
              </div>
              <div className="row">
                <div className="col-lg-6 col-md-12">
                  <Input
                    title="Judul"
                    name="storyTitle"
                    type="text"
                    errors={props?.errors}
                    touched={props?.touched}
                    {...props?.getFieldProps('storyTitle')}
                  />
                </div>
                <div className="col-lg-6 col-md-12">
                  <Input
                    title="Thumbnail"
                    name="storyThumbnail"
                    isShowMedia
                    onShowMedia={handleShowThumbnailInput}
                    filename={nameThumbnail}
                    type="file"
                    errors={props?.errors}
                    touched={props?.touched}
                    onChange={(e) => {
                      props.setFieldValue(
                        'storyThumbnail',
                        e.target.files[0],
                        true,
                      );
                      setNameThumbnail(e.target.files[0].name);
                      setEditThumbnail(true);
                    }}
                    accept="image/png, image/jpeg"
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-lg-12">
                  <div className="col-lg-12 col-md-12">
                    <div className="col-lg-6 col-md-12 description-label">
                      Deskripsi
                    </div>
                    <ReactQuill
                      title="Deskripsi"
                      theme="snow"
                      modules={modules}
                      onChange={setQuillValue}
                      ref={quillRef}
                    />
                  </div>
                </div>
              </div>
              <div className="d-flex align-items-center mt-5">
                <div className="ms-auto">
                  <ButtonAction
                    cancelTitle="Batal"
                    onClickCancel={() => {
                      navigate('/infaq/penggunaan?activeTab=penyaluran');
                    }}
                    confirmTitle="Simpan"
                    toggle={() => {
                      isFormEditedPenyaluranProgress();
                    }}
                  />
                </div>
              </div>
            </Form>
          )}
        </Formik>
        <ConfirmationModal
          show={modalCloseConfirmation}
          message="Apakah anda yakin ingin membatalkan proses edit penyaluran infaq?"
          onHide={handleClose}
          confirmTitle="Yakin"
          cancelTitle="Batal"
          toggle={handleCloseModalConfirmation}
          inlineIcon={saveIcon}
          confirm={() => {
            dispatch(setEditedInfaq(false));
            navigate('/infaq/penggunaan?activeTab=penyaluran');
          }}
        />
        <ToastContainer />
      </Card>
    </div>
  );
}

export default editPenyaluran;
