/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/prop-types */
import React from 'react';
import styles from '../../../assets/styles/_variables.scss';

function TableStatus(props) {
  const {
    status, style, color, toggle, leftIcon, rightIcon,
  } = props;

  const bgColor = {
    primary: 'rgba(46, 196, 182, 0.15)',
    primaryLabel: styles.primary,
    warning: 'rgba(238, 157, 98, 0.15)',
    warningLabel: styles.warning,
    danger: 'rgba(242, 102, 100, 0.15)',
    dangerLabel: styles.danger,
    secondary: '#B8B8D6',
    secondaryLabel: '#fff',
    scheduled: 'rgba(123, 137, 209, 0.15)',
    scheduledLabel: '#7B89D1',
    inactive: '#EDEDED',
    inactiveLabel: '#A9A9A9',
    success: '#3FA5EF26',
    successLabel: '#3FA5EF',
  };

  return (
    <div
      style={{
        backgroundColor: bgColor[color],
        color: bgColor[`${color}Label`],
        ...style,
      }}
      className="status"
      onClick={toggle}
    >
      {leftIcon && (
        <span className="material-icons-round left-icon">{leftIcon}</span>
      )}

      <span>{status}</span>

      {rightIcon && (
        <span className="material-icons-round right-icon">{rightIcon}</span>
      )}
    </div>
  );
}

export default TableStatus;
