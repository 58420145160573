/* eslint-disable react/jsx-boolean-value */
/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
import React from 'react';
// eslint-disable-next-line import/no-unresolved
import { Col, Row } from 'react-bootstrap';
// import Timeline from '../../assets/images/icons/TimeLine.svg';
import TimeLineDot from '../../assets/images/icons/TimeLineDot.svg';
// eslint-disable-next-line import/no-unresolved
import NotificationItem from './NotificationItem';

function ListNotification(props) {
  return (
    <div className="card-body timeline-notification pb-5">
      <div className="timeline-dot d-flex align-items-center">
        <img src={TimeLineDot} alt="timeline dot" />
        <p className="ms-3">{props.day}</p>
      </div>
      <div className="timeline-content ms-2 d-flex">
        <Row className="list-notification d-flex flex-wrap mt-3 w-100">
          {props.notifications.map((notification) => (
            <Col lg="12" className="ms-2 mb-2 box-notification" key={notification.id}>
              <NotificationItem
                className="notification-item"
                image={notification.media}
                time={notification.time}
                id={notification.id}
                title={notification.name}
                type={notification.type}
                link={notification.link}
                special={notification.type === 'KHUSUS'}
                caption={`"${notification.caption}"`}
              />
            </Col>
          ))}
        </Row>
      </div>
    </div>
  );
}

export default ListNotification;
