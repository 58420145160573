/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-no-useless-fragment */
import React from 'react';
import ReactPaginate from 'react-paginate';
import '../../../assets/styles/pagination.scss';

function Pagination({
  page,
  length,
  maxPage,
  recordTotal,
  toggle,
}) {
  const handlePageClick = (event) => {
    if (toggle) {
      toggle(event.selected);
    }
  };

  const initialPage = page <= 0 ? 0 : (page);

  return (
    <>
      {
        recordTotal !== 0 && recordTotal !== null && recordTotal > 10 && (
        <>
          <div className="mini-show" style={{ marginTop: 33, marginBottom: 33, textAlign: 'right' }}>
            <p style={{ margin: 0, fontSize: 14 }}>{`Menampilkan ${length ?? 0} dari ${recordTotal ?? 0}`}</p>
          </div>
          <div className="mini-show">
            <div>
              <ReactPaginate
                marginPagesDisplayed={1}
                previousLabel={(<span className="material-icons-round">arrow_back</span>)}
                previousLinkClassName="page-link"
                nextLabel={(<span className="material-icons-round">arrow_forward</span>)}
                nextLinkClassName="page-link"
                pageCount={maxPage}
                containerClassName="pagination justify-content-end"
                pageClassName="page-item"
                breakClassName="page-item"
                pageLinkClassName="page-link"
                breakLinkClassName="page-link"
                activeClassName="active"
                onPageChange={(event) => handlePageClick(event)}
                forceSelected={initialPage}
                forcePage={initialPage}
              />
            </div>
          </div>
          <div style={{
            display: 'flex', justifyContent: 'space-between', justifyItems: 'center', marginTop: 33,
          }}
          >
            <div className="mini-hide">
              <p style={{ margin: 0, fontSize: 14 }}>{`Menampilkan ${length ?? 0} dari ${recordTotal ?? 0}`}</p>
            </div>
            <div className="mini-hide">
              <ReactPaginate
                pageRangeDisplayed={2}
                marginPagesDisplayed={2}
                previousLabel={(<span className="material-icons-round">arrow_back</span>)}
                previousLinkClassName="page-link"
                nextLabel={(<span className="material-icons-round">arrow_forward</span>)}
                nextLinkClassName="page-link"
                pageCount={maxPage}
                containerClassName="pagination justify-content-end"
                pageClassName="page-item"
                breakClassName="page-item"
                pageLinkClassName="page-link"
                breakLinkClassName="page-link"
                activeClassName="active"
                onPageChange={(event) => handlePageClick(event)}
                forceSelected={initialPage}
                forcePage={initialPage}
              />
            </div>
          </div>
        </>
        )
      }
    </>
  );
}

export default Pagination;
