/* eslint-disable react/prop-types */
/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable react/react-in-jsx-scope */
import { useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
// import { useNavigate } from 'react-router-dom';
import { Card } from 'react-bootstrap';
import moment from 'moment';
// import MenuItem from '@mui/material/MenuItem';

import Table from '../../components/table/index';
// import ButtonDetail from '../../components/table/button-detail';

import UserService from '../../stores/actions/user';

const User = ({ user }) => {
  const dispatch = useDispatch();

  const [page, setPage] = useState(0);
  const [maxPage, setMaxPage] = useState(1);
  const [totalData, setTotalData] = useState(1);
  const [userData, setUserData] = useState([]);

  useEffect(() => {
    const handleRefresh = () => {
      new Promise((resolve) => {
        const param = {
          page: page ?? 0,
          length: 10,
          sort: ['name.asc'],
        };
        dispatch(UserService.datatable(param, resolve));
      }).then((res) => {
        setUserData(res.data);
        setMaxPage(Math.ceil(res?.totalCount / 10));
        setTotalData(res.totalCount);
      });
    };
    handleRefresh();
  }, [page]);
  const columns = [
    {
      Header: 'Nama Pengguna',
      accessor: 'name',
    },
    {
      Header: 'ID User',
      accessor: 'id',
    },
    {
      Header: 'Tanggal Bergabung',
      Cell: ({ row }) => {
        const { original } = row;
        const { createdAt } = original;
        return moment(createdAt).format('D MMMM YYYY');
      },
    },
    {
      Header: 'Jenis User',
      accessor: 'deviceType',
    },
    {
      Header: 'Umur',
      Cell: ({ row }) => {
        const { original } = row;
        const { birthdate } = original;
        return birthdate !== null ? moment().diff(birthdate, 'years') : '-';
      },
    },
    {
      Header: 'Jenis Kelamin',
      accessor: 'gender',
    },
    {
      Header: 'Alamat Email',
      accessor: 'email',
    },
    // {
    //   Header: '',
    //   accessor: 'actions',
    //   Cell: ({ row }) => {
    //     const original = row ? row?.original : [];
    //     return (
    //       <ButtonDetail>
    //         <MenuItem
    //           onClick={() => navigate(`/user/detail/${original?.name}`)}
    //         >
    //           <span style={{ fontSize: 14 }}>Detail</span>
    //         </MenuItem>
    //         <MenuItem
    //           onClick={() => navigate(`/user/detail/${original?.name}`)}
    //         >
    //           <span style={{ fontSize: 14 }}>Edit</span>
    //         </MenuItem>
    //       </ButtonDetail>
    //     );
    //   },
    // },
  ];

  return (
    <div className="content-container-side">
      <div className="d-flex my-3">
        <p className="content-title">Pengguna</p>
      </div>
      <Card className="card-datatable">
        <div className="d-flex flex-wrap align-items-center">
          <p className="card-datatable-title">Database Pengguna</p>
        </div>
        <Table
          data={userData}
          columns={columns}
          isLoading={user.pending}
          pagination={{
            page,
            totalData,
            maxPage,
            toggle: setPage,
          }}
        />
      </Card>
    </div>
  );
};

const mapStateToProps = (user) => ({ user });

export default connect(mapStateToProps)(User);
