/* eslint-disable consistent-return */
/* eslint-disable jsx-a11y/no-noninteractive-tabindex */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable no-return-assign */
/* eslint-disable no-param-reassign */
/* eslint-disable no-unused-vars */
/* eslint-disable import/no-unresolved */
/* eslint-disable react/button-has-type */
/* eslint-disable max-len */
import React, { useState, useEffect } from 'react';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import { useDispatch } from 'react-redux';
import Typography from '@mui/material/Typography';
import { Link, useParams, useNavigate } from 'react-router-dom';
import { Card } from 'react-bootstrap';
import { setEditedVersionValue } from '../../stores/actions/whatnew/form';
import ButtonAction from '../../components/buttons/ButtonAction';
import removeButton from '../../assets/images/icons/remove-button.svg';
import addButton from '../../assets/images/icons/add-button.svg';
import { getUpdateMobileById, updateWhatNewById } from '../../services/whatsnew/whatsnewService';
import ConfirmModal from '../../components/modal/ConfirmModal';
import saveIcon from '../../assets/images/icons/SaveIcon.svg';
import '../../assets/styles/whatnews.scss';

const styleInactiveBreadcrumb = {
  fontWeight: 500,
  fontSize: '12px',
  lineHeight: '14px',
  color: 'rgba(79, 88, 103, 0.7)',
  textDecoration: 'none',
  fontFamily: 'BlueberrySans-Regular',
};
const styleActiveBreadcrumb = {
  fontWeight: 600,
  fontSize: '12px',
  lineHeight: '14px',
  color: 'rgba(79, 88, 103, 0.7)',
  textDecoration: 'none',
  fontFamily: 'BlueberrySans-Regular',
};

function EditFormWhatNew() {
  const [versionList, setVersionList] = useState([]);
  const [version, setVersion] = useState('');
  const [oldVersion, setOldVersion] = useState('');
  const [oldVersionList, setOldVersionList] = useState([]);
  const [isInvalid, setIsInvalid] = useState('');
  const [invalidMessage, setInvalidMessage] = useState('field versi wajib berisi angka dan titik');
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { id } = useParams();
  const [modalInputConfirmation, setModalInputConfirmation] = useState(false);
  const [modalShowCloseConfirmation, setShowModalCloseConfirmation] = useState(false);
  const addInput = () => {
    const arrayEmptyValue = versionList.filter((item) => item.value.length < 3);
    if (arrayEmptyValue.length === 0) {
      setVersionList((s) => {
        const lastId = s[s.length - 1].id + 1;
        return [
          ...s,
          {
            type: 'text',
            id: lastId,
            value: '',
            isInvalid: '',
            invalidFeedBack: '',
          },
        ];
      });
    } else {
      const newArr = [];
      versionList.forEach((item) => {
        if (item.value.length < 3) {
          item.isInvalid = 'is-invalid';
          item.invalidFeedBack = 'd-block';
        }
        newArr.push(item);
      });

      setVersionList(newArr);
    }
  };

  const deleteInput = (index) => {
    const array = [...versionList];
    array.splice(index, 1);
    setVersionList(array);
  };

  const areEqual = (arr1, arr2) => {
    if (arr1.length === arr2.length) {
      const isChange = [];
      arr1.forEach((versionItem, index) => {
        if (arr2[index].value !== versionItem.value) {
          isChange.push(arr2[index].value);
        }
      });

      return (isChange.length === 0);
    }
  };

  const handleChange = (e) => {
    e.preventDefault();
    const index = e.target.id;
    if (e.target.value.length < 3) {
      setVersionList((s) => {
        const newArr = s.slice();
        newArr[index].isInvalid = 'is-invalid';
        newArr[index].invalidFeedBack = 'd-block';
        newArr[index].value = e.target.value;
        return newArr;
      });
    } else {
      setVersionList((s) => {
        const newArr = s.slice();
        newArr[index].value = e.target.value;
        newArr[index].isInvalid = '';
        newArr[index].invalidFeedBack = '';
        return newArr;
      });
    }

    setTimeout(() => {
      if (areEqual(oldVersionList, versionList) && version === oldVersion) {
        dispatch(setEditedVersionValue(false));
      } else {
        dispatch(setEditedVersionValue(true));
      }
    }, 100);
  };
  const handleInputVersion = (inputValue) => {
    const value = inputValue[inputValue.length - 1];
    const oldValue = inputValue[inputValue.length - 2];
    if (value === '.' && oldValue === '.') {
      setInvalidMessage('Titik tidak boleh lebih dari satu kali berturut-turut');
      setIsInvalid('d-block is-invalid');
    } else if (value === '1' || value === '2'
      || value === '3' || value === '4'
      || value === '5' || value === '6'
      || value === '7' || value === '8'
      || value === '9' || value === '10'
      || value === '.' || value === '0' || value === undefined) {
      setVersion(inputValue);
      setIsInvalid('');
    } else {
      setIsInvalid('d-block is-invalid');
    }
  };
  const handleButton = () => {
    const arrayEmptyValue = versionList.filter((item) => item.value.length < 3);
    if (arrayEmptyValue.length === 0) {
      setModalInputConfirmation(true);
    } else {
      const newArr = [];
      versionList.forEach((item) => {
        if (item.value.length < 3) {
          item.isInvalid = 'is-invalid';
          item.invalidFeedBack = 'd-block';
        }
        newArr.push(item);
      });
      setVersionList(newArr);
    }
  };

  const handleSubmit = async () => {
    const data = [];
    versionList.forEach((item) => {
      data.push({ description: item.value, mobileUpdateId: id });
    });
    const versionDetail = {
      mobileUpdateDetails: data,
      version,
    };

    updateWhatNewById(id, versionDetail).then((response) => {
      if (response.status === 200) {
        localStorage.setItem('isEdited', true);
        dispatch(setEditedVersionValue(false));
        navigate('/whatsnew');
      }
    }).catch((err) => console.log(err));
  };

  const handleClose = () => {
    setModalInputConfirmation(false);
    setShowModalCloseConfirmation(false);
  };

  const handleCloseConfirmation = () => {
    if (areEqual(oldVersionList, versionList) && version === oldVersion) {
      navigate('/whatsnew');
    } else {
      setShowModalCloseConfirmation(true);
    }
  };

  useEffect(() => {
    const handleWhatNew = async () => {
      const detailVersion = await getUpdateMobileById(id);
      const listInputValue = [];
      detailVersion.mobileUpdateDetails
        .forEach((versionValue) => {
          const inputItem = {
            type: 'text',
            id: 1,
            value: versionValue.description,
            isInvalid: '',
            invalidFeedBack: '',
          };

          listInputValue.push(inputItem);
        });
      setVersionList(listInputValue);
      setVersion(detailVersion.version);
    };
    handleWhatNew();
  }, []);

  useEffect(() => {
    const handleWhatNew = async () => {
      const detailVersion = await getUpdateMobileById(id);
      const listInputValue = [];
      detailVersion.mobileUpdateDetails
        .forEach((versionValue) => {
          const inputItem = {
            type: 'text',
            id: 1,
            value: versionValue.description,
            isInvalid: '',
            invalidFeedBack: '',
          };

          listInputValue.push(inputItem);
        });
      setOldVersion(detailVersion.version);
      setOldVersionList(listInputValue);
    };
    handleWhatNew();
  }, []);

  return (
    <div className="content-container-side">
      <div className="d-flex">
        <p className="content-title">
          Management What’s New Update
        </p>
      </div>
      <div className="mb-4">
        <Breadcrumbs aria-label="breadcrumb" separator="">
          <Link
            color="inherit"
            to="/mission"
            onClick={() => {
            }}
            style={styleActiveBreadcrumb}
          >
            Management What’s New Update
          </Link>
          <Typography style={styleInactiveBreadcrumb}>
            Edit What’s New Update
          </Typography>
        </Breadcrumbs>
      </div>
      <Card className="card-datatable">
        <div className="d-flex flex-wrap align-items-center">
          <span
            className="material-icons clickable"
            style={{ marginTop: '-12px' }}
            onClick={() => {
              handleCloseConfirmation();
            }}
            onKeyPress={() => console.log('navigate')}
            role="button"
            tabIndex={0}
          >
            arrow_back
          </span>
          <p className="card-datatable-title ms-3">
            Edit What’s New Update
          </p>
        </div>
        <div className="row">
          <div className="col-lg-12 mb-4">
            <p className="label mb-2">Versi</p>
            <input type="text" className={`form-input form-control ${isInvalid}`} value={version} onChange={(event) => handleInputVersion(event.target.value)} />
            <div className={`col-lg-12 invalid-feedback ${isInvalid}`}>
              <p className="ms-1">{invalidMessage}</p>
            </div>
          </div>
          <p className="label mb-2">Deskripsi</p>
          {versionList.map((item, i) => (
            <div className="col-lg-12 mb-4">
              <div className="row">
                <div className="col-lg-12 d-flex">
                  {i === 0 ? (
                    <button onClick={addInput} className="button-img">
                      <img src={addButton} alt="add input button" />
                    </button>
                  ) : (
                    <button className="button-img" onClick={() => deleteInput(i)}>
                      <img src={removeButton} alt="input button" />
                    </button>
                  )}
                  <input
                    onChange={handleChange}
                    value={item.value}
                    id={i}
                    type={item.type}
                    className={`form-control form-input ${item.isInvalid}`}
                  />
                </div>
                <div className={`col-lg-12 invalid-feedback ${item.invalidFeedBack}`}>
                  <p className="ms-5">Field Deskripsi Harus Diisi</p>
                </div>
              </div>
            </div>
          ))}
        </div>
        <div className="d-flex align-items-center mt-5">
          <div className="ms-auto">
            <ButtonAction
              cancelTitle="Batal"
              confirmTitle="Simpan"
              confirm={handleButton}
              toggle={() => {
                handleCloseConfirmation();
              }}
            />
          </div>
        </div>
      </Card>
      <ConfirmModal
        show={modalInputConfirmation}
        message="Apakah anda yakin ingin mengubah data?"
        onHide={handleClose}
        confirmTitle="Yakin"
        cancelTitle="Batal"
        toggle={handleClose}
        inlineIcon={saveIcon}
        confirm={handleSubmit}
      />

      <ConfirmModal
        show={modalShowCloseConfirmation}
        message="Apakah anda yakin ingin membatalkan edit?"
        onHide={handleClose}
        confirmTitle="Yakin"
        cancelTitle="Batal"
        toggle={handleClose}
        inlineIcon={saveIcon}
        confirm={() => navigate('/whatsnew')}
      />
    </div>
  );
}

export default EditFormWhatNew;
