/* eslint-disable react/jsx-no-comment-textnodes */
/* eslint-disable react/jsx-closing-bracket-location */
/* eslint-disable react/jsx-closing-tag-location */
/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable indent */
/* eslint-disable react/jsx-indent */
/* eslint-disable react/prop-types */
/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable react/react-in-jsx-scope */
/* eslint-disable no-nested-ternary */
import { useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { Card } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';
import Badge from '@mui/material/Badge';
import SortIcon from '@mui/icons-material/UnfoldMore';
import DescIcon from '@mui/icons-material/South';
import AscIcon from '@mui/icons-material/North';
import moment from 'moment';
import 'moment/locale/id';

import FilterIcon from '@mui/icons-material/FilterList';
import Table from '../../components/table/index';
import TableStatus from '../../components/table/status';
import ButtonDetail from '../../components/table/button-detail';
// import ButtonAdd from '../../components/buttons/Button';
import ButtonAdd from '../../components/buttons/ButtonAddMulti';
import Search from '../../components/filter/Search';
import { toastSuccess, toastError } from '../../components/toast';
import FilterModal from '../../components/modal/FilterModal';

import MissionService from '../../stores/actions/mission';
import ConfirmationModal from '../../components/modal/ConfirmModal';
import TrashIcon from '../../assets/images/icons/TrashIcon.svg';

const Misi = ({ misi }) => {
  const styledButton = {
    textTransform: 'capitalize',
    color: '#4F5867',
    background: '#fff',
    borderRadius: '8px',
    boxShadow: 'none',
  };
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [page, setPage] = useState(0);
  const [maxPage, setMaxPage] = useState(1);
  const [totalData, setTotalData] = useState(1);
  const [missionData, setMissionData] = useState([]);
  const [search, setSearch] = useState('');
  const [deletedMission, setDeletedMission] = useState(0);
  const [directionSort, setDirectionSort] = useState('');
  const [sortIcon, setSortIcon] = useState(<SortIcon />);
  const [confirmStatusModal, setConfirmStatusModal] = useState(false);
  const [idStoppedMission, setIdStoppedMission] = useState(null);
  const [isOnFilter, setIsOnFilter] = useState(false);
  const [showModalFilter, setShowModalFilter] = useState(false);
  const [paramFilter, setParamFilter] = useState({});
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [dataDeleted, setDataDeleted] = useState([]);

  const getDate = moment().format('YYYY-MM-DD');

  useEffect(() => {
    const handleRefresh = () => {
      new Promise((resolve) => {
        const param = {
          page: page ?? 0,
          length: 10,
          missionType: paramFilter?.missionType ?? '',
          searchText: search ?? '',
          sort: directionSort !== '' ? [`missionName.${directionSort}`] : ['activationDate.desc'],
        };
        dispatch(MissionService.datatable(param, resolve));
      }).then((res) => {
        setMissionData(res.data);
        setMaxPage(Math.ceil(res?.totalCount / 10));
        setTotalData(res.totalCount);
      });
    };
    handleRefresh();
  }, [page, search, directionSort, deletedMission, paramFilter]);

  const handleSearch = (e) => {
    setSearch(e.target.value);
  };

  const handleClickSort = () => {
    if (directionSort === 'asc') {
      setDirectionSort('desc');
      setSortIcon(<DescIcon />);
    } else {
      setDirectionSort('asc');
      setSortIcon(<AscIcon />);
    }
  };
  const optionsFilter = [
    { value: 'draft', label: 'Draft' },
    { value: 'inactive', label: 'Dijadwalkan' },
    { value: 'inactive', label: 'Aktif' },
    { value: 'inactive', label: 'Tidak Aktif' },
  ];

  const secondOptionsFilter = [
    { value: 'Daily', label: 'Rutin' },
    { value: 'Special', label: 'Spesial' },
  ];
  const handleFilter = (selectedOption, secondSelectedOption, startDate, endDate) => {
    console.log(selectedOption, secondSelectedOption, startDate, endDate);
    setParamFilter({
      missionType: secondSelectedOption.value,
    });
    setShowModalFilter(false);
    setIsOnFilter(true);
  };

  const handleFilterReset = () => {
    setParamFilter({
      missionType: '',
    });
    setShowModalFilter(false);
    setIsOnFilter(false);
  };

  // handle delete mission
  const showConfirmModal = (data) => {
    setShowDeleteConfirmation(!showDeleteConfirmation);
    setDataDeleted(data ?? null);
  };

  const handleDelete = () => {
    if (dataDeleted) {
      const id = dataDeleted?.id;
      return new Promise((resolve) => {
        dispatch(MissionService.deleteMission(id, resolve, () => {
          setDeletedMission(deletedMission + 1);
          setShowDeleteConfirmation(!showDeleteConfirmation);
          toastSuccess('Data Misi Berhasil Dihapus');
        }));
      });
    }

    return null;
  };

  const columns = [
    {
      Header: 'No',
      accessor: 'no',
    },
    {
      Header: 'Nama Misi',
      Cell: ({ row }) => {
        const { original } = row;
        const { missionName } = original;
        return missionName !== null
          ? missionName.charAt(0).toUpperCase() + missionName.slice(1)
          : '-';
      },
    },
    {
      Header: 'Jenis Misi',
      Cell: ({ row }) => {
        const { original } = row;
        const { missionType } = original;
        return missionType !== null
          ? `${missionType.charAt(0).toUpperCase() + missionType.slice(1)} Mission`
          : '-';
      },
    },
    {
      Header: 'Tanggal Aktivasi',
      Cell: ({ row }) => {
        const { original } = row;
        const { activationDate } = original;
        return activationDate !== null
          ? moment(activationDate).format('D MMMM YYYY')
          : '-';
      },
    },
    {
      Header: 'Jumlah Peserta',
      accessor: 'totalParticipants',
      Cell: ({ row }) => {
        const { original } = row;
        const { totalParticipants } = original;
        return totalParticipants !== null
          ? totalParticipants
          : 0;
      },
    },
    {
      Header: 'Status',
      accessor: 'status',
      Cell: ({ row }) => {
        const { original } = row;
        const { status } = original;
        const activationDate = moment(original.activationDate).format('YYYY-MM-DD');
        return (
          <TableStatus
            status={
              status === 0
                ? 'Draft'
                : status === 1
                  ? getDate < activationDate
                    ? 'Dijadwalkan'
                    : 'Aktif'
                  : 'Tidak Aktif'
            }
            color={
              status === 0
                ? 'warning'
                : status === 1
                  ? getDate < activationDate
                    ? 'scheduled'
                    : 'primary'
                  : 'danger'
            }
          />
        );
      },
    },
    {
      Header: '',
      accessor: 'missionDetail',
      Cell: ({ row }) => {
        const original = row ? row?.original : [];
        const type = original.missionType;
        const activationDate = moment(original.activationDate).format('YYYY-MM-DD');
        return (
          <ButtonDetail>
              <MenuItem
                onClick={() => {
                  localStorage.removeItem('mission');
                  localStorage.removeItem('missionDetails');
                  navigate(`/mission/detail/${type.toLowerCase()}/${original?.id}`);
                }}
              >
                <span style={{ fontSize: 14 }}>Detail</span>
              </MenuItem>
              {original.status === 0 || (original.status === 1 && getDate < activationDate) ? (
                <MenuItem
                  onClick={() => {
                    localStorage.removeItem('mission');
                    localStorage.removeItem('missionDetails');
                    navigate(`/mission/edit/${type.toLowerCase()}/${original?.id}`);
                  }}
                >
                  <span style={{ fontSize: 14 }}>Edit</span>
                </MenuItem>
              ) : null}
                {original.status === 0 ? (
                <MenuItem
                  onClick={() => {
                    showConfirmModal(original);
                }}
              >
                <span style={{ fontSize: 14 }}>Hapus</span>
              </MenuItem>
              ) : null}
              {(original.status === 1 && getDate >= activationDate) ? (
                <MenuItem
                  onClick={() => {
                    setIdStoppedMission(original.id);
                    setConfirmStatusModal(true);
                  }}
                >
                  <span style={{ fontSize: 14 }}>Hentikan Misi</span>
                </MenuItem>
              ) : null}
            </ButtonDetail>
        );
      },
    },
  ];

  return (
    <div className="content-container-side">
      <div className="d-flex my-3">
        <p className="content-title">
          Misi
        </p>
      </div>
      <Card className="card-datatable">
        <div className="d-flex flex-wrap align-items-center">
          <p className="card-datatable-title">
            Database Misi
          </p>
          <div className="ms-auto">
            <div className="d-flex flex-wrap justify-content-end align-items-center">
              <Search
                name="search"
                type="text"
                placeholder="Search"
                value={search}
                onChange={handleSearch}
                style={{ marginRight: 10 }}
                mt="3 mt-sm-0"
              />
                {isOnFilter ? (
                  <Badge variant="dot" color="warning">
                    <Button
                      onClick={() => setShowModalFilter(true)}
                      variant="contained"
                      aria-label="filter"
                      aria-controls="long-menu"
                      aria-haspopup="true"
                      size="medium"
                      sx={{
                        border: '1px solid #2EC4B6 !important',
                      }}
                      style={styledButton}
                      endIcon={<FilterIcon />}
                    >
                      Filter
                    </Button>
                  </Badge>
                ) : (
                  <Button
                    onClick={() => setShowModalFilter(true)}
                    variant="contained"
                    aria-label="filter"
                    aria-controls="long-menu"
                    aria-haspopup="true"
                    size="medium"
                    style={styledButton}
                    endIcon={<FilterIcon />}
                  >
                    Filter
                  </Button>
                )}
              <Button
                variant="contained"
                aria-label="sort"
                size="medium"
                onClick={handleClickSort}
                style={{
                  color: '#4F5867',
                  background: '#fff',
                  border: '1px solid #e4e4e4',
                }}
                endIcon={sortIcon}
                className="ms-2 mt-3 mt-sm-0"
              >
                Sort
              </Button>
              <ButtonAdd customClass="mt-3 mt-sm-0">
                <MenuItem
                  onClick={() => {
                    localStorage.removeItem('mission');
                    localStorage.removeItem('missionDetails');
                    navigate('/mission/add/daily');
                  }}
                >
                  <span style={{ fontSize: 14 }}>
                    Daily Mission
                  </span>
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    localStorage.removeItem('mission');
                    localStorage.removeItem('missionDetails');
                    navigate('/mission/add/special');
                  }}
                >
                  <span style={{ fontSize: 14 }}>
                    Special Mission
                  </span>
                </MenuItem>
              </ButtonAdd>
            </div>
          </div>
        </div>
        <Table
          data={missionData}
          columns={columns}
          isLoading={misi.pending}
          pagination={{
            page, totalData, maxPage, toggle: setPage,
          }}
        />
      </Card>
      <ConfirmationModal
        show={confirmStatusModal}
        toggle={() => setConfirmStatusModal(false)}
        message="Apakah Anda yakin ingin menghentikan misi?"
        confirm={() => {
          new Promise((resolve) => {
            dispatch(MissionService.stopMission(idStoppedMission, resolve));
            }).then(() => {
              setDeletedMission(deletedMission + 1);
              toastSuccess('Data Misi Berhasil Dinonaktifkan');
            }).catch(() => {
              toastError('Data Misi Gagal Dinonaktifkan');
            });
            setConfirmStatusModal(false);
        }}
        confirmTitle="Hentikan"
        cancelTitle="Batal"
        isWarning
      />
            <FilterModal
              show={showModalFilter}
              onHide={() => setShowModalFilter(false)}
              titleSelect="Status"
              select
              placeholderSelect="Pilih Status"
              confirmTitle="Terapkan"
              cancelTitle="Batal"
              withDateRange
              paramFilter={paramFilter}
              selectOptions={optionsFilter}
              secondSelect
              titleSecondSelect="Jenis Misi"
              placeholderSecondSelect="Pilih Jenis Misi"
              secondSelectOptions={secondOptionsFilter}
              handleFilter={handleFilter}
              handleReset={handleFilterReset}
              toggle={() => setShowModalFilter(false)}
              confirm={() => {
        }}
      />
      <ConfirmationModal
        show={showDeleteConfirmation}
        toggle={showConfirmModal}
        inlineIcon={TrashIcon}
        confirm={handleDelete}
        message="Apakah anda yakin ingin menghapus data?"
        cancelTitle="Batal"
        confirmTitle="Hapus"
      />
    </div>
  );
};

const mapStateToProps = (misi) => ({ misi });

export default connect(mapStateToProps)(Misi);
