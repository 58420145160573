/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import { Breadcrumbs, Typography } from '@mui/material';
import { Link, useNavigate, useParams } from 'react-router-dom';
import moment from 'moment/moment';
import Input from '../../../components/forms/input';
import ButtonAction from '../../../components/buttons/ButtonAction';
import InputPrepend2 from '../../../components/forms/InputPrepend2';
import Textarea from '../../../components/forms/Textarea';
import { getPenerimaanInfaqById, printNotaPenerimaanInfaqById } from '../../../services/infaq/index';

const styleActiveBreadcrumb = {
  fontWeight: 600,
  fontSize: '12px',
  lineHeight: '14px',
  color: 'rgba(79, 88, 103, 0.7)',
  textDecoration: 'none',
  fontFamily: 'BlueberrySans-Regular',
};

const styleInactiveBreadcrumb = {
  fontWeight: 500,
  fontSize: '12px',
  lineHeight: '14px',
  color: 'rgba(79, 88, 103, 0.7)',
  textDecoration: 'none',
  fontFamily: 'BlueberrySans-Regular',
};

function PrintNote() {
  const navigate = useNavigate();
  const [name, setName] = useState('');
  const [programName, setProgramName] = useState('');
  const [transactionNumber, setTransactionNumber] = useState('');
  const [description, setDescription] = useState('');
  const [amount, setAmount] = useState('');
  const [donationDate, setDonationDate] = useState('');
  const { id } = useParams();

  useEffect(() => {
    const handleGetPenerimaanInfaqById = async (idPenerimaanInfaq) => {
      const response = await getPenerimaanInfaqById(idPenerimaanInfaq);
      setName(response.user.name);
      setTransactionNumber(response.transactionNumber);
      setDescription(response.description || '-');
      setAmount(response.amount);
      setProgramName(response.infaqProgram.programName);
      const date = moment(response.admissionDate).utc().format('DD-MM-YYYY');
      setDonationDate(date);
    };

    handleGetPenerimaanInfaqById(id);
  }, []);

  return (
    <div className="content-container-side">
      <div className="d-flex">
        <p className="content-title">Infaq</p>
      </div>
      <div className="mb-4">
        <Breadcrumbs aria-label="breadcrumb" separator="">
          <Link
            color="inherit"
            to="/infaq/penerimaan"
            style={styleActiveBreadcrumb}
          >
            Penerimaan Dana
          </Link>
          <Typography style={styleInactiveBreadcrumb}>
            Cetak Nota
          </Typography>
        </Breadcrumbs>
      </div>

      <div className="card-datatable">
        <div className="d-flex">
          <span
            className="material-icons clickable"
            onClick={() => {
              navigate('/infaq/penerimaan');
            }}
            onKeyPress={() => {
            }}
            role="button"
            tabIndex={0}
          >
            arrow_back
          </span>
          <p className="card-datatable-title ms-3">
            Cetak Nota
          </p>
        </div>
        <div className="row">
          <div className="col-lg-6">
            <Input
              title="Nama Lengkap"
              name="fullName"
              type="text"
              value={name}
              disabled
            />
          </div>
          <div className="col-lg-6">
            <Input
              title="Nomor Transaksi"
              name="no"
              type="text"
              value={transactionNumber}
              disabled
            />
          </div>
        </div>
        <div className="row">
          <div className="col-lg-6">
            <Input
              title="Tanggal Donasi"
              name="donationDate"
              type="string"
              value={donationDate}
              disabled
            />
          </div>
          <div className="col-lg-6">
            <Input
              title="Nama Donasi"
              name="programName"
              type="string"
              value={programName}
              disabled
            />
          </div>
        </div>
        <div className="row">
          <div className="col-lg-6">
            <Textarea
              title="Keterangan"
              name="description"
              type="textarea"
              value={description}
              rows={6}
              disabled
            />
          </div>
          <div className="col-lg-6">
            <InputPrepend2
              label="Jumlah Donasi Yang Masuk"
              title="Rp"
              name="donationTarget"
              type="text"
              width="10%"
              value={amount}
              disabled
            />
          </div>
        </div>
        <div className="d-flex align-items-center mt-5">
          <div className="ms-auto">
            <ButtonAction
              cancelTitle="Batal"
              confirmTitle="Cetak Nota"
              toggle={() => {
                navigate('/infaq/penerimaan');
              }}
              confirm={() => {
                window.open(printNotaPenerimaanInfaqById(id));
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default PrintNote;
