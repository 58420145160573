/* eslint-disable import/no-named-as-default-member */
/* eslint-disable import/no-named-as-default */
/* eslint-disable max-len */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable react/jsx-no-comment-textnodes */
/* eslint-disable react/jsx-no-undef */
/* eslint-disable no-undef */
/* eslint-disable no-unused-vars */
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Typography from '@mui/material/Typography';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Card } from 'react-bootstrap';
import React, {
  useState, useRef, useMemo, useEffect,
} from 'react';
import ReactQuill from 'react-quill';
import moment from 'moment';
import Input from '../../../../components/forms/input';
import InputPrepend2 from '../../../../components/forms/InputPrepend2';
import ImageModal from '../../../../components/modal/ImageModal';
import 'react-toastify/dist/ReactToastify.css';
import 'react-quill/dist/quill.snow.css';
import { getPenyaluranDanaById, getProgramInfaqById } from '../../../../services/infaq/index';

const styleInactiveBreadcrumb = {
  fontWeight: 500,
  fontSize: '12px',
  lineHeight: '14px',
  color: 'rgba(79, 88, 103, 0.7)',
  textDecoration: 'none',
  fontFamily: 'BlueberrySans-Regular',
};
const styleActiveBreadcrumb = {
  fontWeight: 600,
  fontSize: '12px',
  lineHeight: '14px',
  color: 'rgba(79, 88, 103, 0.7)',
  textDecoration: 'none',
  fontFamily: 'BlueberrySans-Regular',
};

function detailPenyaluran() {
  const navigate = useNavigate();
  const { id } = useParams();
  const [programName, setProgramName] = useState('');
  const [usageDate, setUsageDate] = useState('');
  const [amount, setAmount] = useState('');
  const [storyTitle, setStoryTitle] = useState('');
  const [storyThumbnail, setStoryThumbnail] = useState(null);
  const [storyThumbnailName, setStoryThumbnailName] = useState(null);
  const [storyDescription, setStoryDescription] = useState('');
  const [quillValue, setQuillValue] = useState('');
  const [availableFunds, setAvailableFunds] = useState(0);
  const [showThumbnail, setShowThumbnail] = useState(false);
  const quillRef = useRef();

  const handleAvailableFunds = async (idProgram) => {
    try {
      const detailProgramInfaq = await getProgramInfaqById(idProgram);
      setAvailableFunds(detailProgramInfaq.infaqUsageDetail.availableFunds);
    } catch (error) {
      setAvailableFunds(0);
    }
  };
  const handleShowThumbnailInput = () => {
    setShowThumbnail(true);
  };
  const handleCloseThumnailInput = () => {
    setShowThumbnail(false);
  };

  useEffect(() => {
    const handleDetailPenyaluran = async () => {
      const detailPenyaluranDana = await getPenyaluranDanaById(id);
      await handleAvailableFunds(detailPenyaluranDana.infaqProgramId);
      setProgramName(detailPenyaluranDana.infaqProgram.programName);
      const date = moment(detailPenyaluranDana.usageDate).utc().format('DD-MM-YYYY');
      setUsageDate(date);
      const moneyFormatter = new Intl.NumberFormat();
      const donationFormat = moneyFormatter.format(detailPenyaluranDana.amount);
      setAmount(donationFormat.replaceAll(',', '.'));
      setStoryTitle(detailPenyaluranDana.storyTitle);
      setStoryThumbnail(detailPenyaluranDana.storyThumbnail);
      if (detailPenyaluranDana.storyThumbnail !== null) {
        const storyThumbnailFilename = detailPenyaluranDana.storyThumbnail.split('/').pop();
        setStoryThumbnailName(storyThumbnailFilename);
      }
      setStoryDescription(detailPenyaluranDana.storyDescription);
      setQuillValue(detailPenyaluranDana.description);
    };
    handleDetailPenyaluran();
  }, []);
  const modules = useMemo(() => ({
    toolbar: {
      container: [
        [{ font: [] }],
        [{ header: [1, 2, 3, 4, 5, 6, false] }],
        ['bold', 'italic', 'underline', 'strike'],
        [{ color: [] }, { background: [] }],
        [{ script: 'sub' }, { script: 'super' }],
        ['blockquote', 'code-block'],
        [{ list: 'ordered' }, { list: 'bullet' }],
        [{ indent: '-1' }, { indent: '+1' }, { align: [] }],
        ['link', 'image'],
        ['clean'],
      ],
    },
  }), []);
  return (
    <div className="content-container-side">
      <ImageModal show={showThumbnail} handleClose={handleCloseThumnailInput} srcMedia={storyThumbnail} />
      <div className="d-flex">
        <p className="content-title">
          Infaq
        </p>
      </div>
      <div className="mb-4">
        <Breadcrumbs aria-label="breadcrumb" separator="">
          <Link
            color="inherit"
            to="/infaq/penggunaan?activeTab=penyaluran"
            onClick={() => {
            }}
            style={styleActiveBreadcrumb}
          >
            Penyaluran Dana
          </Link>
          <Typography style={styleInactiveBreadcrumb}>
            Detail Penyaluran Dana
          </Typography>
        </Breadcrumbs>
      </div>
      <Card className="card-datatable">
        <div className="d-flex flex-wrap align-items-center">
          <span
            className="material-icons clickable"
            style={{ marginTop: '-12px' }}
            onClick={() => {
              navigate('/infaq/penggunaan?activeTab=penyaluran');
            }}
            onKeyPress={() => navigate('/whatnew')}
            role="button"
            tabIndex={0}
          >
            arrow_back
          </span>
          <p className="card-datatable-title ms-3">
            Detail Penyaluran Dana
          </p>
        </div>
        <div className="row">
          <div className="col-lg-6 col-md-12">
            <Input
              title="Nama Donasi"
              name="programName"
              type="text"
              value={programName}
              disabled
            />
          </div>
          <div className="col-lg-6 col-md-12">
            <Input
              title="Tanggal Penggunaan Dana"
              name="usageDate"
              type="string"
              value={usageDate}
              disabled
            />
          </div>
        </div>
        <div className="row">
          <div className="col-lg-6 col-md-12">
            <InputPrepend2
              label="Total Dana Yang Digunakan"
              title="Rp"
              name="amount"
              type="number/text"
              value={amount}
              disabled
              width="10%"
            />
          </div>
          <div className="col-lg-6 donation-goals disabled">
            <InputPrepend2
              label="Sisa Pencairan Dana"
              title="Rp"
              name="donationAmount"
              type="text"
              width="10%"
              disabled
              value={availableFunds}
              mb="0"
            />
          </div>
        </div>
        <div className="row">
          <div className="col-lg-6 col-md-12 card-datatable-title">
            Cerita Penyaluran
          </div>
        </div>
        <div className="row">
          <div className="col-lg-6 col-md-12">
            <Input
              title="Judul"
              name="storyTitle"
              type="text"
              value={storyTitle}
              disabled
            />
          </div>
          <div className="col-lg-6 col-md-12">
            <Input
              title="Thumbnail"
              name="storyThumbnail"
              filename={storyThumbnailName}
              type="file"
              isShowMedia
              onShowMedia={handleShowThumbnailInput}
              disabled
              accept="image/png, image/jpeg"
            />
          </div>
        </div>
        <div className="row">
          <div className="col-lg-12">
            <div className="col-lg-12 col-md-12 description-label">
              Deskripsi
            </div>
            <ReactQuill
              title="Deskripsi"
              theme="snow"
              modules={modules}
              ref={quillRef}
              value={storyDescription}
              readOnly
            />
          </div>
        </div>
      </Card>
    </div>
  );
}

export default detailPenyaluran;
