/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-unused-vars */
/* eslint-disable no-param-reassign */
/* eslint-disable no-use-before-define */
/* eslint-disable no-shadow */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable react/prop-types */
/* eslint-disable arrow-body-style */
import React, {
  forwardRef,
  useRef,
  useEffect,
  useState,
} from 'react';
import { useTable, useRowSelect } from 'react-table';
import { DndProvider, useDrag, useDrop } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import update from 'immutability-helper';
import DragIcon from '@mui/icons-material/Dehaze';
import Pagination from '../pagination/index';
import Loader from '../../loader/index';
import '../../../assets/styles/table.scss';

function TableDragDrop({
  id,
  columns,
  data,
  pagination,
  isLoading,
  selection,
  selectionAlign = 'left',
  customHeaderSelection,
  onSelection,
  selectionRow,
  length,
  movedRow,
}) {
  const [records, setRecords] = useState(data);
  useEffect(() => {
    setRecords(data);
  }, [data?.length]);

  const IndeterminateCheckbox = forwardRef(({ value, row }, ref) => {
    const defaultRef = useRef();

    useEffect(() => {
      defaultRef.current.checked = selection?.indexOf(value) > -1;
    }, [selection, row]);

    return (
      <label className="checkbox-container" style={{ display: 'initial' }}>
        <input
          type="checkbox"
          ref={defaultRef}
          value={value}
          onChange={(e) => handleSelect(e)}
        />
        <span className="checkmark" />
      </label>
    );
  });

  useEffect(() => {
    let selectedData = selection ?? [];
    const index = selectedData.indexOf('all');
    if (index > -1) {
      data.forEach((item) => {
        const index = selectedData.indexOf(item.id || item.no);
        if (index === -1) {
          selectedData = [...selectedData, item.id || item.no];
        }
      });
      onSelection(selectedData);
    }
  }, [data]);

  const handleSelect = (event) => {
    let selectedData = selection ?? [];
    if (event.target.value === 'all') {
      const index = selectedData.indexOf(event.target.value);
      if (index > -1) {
        selectedData = [];
      } else {
        selectedData = ['all', ...selectedData];
        data.forEach((item) => {
          selectedData = [...selectedData, item.id || item.no];
        });
      }
    } else if (event.target.value) {
      const indexAll = selectedData.indexOf('all');
      if (indexAll > -1) {
        selectedData.splice(indexAll, 1);
      }
      const index = selectedData.indexOf(event.target.value);
      if (index > -1) {
        selectedData.splice(index, 1);
      } else {
        selectedData = [...selectedData, event.target.value];
      }
    }
    const uniqArr = [...new Set(selectedData)];
    onSelection(uniqArr);
  };

  const {
    getTableProps,
    headerGroups,
    rows,
    prepareRow,
    selectedFlatRows,
    state: { selectedRowIds },
  } = useTable(
    {
      columns,
      data: records ?? [{}],
    },
    useRowSelect,
    (hooks) => {
      hooks.visibleColumns.push((columns) => {
        if (selectionRow) {
          const headSelection = {
            id: 'selection',
            Header: ({ getToggleAllRowsSelectedProps }) => (
              <div>
                {customHeaderSelection || (
                <IndeterminateCheckbox value="all" />
                )}
              </div>
            ),
            Cell: ({ row }) => {
              const id = row?.original?.id;
              return (
                <div>
                  <IndeterminateCheckbox value={id} row={rows} />
                </div>
              );
            },
          };
          if (selectionAlign === 'left') {
            return [headSelection, ...columns];
          } if (selectionAlign === 'right') {
            return [...columns, headSelection];
          }
          return [...columns];
        }
        return [...columns];
      });
    },
  );

  const moveRow = (dragIndex, hoverIndex) => {
    const dragRecord = records[dragIndex];
    setRecords(
      update(records, {
        $splice: [
          [dragIndex, 1],
          [hoverIndex, 0, dragRecord],
        ],
      }),
    );
  };

  // Set updated data to props
  let isMoved = false;
  const dataMoved = rows?.map((item) => {
    if (item.original.orderDzikir !== item.index) {
      isMoved = true;
    }
    item.original.orderDzikir = item.index;
    return item?.original;
  });
  if (movedRow) {
    movedRow(isMoved, dataMoved);
  }

  const DND_ITEM_TYPE = 'row';

  const Row = ({ row, index, moveRow }) => {
    const dropRef = React.useRef(null);
    const dragRef = React.useRef(null);

    const [, drop] = useDrop({
      accept: DND_ITEM_TYPE,
      hover(item, monitor) {
        if (!dropRef.current) {
          return;
        }
        const dragIndex = item.index;
        const hoverIndex = index;
        // Don't replace items with themselves
        if (dragIndex === hoverIndex) {
          return;
        }
        // Determine rectangle on screen
        const hoverBoundingRect = dropRef.current.getBoundingClientRect();
        // Get vertical middle
        const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
        // Determine mouse position
        const clientOffset = monitor.getClientOffset();
        // Get pixels to the top
        const hoverClientY = clientOffset.y - hoverBoundingRect.top;
        // Only perform the move when the mouse has crossed half of the items height
        // When dragging downwards, only move when the cursor is below 50%
        // When dragging upwards, only move when the cursor is above 50%
        // Dragging downwards
        if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
          return;
        }
        // Dragging upwards
        if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
          return;
        }
        // Time to actually perform the action
        moveRow(dragIndex, hoverIndex);
        // Note: we're mutating the monitor item here!
        // Generally it's better to avoid mutations,
        // but it's good here for the sake of performance
        // to avoid expensive index searches.
        item.index = hoverIndex;
      },
    });

    const [{ isDragging }, drag, preview] = useDrag({
      type: DND_ITEM_TYPE,
      item: () => ({ index }),
      collect: (monitor) => ({
        isDragging: monitor.isDragging(),
      }),
    });

    const opacity = isDragging ? 0 : 1;

    preview(drop(dropRef));
    drag(dragRef);

    return (
      <tr ref={dropRef} style={{ opacity }}>
        {movedRow && (
          <td ref={dragRef}><DragIcon /></td>
        )}
        {row.cells.map((cell) => (
          <td {...cell.getCellProps()} style={{ fontSize: 14 }}>
            {cell.render('Cell')}
          </td>
        ))}
      </tr>
    );
  };

  return (
    <div>
      <div
        className="table-responsive"
        style={{ position: 'relative', minHeight: '215px' }}
      >
        {isLoading && <Loader loading={isLoading} />}
        <DndProvider backend={HTML5Backend}>
          <table {...getTableProps()} className="table-customize">
            <thead>
              {headerGroups.map((headerGroup) => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  <th style={{ visibility: 'hidden' }}>Move</th>
                  {headerGroup.headers.map((column) => (
                    <th
                      {...column.getHeaderProps()}
                      style={{ whiteSpace: 'nowrap' }}
                    >
                      {column.render('Header')}
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody>
              {isLoading && (
                <tr>
                  <td colSpan={columns.length}>
                    <Loader loading />
                  </td>
                </tr>
              )}
              {!isLoading && (
                <>
                  {rows.map(
                    (row, index) => prepareRow(row) || (
                      <Row
                        index={index}
                        row={row}
                        moveRow={moveRow}
                        {...row.getRowProps()}
                      />
                    ),
                  )}
                  {rows?.length < 1 && !isLoading && (
                    <tr>
                      <td
                        colSpan={
                          headerGroups ? headerGroups[0]?.headers?.length + 1 : 3
                        }
                        style={{ textAlign: 'center' }}
                      >
                        Data tidak ditemukan
                      </td>
                    </tr>
                  )}
                </>
              )}
            </tbody>
          </table>
        </DndProvider>
      </div>
      <div style={{ width: '100%' }}>
        {pagination && !isLoading && (
        <Pagination
          page={pagination?.page !== 0 ? pagination.page : 0}
          length={length ?? data?.length}
          recordTotal={pagination?.totalData}
          maxPage={pagination?.maxPage}
          toggle={pagination?.toggle}
        />
        )}
      </div>
    </div>
  );
}

export default TableDragDrop;
