/* eslint-disable no-sequences */
/* eslint-disable no-unused-expressions */
/* eslint-disable no-param-reassign */
/* eslint-disable react/button-has-type */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/no-unescaped-entities */
/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
/* eslint-disable react/jsx-no-undef */
/* eslint-disable no-undef */
/* eslint-disable import/no-cycle */
/* eslint-disable no-unused-vars */
/* eslint-disable max-len */
import React, { useState } from 'react';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Typography from '@mui/material/Typography';
import { Link, useNavigate } from 'react-router-dom';
import { Card } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
// import AddIcon from '@mui/icons-material/Add';
import ButtonAction from '../../components/buttons/ButtonAction';
import ConfirmationModal from '../../components/modal/ConfirmModal';
import saveIcon from '../../assets/images/icons/SaveIcon.svg';
import addButton from '../../assets/images/icons/addButton.svg';
import removeButton from '../../assets/images/icons/removeButton.svg';
import { addVersion } from '../../services/whatsnew/whatsnewService';
import { setAddeddVersionValue } from '../../stores/actions/whatnew/form';
import { toastError } from '../../components/toast';

const styleInactiveBreadcrumb = {
  fontWeight: 500,
  fontSize: '12px',
  lineHeight: '14px',
  color: 'rgba(79, 88, 103, 0.7)',
  textDecoration: 'none',
  fontFamily: 'BlueberrySans-Regular',
};
const styleActiveBreadcrumb = {
  fontWeight: 600,
  fontSize: '12px',
  lineHeight: '14px',
  color: 'rgba(79, 88, 103, 0.7)',
  textDecoration: 'none',
  fontFamily: 'BlueberrySans-Regular',
};

const height = {
  height: '80vh',
};

function AddWhats() {
  const inputArr = [
    {
      type: 'text',
      id: 1,
      value: '',
      isInvalid: '',
      invalidFeedBack: '',
    },
  ];
  const [versionList, setVersionList] = useState(inputArr);
  const [version, setVersion] = useState('');
  const [confirmModal, setConfirmModal] = useState(false);
  const [modalShow, setModalShow] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isInvalid, setIsInvalid] = useState('');
  const [invalidMessage, setInvalidMessage] = useState('field versi wajib berisi angka dan titik');

  const addInput = () => {
    const arrayEmptyValue = versionList.filter((item) => item.value.length < 3);
    if (arrayEmptyValue.length === 0) {
      setVersionList((s) => {
        const lastId = s[s.length - 1].id + 1;
        return [
          ...s,
          {
            type: 'text',
            id: lastId,
            value: '',
            isInvalid: '',
            invalidFeedBack: '',
          },
        ];
      });
    } else {
      const newArr = [];
      versionList.forEach((item) => {
        if (item.value.length < 3) {
          item.isInvalid = 'is-invalid';
          item.invalidFeedBack = 'd-block';
        }
        newArr.push(item);
      });

      setVersionList(newArr);
    }
  };

  const deleteInput = (index) => {
    const array = [...versionList];
    array.splice(index, 1);
    setVersionList(array);
  };

  const handleChange = (e) => {
    e.preventDefault();
    const index = e.target.id;
    if (e.target.value.length < 1) {
      setVersionList((s) => {
        const newArr = s.slice();
        newArr[index].isInvalid = 'is-invalid';
        newArr[index].invalidFeedBack = 'd-block';
        newArr[index].value = e.target.value;
        return newArr;
      });
    } else {
      setVersionList((s) => {
        const newArr = s.slice();
        newArr[index].value = e.target.value;
        newArr[index].isInvalid = '';
        newArr[index].invalidFeedBack = '';
        return newArr;
      });
    }
  };
  const handleInputVersion = (inputValue) => {
    const value = inputValue[inputValue.length - 1];
    const oldValue = inputValue[inputValue.length - 2];
    if (value === '.' && oldValue === '.') {
      setInvalidMessage('Titik tidak boleh lebih dari satu kali berturut-turut');
      setIsInvalid('d-block is-invalid');
    } else if (value === '1' || value === '2'
      || value === '3' || value === '4'
      || value === '5' || value === '6'
      || value === '7' || value === '8'
      || value === '9' || value === '10'
      || value === '.' || value === '0' || value === undefined) {
      setVersion(inputValue);
      setIsInvalid('');
    } else {
      setIsInvalid('d-block is-invalid');
      setInvalidMessage('Field versi wajib diisi angka dan titik');
    }
  };
  const handleButton = () => {
    const arrayEmptyValue = versionList.filter((item) => item.value.length < 3);
    if (version.length <= 0) {
      setIsInvalid('d-block is-invalid');
      setInvalidMessage('Field versi wajib diisi');
    }
    if (arrayEmptyValue.length === 0) {
      setConfirmModal(true);
    } else {
      const newArr = [];
      versionList.forEach((item) => {
        if (item.value.length < 3) {
          item.isInvalid = 'is-invalid';
          item.invalidFeedBack = 'd-block';
        }
        newArr.push(item);
      });
      setVersionList(newArr);
    }
  };

  const closeConfirmModal = () => {
    setConfirmModal(false);
    setModalShow(false);
  };
  const handleCancel = () => {
    if (version === '') {
      navigate('/whatsnew');
    } else {
      setModalShow(true);
    }
  };

  return (
    <div className="content-container-side">
      <div className="d-flex">
        <p className="content-title">
          Management What’s New Update
        </p>
      </div>
      <div className="mb-4">
        <Breadcrumbs aria-label="breadcrumb" separator="">
          <Link
            color="inherit"
            to="/mission"
            onClick={() => {
            }}
            style={styleActiveBreadcrumb}
          >
            Management What’s New Update
          </Link>
          <Typography style={styleInactiveBreadcrumb}>
            Tambah What’s New Update
          </Typography>
        </Breadcrumbs>
      </div>
      <ConfirmationModal
        show={confirmModal}
        toggle={closeConfirmModal}
        inlineIcon={saveIcon}
        message="Apakah anda yakin ingin menambahkan data?"
        confirm={() => {
          const data = [];
          versionList.forEach((item) => {
            data.push({ description: item.value });
          });
          const success = {
            mobileUpdateDetails: data,
            version,
          };
          addVersion(success).then((response) => {
            if (response.status === 200) {
              localStorage.setItem('isAdded', true);
              setConfirmModal(false);
              navigate('/whatsnew');
            } else if (response.status === 500 && response.message === 'Version already exist!') {
              setConfirmModal(false);
              setIsDuplicateVersion(true);
              toastError('Versi yang anda masukkan sudah ada');
            }
          });
        }}
        confirmTitle="Simpan"
        cancelTitle="Batal"
      />
      <ConfirmationModal
        show={modalShow}
        message="Apakah Anda yakin ingin membatalkan proses Tambah Update What’s New ?"
        onHide={closeConfirmModal}
        confirmTitle="Yakin"
        cancelTitle="Batal"
        toggle={closeConfirmModal}
        inlineIcon={saveIcon}
        confirm={() => {
          dispatch(setAddeddVersionValue(false));
          navigate('/whatsnew');
        }}
      />
      <Card className="card-datatable" style={height}>
        <div className="d-flex flex-wrap align-items-center">
          <span
            className="material-icons clickable"
            style={{ marginTop: '-12px' }}
            onClick={() => {
              handleCancel();
            }}
            onKeyPress={() => console.log('navigate')}
            role="button"
            tabIndex={0}
          >
            arrow_back
          </span>
          <p className="card-datatable-title ms-3">
            Tambah What’s New Update
          </p>
        </div>
        <div className="row">
          <div className="col-lg-12">
            <p className="label">Versi</p>
            <input
              onChange={(event) => handleInputVersion(event.target.value)}
              type="text"
              placeholder="Masukkan versi"
              className={`form-control form-input ${isInvalid}`}
              value={version}
            />
            <div className={`col-lg-12 invalid-feedback ${isInvalid}`}>
              <p className="ms-1">{invalidMessage}</p>
            </div>
          </div>
          <p className="label mb-2 mt-3">Deskripsi</p>
          {versionList.map((item, i) => (
            <div className="col-lg-12 mb-4">
              <div className="row">
                <div className="col-lg-12 d-flex">
                  {i === 0 ? (
                    <button onClick={addInput} className="button-img">
                      <img src={addButton} alt="add input button" />
                    </button>
                  ) : (
                    <button className="button-img" onClick={() => deleteInput(i)}>
                      <img src={removeButton} alt="input button" />
                    </button>
                  )}
                  <input
                    onChange={handleChange}
                    placeholder="Masukkan deskripsi"
                    value={item.value}
                    id={i}
                    type={item.type}
                    className={`form-control form-input ${item.isInvalid}`}
                  />
                </div>
                <div className={`col-lg-12 invalid-feedback ${item.invalidFeedBack}`}>
                  <p className="ms-5">Field Deskripsi Harus Diisi</p>
                </div>
              </div>
            </div>
          ))}
        </div>
        <div className="d-flex align-items-center mt-5">
          <div className="ms-auto">
            <ButtonAction
              cancelTitle="Batal"
              confirmTitle="Simpan Data"
              confirm={handleButton}
              toggle={() => {
                handleCancel();
              }}
            />
          </div>
        </div>
      </Card>
    </div>
  );
}

export default AddWhats;
