/* eslint-disable import/no-named-as-default-member */
/* eslint-disable import/no-named-as-default */
/* eslint-disable max-len */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable react/jsx-no-comment-textnodes */
/* eslint-disable react/jsx-no-undef */
/* eslint-disable no-undef */
/* eslint-disable no-unused-vars */
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Typography from '@mui/material/Typography';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Card } from 'react-bootstrap';
import moment from 'moment/moment';
import React, {
  useState, useEffect,
} from 'react';
import Input from '../../../components/forms/input';
import Textarea from '../../../components/forms/Textarea';
import InputPrepend2 from '../../../components/forms/InputPrepend2';
import 'react-toastify/dist/ReactToastify.css';
import 'react-quill/dist/quill.snow.css';
import { getPenerimaanInfaqById } from '../../../services/infaq/index';

const styleInactiveBreadcrumb = {
  fontWeight: 500,
  fontSize: '12px',
  lineHeight: '14px',
  color: 'rgba(79, 88, 103, 0.7)',
  textDecoration: 'none',
  fontFamily: 'BlueberrySans-Regular',
};
const styleActiveBreadcrumb = {
  fontWeight: 600,
  fontSize: '12px',
  lineHeight: '14px',
  color: 'rgba(79, 88, 103, 0.7)',
  textDecoration: 'none',
  fontFamily: 'BlueberrySans-Regular',
};

function detailPenerimaan() {
  const navigate = useNavigate();
  const { id } = useParams();
  const [name, setName] = useState('');
  const [gender, setGender] = useState('');
  const [birthdate, setBirthdate] = useState('');
  const [birthplace, setBirthplace] = useState('');
  const [identityType, setIdentityType] = useState('');
  const [address, setAddress] = useState('');
  const [identityNumber, setIdentityNumber] = useState('');
  const [admissionDate, setAdmissionDate] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [description, setDescription] = useState('');
  const [amount, setAmount] = useState('');
  const [transactionNumber, setTransactionNumber] = useState('');
  const [midtransTransaction, setMidtransTransaction] = useState('');
  const [status, setStatus] = useState('');
  const [programName, setProgramName] = useState('');

  useEffect(() => {
    const handleDetailPenerimaan = async () => {
      const detailPenerimaanInfaq = await getPenerimaanInfaqById(id);
      if (detailPenerimaanInfaq.user !== null) {
        setName(detailPenerimaanInfaq.user.name);
        if (detailPenerimaanInfaq.user.birthdate === null) {
          setBirthdate('-');
        } else {
          const date = moment(detailPenerimaanInfaq.user.birthdate).utc().format('DD-MM-YYYY');
          setBirthdate(date);
        }
        if (detailPenerimaanInfaq.user.identityNumber === null) {
          setIdentityNumber('-');
        } else {
          setIdentityNumber(detailPenerimaanInfaq.user.identityNumber);
        }
        if (detailPenerimaanInfaq.user.birthplace === null) {
          setBirthplace('-');
        } else {
          setBirthplace(detailPenerimaanInfaq.user.birthplace);
        }
        if (detailPenerimaanInfaq.user.address === null) {
          setAddress('-');
        } else {
          setAddress(detailPenerimaanInfaq.user.address);
        }
        if (detailPenerimaanInfaq.user.gender === null) {
          setGender('-');
        } else if (detailPenerimaanInfaq.user.gender === 1) {
          setGender('Laki-laki');
        } else if (detailPenerimaanInfaq.user.gender === 2) {
          setGender('Perempuan');
        }
        if (detailPenerimaanInfaq.user.phoneNumber === null) {
          setPhoneNumber('-');
        } else {
          setPhoneNumber(detailPenerimaanInfaq.user.phoneNumber);
        }
        if (detailPenerimaanInfaq.user.identityType === null) {
          setIdentityType('-');
        } else {
          setIdentityType(detailPenerimaanInfaq.user.identityType);
        }
      } else {
        setName('-');
        setBirthdate('-');
        setBirthplace('-');
        setIdentityType('-');
        setAddress('-');
        setIdentityNumber('-');
        setGender('-');
        setPhoneNumber('-');
      }
      const date = moment(detailPenerimaanInfaq.admissionDate).utc().format('DD-MM-YYYY');
      setAdmissionDate(date);
      setDescription(detailPenerimaanInfaq.description || '-');
      const moneyFormatter = new Intl.NumberFormat();
      const donationFormat = moneyFormatter.format(detailPenerimaanInfaq.amount);
      setAmount(donationFormat.replaceAll(',', '.'));
      setTransactionNumber(detailPenerimaanInfaq.transactionNumber);
      setMidtransTransaction(detailPenerimaanInfaq.midtransTransactionId);
      setStatus(detailPenerimaanInfaq.status);
      setProgramName(detailPenerimaanInfaq.infaqProgram.programName);
    };
    handleDetailPenerimaan();
  }, []);
  return (
    <div className="content-container-side">
      <div className="d-flex">
        <p className="content-title">
          Infaq
        </p>
      </div>
      <div className="mb-4">
        <Breadcrumbs aria-label="breadcrumb" separator="">
          <Link
            color="inherit"
            to="/infaq/penerimaan"
            onClick={() => {
            }}
            style={styleActiveBreadcrumb}
          >
            Penerimaan Dana
          </Link>
          <Typography style={styleInactiveBreadcrumb}>
            Detail Penerimaan Dana
          </Typography>
        </Breadcrumbs>
      </div>
      <Card className="card-datatable">
        <div className="d-flex flex-wrap align-items-center">
          <span
            className="material-icons clickable"
            style={{ marginTop: '-12px' }}
            onClick={() => {
              navigate('/infaq/penerimaan');
            }}
            onKeyPress={() => navigate('/whatnew')}
            role="button"
            tabIndex={0}
          >
            arrow_back
          </span>
          <p className="card-datatable-title ms-3">
            Detail Penerimaan Dana
          </p>
        </div>
        <div className="row">
          <div className="col-lg-6 col-md-12 card-datatable-title">
            Data Donatur
          </div>
        </div>
        <div className="row">
          <div className="col-lg-6 col-md-12">
            <Input
              title="Nama"
              name="name"
              type="text"
              value={name}
              disabled
            />
          </div>
          <div className="col-lg-6 col-md-12">
            <Input
              title="Nomor Telepon"
              name="phoneNumber"
              type="text"
              value={phoneNumber}
              disabled
            />
          </div>
        </div>
        <div className="row">
          <div className="col-lg-6 col-md-12">
            <Input
              title="Gender"
              name="gender"
              type="text"
              value={gender}
              disabled
            />
          </div>
          <div className="col-lg-6 col-md-12">
            <Input
              title="Jenis Kartu Identitas"
              name="identityType"
              type="text"
              value={identityType}
              disabled
            />
          </div>
        </div>
        <div className="row">
          <div className="col-lg-6 col-md-12">
            <Input
              title="Tempat Tanggal Lahir"
              name="birtdate"
              type="string"
              value={`${birthplace}, ${birthdate}`}
              disabled
            />
          </div>
          <div className="col-lg-6 col-md-12">
            <Input
              title="Nomor Kartu Identitas"
              name="identityNumber"
              type="text"
              value={identityNumber}
              disabled
            />
          </div>
        </div>
        <div className="row">
          <div className="col-lg-6 col-md-12">
            <Input
              title="Alamat"
              name="address"
              type="text"
              value={address}
              disabled
            />
          </div>
        </div>
        <div className="row">
          <div className="col-lg-6 col-md-12 card-datatable-title">
            Data Donasi
          </div>
        </div>
        <div className="row">
          <div className="col-lg-6 col-md-12">
            <Input
              title="Nomor Transaksi"
              name="transactionNumber"
              type="string"
              value={transactionNumber}
              disabled
            />
          </div>
          <div className="col-lg-6 col-md-12">
            <Input
              title="Nomor Transaksi (Midtrans)"
              name="midtransTransaction"
              type="string"
              value={midtransTransaction}
              disabled
            />
          </div>
          <div className="col-lg-6 col-md-12">
            <InputPrepend2
              label="Jumlah Donasi Yang Masuk"
              title="Rp"
              name="amount"
              type="string"
              value={amount}
              disabled
              width="10%"
            />
          </div>
          <div className="col-lg-6 col-md-12">
            <Input
              title="Status"
              name="status"
              type="text"
              value={status}
              disabled
            />
          </div>
          <div className="col-lg-6 col-md-12">
            <Input
              title="Tanggal Donasi"
              name="admissionDate"
              type="string"
              value={admissionDate}
              disabled
            />
          </div>
          <div className="col-lg-6 col-md-12">
            <Input
              title="Nama Donasi"
              name="programName"
              type="text"
              value={programName}
              disabled
            />
          </div>
          <div className="col-lg-6 col-md-12">
            <Textarea
              title="Keterangan"
              name="description"
              type="textarea"
              value={description}
              rows={10}
              disabled
            />
          </div>
        </div>
      </Card>
    </div>
  );
}

export default detailPenerimaan;
