/* eslint-disable react/jsx-no-comment-textnodes */
/* eslint-disable react/jsx-closing-bracket-location */
/* eslint-disable react/jsx-closing-tag-location */
/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable indent */
/* eslint-disable react/jsx-indent */
/* eslint-disable react/prop-types */
/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable react/react-in-jsx-scope */
/* eslint-disable no-nested-ternary */
import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Card } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';
import SortIcon from '@mui/icons-material/UnfoldMore';
import DescIcon from '@mui/icons-material/South';
import AscIcon from '@mui/icons-material/North';
import FilterIcon from '@mui/icons-material/FilterList';

import Table from '../../components/table/index';
import TableStatus from '../../components/table/status';
import ButtonDetail from '../../components/table/button-detail';
import ButtonAdd from '../../components/buttons/Button';
import FilterModal from '../../components/modal/FilterModal';
import Search from '../../components/filter/Search';
import ConfirmationModal from '../../components/modal/ConfirmModal';

import DoaService from '../../stores/actions/doa';

const Doa = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [page, setPage] = useState(0);
  const [maxPage, setMaxPage] = useState(1);
  const [totalData, setTotalData] = useState(1);
  const [doaData, setDoaData] = useState([]);
  const [doaCategories, setDoaCategories] = useState([]);
  const [isActiveFilter, setIsActiveFilter] = useState(false);
  const [paramFilter, setParamFilter] = useState({});
  const [selectedCategory, setSelectedCategory] = useState({});
  const [showModalFilter, setShowModalFilter] = useState(false);
  const [selectedReferensi, setSelectedReferensi] = useState(false);
  const [selectedAudio, setSelectedAudio] = useState(false);
  const [directionSort, setDirectionSort] = useState('');
  const [sortIcon, setSortIcon] = useState(<SortIcon />);
  const [search, setSearch] = useState('');
  const [confirmModal, setConfirmModal] = useState(false);
  const [dataDeleted, setDataDeleted] = useState([]);

  // const styledButton = {
  //   textTransform: 'capitalize',
  //   color: '#4F5867',
  //   background: '#fff',
  //   border: paramFilter ? '1px solid #2EC4B6 !important' : '1px solid #e4e4e4 !important',
  //   borderRadius: '8px',
  //   boxShadow: 'none',
  // };

  const handleSearch = (e) => {
    setSearch(e.target.value);
  };

  const handleRefresh = () => {
    new Promise((resolve) => {
      const param = {
        page: page ?? 0,
        length: 10,
        contentIsExists: paramFilter?.contentIsExists ?? [],
        categoryId: paramFilter?.selectValue ?? '',
        searchText: search ?? '',
        sort: directionSort !== '' ? [`name.${directionSort}`] : ['name.desc'],
      };
      dispatch(DoaService.datatable(param, resolve));
    }).then((res) => {
      setDoaData(res.data);
      setMaxPage(Math.ceil(res?.totalCount / 10));
      setTotalData(res.totalCount);
    });
  };

  useEffect(() => {
    handleRefresh();
  }, [page, search, paramFilter, directionSort]);

  useEffect(() => {
    new Promise((resolve) => {
      const param = {
        page: 0,
        length: 999,
        categoryType: 'Doa',
      };
      dispatch(DoaService.dataCategories(param, resolve));
    }).then((res) => {
      const categories = [];
      res?.data?.forEach((item) => {
        categories?.push({ value: item?.id, label: `${item?.categoryType} ${item?.categoryName}` });
      });
      setDoaCategories(categories);
    });
  }, []);

  const handleClickSort = () => {
    if (directionSort === 'asc') {
      setDirectionSort('desc');
      setSortIcon(<DescIcon />);
    } else {
      setDirectionSort('asc');
      setSortIcon(<AscIcon />);
    }
  };

  const handleFilter = (selectedOption) => {
    let selectValue;
    let contentIsExists = [];

    if (selectedOption.value !== undefined) {
      selectValue = selectedOption.value;
    }
    if (selectedAudio) {
      contentIsExists = [...contentIsExists, 'audio'];
    }
    if (selectedReferensi) {
      contentIsExists = [...contentIsExists, 'referensi'];
    }
    setIsActiveFilter(!isActiveFilter);
    setParamFilter({ selectValue, contentIsExists });
    setShowModalFilter(false);
  };

  const handleFilterReset = () => {
    setIsActiveFilter(!isActiveFilter);
    setParamFilter({ selectValue: '', contentIsExists: [] });
    setSelectedAudio(false);
    setSelectedReferensi(false);
    setShowModalFilter(false);
  };

  const showConfirmModal = (data) => {
    setConfirmModal(!confirmModal);
    setDataDeleted(data ?? null);
  };

  const handleDelete = () => {
    const callback = () => {
      handleRefresh();
      setConfirmModal(!confirmModal);
    };

    if (dataDeleted) {
      const id = dataDeleted?.id;
      dispatch(DoaService.deleteDoa(id, callback));
    }
  };

  const columns = [
    {
      Header: 'No',
      accessor: 'no',
    },
    {
      Header: 'Nama Doa',
      accessor: 'doaName',
      Cell: ({ row }) => {
        const { original } = row;
        const { doaName } = original;
        return doaName !== null
          ? doaName.charAt(0).toUpperCase() + doaName.slice(1)
          : '-';
      },
    },
    {
      Header: 'Kategori',
      accessor: 'category',
      Cell: ({ row }) => {
        const { original } = row;
        const { category } = original;
        return category !== null
          ? `${category?.categoryType} ${category?.categoryName}`
          : '-';
      },
    },
    {
      Header: 'Konten',
      accessor: 'description',
      Cell: ({ row }) => {
        const { original } = row;
        const contents = [
          {
            label: 'Referensi',
            value: original?.referensi,
          },
          {
            label: 'Audio',
            value: original?.audioName,
          },
        ];
        return (
          <div className="d-flex align-items-center">
            {contents?.map((item) => (
              <div className="mx-1">
                <TableStatus
                  status={item?.label}
                  color={item?.value !== null && item?.value !== '' ? 'primary' : 'inactive'}
                />
              </div>
            ))}
          </div>
        );
      },
    },
    {
      Header: '',
      accessor: 'id',
      Cell: ({ row }) => {
        const original = row ? row?.original : [];
        return (
          <ButtonDetail>
            <MenuItem
              onClick={() => {
                navigate(`/doa/detail/${original?.id}`);
              }}
            >
              <span style={{ fontSize: 14 }}>Detail</span>
            </MenuItem>
            <MenuItem
              onClick={() => {
                navigate(`/doa/edit/${original?.id}`);
              }}
            >
              <span style={{ fontSize: 14 }}>Edit</span>
            </MenuItem>
            <MenuItem onClick={() => showConfirmModal(original)}>
              <span style={{ fontSize: 14 }}>Hapus</span>
            </MenuItem>
          </ButtonDetail>
        );
      },
    },
  ];

  return (
    <div className="content-container-side">
      <div className="d-flex my-3">
        <p className="content-title">
          Doa
        </p>
      </div>
      <Card className="card-datatable">
        <div className="d-flex flex-wrap align-items-center">
          <p className="card-datatable-title">
            Database Doa
          </p>
          <div className="ms-auto">
            <div className="d-flex flex-wrap justify-content-end align-items-center">
              <Search
                name="search"
                type="text"
                placeholder="Search"
                value={search}
                onChange={handleSearch}
                style={{ marginRight: 10 }}
                mt="3 mt-sm-0"
              />
              <div className={`mt-3 mt-sm-0 me-2 ${isActiveFilter ? 'btnFilterActive' : 'btnFilterInactive'}`}>
                {isActiveFilter && (
                  <div className="circleRightBtn" />
                )}
                <Button
                  variant="outlined"
                  aria-label="filter"
                  aria-controls="long-menu"
                  aria-haspopup="true"
                  size="medium"
                  style={{
                    color: '#4F5867',
                    background: '#fff',
                    position: 'relative',
                  }}
                  onClick={() => setShowModalFilter(true)}
                  endIcon={<FilterIcon />}
                >
                  Filter
                </Button>
              </div>
              <Button
                variant="contained"
                aria-label="sort"
                size="medium"
                onClick={handleClickSort}
                style={{
                  color: '#4F5867',
                  background: '#fff',
                  border: '1px solid #e4e4e4',
                }}
                endIcon={sortIcon}
                className="ms-2 mt-3 mt-sm-0"
              >
                Sort
              </Button>
              <ButtonAdd
                type="button"
                title="Tambah"
                leftIcon="add"
                style={{
                  textAlign: 'center',
                  padding: '0.7em 1em',
                  borderRadius: '8px',
                  display: 'flex',
                  alignItems: 'center',
                  marginLeft: '0.5rem',
                }}
                onClick={() => navigate('/doa/add')}
              />
            </div>
          </div>
        </div>
        <Table
          data={doaData}
          columns={columns}
          pagination={{
            page, totalData, maxPage, toggle: setPage,
          }}
        />
      </Card>
      <FilterModal
        show={showModalFilter}
        onHide={() => setShowModalFilter(false)}
        titleSelect="Kategori"
        placeholderSelect="Pilih Kategori"
        confirmTitle="Terapkan"
        cancelTitle="Batal"
        options={doaCategories}
        selectedOptionValue={selectedCategory}
        onChangeSelect={(selected) => {
          setSelectedCategory(selected);
        }}
        inputChips={[
          {
            label: 'Referensi',
            onChange: () => setSelectedReferensi(!selectedReferensi),
            onDelete: selectedReferensi && (() => {}),
            color: selectedReferensi ? '#2EC4B6' : 'default',
            variant: 'outlined',
          },
          {
            label: 'Audio',
            onChange: () => setSelectedAudio(!selectedAudio),
            onDelete: selectedAudio && (() => {}),
            color: selectedAudio ? '#2EC4B6' : 'default',
            variant: 'outlined',
          },
        ]}
        handleFilter={handleFilter}
        paramFilter={paramFilter}
        handleReset={handleFilterReset}
        toggle={() => setShowModalFilter(false)}
      />
      <ConfirmationModal
        show={confirmModal}
        toggle={showConfirmModal}
        message="Apakah Anda yakin ingin menghapus Do'a?"
        confirm={handleDelete}
        confirmTitle="Hapus"
        cancelTitle="Batal"
        icon="delete"
      />
    </div>
  );
};

export default Doa;
