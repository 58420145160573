/* eslint-disable no-restricted-globals */
/* eslint-disable radix */
/* eslint-disable max-len */
/* eslint-disable no-undef */
/* eslint-disable no-shadow */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/jsx-boolean-value */
/* eslint-disable no-unused-vars */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/prop-types */
/* eslint-disable no-console */
import { Breadcrumbs, Typography } from '@mui/material';
import { Form, Formik } from 'formik';
import ReactQuill from 'react-quill';
import { useDispatch } from 'react-redux';
import * as Yup from 'yup';
import moment from 'moment/moment';
import React, {
  useState, useRef, useEffect, useMemo,
} from 'react';
import { Card } from 'react-bootstrap';
import { Link, useNavigate, useParams } from 'react-router-dom';
import ButtonAction from '../../../../components/buttons/ButtonAction';
import Input from '../../../../components/forms/input';
import Textarea from '../../../../components/forms/Textarea';
import Select from '../../../../components/forms/Select';
import ConfirmModal from '../../../../components/modal/ConfirmModal';
import InputPrepend2 from '../../../../components/forms/InputPrepend2';
import saveIcon from '../../../../assets/images/icons/SaveIcon.svg';
import {
  AddStoryImage, getAllProgramInfaq, addPenyaluranDanaInfaq, getProgramInfaqById,
} from '../../../../services/infaq';

const styleActiveBreadcrumb = {
  fontWeight: 600,
  fontSize: '12px',
  lineHeight: '14px',
  color: 'rgba(79, 88, 103, 0.7)',
  textDecoration: 'none',
  fontFamily: 'BlueberrySans-Regular',
};

const styleInactiveBreadcrumb = {
  fontWeight: 500,
  fontSize: '12px',
  lineHeight: '14px',
  color: 'rgba(79, 88, 103, 0.7)',
  textDecoration: 'none',
  fontFamily: 'BlueberrySans-Regular',
};

function index(props) {
  const formikRef = useRef();
  const navigate = useNavigate();
  const [quillValue, setQuillValue] = useState('');
  const quillRef = useRef();
  const [donationName, setDonationName] = useState('');
  const [donationDate, setDonationDate] = useState('');
  const [donationAmount, setDonationAmount] = useState(null);
  const [title, setTitle] = useState('');
  const [thumbnail, setThumbnail] = useState('');
  const [thumbnailDisplay, setThumbnailDisplay] = useState(null);
  const [donationAmountDisplay, setDonationAmountDisplay] = useState('');
  const [modalCloseConfirmation, setShowModalCloseConfirmation] = useState(false);
  const [modalAddConfirmation, setShowModalAddConfirmation] = useState(false);
  const [donationNameList, setDonationNameList] = useState([]);
  const [availableFunds, setAvailableFunds] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const initialValues = {
    donationName: donationName || '',
    donationDate: donationDate || '',
    donationAmount: donationAmount || null,
    title: title || '',
    thumbnail: thumbnail || null,
  };
  const validation = Yup.object().shape({
    donationName: Yup.string().required('Nama Donasi Wajib Diisi').nullable(),
    title: Yup.string().required('Keterangan Wajib Diisi').nullable(),
    donationDate: Yup.string()
      .required('Tanggal Pencairan Donasi Wajib Diisi')
      .nullable(),
    donationAmount: Yup.string()
      .required('Jumlah Dana yang Dicairkan Wajib Diisi')
      .nullable(),
    thumbnail: Yup.mixed()
      .required('Thumbnail Wajib Diisi')
      .test(
        'fileSize',
        'Gambar Maksimal 1MB',
        (value) => value === null || value.size <= 1000000,
      )
      .test(
        'fileFormat',
        'Unsupported file type',
        (value) => value === null
          || (value
            && ['image/jpg', 'image/jpeg', 'image/gif', 'image/png'].includes(
              value.type,
            )),
      ),
  });
  const imageHandler = () => {
    // get editor
    const editor = quillRef.current.getEditor();
    const input = document.createElement('input');
    input.setAttribute('type', 'file');
    input.setAttribute('accept', 'image/*');
    input.click();

    input.onchange = async () => {
      const file = input.files[0];
      try {
        const formData = new FormData();
        formData.append('storyDescriptionImg', file);
        const response = await AddStoryImage(formData);
        const result = await response.text();
        const link = result;
        editor.insertEmbed(editor.getSelection(), 'image', link);
      } catch (err) {
        console.log('upload err:', err);
      }
    };
  };
  const modules = useMemo(() => ({
    toolbar: {
      container: [
        [{ font: [] }],
        [{ header: [1, 2, 3, 4, 5, 6, false] }],
        ['bold', 'italic', 'underline', 'strike'],
        [{ color: [] }, { background: [] }],
        [{ script: 'sub' }, { script: 'super' }],
        ['blockquote', 'code-block'],
        [{ list: 'ordered' }, { list: 'bullet' }],
        [{ indent: '-1' }, { indent: '+1' }, { align: [] }],
        ['link', 'image'],
        ['clean'],
      ],
      handlers: {
        image: imageHandler,
      },
    },
  }), []);

  const checkForm = (values) => {
    if (
      values.donationName !== ''
      || values.donationAmount !== null
      || values.donationDate !== ''
      || values.thumbnail !== null
      || values.title !== ''
    ) {
      setShowModalCloseConfirmation(true);
    } else {
      navigate('/infaq/penggunaan?activeTab=penyaluran');
    }
  };

  const handleSubmit = async (values) => {
    try {
      const formData = new FormData();
      formData.append('amount', parseInt(values.donationAmount.replaceAll('.', '')));
      formData.append('infaqProgramId', values.donationName);
      formData.append('storyDescription', quillValue);
      formData.append('storyThumbnail', values.thumbnail);
      formData.append('storyTitle', values.title);
      formData.append('usageDate', values.donationDate);
      const response = await addPenyaluranDanaInfaq(formData);
      setShowModalAddConfirmation(false);
      if (response.status === 200) {
        localStorage.setItem('isPenyaluranAdded', true);
        navigate('/infaq/penggunaan?activeTab=penyaluran');
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleAvailableFunds = async (id) => {
    try {
      const detailProgramInfaq = await getProgramInfaqById(id);
      setAvailableFunds(detailProgramInfaq.availableFunds);
    } catch (error) {
      setAvailableFunds(0);
    }
  };
  useEffect(() => {
    const handleProgramName = async () => {
      const programList = await getAllProgramInfaq(true);
      const dummyDonationNameList = [];
      programList.forEach((value) => {
        const objOption = { value: value.id, label: value.programName };
        dummyDonationNameList.push(objOption);
      });
      setDonationNameList(dummyDonationNameList);
    };

    handleProgramName();
  }, []);
  return (
    <div className="content-container-side">
      <div className="d-flex">
        <p className="content-title">Infaq</p>
      </div>
      <div className="mb-4">
        <Breadcrumbs aria-label="breadcrumb" separator="">
          <Link
            color="inherit"
            to="/infaq/penggunaan?activeTab=penyaluran"
            style={styleActiveBreadcrumb}
          >
            Penyaluran Dana
          </Link>
          <Typography style={styleInactiveBreadcrumb}>
            Tambah Penyaluran Dana
          </Typography>
        </Breadcrumbs>
      </div>
      <Card className="card-datatable">
        <div className="d-flex">
          <span
            className="material-icons clickable"
            onClick={() => {
              checkForm(formikRef.current.values);
            }}
            onKeyPress={() => navigate('/notification')}
            role="button"
            tabIndex={0}
          >
            arrow_back
          </span>
          <p className="card-datatable-title ms-3">Tambah Penyaluran Dana</p>
        </div>
        <Formik
          innerRef={formikRef}
          initialValues={initialValues}
          enableReinitialize
          validationSchema={validation}
          onSubmit={(values, errors, touched) => {
            setShowModalAddConfirmation(true);
          }}
        >
          {(props) => (
            <Form>
              <div className="row">
                <div className="col-lg-6">
                  <Select
                    name="donationName"
                    title="Nama Donasi"
                    value={props?.values?.donationName}
                    errors={props?.errors}
                    touched={props?.touched}
                    isSearchable={true}
                    options={donationNameList}
                    onChange={async (name, value) => {
                      props.setFieldValue('donationName', value);
                      await handleAvailableFunds(value);
                    }}
                    onBlur={props.setFieldTouched}
                  />
                </div>
                <div className="col-lg-6">
                  <Input
                    title="Tanggal Penggunaan Dana"
                    name="donationDate"
                    type="date"
                    errors={props?.errors}
                    touched={props?.touched}
                    {...props?.getFieldProps('donationDate')}
                  />
                </div>
                <div className="col-lg-6 donation-goals">
                  <InputPrepend2
                    label="Total Dana yang Digunakan"
                    title="Rp"
                    name="donationAmount"
                    type="text"
                    width="10%"
                    value={donationAmountDisplay}
                    mb="0"
                    onChange={(e) => {
                      if (!isNaN(e.target.value.replaceAll('.', ''))) {
                        const moneyFormatter = new Intl.NumberFormat();
                        const donationFormat = moneyFormatter.format(e.target.value.replaceAll('.', ''));
                        setDonationAmountDisplay(donationFormat.replaceAll(',', '.'));
                        props.setFieldValue(
                          'donationAmount',
                          e.target.value,
                          true,
                        );
                      }
                    }}
                    errors={props?.errors}
                    touched={props?.touched}
                  />
                </div>
                <div className="col-lg-6 donation-goals disabled">
                  <InputPrepend2
                    label="Sisa Pencairan Dana"
                    title="Rp"
                    name="donationAmount"
                    type="text"
                    width="10%"
                    disabled
                    value={availableFunds}
                    mb="0"
                    errors={props?.errors}
                    touched={props?.touched}
                  />
                </div>
              </div>
              <p className="card-datatable-title mt-4">Cerita Penyaluran</p>
              <div className="row">
                <div className="col-lg-6">
                  <Input
                    title="Judul"
                    name="title"
                    type="text"
                    errors={props?.errors}
                    touched={props?.touched}
                    {...props?.getFieldProps('title')}
                  />
                </div>
                <div className="col-lg-6">
                  <Input
                    title="Thumbnail"
                    name="thumbnail"
                    type="file"
                    filename={thumbnailDisplay}
                    errors={props?.errors}
                    touched={props?.touched}
                    onChange={(e) => {
                      props.setFieldValue('thumbnail', e.target.files[0], true);
                      setThumbnailDisplay(e.target.files[0].name);
                    }}
                    accept="image/png, image/jpeg"
                  />
                </div>
                <div className="row">
                  <div className="col-lg-12">
                    <div className="col-lg-12 col-md-12">
                      <div className="col-lg-6 col-md-12 description-label">
                        Deskripsi
                      </div>
                      <ReactQuill
                        theme="snow"
                        modules={modules}
                        onChange={setQuillValue}
                        ref={quillRef}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="d-flex align-items-center mt-5">
                <div className="ms-auto">
                  <ButtonAction
                    cancelTitle="Batal"
                    confirmTitle="Simpan Data"
                    toggle={() => {
                      checkForm(formikRef.current.values);
                    }}
                  />
                </div>
              </div>
            </Form>
          )}
        </Formik>
        <ConfirmModal
          show={modalCloseConfirmation}
          message="Apakah Anda yakin ingin membatalkan proses Tambah Penyaluran Dana?"
          onHide={() => setShowModalCloseConfirmation(false)}
          confirmTitle="Yakin"
          cancelTitle="Batal"
          toggle={() => setShowModalCloseConfirmation(false)}
          inlineIcon={saveIcon}
          confirm={() => {
            navigate('/infaq/penggunaan?activeTab=penyaluran');
          }}
        />
        <ConfirmModal
          show={modalAddConfirmation}
          message="Apakah Anda yakin data yang dimasukkan sudah sesuai?"
          onHide={() => setShowModalAddConfirmation(false)}
          confirmTitle={
            isLoading ? (
              <>
                <span className="spinner-border spinner-border-sm me-2" />
                Loading...
              </>
            ) : (
              'Ya, lanjutkan'
            )
          }
          cancelTitle="Periksa Kembali"
          toggle={() => setShowModalAddConfirmation(false)}
          inlineIcon={saveIcon}
          confirm={() => {
            setIsLoading(true);
            handleSubmit(formikRef.current.values);
          }}
          isLoading={isLoading}
        />
      </Card>
    </div>
  );
}

export default index;
