/* eslint-disable no-unused-vars */
/* eslint-disable default-param-last */
const initialState = {
  values: [],
  isEdited: false,
  isAdded: false,
  action: null,
};
const doaReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case 'ADD_DOA':
      return {
        ...state,
        values: payload,
      };
    case 'DEL_DOA':
      return {
        ...state,
        values: [],
        isEdited: false,
      };
    case 'EDITED_DOA':
      return {
        ...state,
        isEdited: payload,
      };
    case 'ADDED_DOA':
      return {
        ...state,
        isAdded: payload,
      };
    case 'SET_ACTION_DOA':
      return {
        ...state,
        action: payload,
      };
    default:
      return {
        ...state,
      };
  }
};
export default doaReducer;
