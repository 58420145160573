/* eslint-disable no-unused-vars */
/* eslint-disable max-len */
/* eslint-disable react/jsx-no-comment-textnodes */
/* eslint-disable react/jsx-closing-bracket-location */
/* eslint-disable react/jsx-closing-tag-location */
/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable indent */
/* eslint-disable react/jsx-indent */
/* eslint-disable react/prop-types */
/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable react/react-in-jsx-scope */
/* eslint-disable no-nested-ternary */
import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Card } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';
import Badge from '@mui/material/Badge';
import SortIcon from '@mui/icons-material/UnfoldMore';
import DescIcon from '@mui/icons-material/South';
import AscIcon from '@mui/icons-material/North';

import Table from '../../components/table/index';
import ButtonDetail from '../../components/table/button-detail';
import ButtonAdd from '../../components/buttons/Button';
import Filter from '../../components/filter/Filter';
import Search from '../../components/filter/Search';
import ConfirmationModal from '../../components/modal/ConfirmModal';

import DzikirService from '../../stores/actions/dzikir';

const Dzikir = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [page, setPage] = useState(0);
  const [maxPage, setMaxPage] = useState(1);
  const [dzikirCategories, setDzikirCategories] = useState([]);
  const [totalData, setTotalData] = useState(1);
  const [dzikirData, setDzikirData] = useState([]);
  const [isActiveFilter, setIsActiveFilter] = useState(false);
  const [filter, setFilter] = useState('');
  const [directionSort, setDirectionSort] = useState('');
  const [sortIcon, setSortIcon] = useState(<SortIcon />);
  const [search, setSearch] = useState('');
  const [confirmModal, setConfirmModal] = useState(false);
  const [dataDeleted, setDataDeleted] = useState([]);

  const handleSearch = (e) => {
    setSearch(e.target.value);
  };

  useEffect(() => {
    new Promise((resolve) => {
      const param = {
        page: 0,
        length: 999,
        categoryType: 'Dzikir',
      };
      dispatch(DzikirService.dataCategories(param, resolve));
    }).then((res) => {
      const categories = [
        {
          value: '',
          label: 'Semua Kategori',
        },
      ];
      res?.data?.forEach((item) => {
        categories?.push({ value: item?.id, label: `${item?.categoryType} ${item?.categoryName}` });
      });
      setDzikirCategories(categories);
    });
  }, []);

  const handleRefresh = () => {
    new Promise((resolve) => {
      const param = {
        page: page ?? 0,
        length: 10,
        searchText: search ?? '',
        categoryId: filter ?? '',
        sort: directionSort !== '' ? [`name.${directionSort}`] : ['name.desc'],
      };
      dispatch(DzikirService.datatable(param, resolve));
    }).then((res) => {
      setDzikirData(res.data);
      setMaxPage(Math.ceil(res?.totalCount / 10));
      setTotalData(res.totalCount);
    });
  };

  useEffect(() => {
    handleRefresh();
  }, [page, search, filter, directionSort]);

  const handleClickSort = () => {
    if (directionSort === 'asc') {
      setDirectionSort('desc');
      setSortIcon(<DescIcon />);
    } else {
      setDirectionSort('asc');
      setSortIcon(<AscIcon />);
    }
  };

  const showConfirmModal = (data) => {
    setConfirmModal(!confirmModal);
    setDataDeleted(data ?? null);
  };

  const handleDelete = () => {
    const callback = () => {
      handleRefresh();
      setConfirmModal(!confirmModal);
    };

    if (dataDeleted) {
      const id = dataDeleted?.id;
      dispatch(DzikirService.deleteDzikir(id, callback));
    }
  };

  const columns = [
    {
      Header: 'No',
      accessor: 'no',
    },
    {
      Header: 'Nama Dzikir',
      accessor: 'dzikirName',
      Cell: ({ row }) => {
        const { original } = row;
        const { dzikirName } = original;
        return dzikirName !== null
          ? dzikirName
          : '-';
      },
    },
    {
      Header: 'Kategori',
      accessor: 'category',
      Cell: ({ row }) => {
        const { original } = row;
        const { category } = original;
        return category !== null
          ? `${category?.categoryType} ${category?.categoryName}`
          : '-';
      },
    },
    {
      Header: 'Jumlah Doa',
      accessor: 'dzikirDetails',
      Cell: ({ row }) => {
        const { original } = row;
        const { dzikirDetails } = original;
        return dzikirDetails?.length;
      },
    },
    {
      Header: '',
      accessor: 'id',
      Cell: ({ row }) => {
        const original = row ? row?.original : [];
        return (
          <ButtonDetail>
            <MenuItem
              onClick={() => {
                navigate(`/dzikir/detail/${original?.id}`);
              }}
            >
              <span style={{ fontSize: 14 }}>Detail</span>
            </MenuItem>
            <MenuItem
              onClick={() => {
                navigate(`/dzikir/edit/${original?.id}`);
              }}
            >
              <span style={{ fontSize: 14 }}>Edit</span>
            </MenuItem>
            <MenuItem onClick={() => showConfirmModal(original)}>
              <span style={{ fontSize: 14 }}>Hapus</span>
            </MenuItem>
          </ButtonDetail>
        );
      },
    },
  ];

  return (
    <div className="content-container-side">
      <div className="d-flex my-3">
        <p className="content-title">
          Dzikir
        </p>
      </div>
      <Card className="card-datatable">
        <div className="d-flex flex-wrap align-items-center">
          <p className="card-datatable-title">
            Database Dzikir
          </p>
          <div className="ms-auto">
            <div className="d-flex flex-wrap justify-content-end align-items-center">
              <Search
                name="search"
                type="text"
                placeholder="Search"
                value={search}
                onChange={handleSearch}
                style={{ marginRight: 10 }}
                mt="3 mt-sm-0"
              />
              {isActiveFilter ? (
<Badge variant="dot" color="warning">
              <Filter isOnFilter customClass="mt-3 mt-sm-0">
                  {dzikirCategories?.map((item) => (
                    <MenuItem
                      key={item?.value}
                      onClick={() => {
                        if (item?.value !== '') {
                          setIsActiveFilter(true);
                        } else {
                          setIsActiveFilter(false);
                        }
                        setFilter(item?.value);
                      }}
                    >
                      <span style={{ fontSize: 14 }}>
                        {item?.label}
                      </span>
                    </MenuItem>
                  ))}
              </Filter>
              </Badge>
) : (
<Filter customClass="mt-3 mt-sm-0">
                  {dzikirCategories?.map((item) => (
                    <MenuItem
                      key={item?.value}
                      onClick={() => {
                        if (item?.value !== '') {
                          setIsActiveFilter(true);
                        } else {
                          setIsActiveFilter(false);
                        }
                        setFilter(item?.value);
                      }}
                    >
                      <span style={{ fontSize: 14 }}>
                        {item?.label}
                      </span>
                    </MenuItem>
                  ))}
              </Filter>
) }
              <Button
                variant="contained"
                aria-label="sort"
                size="medium"
                onClick={handleClickSort}
                style={{
                  color: '#4F5867',
                  background: '#fff',
                  border: '1px solid #e4e4e4',
                }}
                endIcon={sortIcon}
                className="ms-2 mt-3 mt-sm-0"
              >
                Sort
              </Button>
              <ButtonAdd
                type="button"
                title="Tambah"
                leftIcon="add"
                style={{
                  textAlign: 'center',
                  padding: '0.7em 1em',
                  borderRadius: '8px',
                  display: 'flex',
                  alignItems: 'center',
                  marginLeft: '0.5rem',
                }}
                onClick={() => navigate('/dzikir/add')}
              />
            </div>
          </div>
        </div>
        <Table
          data={dzikirData}
          columns={columns}
          pagination={{
            page, totalData, maxPage, toggle: setPage,
          }}
        />
      </Card>
      <ConfirmationModal
        show={confirmModal}
        toggle={showConfirmModal}
        messageData={dataDeleted?.dzikirName}
        confirm={handleDelete}
        confirmTitle="Hapus"
        cancelTitle="Batal"
        icon="delete"
      />
    </div>
  );
};

export default Dzikir;
