/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/prop-types */
/* eslint-disable no-nested-ternary */
import { React } from 'react';
import SearchIcon from '../../assets/images/icons/Search.svg';

const Search = ({
  name,
  value,
  disabled,
  label,
  placeholder,
  type,
  mt,
  onKeyUp,
  onChange,
  onKeyDown,
  style,
}) => {
  const styledInputSearch = {
    borderRightStyle: 'none',
    border: '1px solid #F0F3F6',
    borderRadius: '21px',
    position: 'relative',
    height: '42px',
    width: '220px',
  };

  const styledInputGroup = {
    width: '46px',
    position: 'absolute',
    right: '0',
    zIndex: 99,
  };

  const styledIconSearch = {
    width: '100%',
    background: '#2EC4B6',
    borderRadius: '21px',
    height: '42px',
  };

  return (
    <div className="form-group mb-0" style={style}>
      <div className={`input-group mb-3 mt-${mt ?? 0}`}>
        <input
          name={name}
          type={type ?? 'text'}
          className={`form-control form-input ${disabled ? 'input-disabled ' : ''}`}
          value={value != null ? value : ''}
          onChange={onChange}
          onKeyUp={onKeyUp}
          onKeyDown={onKeyDown}
          disabled={disabled}
          placeholder={placeholder ?? `Masukkan ${label}`}
          aria-label={placeholder}
          aria-describedby="basic-addon1"
          style={styledInputSearch}
        />
        <div
          className="input-group-append"
          style={styledInputGroup}
        >
          <span
            className={`input-group-text ${disabled ? 'input-group-text-disabled' : ''}`}
            style={styledIconSearch}
            id="basic-addon1"
          >
            <img
              src={SearchIcon}
              alt="Search Icon"
              style={{ height: '14px', width: '14px', margin: 'auto' }}
            />
          </span>
        </div>
      </div>
    </div>
  );
};
export default Search;
