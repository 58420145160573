export const addValues = (data) => ({
  type: 'ADD_DZIKIR',
  payload: data,
});
export const delValues = (data) => ({
  type: 'DEL_DZIKIR',
  payload: data,
});

export const setEditedDzikirValue = (data) => ({
  type: 'EDITED_DZIKIR',
  payload: data,
});

export const setAddedDzikirValue = (data) => ({
  type: 'ADDED_DZIKIR',
  payload: data,
});

export const setAction = (data) => ({
  type: 'SET_ACTION_DZIKIR',
  payload: data,
});
