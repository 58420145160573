/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/prop-types */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable no-promise-executor-return */
/* eslint-disable jsx-a11y/label-has-associated-control */
import {
  React,
  useEffect, useRef, useState,
} from 'react';
import { useParams, useNavigate, Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Card } from 'react-bootstrap';
import { Formik, Form } from 'formik';
import moment from 'moment';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Typography from '@mui/material/Typography';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import Button from '@mui/material/Button';
import SortIcon from '@mui/icons-material/UnfoldMore';
import DescIcon from '@mui/icons-material/South';
import AscIcon from '@mui/icons-material/North';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';

import Table from '../../components/table/index';
import TableStatus from '../../components/table/status';
import Input from '../../components/forms/input';
import InputPrepend from '../../components/forms/InputPrepend';
import Search from '../../components/filter/Search';
import ImageModal from '../../components/modal/ImageModal';
import UserStatistic from '../../assets/images/icons/StatistikUser.svg';

import MissionService from '../../stores/actions/mission';

const styleActiveBreadcrumb = {
  fontWeight: 600,
  fontSize: '12px',
  lineHeight: '14px',
  color: 'rgba(79, 88, 103, 0.7)',
  textDecoration: 'none',
  fontFamily: 'BlueberrySans-Regular',
};

const styleInactiveBreadcrumb = {
  fontWeight: 500,
  fontSize: '12px',
  lineHeight: '14px',
  color: 'rgba(79, 88, 103, 0.7)',
  textDecoration: 'none',
  fontFamily: 'BlueberrySans-Regular',
};

const styleTextTabs = {
  textTransform: 'capitalize',
  fontFamily: 'BlueberrySans-Regular',
  fontSize: '14px',
};

const MissionForm = () => {
  const dispatch = useDispatch();
  const formikRef = useRef();
  const navigate = useNavigate();

  const [activeTab, setActiveTab] = useState('1');
  const [missionData, setMissionData] = useState([]);
  const [missionItemData, setMissionItemData] = useState([]);
  const [status, setStatus] = useState(null);
  const [activationDate, setActivationDate] = useState('');
  const [bannerName, setBannerName] = useState('Upload Media');
  const [media, setMedia] = useState(null);
  const [badgeImgName, setBadgeImgName] = useState('Upload Gambar Badge');
  const [badge, setBadge] = useState(null);
  const [bannerFile, setBannerFile] = useState();
  const [badgeImgFile, setBadgeImgFile] = useState();
  const [badgeName, setBadgeName] = useState('');
  const [badgeDescription, setBadgeDescription] = useState('');
  const [usersData, setUsersData] = useState([]);
  const [totalUser, setTotalUser] = useState(0);
  const [percentage, setPercentage] = useState(0);
  const [page, setPage] = useState(0);
  const [maxPage, setMaxPage] = useState(1);
  const [totalData, setTotalData] = useState(1);
  const [search, setSearch] = useState('');
  const [directionSort, setDirectionSort] = useState('');
  const [sortIcon, setSortIcon] = useState(<SortIcon />);
  const [showImageMedia, setShowImageMedia] = useState(false);
  const [showImageBadge, setShowImageBadge] = useState(false);

  const { type, id } = useParams();
  const getDate = moment().format('YYYY-MM-DD');

  useEffect(() => {
    const handleRefresh = () => {
      new Promise((resolve) => {
        const param = {
          id,
        };
        dispatch(MissionService.datatable(param, resolve));
      }).then((res) => {
        if (res) {
          setMissionData(res?.data[0]);
          setStatus(res?.data[0].status);
          setActivationDate(moment(res?.data[0].activationDate).format('YYYY-MM-DD'));
          setMissionItemData(res?.data[0].missionDetail);
          if (res?.data[0]?.banner !== null) {
            const bannerUrl = (`${process.env.REACT_APP_API_URL}api/file/${res?.data[0]?.banner}`);
            const bannerFilename = bannerUrl.split('/').pop();
            setMedia(`${process.env.REACT_APP_API_URL}api/file?imageUrl=${res?.data[0]?.banner}`);
            setBannerName(bannerFilename);
            setBannerFile(new File([bannerUrl], bannerFilename, { type: 'image/png' }));
          }
          if (res?.data[0]?.badges.length && res?.data[0]?.badges !== undefined) {
            const badgeUrl = (`${process.env.REACT_APP_API_URL}api/file/${res?.data[0]?.badges[0]?.imageUrl}`);
            const badgeFilename = badgeUrl.split('/').pop();
            setBadge(`${process.env.REACT_APP_API_URL}api/file?imageUrl=${res?.data[0]?.badges[0]?.imageUrl}`);
            setBadgeImgName(badgeFilename);
            setBadgeImgFile(new File([badgeUrl], badgeFilename, { type: 'image/jpg' }));
            setBadgeName(res?.data[0].badges[0].name);
            setBadgeDescription(res?.data[0].badges[0].description);
          }
        }
      });
    };
    handleRefresh();
    document.documentElement.scrollTop = 0;
  }, []);

  useEffect(() => {
    const getStatisticSummary = () => {
      new Promise((resolve) => {
        const param = {
          missionId: id,
        };
        dispatch(MissionService.statistic_summary(param, resolve));
      }).then((res) => {
        if (res) {
          setTotalUser(res.data.total_user);
          setPercentage(res.data.percentage.toFixed(2));
        }
      });
    };
    if (type === 'special') {
      getStatisticSummary();
    }
  }, []);

  useEffect(() => {
    const getStatisticUsers = () => {
      new Promise((resolve) => {
        const param = {
          missionId: id,
          page: page ?? 0,
          length: 10,
          searchText: search ?? '',
          sortOrder: directionSort !== '' ? directionSort : null,
        };
        dispatch(MissionService.statistic_user(param, resolve));
      }).then((res) => {
        if (res) {
          setUsersData(res.data);
          setMaxPage(Math.ceil(res?.totalCount / 10));
          setTotalData(res.totalCount);
        }
      });
    };
    if (type === 'special') {
      getStatisticUsers();
    }
  }, [page, search, directionSort]);

  const handleSearch = (e) => {
    setSearch(e.target.value);
  };

  const handleClickSort = () => {
    if (directionSort === 'asc') {
      setDirectionSort('desc');
      setSortIcon(<DescIcon />);
    } else {
      setDirectionSort('asc');
      setSortIcon(<AscIcon />);
    }
  };

  const handleShowMediaInput = () => {
    setShowImageMedia(true);
  };

  const handleCloseMediaInput = () => {
    setShowImageMedia(false);
  };

  const handleShowBadgeInput = () => {
    setShowImageBadge(true);
  };

  const handleCloseBadgeInput = () => {
    setShowImageBadge(false);
  };

  const initialValues = {
    missionName: missionData?.missionName ?? '',
    description: missionData?.description ?? '',
    missionType: `${type.charAt(0).toUpperCase() + type.slice(1)} Mission`,
    activationDate: missionData?.activationDate
      ? moment(missionData?.activationDate).format('YYYY-MM-DD')
      : '',
    totalDay: missionData?.totalDay ?? '',
    totalParticipants: missionData?.totalParticipants ?? 0,
    banner: bannerFile ?? '',
    color: missionData?.color ?? '',
    badgeImage: badgeImgFile ?? '',
    badgeName: badgeName ?? '',
    badgeDescription: badgeDescription ?? '',
  };

  const columns = [
    {
      Header: 'No',
      Cell: ({ row }) => {
        const idDetailMission = row.index + 1;
        return idDetailMission;
      },
    },
    {
      Header: 'Aktivitas',
      accessor: 'name',
    },
    {
      Header: 'Target',
      Cell: ({ row }) => {
        const original = row ? row?.original : [];
        return `${original.target} ${original.unit}`;
      },
    },
    {
      Header: 'Reward',
      Cell: ({ row }) => {
        const original = row ? row?.original : [];
        const rewards = original.missionDetailReward?.map((item) => (
          `${item.value} ${item.type}`
        ));
        return rewards?.join(', ');
      },
    },
  ];

  const columnUsers = [
    {
      Header: 'No',
      accessor: 'no',
    },
    {
      Header: 'Nama Peserta',
      accessor: 'name',
      Cell: ({ row }) => {
        const original = row ? row?.original : [];
        return `${original.name.charAt(0).toUpperCase() + original.name.slice(1)}`;
      },
    },
    {
      Header: 'Rata-rata Progress',
      Cell: ({ row }) => {
        const original = row ? row?.original : [];
        return `${original.percentage.toFixed(2)}%`;
      },
    },
  ];

  const showTab = (active, props) => {
    switch (active) {
      case '1':
        return (
          <>
            <div className="row">
              <div className="col-lg-6 col-md-12">
                <Input
                  title="Nama Misi"
                  name="missionName"
                  type="text"
                  disabled
                  errors={props?.errors}
                  touched={props?.touched}
                  {...props?.getFieldProps('missionName')}
                />
              </div>
              <div className="col-lg-6 col-md-12">
                <Input
                  title="Deskripsi"
                  name="description"
                  type="text"
                  disabled
                  errors={props?.errors}
                  touched={props?.touched}
                  {...props?.getFieldProps('description')}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-lg-6 col-md-12">
                <Input
                  title="Jenis Misi"
                  name="missionType"
                  type="text"
                  disabled
                  errors={props?.errors}
                  touched={props?.touched}
                  {...props?.getFieldProps('missionType')}
                />
              </div>
              <div className="col-lg-6 col-md-12">
                <Input
                  title="Tanggal Aktivasi"
                  name="activation_date"
                  type="date"
                  disabled
                  min={getDate}
                  errors={props?.errors}
                  touched={props?.touched}
                  {...props?.getFieldProps('activationDate')}
                />
              </div>
            </div>
            {type === 'special' && (
              <>
                <div className="row">
                  <div className="col-lg-6 col-md-12">
                    <InputPrepend
                      name="totalDay"
                      label="Durasi Misi"
                      title="Hari"
                      type="number"
                      disabled
                      width="20%"
                      errors={props?.errors}
                      touched={props?.touched}
                      {...props.getFieldProps('totalDay')}
                      style={{ textAlign: 'right' }}
                    />
                  </div>
                  <div className="col-lg-6 col-md-12">
                    <Input
                      title="Media"
                      name="banner"
                      filename={bannerName}
                      type="file"
                      isShowMedia
                      onShowMedia={handleShowMediaInput}
                      disabled
                      accept="image/png, image/jpeg"
                      errors={props?.errors}
                      touched={props?.touched}
                      // {...props?.getFieldProps('banner')}
                      onChange={(e) => {
                        props?.setFieldValue('banner', e.target.files[0]);
                        setBannerName(e.target.files[0].name);
                      }}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-6 col-md-12">
                    <Input
                      name="color"
                      title="Warna Banner Misi"
                      type="color"
                      disabled
                      errors={props?.errors}
                      touched={props?.touched}
                      {...props.getFieldProps('color')}
                    />
                  </div>
                  <div className="col-lg-6 col-md-12">
                    <Input
                      title="Gambar Badge"
                      name="badgeImage"
                      filename={badgeImgName}
                      type="file"
                      isShowMedia
                      onShowMedia={handleShowBadgeInput}
                      disabled
                      accept="image/png, image/jpeg"
                      errors={props?.errors}
                      touched={props?.touched}
                      // {...props?.getFieldProps('badgeImage')}
                      onChange={(e) => {
                        props?.setFieldValue('badgeImage', e.target.files[0]);
                        setBadgeImgName(e.target.files[0].name);
                      }}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-6 col-md-12">
                    <Input
                      name="badgeName"
                      title="Nama Badge"
                      type="text"
                      disabled
                      errors={props?.errors}
                      touched={props?.touched}
                      {...props.getFieldProps('badgeName')}
                    />
                  </div>
                  <div className="col-lg-6 col-md-12">
                    <Input
                      title="Deskripsi Badge"
                      name="badgeDescription"
                      type="text"
                      disabled
                      errors={props?.errors}
                      touched={props?.touched}
                      {...props?.getFieldProps('badgeDescription')}
                    />
                  </div>
                </div>
              </>
            )}
            <div className="row">
              <div className="col-lg-6 col-md-12">
                <Input
                  title="Jumlah Peserta"
                  name="totalParticipants"
                  type="text"
                  disabled
                  errors={props?.errors}
                  touched={props?.touched}
                  {...props?.getFieldProps('totalParticipants')}
                />
              </div>
              <div className="col-lg-2 col-md-4">
                <div className="my-3">
                  <p className="form-label">Status</p>
                  <TableStatus
                    status={
                      status === 0
                        ? 'Draft'
                        : status === 1
                          ? getDate < activationDate
                            ? 'Dijadwalkan'
                            : 'Aktif'
                          : 'Tidak Aktif'
                    }
                    color={
                      status === 0
                        ? 'warning'
                        : status === 1
                          ? getDate < activationDate
                            ? 'scheduled'
                            : 'primary'
                          : 'danger'
                    }
                  />
                </div>
              </div>
            </div>
            <div className="d-flex align-items-center mt-5">
              <p className="card-datatable-title">
                Butir Misi
              </p>
            </div>
            <Table
              data={missionItemData}
              columns={columns.filter((item) => item.show !== false)}
            />
          </>
        );
      case '2':
        return (
          <>
            <div className="d-flex align-item-center my-3">
              <Card className="card-border" style={{ minWidth: '260px' }}>
                <div className="d-flex align-items-start">
                  <img
                    src={UserStatistic}
                    alt="Statistic User Icon"
                    style={{
                      width: '45px',
                      marginRight: 20,
                    }}
                  />
                  <div className="d-flex flex-column">
                    <p className="card-datatable-title mb-2">
                      {totalUser}
                    </p>
                    <p className="card-text">
                      Jumlah Peserta
                    </p>
                  </div>
                </div>
              </Card>
              <Card className="card-border ms-3" style={{ minWidth: '260px' }}>
                <div className="d-flex align-items-start">
                  <div style={{ width: 42, height: 42, marginRight: 20 }}>
                    <CircularProgressbar
                      value={percentage}
                      strokeWidth={10}
                      styles={buildStyles({
                        pathColor: '#2EC4B6',
                      })}
                    />
                  </div>
                  <div className="d-flex flex-column">
                    <p className="card-datatable-title mb-2">
                      {percentage}%
                    </p>
                    <p className="card-text">
                      Progress Misi
                    </p>
                  </div>
                </div>
              </Card>
            </div>
            <Table
              data={usersData}
              columns={columnUsers.filter((item) => item.show !== false)}
              pagination={{
                page, totalData, maxPage, toggle: setPage,
              }}
            />
          </>
        );
      default:
        return null;
    }
  };

  return (
    <div className="content-container-side">
      <ImageModal show={showImageMedia} handleClose={handleCloseMediaInput} srcMedia={media} />
      <ImageModal show={showImageBadge} handleClose={handleCloseBadgeInput} srcMedia={badge} />
      <div className="d-flex">
        <p className="content-title">
          Misi
        </p>
      </div>
      <div className="mb-4">
        <Breadcrumbs aria-label="breadcrumb" separator="">
          <Link
            color="inherit"
            to="/mission"
            onClick={() => {
              localStorage.removeItem('mission');
              localStorage.removeItem('missionDetails');
              navigate('/mission');
            }}
            style={styleActiveBreadcrumb}
          >
            Misi
          </Link>
          <Typography style={styleInactiveBreadcrumb}>
            {`Detail ${type.charAt(0).toUpperCase() + type.slice(1)} Mission`}
          </Typography>
        </Breadcrumbs>
      </div>
      <Card className="card-datatable">
        <div className="d-flex flex-wrap align-items-center">
          <span
            className="material-icons clickable"
            style={{ marginTop: '-12px' }}
            onClick={() => {
              localStorage.removeItem('mission');
              localStorage.removeItem('missionDetails');
              navigate('/mission');
            }}
            onKeyPress={() => navigate('/mission')}
            role="button"
            tabIndex={0}
          >
            arrow_back
          </span>
          <p className="card-datatable-title ms-3">
            {`Detail ${type.charAt(0).toUpperCase() + type.slice(1)} Mission`}
          </p>
          {activeTab === '2' && (
            <div className="ms-auto">
              <div className="d-flex flex-wrap justify-content-end align-items-center">
                <Search
                  name="search"
                  type="text"
                  placeholder="Search"
                  value={search}
                  onChange={handleSearch}
                  style={{ marginRight: 10 }}
                />
                <Button
                  variant="contained"
                  aria-label="sort"
                  size="medium"
                  onClick={handleClickSort}
                  style={{
                    color: '#4F5867',
                    background: '#fff',
                    border: '1px solid #e4e4e4',
                  }}
                  endIcon={sortIcon}
                  className="ms-2"
                >
                  Sort
                </Button>
              </div>
            </div>
          )}
        </div>
        <Formik
          innerRef={formikRef}
          initialValues={initialValues}
          enableReinitialize
        >
          {(props) => (
            <Form>
              <div className="tab-menu overflow">
                <div style={{ maxWidth: 'fit-content' }}>
                  <Tabs
                    value={activeTab}
                    variant="scrollable"
                    scrollButtons="auto"
                    onChange={(event, newValue) => {
                      setActiveTab(newValue);
                    }}
                  >
                    <Tab
                      label="Informasi"
                      value="1"
                      style={styleTextTabs}
                    />
                    {type === 'special' && (
                      <Tab
                        label="Statistik"
                        value="2"
                        style={styleTextTabs}
                      />
                    )}
                  </Tabs>
                </div>
              </div>
              <div className="overflow mt-3">
                {showTab(activeTab, props)}
              </div>
            </Form>
          )}
        </Formik>
      </Card>
    </div>
  );
};

export default MissionForm;
