/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/prop-types */
import React from 'react';
import '../../assets/styles/profile.scss';
import AvatarIcon from '../../assets/images/icons/Avatar.svg';

function Profile(props) {
  return (
    <div className="Profile-Avatar d-flex align-items-center">
      <h2 className="label">{props.label}</h2>
      <img src={AvatarIcon} alt="Avatar" className="Avatar" />
    </div>
  );
}

export default Profile;
