/* eslint-disable react/prop-types */
import React from 'react';
import SyncLoader from 'react-spinners/SyncLoader';

import styles from '../../assets/styles/_variables.scss';

function Loader({
  color,
  loading,
  fullscreen,
}) {
  return (
    <>
      {fullscreen && (
        <div style={{
          position: 'fixed', width: '100vw', height: '100vh', top: 0, left: 0, zIndex: 1042, background: '#00000000',
        }}
        >
          <div style={{ textAlign: 'center', marginTop: '50vh' }}>
            <SyncLoader color={color ?? styles?.primary} loading={loading} />
          </div>
        </div>
      )}

      {!fullscreen && (
        <div style={{
          position: 'absolute', width: '100%', height: '100%', top: 0, left: 0, zIndex: 1042, background: '#00000000',
        }}
        >
          <div style={{ textAlign: 'center', marginTop: '10%' }}>
            <SyncLoader color={color ?? styles?.primary} loading={loading} />
          </div>
        </div>
      )}
    </>
  );
}

export default Loader;
