/* eslint-disable import/named */
/* eslint-disable react/prop-types */
import React from 'react';
import { Navigate } from 'react-router-dom';
// import Layout from '../../layout';
import Sidebar from '../../sidebar';
import { getUser } from '../../../utils/User';

const styledGrid = {
  display: 'grid',
  gridTemplateColumns: 'min-content 1fr',
  gridTemplateRows: 'min-content 1fr',
  height: '100%',
  minHeight: '100hv',
};

const styledMain = {
  background: '#F4F7F7',
  minHeight: '100vh',
  // width: 'calc(100vw - 108px)',
};

function PrivateRoute({ element }) {
  const currentUser = getUser();
  return currentUser ? (
    <div style={styledGrid}>
      <Sidebar>{element}</Sidebar>
      <div style={styledMain} className="main-top px-2 py-3">
        {element}
      </div>
    </div>
  ) : (
    <Navigate to="/login" />
  );
}

export default PrivateRoute;
