/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/prop-types */
/* eslint-disable no-nested-ternary */
import React from 'react';

const Checkbox = ({
  name,
  checked,
  onChange,
  label,
  value,
  styleInput,
  disabled,
  style,
  desc,
}) => (
  <label className="checkbox-container">
    {label && (
      <p
        className="side-menu-title text-extra-bold"
        style={{ fontSize: 14, cursor: 'context-menu' }}
      >
          {label}
      </p>
    )}
    {desc && (
      <div className="normal-title">
        {desc}
      </div>
    )}
    <input
      type="checkbox"
      style={style}
      name={name}
      value={value}
      checked={checked ?? false}
      onChange={onChange}
      disabled={disabled}
    />
    <span className="checkmark" style={styleInput} />
  </label>
);

export default Checkbox;
