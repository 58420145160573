/* eslint-disable radix */
/* eslint-disable space-infix-ops */
/* eslint-disable no-else-return */
/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/prop-types */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable no-lonely-if */

import {
  React, useEffect, useRef,
  useState,
} from 'react';
import { useDispatch } from 'react-redux';
import { useParams, useNavigate, Link } from 'react-router-dom';
import { Card } from 'react-bootstrap';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Typography from '@mui/material/Typography';

import { toastSuccess, toastError } from '../../../components/toast';
import Input from '../../../components/forms/input';
import Textarea from '../../../components/forms/Textarea';
import Select from '../../../components/forms/Select';
import InputPrepend from '../../../components/forms/InputPrepend';
import Checkbox from '../../../components/forms/Checkbox';
import ButtonAction from '../../../components/buttons/ButtonAction';

import MissionService from '../../../stores/actions/mission';

const styleActiveBreadcrumb = {
  fontWeight: 600,
  fontSize: '12px',
  lineHeight: '14px',
  color: 'rgba(79, 88, 103, 0.7)',
  textDecoration: 'none',
  fontFamily: 'BlueberrySans-Regular',
};

const styleInactiveBreadcrumb = {
  fontWeight: 500,
  fontSize: '12px',
  lineHeight: '14px',
  color: 'rgba(79, 88, 103, 0.7)',
  textDecoration: 'none',
  fontFamily: 'BlueberrySans-Regular',
};

const MissionItem = () => {
  document.documentElement.scrollTop = 0;
  const dispatch = useDispatch();
  const formikRef = useRef();
  const navigate = useNavigate();

  const exceptThisSymbols = ['e', 'E', '+', '-', '.'];

  const [missionItemData, setMissionItemData] = useState([]);
  const [activitiesOptions, setActivitiesOptions] = useState([]);
  const [disabledDziko, setDisabledDziko] = useState(true);
  const [disabledXP, setDisabledXP] = useState(true);
  // const [disabledCheckboxDziko, setDisabledCheckboxDziko] = useState(false);
  // const [disabledCheckboxXP, setDisabledCheckboxXP] = useState(false);

  const {
    action,
    type,
    subaction,
    id,
  } = useParams();
  let title = '';
  if (subaction === 'edit') {
    title = 'Edit';
  } else if (subaction === 'detail') {
    title = 'Detail';
  } else if (subaction === 'add') {
    title = 'Tambah';
  }
  // const title = subaction === 'edit' ? 'Edit' : 'detail' ? 'Detail' : 'tambah' ? 'Tambah' : '';
  const titleParent = action === 'edit' ? 'Edit' : 'Tambah';
  useEffect(() => {
    const missionDetails = JSON.parse(localStorage.getItem('missionDetails'));
    if (missionDetails) {
      if (id) {
        const index = id - 1;
        setMissionItemData(missionDetails[index]);
        missionDetails[index].missionDetailReward.map((item) => {
          if (item.type === 'XP') {
            setDisabledXP(!disabledXP);
          }

          if (item.type === 'Dziko') {
            setDisabledDziko(!disabledDziko);
          }
          return item;
        });
      }
    }
  }, []);

  useEffect(() => {
    const getActivitiesOptions = () => {
      new Promise((resolve) => {
        dispatch(MissionService.activities(resolve));
      }).then((res) => {
        if (res) {
          const list = res.data.map((item) => ({
            id: item?.id,
            type: item?.type,
            value: item?.name,
            label: item?.name,
          }));
          setActivitiesOptions(list);
        }
      });
    };
    getActivitiesOptions();
  }, []);

  const validation = Yup.object().shape({
    description: Yup.string().required('Keterangan butir misi wajib diisi').nullable(),
    name: Yup.string().required('Nama aktivitas butir misi wajib diisi').nullable().test('len', 'Aktivitas Tidak Boleh Sama Dengan Butir Misi Yang Lain', (val) => {
      const missionDetails = JSON.parse(localStorage.getItem('missionDetails'));
      if (missionDetails === null) {
        return true;
      }

      if (subaction === 'edit') {
        const indexMission = parseInt(id) - 1;
        missionDetails.splice(indexMission, 1);
        const find = missionDetails.find((item) => item.name === val);
        if (find === undefined) {
          return true;
        } else {
          return false;
        }
      } else {
        const find = missionDetails.find((item) => item.name === val);
        if (find === undefined) {
          return true;
        } else {
          return false;
        }
      }
    }),
    target: Yup.string().required('Target butir misi wajib diisi').nullable(),
    unit: Yup.string().required('Unit target butir misi wajib diisi').nullable(),
    rewardXP: Yup.number().when('checkedXP', {
      is: true,
      then: (d) => d.required('Reward butir misi wajib diisi').nullable(),
    }),
    rewardDziko: Yup.number().when('checkedDziko', {
      is: true,
      then: (d) => d.required('Reward butir misi wajib diisi').nullable(),
    }),
  });

  const initialValues = {
    name: missionItemData?.name ?? '',
    description: missionItemData?.description ?? '',
    target: missionItemData?.target ?? '',
    unit: missionItemData?.unit ?? '',
    type: missionItemData?.type ?? '',
    objectId: missionItemData?.objectId ?? '',
    checkedXP: missionItemData?.missionDetailReward?.map((e) => e.type).indexOf('XP') > -1 ?? false,
    checkedDziko: missionItemData?.missionDetailReward?.map((e) => e.type).indexOf('Dziko') > -1 ?? false,
    rewardXP: missionItemData?.missionDetailReward?.map((e) => e.type).indexOf('XP') > -1
      ? missionItemData?.missionDetailReward[
        missionItemData?.missionDetailReward.map((e) => e.type).indexOf('XP')
      ].value
      : '',
    rewardDziko: missionItemData?.missionDetailReward?.map((e) => e.type).indexOf('Dziko') > -1
      ? missionItemData?.missionDetailReward[
        missionItemData?.missionDetailReward.map((e) => e.type).indexOf('Dziko')
      ].value
      : '',
  };

  // const activitiesOptions = [
  //   {
  //     value: 'Dzikir',
  //     label: 'Dzikir',
  //   },
  //   {
  //     value: 'Baca Quran',
  //     label: 'Baca Quran',
  //   },
  // ];

  const unitQuran = [
    {
      value: 'Ayat',
      label: 'Ayat',
    },
    {
      value: 'Halaman',
      label: 'Halaman',
    },
    {
      value: 'Juz',
      label: 'Juz',
    },
    {
      value: 'Surat',
      label: 'Surat',
    },
  ];

  const unitDzikir = [
    {
      value: 'Kali',
      label: 'Kali',
    },
  ];

  return (
    <div className="content-container-side">
      <div className="d-flex">
        <p className="content-title">
          Misi
        </p>
      </div>
      <div className="mb-4">
        <Breadcrumbs aria-label="breadcrumb" separator="">
          <Link
            color="inherit"
            to="/mission"
            onClick={() => {
              localStorage.removeItem('mission');
              localStorage.removeItem('missionDetails');
              navigate('/mission');
            }}
            style={styleActiveBreadcrumb}
          >
            Misi
          </Link>
          <Link
            color="inherit"
            to={() => navigate(-1)}
            onClick={() => navigate(-1)}
            style={styleActiveBreadcrumb}
          >
            {`${titleParent} ${type.charAt(0).toUpperCase() + type.slice(1)} Mission`}
          </Link>
          <Typography style={styleInactiveBreadcrumb}>
            {`${title} Butir ${type.charAt(0).toUpperCase() + type.slice(1)} Misi`}
          </Typography>
        </Breadcrumbs>
      </div>
      <Card className="card-datatable">
        <div className="d-flex">
          <span
            className="material-icons clickable"
            onClick={() => navigate(-1)}
            onKeyPress={() => navigate(-1)}
            role="button"
            tabIndex={0}
          >
            arrow_back
          </span>
          <p className="card-datatable-title ms-3">
            {`${title} Butir ${type.charAt(0).toUpperCase() + type.slice(1)} Misi`}
          </p>
        </div>
        <Formik
          innerRef={formikRef}
          initialValues={initialValues}
          enableReinitialize
          validationSchema={validation}
          onSubmit={(values) => {
            const val = {
              description: values.description,
              name: values.name,
              target: values.target,
              unit: values.unit,
              type: values.type,
              objectId: values.objectId,
              missionDetailReward: [],
            };

            if (values.checkedXP) {
              val.missionDetailReward.push({
                type: 'XP',
                value: values.rewardXP,
              });
            }
            if (values.checkedDziko) {
              val.missionDetailReward.push({
                type: 'Dziko',
                value: values.rewardDziko,
              });
            }

            if (!val.missionDetailReward.length) {
              toastError('Reward butir misi wajib diisi!');
              return;
            }

            const missionDetailStorage = JSON.parse(localStorage.getItem('missionDetails'));
            if (missionDetailStorage) {
              if (title === 'Edit') {
                const index = id - 1;
                if (missionDetailStorage[index].missionId) {
                  missionDetailStorage[index].description = values.description;
                  missionDetailStorage[index].name = values.name;
                  missionDetailStorage[index].target = values.target;
                  missionDetailStorage[index].unit = values.unit;
                  missionDetailStorage[index].type = values.type;
                  missionDetailStorage[index].objectId = values.objectId;

                  if (missionItemData?.missionDetailReward
                    .map((e) => e.type)
                    .indexOf('XP') !== -1) {
                    if (values.checkedXP) {
                      missionDetailStorage[index]
                        .missionDetailReward[
                          missionItemData?.missionDetailReward
                            .map((e) => e.type)
                            .indexOf('XP')]
                        .value = values.rewardXP;
                    } else {
                      missionDetailStorage[index]
                        .missionDetailReward.splice(
                          missionItemData?.missionDetailReward
                            .map((e) => e.type)
                            .indexOf('XP'),
                          1,
                        );
                    }
                  } else {
                    if (values.checkedXP) {
                      missionDetailStorage[index]
                        .missionDetailReward.push({
                          type: 'XP',
                          value: values.rewardXP,
                        });
                    }
                  }

                  if (missionItemData?.missionDetailReward
                    .map((e) => e.type)
                    .indexOf('Dziko') !== -1) {
                    if (values.checkedDziko) {
                      missionDetailStorage[index]
                        .missionDetailReward[
                          missionItemData?.missionDetailReward
                            .map((e) => e.type)
                            .indexOf('Dziko')]
                        .value = values.rewardDziko;
                    } else {
                      missionDetailStorage[index]
                        .missionDetailReward.splice(
                          missionItemData?.missionDetailReward
                            .map((e) => e.type)
                            .indexOf('Dziko'),
                          1,
                        );
                    }
                  } else {
                    if (values.checkedDziko) {
                      missionDetailStorage[index]
                        .missionDetailReward.push({
                          type: 'Dziko',
                          value: values.rewardDziko,
                        });
                    }
                  }
                } else {
                  missionDetailStorage[index] = val;
                }
                localStorage.setItem('missionDetails', JSON.stringify(missionDetailStorage));
              } else {
                missionDetailStorage.push(val);
                localStorage.setItem('missionDetails', JSON.stringify(missionDetailStorage));
              }
            } else {
              const missionDetails = [];
              missionDetails.push(val);
              localStorage.setItem('missionDetails', JSON.stringify(missionDetails));
            }
            navigate(-1);
            if (title === 'Edit') {
              toastSuccess('Data butir misi berhasil diperbarui!');
            } else {
              toastSuccess('Data butir misi berhasil ditambahkan!');
            }
          }}
        >
          {(props) => (
            <Form>
              <div className="row">
                <div className="col-lg-6 col-md-12">
                  <Select
                    title="Aktivitas"
                    name="name"
                    errors={props?.errors}
                    touched={props?.touched}
                    isSearchable={false}
                    disabled={title === 'Detail'}
                    options={activitiesOptions}
                    value={props.values?.name}
                    onChange={(name, value) => {
                      const index = activitiesOptions
                        ?.map((e) => e.label)
                        .indexOf(value);
                      if (index > -1) {
                        props.setFieldValue('name', activitiesOptions[index]?.label);
                        props.setFieldValue('type', activitiesOptions[index]?.type);
                        props.setFieldValue('objectId', activitiesOptions[index]?.id);
                        if (activitiesOptions[index]?.type?.toLowerCase() === 'dzikir') {
                          props.setFieldValue('unit', 'Kali');
                        }
                      }
                    }}
                    onBlur={props.setFieldTouched}
                  />
                </div>
                <div className="col-lg-6 col-md-12">
                  <div className="row">
                    <div className="col-md-6">
                      <Input
                        title="Target"
                        name="target"
                        type="number"
                        onKeyDown={(e) => exceptThisSymbols.includes(e.key) && e.preventDefault()}
                        disabled={title === 'Detail'}
                        errors={props?.errors}
                        touched={props?.touched}
                        {...props?.getFieldProps('target')}
                      />
                    </div>
                    <div className="col-md-6">
                      <Select
                        name="unit"
                        errors={props?.errors}
                        touched={props?.touched}
                        isSearchable={false}
                        options={
                          props?.values?.name?.toLowerCase().indexOf('dzikir') !== -1
                            ? unitDzikir
                            : unitQuran
                        }
                        value={props?.values?.unit}
                        disabled={
                          title === 'Detail'
                          || props?.values?.name?.toLowerCase().indexOf('dzikir') !== -1
                        }
                        onChange={(name, value) => {
                          props.setFieldValue('unit', value);
                        }}
                        onBlur={props.setFieldTouched}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-6 col-md-12">
                  <Textarea
                    title="Keterangan"
                    name="description"
                    type="text"
                    disabled={title === 'Detail'}
                    rows={5}
                    errors={props?.errors}
                    touched={props?.touched}
                    {...props?.getFieldProps('description')}
                  />
                </div>
                <div className="col-lg-6 col-md-12">
                  <div className="d-flex align-items-center">
                    <Checkbox
                      styleInput={{ marginTop: '15px' }}
                      name="checkedXP"
                      checked={!disabledXP}
                      disabled={title === 'Detail'}
                      // disabled={disabledCheckboxXP}
                      onChange={() => {
                        if (props?.values?.checkedXP === false) {
                          props.setFieldValue('checkedXP', true);
                        } else {
                          props.setFieldValue('checkedXP', false);
                        }
                        setDisabledXP(!disabledXP);
                        // setDisabledCheckboxDziko(!disabledCheckboxDziko);
                      }}
                      value={1}
                    />
                    <InputPrepend
                      name="rewardXP"
                      label="Reward"
                      title="XP"
                      type="number"
                      onKeyDown={(e) => exceptThisSymbols.includes(e.key) && e.preventDefault()}
                      width="20%"
                      disabled={title === 'Detail' || disabledXP}
                      errors={props?.errors}
                      touched={props?.touched}
                      {...props.getFieldProps('rewardXP')}
                    />
                  </div>
                  <div className="d-flex align-items-center">
                    <Checkbox
                      styleInput={{ top: '-5px' }}
                      name="checkedDziko"
                      checked={!disabledDziko}
                      disabled={title === 'Detail'}
                      // disabled={disabledCheckboxDziko}
                      onChange={() => {
                        if (props?.values?.checkedDziko === false) {
                          props.setFieldValue('checkedDziko', true);
                        } else {
                          props.setFieldValue('checkedDziko', false);
                        }
                        setDisabledDziko(!disabledDziko);
                        // setDisabledCheckboxXP(!disabledCheckboxXP);
                      }}
                      value={1}
                    />
                    <InputPrepend
                      name="rewardDziko"
                      title="Dziko"
                      type="number"
                      onKeyDown={(e) => exceptThisSymbols.includes(e.key) && e.preventDefault()}
                      width="20%"
                      placeholder="Masukkan Reward"
                      disabled={title === 'Detail' || disabledDziko}
                      errors={props?.errors}
                      touched={props?.touched}
                      {...props.getFieldProps('rewardDziko')}
                    />
                  </div>
                </div>
              </div>
              {title !== 'Detail' && (
                <div className="my-4">
                  <ButtonAction
                    toggle={() => navigate(-1)}
                    confirmTitle="Simpan Data"
                    cancelTitle="Batal"
                  />
                </div>
              )}
            </Form>
          )}
        </Formik>
      </Card>
    </div>
  );
};

export default MissionItem;
