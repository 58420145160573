/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable max-len */
/* eslint-disable no-undef */
/* eslint-disable no-shadow */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/jsx-boolean-value */
/* eslint-disable no-unused-vars */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/prop-types */
/* eslint-disable no-console */

/* eslint-disable */
import { Breadcrumbs, Typography } from '@mui/material';
import { Form, Formik } from 'formik';
import { useDispatch } from 'react-redux';
import * as Yup from 'yup';
import moment from 'moment/moment';
import React, { useState, useRef, useEffect } from 'react';
import { Card } from 'react-bootstrap';
import { Link, useNavigate, useParams } from 'react-router-dom';
import DatePicker from 'react-datepicker';
import ButtonAction from '../../components/buttons/ButtonAction';
import Input from '../../components/forms/input';
import Textarea from '../../components/forms/Textarea';
import 'react-datepicker/dist/react-datepicker.css';
import Select from '../../components/forms/Select';
import ConfirmModal from '../../components/modal/ConfirmModal';
import ImageModal from '../../components/modal/ImageModal';
import saveIcon from '../../assets/images/icons/SaveIcon.svg';
import {
  getNotificationById,
  editNotificationById,
  AddData,
} from '../../services/notification/notificationService';
import 'react-toastify/dist/ReactToastify.css';
import { setEditedNotificationValue, setAddeddNotificationValue } from '../../stores/actions/notification/formNotification';

const styleActiveBreadcrumb = {
  fontWeight: 600,
  fontSize: '12px',
  lineHeight: '14px',
  color: 'rgba(79, 88, 103, 0.7)',
  textDecoration: 'none',
  fontFamily: 'BlueberrySans-Regular',
};

const styleInactiveBreadcrumb = {
  fontWeight: 500,
  fontSize: '12px',
  lineHeight: '14px',
  color: 'rgba(79, 88, 103, 0.7)',
  textDecoration: 'none',
  fontFamily: 'BlueberrySans-Regular',
};

function DailyNotificationForm(props) {
  const { id } = useParams();
  const [title, setTitle] = useState('');
  const [caption, setCaption] = useState('');
  const [link, setLink] = useState('');
  const [repeat, setRepeat] = useState('');
  const [time, setTime] = useState('');
  const [dateRange, setDateRange] = useState([null, null]);
  const [startDate, endDate] = dateRange;
  const [defaultStartDate, setDefaultStartDate] = useState(null);
  const [defaultEndDate, setDefaultEndDate] = useState(null);
  const [defaultImageNotification, setDefaultImageNotification] = useState(null);
  const [imageNotification, setImageNotification] = useState(null);
  const [imageName, setImageName] = useState('Upload File Media');
  const [isEdited, setIsEdited] = useState(false);
  const [isAdded, setIsAdded] = useState(false);
  const [inputColor, setInputColor] = useState('');
  const [isInvalidClassName, setIsInvalidClassName] = useState('');
  const [isDblock, setIsDblock] = useState('d-none');

  const [showModal, setShowModal] = useState(false);
  const [showImageMedia, setShowImageMedia] = useState(false);
  const [modalCloseConfirmation, setShowModalCloseConfirmation] = useState(false);
  const formikRef = useRef();
  const dispatch = useDispatch();

  const navigate = useNavigate();
  const getDate = moment().format('YYYY-MM-DD');

  const initialValues = {
    title: title || '',
    caption: caption || '',
    link: link || '',
    repeat: repeat || '',
    time: time || '',
    imageNotification: imageNotification || null,
  };

  const validation = Yup.object().shape({
    title: Yup.string()
      .required('Nama Notifikasi Wajib Diisi')
      .max(40, 'Nama Notifikasi Terlalu Panjang')
      .nullable(),
    caption: Yup.string()
      .required('Caption Wajib Diisi')
      .nullable()
      .max(100, 'Caption Notifikasi Terlalu Panjang'),
    link: Yup.string().required('Link Wajib Diisi').nullable(),
    repeat: Yup.string().required('Ulangi Wajib Diisi').nullable(),
    time: Yup.string().required('Waktu Wajib Diisi').nullable(),
    imageNotification: Yup.mixed()
      .test(
        'fileSize',
        'Gambar Maksimal 1MB',
        (value) => value === null || value.size <= 1000000,
      )
      .test(
        'fileFormat',
        'Unsupported file type',
        (value) => value === null
          || (value
            && ['image/jpg', 'image/jpeg', 'image/gif', 'image/png'].includes(
              value.type,
            )),
      ),
  });

  // options
  const optionsUlangi = [
    { value: 'NONE', label: 'Tidak Diulang' },
    { value: 'WEEKLY', label: 'Setiap Minggu' },
    { value: 'DAILY', label: 'Setiap Hari' },
  ];

  const optionsLink = [
    { value: 'dzikir_pagi', label: 'Dzikir Pagi' },
    { value: 'dzikir_petang', label: 'Dzikir Petang' },
    { value: 'al_kahfi', label: 'Al-Kahfi' },
    { value: 'infaq', label: 'Infaq'},
  ];

  const handleClose = () => setShowModal(false);
  const handleCloseModalConfirmation = () => setShowModalCloseConfirmation(false);
  const isFormValueEdited = (oldValues, currentValues) => {
    if (id) {
      if (
        oldValues.title !== currentValues.title
        || oldValues.caption !== currentValues.caption
        || oldValues.repeat !== currentValues.repeat
        || oldValues.time !== currentValues.time
      ) {
        setIsEdited(true);
        dispatch(setEditedNotificationValue(true));
      } else {
        setIsEdited(false);
        dispatch(setEditedNotificationValue(false));
      }
    }
  };
  const isFormValueAdded = (values) => {
    if (!id) {
      if (
        values.title === ''
        && values.caption === ''
        && values.link === ''
        && values.repeat === ''
        && values.time === ''
      ) {
        setIsAdded(false);
        dispatch(setAddeddNotificationValue(false));
      } else {
        setIsAdded(true);
        dispatch(setAddeddNotificationValue(true));
      }
    }
  };

  const checkIsFormIsOnProgress = () => {
    if (id && isEdited) {
      setShowModalCloseConfirmation(true);
    } else if (isAdded) {
      setShowModalCloseConfirmation(true);
    } else {
      navigate('/notification');
    }
  };

  const handleShowMediaInput = () => {
    setShowImageMedia(true);
  };

  const handleCloseMediaInput = () => {
    setShowImageMedia(false);
  };

  useEffect(() => {
    const handleNotification = async () => {
      const detailNotification = await getNotificationById(id);
      const mediaUrl = detailNotification.media;
      const mediaFilename = mediaUrl.split('/').pop();

      setTitle(detailNotification.name);
      setDefaultStartDate(detailNotification.startDate.replaceAll('-', '/'));
      setDefaultEndDate(detailNotification.endDate.replaceAll('-', '/'));
      setTime(detailNotification.time);
      setCaption(detailNotification.caption);
      setRepeat(detailNotification.repeat);
      setLink(detailNotification.link);
      setDefaultImageNotification(detailNotification.media);

      if (mediaFilename !== `file?imageUrl=${null}`) {
        setImageName(mediaFilename)
      }
    };

    if (id) {
      handleNotification();
    }
  }, []);

  return (
    <div className="content-container-side">
      <ImageModal
        show={showImageMedia}
        handleClose={handleCloseMediaInput}
        srcMedia={
          defaultImageNotification !== `${process.env.REACT_APP_API_URL}api/file?imageUrl=${null}` && defaultImageNotification
        }
      />
      <div className="d-flex">
        <p className="content-title">Notifikasi</p>
      </div>
      <div className="mb-4">
        <Breadcrumbs aria-label="breadcrumb" separator="">
          <Link
            color="inherit"
            to="/notification"
            style={styleActiveBreadcrumb}
          >
            Notifikasi
          </Link>
          <Typography style={styleInactiveBreadcrumb}>
            Notifikasi Rutin
          </Typography>
        </Breadcrumbs>
      </div>
      <Card className="card-datatable">
        <div className="d-flex">
          <span
            className="material-icons clickable"
            onClick={() => {
              localStorage.removeItem('mission');
              localStorage.removeItem('missionDetails');
              checkIsFormIsOnProgress();
            }}
            onKeyPress={() => navigate('/notification')}
            role="button"
            tabIndex={0}
          >
            arrow_back
          </span>
          <p className="card-datatable-title ms-3">
            {id ? 'Edit' : 'Tambah'}
            {' '}
            Notifikasi Rutin
          </p>
        </div>
        <Formik
          innerRef={formikRef}
          initialValues={initialValues}
          enableReinitialize
          validationSchema={validation}
          onSubmit={(values, errors, touched) => {
            // custom check validasi date picker
            if (startDate === null && defaultStartDate === null) {
              setIsInvalidClassName('is-invalid');
              setIsDblock('d-block');
            } else {
              setIsInvalidClassName('');
              setIsDblock('d-none');
              setShowModal(true);
            }
          }}
        >
          {(props) => (
            <Form>
              {isFormValueEdited(initialValues, props.values)}
              {isFormValueAdded(props.values)}
              <ConfirmModal
                show={showModal}
                message={
                  id
                    ? 'Apakah anda yakin ingin mengubah data?'
                    : 'Apakah anda yakin ingin menyimpan data?'
                }
                onHide={handleClose}
                confirmTitle={id ? 'Ubah' : 'Simpan'}
                cancelTitle="Batal"
                toggle={handleClose}
                inlineIcon={saveIcon}
                confirm={() => {
                  setShowModal(false);
                  // set range date
                  const end = new Date(endDate);
                  const start = new Date(startDate);
                  const rangeStart = start.toLocaleDateString().split('/');
                  const rangeEnd = end.toLocaleDateString().split('/');
                  const formatedStartDate = (`${rangeStart[2]}/${rangeStart[0]}/${rangeStart[1]}`);
                  const formatedEndDate = (`${rangeEnd[2]}/${rangeEnd[0]}/${rangeEnd[1]}`);
                  if (id) {
                    const formData = new FormData();
                    formData.append('name', props.values.title);
                    formData.append('caption', props.values.caption);
                    formData.append('endDate', endDate === null ? defaultEndDate : formatedEndDate);
                    formData.append('startDate', startDate === null ? defaultStartDate : formatedStartDate);
                    formData.append('time', props.values.time);
                    formData.append('repeat', props.values.repeat);
                    formData.append('type', 'RUTIN');
                    formData.append('link', props.values.link);
                    formData.append('media', props.values.imageNotification);
                    editNotificationById(id, formData).then((response) => {
                      if (response.status === 200) {
                        localStorage.setItem('isEdited', true);
                        dispatch(setEditedNotificationValue(false));
                        dispatch(setAddeddNotificationValue(false));
                        setTimeout(() => {
                          navigate('/notification');
                        }, 100);
                      }
                    });
                  } else {
                    const formData = new FormData();
                    formData.append('name', props.values.title);
                    formData.append('startDate', formatedStartDate.replaceAll('/', '-'));
                    formData.append('endDate', formatedEndDate.replaceAll('/', '-'));
                    formData.append('time', props.values.time);
                    formData.append('repeat', props.values.repeat);
                    formData.append('caption', props.values.caption);
                    formData.append('media', props.values.imageNotification);
                    formData.append('type', 'RUTIN');
                    formData.append('link', props.values.link);
                    AddData(formData).then((response) => {
                      if (response.status === 200) {
                        localStorage.setItem('isAdded', true);
                        dispatch(setEditedNotificationValue(false));
                        dispatch(setAddeddNotificationValue(false));
                        setTimeout(() => {
                          navigate('/notification');
                        }, 100);
                      }
                    });
                  }
                }}
              />
              <div className="row">
                <div className="col-lg-6">
                  <Input
                    title="Nama Notifikasi"
                    name="title"
                    type="text"
                    errors={props?.errors}
                    touched={props?.touched}
                    {...props?.getFieldProps('title')}
                  />
                </div>
                <div className="col-lg-6">
                  <Input
                    title="Media"
                    isShowMedia={!!id}
                    onShowMedia={handleShowMediaInput}
                    name="imageNotification"
                    inputColor={inputColor}
                    type="file"
                    filename={imageName}
                    errors={props?.errors}
                    touched={props?.touched}
                    onChange={(e) => {
                      props.setFieldValue(
                        'imageNotification',
                        e.target.files[0],
                        true,
                      );
                      setImageName(e.target.files[0].name);
                      setInputColor('color-input-black');
                    }}
                    accept="image/png, image/jpeg"
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-lg-6">
                  <div className="form-group">
                    <label className="form-label">Tanggal</label>
                    <DatePicker
                      selectsRange
                      className={`form-input form-control ${isInvalidClassName} ${id ? 'placeholder-black' : ''} `}
                      startDate={startDate}
                      endDate={endDate}
                      placeholderText={defaultStartDate ? `${defaultStartDate} - ${defaultEndDate}` : 'Masukan Tanggal'}
                      minDate={new Date(getDate)}
                      onChange={(update) => {
                        setDateRange(update);
                        // custom check validasi date picker
                        if (startDate === null && defaultStartDate === null) {
                          setIsInvalidClassName('is-invalid');
                          setIsDblock('d-block');
                        } else {
                          setIsInvalidClassName('');
                          setIsDblock('d-none');
                        }
                      }}
                    />
                    <div className={`invalid-feedback d-block ${isDblock}`}>
                      <p className="ms-1">Field Tanggal Harus Diisi</p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6">
                  <Select
                    name="link"
                    title="Link"
                    placeholder="Pilih Data Tujuan"
                    value={props?.values?.link}
                    errors={props?.errors}
                    touched={props?.touched}
                    isSearchable={false}
                    options={optionsLink}
                    onChange={(name, value) => {
                      props.setFieldValue('link', value);
                    }}
                    onBlur={props.setFieldTouched}
                  />
                </div>
                <div className="col-lg-6">
                  <div className="row">
                    <div className="col-lg-6 col-md-12">
                      <Input
                        name="timeName"
                        title="Waktu"
                        type="time"
                        errors={props?.errors}
                        touched={props?.touched}
                        {...props?.getFieldProps('time')}
                      />
                    </div>
                    <div className="col-lg-6 col-md-12">
                      <Select
                        name="repeat"
                        title="Ulangi"
                        placeholder="Ulangi Notifikasi"
                        value={props?.values?.repeat}
                        errors={props?.errors}
                        touched={props?.touched}
                        isSearchable={false}
                        options={optionsUlangi}
                        onChange={(name, value) => {
                          props.setFieldValue('repeat', value);
                        }}
                        onBlur={props.setFieldTouched}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 col-md-12">
                  <Textarea
                    title="Caption"
                    name="activation_date"
                    type="textarea"
                    rows={6}
                    errors={props?.errors}
                    touched={props?.touched}
                    {...props?.getFieldProps('caption')}
                  />
                </div>
              </div>
              <div className="d-flex align-items-center mt-5">
                <div className="ms-auto">
                  <ButtonAction
                    cancelTitle="Batal"
                    confirm={() => {
                      // custom check validasi date picker
                      if (startDate === null && defaultStartDate === null) {
                        setIsInvalidClassName('is-invalid');
                        setIsDblock('d-block');
                      } else {
                        setIsInvalidClassName('');
                        setIsDblock('d-none');
                      }
                    }}
                    confirmTitle={id ? 'Simpan Data' : 'Tambah Data'}
                    toggle={() => {
                      localStorage.removeItem('mission');
                      localStorage.removeItem('missionDetails');
                      checkIsFormIsOnProgress();
                    }}
                  />
                </div>
              </div>
              <ConfirmModal
                show={modalCloseConfirmation}
                message={`Apakah Anda yakin ingin membatalkan proses ${id ? 'edit' : 'tambah'} notifikasi?`}
                onHide={handleClose}
                confirmTitle="Yakin"
                cancelTitle="Batal"
                toggle={handleCloseModalConfirmation}
                inlineIcon={saveIcon}
                confirm={() => {
                  dispatch(setEditedNotificationValue(false));
                  dispatch(setAddeddNotificationValue(false));
                  navigate('/notification');
                }}
              />
            </Form>
          )}
        </Formik>
      </Card>
    </div>
  );
}

export default DailyNotificationForm;
