/* eslint-disable react/button-has-type */
/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
/* eslint-disable-line jsx-a11y/no-static-element-interactions */
import React from 'react';
import Button from '../Button';

const btnCancelStyle = {
  paddingRight: '1.5em',
  paddingLeft: '1.5em',
  paddingBottom: '1em',
  paddingTop: '1em',
  borderRadius: '8px',
};

const btnStyle = {
  padding: '1em',
  borderRadius: '8px',
};

function ButtonAction({
  warning,
  deleted,
  toggle,
  cancelTitle,
  draft,
  draftTitle,
  disabledDraft,
  confirm,
  confirmTitle,
  disabledConfirm,
  reset,
  hideConfirm,
  type,
  typeConfirm,
  resetTitle,
  withLoading,
  withLoadingDraft,
  withLoadingWarning,
  isLoading,
}) {
  return (
    <div style={{ margin: '20px 0px' }}>
      <div
        style={{
          lineHeight: '30px',
          display: 'flex',
          justifyContent: reset ? 'space-between' : 'flex-end',
          alignItems: 'center',
        }}
      >
        {reset && (
          <div>
            <p
              className="text-bold"
              style={{ cursor: 'pointer', margin: 0 }}
              role="presentation"
              onClick={reset}
              onKeyPress={reset}
            >
              {resetTitle ?? 'Reset'}
            </p>
          </div>
        )}
        <div>
          <Button
            variant="btn-cancel me-3"
            rounded
            title={cancelTitle ?? 'Cancel'}
            onClick={toggle}
            type={type ?? 'Button'}
            style={btnCancelStyle}
          />
          {draft && (
            <Button
              variant="btn-outline-primary me-3"
              rounded
              title={draftTitle ?? 'Draft'}
              onClick={draft}
              type="Button"
              disabled={disabledDraft || isLoading}
              style={btnStyle}
              withLoading={withLoadingDraft}
            />
          )}
          {!hideConfirm && (
            <Button
              variant={deleted ? 'btn-danger' : 'btn-primary'}
              rounded
              title={confirmTitle ?? 'Submit'}
              onClick={confirm}
              type={deleted ? 'Button' : typeConfirm ?? 'Submit'}
              disabled={disabledConfirm || isLoading}
              style={btnStyle}
              withLoading={withLoading}
            />
          )}
          {warning && (
            <Button
              variant="btn-warning"
              rounded
              title={confirmTitle ?? 'Simpan Data'}
              onClick={confirm}
              type="Button"
              disabled={disabledConfirm || isLoading}
              style={btnStyle}
              withLoading={withLoadingWarning}
            />
          )}
        </div>
      </div>
    </div>
  );
}

export default (ButtonAction);
