/* eslint-disable consistent-return */
/* eslint-disable no-param-reassign */
/* eslint-disable no-unused-vars */
/* eslint-disable no-use-before-define */
/* eslint-disable no-unused-expressions */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/prop-types */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable no-promise-executor-return */
/* eslint-disable jsx-a11y/label-has-associated-control */
import {
  React, useEffect, useRef, useState,
} from 'react';
import { useParams, useNavigate, Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Card } from 'react-bootstrap';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Typography from '@mui/material/Typography';
import MenuItem from '@mui/material/MenuItem';

import Select from '../../components/forms/Select';
import { toastSuccess } from '../../components/toast';
import Input from '../../components/forms/input';
import Button from '../../components/buttons/Button';
import ButtonAction from '../../components/buttons/ButtonAction';
import ButtonDetail from '../../components/table/button-detail';
import TableStatus from '../../components/table/status';
import Table from '../../components/table/table-dnd';
import ConfirmationModal from '../../components/modal/ConfirmModal';
import saveIcon from '../../assets/images/icons/SaveIcon.svg';

import AddDzikirItem from './DzikirItemForm';
import DetailDzikirItem from './DetailDzikirItem';

import {
  addValues,
  delValues,
  setEditedDzikirValue,
  setAddedDzikirValue,
  setAction,
} from '../../stores/actions/dzikir/formDzikir';
import DzikirService from '../../stores/actions/dzikir';

const styleActiveBreadcrumb = {
  fontWeight: 600,
  fontSize: '12px',
  lineHeight: '14px',
  color: 'rgba(79, 88, 103, 0.7)',
  textDecoration: 'none',
  fontFamily: 'BlueberrySans-Regular',
};

const styleInactiveBreadcrumb = {
  fontWeight: 500,
  fontSize: '12px',
  lineHeight: '14px',
  color: 'rgba(79, 88, 103, 0.7)',
  textDecoration: 'none',
  fontFamily: 'BlueberrySans-Regular',
};

const DzikirForm = ({ onSubmit }) => {
  const dispatch = useDispatch();
  const formikRef = useRef();
  const navigate = useNavigate();
  const [page, setPage] = useState(0);
  const [maxPage, setMaxPage] = useState(1);
  const [totalData, setTotalData] = useState(1);
  const [showModalClose, setShowModalClose] = useState(false);
  const [confirmModal, setConfirmModal] = useState(false);
  const [isAdded, setIsAdded] = useState(false);
  const [dzikirCategories, setDzikirCategories] = useState([]);
  const [dataDzikirForm, setDataDzikirForm] = useState({});
  const [dataDeleted, setDataDeleted] = useState([]);
  const [showListDzikirItem, setShowListDzikirItem] = useState(true);
  const [showAddDzikirItem, setShowAddDzikirItem] = useState(false);
  const [showDetailDzikirItem, setShowDetailDzikirItem] = useState(false);
  const [lastItemValue, setLastItemValue] = useState();
  const [indexItem, setIndexItem] = useState();
  const [indexCancel, setIndexCancel] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const { action, id } = useParams();
  const title = action === 'edit' ? 'Edit' : 'Tambah';
  const sleep = (ms) => new Promise((r) => setTimeout(r, ms));

  useEffect(() => {
    new Promise((resolve) => {
      const param = {
        page: 0,
        length: 999,
        categoryType: 'Dzikir',
      };
      dispatch(DzikirService.dataCategories(param, resolve));
    }).then((res) => {
      const categories = [];
      res?.data?.forEach((item) => {
        categories?.push({ value: item?.id, label: `${item?.categoryType} ${item?.categoryName}` });
      });
      setDzikirCategories(categories);
    });
  }, []);

  useEffect(() => {
    document.documentElement.scrollTop = 0;
    dispatch(setAction(action));
    new Promise((resolve) => {
      const param = {
        id,
      };
      if (param.id !== undefined) {
        dispatch(DzikirService.datatable(param, resolve));
      }
    }).then((res) => {
      if (res) {
        const dataDzikir = res?.data[0];
        if (dataDzikir?.dzikirDetails?.length) {
          dataDzikir?.dzikirDetails?.forEach((item) => {
            item.uniqueId = `dzikir-detail-${Math.random().toString(16).slice(2)}`;
          });
        }
        setDataDzikirForm(dataDzikir);
      }
    });
  }, []);

  // useEffect(() => {
  //   if (formikRef?.current?.values?.dzikir_items) {
  //     setMaxPage(Math.ceil(formikRef?.current?.values?.dzikir_items?.length / 2));
  //     setTotalData(formikRef?.current?.values?.dzikir_items?.length);
  //   }
  // }, [formikRef?.current?.values?.dzikir_items]);

  // useEffect(() => {
  //   if (page) {
  //     const from = (page * maxPage) - maxPage;
  //     const to = (page * maxPage);
  //     formikRef?.current?.setFieldValue(
  //       'dzikirDetails',
  //       formikRef?.current?.values?.dzikirDetails?.slice(from, to),
  //     );
  //   }
  // }, [page]);

  const initialValues = {
    dzikirName: dataDzikirForm?.dzikirName ?? '',
    dzikirCategory: dataDzikirForm?.dzikirCategory ?? '',
    dzikirDetails: dataDzikirForm?.dzikirDetails?.length
      ? dataDzikirForm?.dzikirDetails.sort((a, b) => a.orderDzikir - b.orderDzikir)
      : [],
  };

  const toggleCloseModal = () => {
    setShowModalClose(false);
  };

  const validation = Yup.object().shape({
    dzikirName: Yup.string().required('Nama dzikir wajib diisi').nullable(),
    dzikirCategory: Yup.string().required('Kategori dzikir wajib diisi').nullable(),
  });

  const isInProgress = (values) => {
    if (action === 'add') {
      if (
        values.dzikirName === ''
        && values.dzikirCategory === ''
        && values.dzikirDetails?.length === 0
      ) {
        setIsAdded(false);
        dispatch(setAddedDzikirValue(false));
      } else {
        setIsAdded(true);
        dispatch(setAddedDzikirValue(true));
      }
    } else if (action === 'edit') {
      if (
        initialValues.dzikirName !== values.dzikirName
        || initialValues.dzikirCategory !== values.dzikirCategory
        || JSON.stringify(initialValues?.dzikirDetails) !== JSON.stringify(values.dzikirDetails)
      ) {
        // setShowModalClose(true);
        setIsAdded(true);
        dispatch(setEditedDzikirValue(true));
      } else {
        // navigate('/doa');
        setIsAdded(false);
        dispatch(setEditedDzikirValue(false));
      }
    }
  };

  const addItemDzikir = (formik, idDetailDzikir) => {
    if (idDetailDzikir !== null && idDetailDzikir !== undefined) {
      const index = formik?.values?.dzikirDetails?.findIndex(
        (item) => item?.uniqueId === idDetailDzikir,
      );
      setIndexItem(index);
      setIndexCancel(index);
      setLastItemValue(formik?.values?.dzikirDetails);
    } else {
      setIndexItem(formik?.values?.dzikirDetails?.length);
      setIndexCancel(null);
    }
    setShowListDzikirItem(!showListDzikirItem);
    setShowAddDzikirItem(!showAddDzikirItem);
  };

  const detailItemDzikir = (formik, idDetailDzikir) => {
    const index = formik?.values?.dzikirDetails?.findIndex(
      (item) => item?.uniqueId === idDetailDzikir,
    );
    setIndexItem(index);
    setShowListDzikirItem(!showListDzikirItem);
    setShowDetailDzikirItem(!showDetailDzikirItem);
  };

  const showConfirmModal = (data, index) => {
    setConfirmModal(!confirmModal);
    setDataDeleted(data ?? null);
  };

  const handleDeleteDzikirItem = () => {
    const i = formikRef?.current?.values?.dzikirDetails?.findIndex(
      (item) => item?.uniqueId === dataDeleted?.uniqueId,
    );
    const dzikirItems = formikRef?.current?.values?.dzikirDetails;
    dzikirItems.splice(i, 1);
    formikRef?.current?.setFieldValue('dzikirDetails', dzikirItems);
    setConfirmModal(!confirmModal);
    toastSuccess('Data Bacaan Dzikir Berhasil Dihapus');
  };

  const movedRow = (isMoved, dataMoved) => {
    if (isMoved !== false) {
      formikRef?.current?.setFieldValue(
        'dzikirDetails',
        dataMoved,
      );
    }
  };

  const handleSubmit = async (values) => {
    setIsLoading(true);

    const callback = () => {
      dispatch(setAddedDzikirValue(false));
      dispatch(setEditedDzikirValue(false));
      navigate('/dzikir');
    };

    if (action === 'edit') {
      values.id = id;
      dispatch(DzikirService.put(values, callback));
      onSubmit(values);
      await sleep(1000);
      setIsLoading(false);
    } else {
      dispatch(DzikirService.post(values, callback));
      onSubmit(values);
      await sleep(1000);
      setIsLoading(false);
    }
  };

  const columns = [
    {
      Header: 'No',
      Cell: ({ row }) => {
        const idDzikirItem = row.index + 1;
        return idDzikirItem;
      },
    },
    {
      Header: 'Nama Bacaan Dzikir',
      accessor: 'doaName',
    },
    {
      Header: 'Pengulangan',
      accessor: 'repeat',
      Cell: ({ row }) => {
        const { original } = row;
        return original?.repeat ? `${original?.repeat}x` : '-';
      },
    },
    {
      Header: 'Konten',
      accessor: 'referensi',
      Cell: ({ row }) => {
        const { original } = row;
        const contents = [
          {
            label: 'Referensi',
            value: original?.referensi,
          },
          {
            label: 'Audio',
            value: original?.audioName,
          },
        ];
        return (
          <div className="d-flex align-items-center">
            {contents?.map((item) => (
              <div className="mx-1">
                <TableStatus
                  status={item?.label}
                  color={item?.value !== null && item?.value !== undefined ? 'primary' : 'inactive'}
                />
              </div>
            ))}
          </div>
        );
      },
    },
    {
      Header: '',
      accessor: 'actions',
      Cell: ({ row }) => {
        const original = row ? row?.original : [];
        return (
          <ButtonDetail>
            <MenuItem
              onClick={() => addItemDzikir(formikRef?.current, original?.uniqueId)}
            >
              <span style={{ fontSize: 14 }}>Edit</span>
            </MenuItem>
            <MenuItem
              onClick={() => detailItemDzikir(formikRef?.current, original?.uniqueId)}
            >
              <span style={{ fontSize: 14 }}>Detail</span>
            </MenuItem>
            <MenuItem onClick={() => showConfirmModal(original, original?.uniqueId)}>
              <span style={{ fontSize: 14 }}>Hapus</span>
            </MenuItem>
          </ButtonDetail>
        );
      },
    },
  ];

  return (
    <>
      <Formik
        innerRef={formikRef}
        initialValues={initialValues}
        enableReinitialize
        validationSchema={validation}
        onSubmit={handleSubmit}
      >
        {(props) => (
          <Form>
            {/** **************** */}
            {isInProgress(props?.values)}
            {/** **************** */}
            {showAddDzikirItem && (
              <AddDzikirItem
                formik={props}
                index={indexItem}
                indexCancel={indexCancel}
                lastItemValue={lastItemValue}
                toggle={() => addItemDzikir()}
              />
            )}
            {showDetailDzikirItem && (
              <DetailDzikirItem
                formik={props}
                index={indexItem}
                toggle={detailItemDzikir}
              />
            )}
            {showListDzikirItem && (
              <div className="content-container-side">
                <div className="d-flex">
                  <p className="content-title">Dzikir</p>
                </div>
                <div className="mb-4">
                  <Breadcrumbs aria-label="breadcrumb" separator="">
                    <Link
                      color="inherit"
                      to="/dzikir"
                      onClick={() => {
                        if (isAdded) {
                          setShowModalClose(true);
                        } else {
                          navigate('/dzikir');
                        }
                      }}
                      style={styleActiveBreadcrumb}
                    >
                      Dzikir
                    </Link>
                    <Typography style={styleInactiveBreadcrumb}>
                      {`${title} Dzikir`}
                    </Typography>
                  </Breadcrumbs>
                </div>
                <Card className="card-datatable">
                  <div className="d-flex">
                    <span
                      className="material-icons clickable"
                      onClick={() => {
                        if (isAdded) {
                          setShowModalClose(true);
                        } else {
                          navigate('/dzikir');
                        }
                      }}
                      onKeyPress={() => {
                        if (isAdded) {
                          setShowModalClose(true);
                        } else {
                          navigate('/dzikir');
                        }
                      }}
                      role="button"
                      tabIndex={0}
                    >
                      arrow_back
                    </span>
                    <p className="card-datatable-title ms-3">
                      {`${title} Dzikir`}
                    </p>
                  </div>
                  <Input
                    title="Nama Dzikir"
                    name="dzikirName"
                    type="text"
                    errors={props?.errors}
                    touched={props?.touched}
                    {...props?.getFieldProps('dzikirName')}
                  />
                  <Select
                    name="dzikirCategory"
                    type="text"
                    title="Kategori"
                    placeholder="Pilih Kategori Dzikir"
                    errors={props?.errors}
                    touched={props?.touched}
                    isSearchable={false}
                    options={dzikirCategories}
                    value={props?.values?.dzikirCategory}
                    onChange={(name, value) => {
                      props.setFieldValue('dzikirCategory', value);
                    }}
                    onBlur={props.setFieldTouched}
                  />
                  <div className="d-flex align-items-center mt-5">
                    <p className="card-datatable-title">Bacaan Dzikir</p>
                    <div className="ms-auto">
                      <Button
                        type="button"
                        title="Tambah"
                        leftIcon="add"
                        style={{
                          textAlign: 'center',
                          padding: '0.7em 1em',
                          borderRadius: '8px',
                          display: 'flex',
                          alignItems: 'center',
                        }}
                        onClick={() => addItemDzikir(props)}
                      />
                    </div>
                  </div>
                  <Table
                    data={props?.values?.dzikirDetails}
                    columns={columns}
                    movedRow={movedRow}
                    // pagination={{
                    //   page, totalData, maxPage, toggle: setPage,
                    // }}
                  />
                  <div className="my-5">
                    <ButtonAction
                      toggle={() => {
                        if (isAdded) {
                          setShowModalClose(true);
                        } else {
                          navigate('/dzikir');
                        }
                      }}
                      confirmTitle="Simpan Data"
                      cancelTitle="Batal"
                      withLoading={isLoading}
                    />
                  </div>
                </Card>
              </div>
            )}
          </Form>
        )}
      </Formik>
      <ConfirmationModal
        show={confirmModal}
        toggle={showConfirmModal}
        message={`Apakah Anda yakin akan menghapus data ${dataDeleted?.doaName}`}
        confirm={handleDeleteDzikirItem}
        confirmTitle="Hapus"
        cancelTitle="Batal"
        icon="delete"
      />
      <ConfirmationModal
        show={showModalClose}
        toggle={toggleCloseModal}
        message={`Apakah anda yakin ingin membatalkan proses ${
          action === 'add' ? 'tambah' : 'edit'
        } dzikir?`}
        confirmTitle="Yakin"
        inlineIcon={saveIcon}
        cancelTitle="Batal"
        confirm={() => {
          dispatch(setEditedDzikirValue(false));
          dispatch(setAddedDzikirValue(false));
          navigate('/dzikir');
        }}
      />
    </>
  );
};

export default DzikirForm;
