/* eslint-disable react/prop-types */
import { React, useState } from 'react';
import Menu from '@mui/material/Menu';
import Button from '../Button';

function ButtonAddMulti({ children, customClass }) {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div className={customClass}>
      <Button
        type="button"
        title="Tambah"
        leftIcon="add"
        style={{
          textAlign: 'center',
          padding: '0.7em 1em',
          borderRadius: '8px',
          display: 'flex',
          alignItems: 'center',
          marginLeft: '0.5rem',
        }}
        onClick={handleClick}
      />
      <Menu
        id="demo-customized-menu"
        MenuListProps={{
          'aria-labelledby': 'demo-customized-button',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        {children}
      </Menu>
    </div>
  );
}

export default ButtonAddMulti;
