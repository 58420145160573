/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/prop-types */
import { React, useState } from 'react';
import Menu from '@mui/material/Menu';
import Button from '@mui/material/Button';
import FilterIcon from '@mui/icons-material/FilterList';

function Filter({ children, customClass, isOnFilter }) {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const styledButton = {
    textTransform: 'capitalize',
    color: '#4F5867',
    background: '#fff',
    border: '1px solid #e4e4e4',
    borderRadius: '8px',
    boxShadow: 'none',
  };

  return (
    <div className={customClass}>
      <Button
        variant="outlined"
        aria-label="filter"
        aria-controls="long-menu"
        aria-haspopup="true"
        size="medium"
        sx={isOnFilter ? {
          border: '1px solid #2EC4B6 !important',
        } : ''}
        style={styledButton}
        onClick={handleClick}
        endIcon={<FilterIcon />}
      >
        Filter
      </Button>
      <Menu
        id="demo-customized-menu"
        MenuListProps={{
          'aria-labelledby': 'demo-customized-button',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        <span onClick={handleClose}>{children}</span>
      </Menu>
    </div>
  );
}

export default Filter;
