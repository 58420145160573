/* eslint-disable no-unused-vars */
/* eslint-disable default-param-last */
const initialState = {
  isEdited: false,
  isAdded: false,
};
const whatNewFormReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case 'EDITED':
      return {
        ...state,
        isEdited: payload,
      };
    case 'ADDED':
      return {
        ...state,
        isAdded: payload,
      };
    default:
      return {
        ...state,
      };
  }
};
export default whatNewFormReducer;
